import React from 'react';
//@ts-ignore
import { View, ViewStyle } from 'react-native';
import { Button, Icon, Text, useTheme } from '@ui-kitten/components';

interface CreateButtonProps {
    onPress: () => void;
    iconName: string;
    text: string;
    style?: ViewStyle;
}

const CreateButton: React.FC<CreateButtonProps> = ({ onPress, iconName, text, style }) => {
    return (
        <Button
            onPress={onPress}
            status="success"
            size="small"
            appearance="outline"
            accessoryLeft={(props) => <Icon {...props} name={iconName} />}
            style={[style, { borderRadius: 8, minWidth: 100 }]}
        >
            <Text numberOfLines={1} style={{ textAlign: 'center' }}>
                {text}
            </Text>
        </Button>
    );
};

export const CreateJobButton = ({ openNewJobModal, style }: { openNewJobModal: () => void, style?: ViewStyle }) => {
    return (
        <CreateButton
            onPress={openNewJobModal}
            iconName="briefcase-outline"
            text="Create Job"
            style={style}
        />
    );
};

export const CreateCaseButton = ({ openCreateCaseContainer, style }: { openCreateCaseContainer: () => void, style?: ViewStyle }) => {
    return (
        <CreateButton
            onPress={openCreateCaseContainer}
            iconName="file-text-outline"
            text="Create Case"
            style={style}
        />
    );
};

export const CreateHoldingButton = ({ openCreateHoldingContainer, style }: { openCreateHoldingContainer: () => void, style?: ViewStyle }) => {
    return (
        <CreateButton
            onPress={openCreateHoldingContainer}
            iconName="cube-outline"
            text="Create Holding"
            style={style}
        />
    );
};

export const CreateRouteButton = ({ openCreateRouteContainer, style }: { openCreateRouteContainer: () => void, style?: ViewStyle }) => {
    return (
        <CreateButton
            onPress={openCreateRouteContainer}
            iconName="pin-outline"
            text="Create Route"
            style={style}
        />
    );
};

export const CreateOrderButton = ({ openCreateOrderModal, style }: { openCreateOrderModal: () => void, style?: ViewStyle }) => {
    return (
        <CreateButton
            onPress={openCreateOrderModal}
            iconName="archive-outline"
            text="Create Order"
            style={style}
        />
    );
}; 