import React, { useState, useEffect, useReducer, useCallback, useRef, useMemo } from 'react';
//@ts-ignore
import { StyleSheet, Text as RNText, Platform, useWindowDimensions, View, AppState, TouchableOpacity, ScrollView } from "react-native";
//@ts-ignore
import { Button, Icon, Layout, Card, Input, Modal, Text, Spinner, RangeDatepicker, CheckBox, List } from "@ui-kitten/components";
import { SafeAreaView } from 'react-native-safe-area-context';
//@ts-ignore
import debounce from 'lodash.debounce';
import * as JobsController from '../../functions/job.controller'
import * as MembershipsController from '../../functions/membership.controller'
import * as UpdateController from '../../functions/update.controller'
import * as ClientController from '../../functions/client.controller'
import * as StorageController from '../../functions/storageController'
import * as ServiceController from '../../functions/service.controller'
import * as HoldingController from '../../functions/holding.controller'
import * as MqttService from '../../services/mqtt.service'
import * as CaseController from '../../functions/case.controller'
import * as RouteController from '../../functions/route.controller'
import * as CompanyController from '../../functions/company.controller'
import ErrorBoundary from '../ErrorBoundary.component';
import _log from '../../utils/log'
import CreateJobModal from '../job/modals/createJobModal.component';
import { CreateRouteContainer } from '../routes/createRouteContainer.component';
import { CreateHoldingContainer } from '../job//holding/createHolding.component';
import { HoldingDetailsModalContainer } from '../job/holding/holdingDetailModal';
import { HoldingDetailsContainer } from "../job/holding/holdingDetailsContainer.component"
import { Job, JOB_STATUS } from '../../models/Job.model';
import { Service } from '../../models/Service';
import { Company } from '../../models/Company.model';
import { Member } from '../../models/Member.model';
import { Case } from '../../models/Case.model';
import { Holding } from '../../models/Holding.model';
import { Client } from '../../models/Client.model';
import { Route } from '../../models/Route.model';
import { JobReportModal, FullJobReport } from '../dashboardComponents/jobReportModal.component';
import { Calendar, momentLocalizer, EventWrapperProps, Views, View as CalendarView } from 'react-big-calendar'
// import 'react-big-calendar/lib/css/react-big-calendar.css';
// import 'react-big-calendar/lib/sass/styles.scss';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import './calendarStyle.css'
import './customStyle.css'
import moment from 'moment'

enum JOB_STATUS_FILTER {
    ALL = "all",
    INCOMPLETE = "incomplete", //all
    REQUEST = "request",
    QUOTE = "quote",
    UNASSIGNED = "unassigned",
    ASSIGNED = "assigned",
    PENDING = "pending",
    COMPLETE = "complete",
    CANCELLED = "cancelled"
}

const localizer = momentLocalizer(moment)

interface ICalendarContainerProps {
    jobs: Job[];
    holdings: Holding[];
    routes: Route[];
    clients: Client[];
    members: Member[];
    services: Service[];
    selectedCompany: Company;
    jobStatusFilter: JOB_STATUS_FILTER
    onSelectJob: (job: Job) => void;
    enableFilters?: boolean;
    filteredClientIds?: string[];
    filteredDriverIds?: string[];
}

interface ICalendarJobEvent extends Event {
    job: Job,
    title: any
}

interface IDateRange {
    start: Date;
    end: Date;
}

type CalendarRange = Date[] | IDateRange;


const jobStatusFilters = {
    [JOB_STATUS_FILTER.ALL]: [
        JOB_STATUS.UNASSIGNED,
        JOB_STATUS.ASSIGNED,
        JOB_STATUS.PENDING,
        JOB_STATUS.COMPLETE,
        JOB_STATUS.CANCELLED,
        JOB_STATUS.REQUEST,
        JOB_STATUS.TRANSFERRED_OUT,
        JOB_STATUS.QUOTE,
        JOB_STATUS.REJECTED_BY_CLIENT,
        JOB_STATUS.REJECTED_BY_VENDOR,
    ],
    [JOB_STATUS_FILTER.INCOMPLETE]: [
        JOB_STATUS.UNASSIGNED,
        JOB_STATUS.ASSIGNED,
        JOB_STATUS.PENDING,
        JOB_STATUS.COMPLETE,
        JOB_STATUS.CANCELLED,
        JOB_STATUS.REQUEST,
        JOB_STATUS.TRANSFERRED_OUT,
        JOB_STATUS.QUOTE,
        JOB_STATUS.REJECTED_BY_CLIENT,
        JOB_STATUS.REJECTED_BY_VENDOR,
    ],
    [JOB_STATUS_FILTER.REQUEST]: [JOB_STATUS.REQUEST],
    [JOB_STATUS_FILTER.QUOTE]: [JOB_STATUS.QUOTE],
    [JOB_STATUS_FILTER.UNASSIGNED]: [JOB_STATUS.UNASSIGNED],
    [JOB_STATUS_FILTER.ASSIGNED]: [JOB_STATUS.ASSIGNED],
    [JOB_STATUS_FILTER.PENDING]: [JOB_STATUS.PENDING],
    [JOB_STATUS_FILTER.COMPLETE]: [JOB_STATUS.COMPLETE],
    [JOB_STATUS_FILTER.CANCELLED]: [JOB_STATUS.CANCELLED],
}

export const CalendarContainer = ({
    // jobs,
    holdings,
    routes,
    clients,
    members,
    services,
    selectedCompany,
    jobStatusFilter,
    onSelectJob,
    enableFilters = false,
    filteredClientIds = [],
    filteredDriverIds = [],
}: ICalendarContainerProps) => {
    // https://jquense.github.io/react-big-calendar/examples/index.html?path=/docs/props-full-prop-list--page
    const [calendarEvents, setCalendarEvents] = useState([] as any[]);
    const [fetchedJobs, setFetchedJobs] = useState([] as Job[])
    const fetchedJobsRef = useRef(fetchedJobs)
    const [selectedJob, setSelectedJob] = useState(null as Job | null);
    const [selectedHolding, setSelectedHolding] = useState(null as Holding | null);
    const [showJobDetailsModal, setShowJobDetailsModal] = useState(false);
    const [showHoldingDetailsModal, setShowHoldingDetailsModal] = useState(false);
    const [showNewJobModal, setShowNewJobModal] = useState(false)
    const selectedNewJobStartTimeRef = useRef<number | null>(null)

    const calendarRef = useRef(null as Calendar | null)
    const [currentView, setCurrentView] = useState(Views.MONTH as CalendarView);
    const [currentRange, setCurrentRange] = useState<IDateRange | null>(null);
    const [showLoading, setShowLoading] = useState(false);

    const onSetFetchedJobs = (jobs: Job[]) => {
        fetchedJobsRef.current = jobs
        setFetchedJobs(jobs)
    }

    const onSetSelectedJob = (job: Job) => {
        setSelectedJob(job)
        onSelectJob(job)
    }


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// CALENDAR EVENTS /////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    // calendar range change sets fetchedJobs -> handleJobFilter -> setCalendarEvents

    useEffect(() => {
        handleJobFilter()
    }, [jobStatusFilter])

    useEffect(() => {
        // selected company changed, load the date range
        if (selectedCompany) {
            setCalendarEvents([])
            onCalendarRangeChange(getRangeForView(currentView, new Date()));
        }
    }, [selectedCompany]);


    useEffect(() => {
        // selected company changed, load the date range
        if (fetchedJobs) {
            handleJobFilter()
        }
    }, [fetchedJobs]);

    useEffect(() => {
        // filters for clients and drivers
        handleJobFilter()
    }, [filteredClientIds, filteredDriverIds, enableFilters]);


    const handleJobFilter = useCallback(() => {
        const statusArray = jobStatusFilters[jobStatusFilter]
        let filteredJobs = fetchedJobsRef.current.filter((job: Job) => {
            return statusArray.includes(job.status);
        });

        if (enableFilters) {
            if (filteredClientIds.length > 0) {
                filteredJobs = filteredJobs.filter((job: Job) => {
                    return filteredClientIds.includes(job.client_id || "");
                });
            }
            if (filteredDriverIds.length > 0) {
                filteredJobs = filteredJobs.filter((job: Job) => {
                    return filteredDriverIds.includes(job.member_id || "");
                });
            }
        }
        setCalendarEvents(transformJobsToEvents(filteredJobs))
    }, [jobStatusFilter, calendarEvents, fetchedJobs, filteredClientIds, filteredDriverIds, enableFilters])


    useEffect(() => {
        // Trigger the range change when the component loads with the initial view
        if (currentView) {
            // Trigger range change on load
            onCalendarRangeChange(getRangeForView(currentView, new Date()));
        }
    }, [currentView]);

    const getRangeForView = (view: string, date: Date) => {
        switch (view) {
            case Views.MONTH:
                return {
                    start: moment(date).startOf('month').toDate(),
                    end: moment(date).endOf('month').toDate(),
                };
            case Views.WEEK:
                return {
                    start: moment(date).startOf('week').toDate(),
                    end: moment(date).endOf('week').toDate(),
                };
            case Views.DAY:
                return {
                    start: moment(date).startOf('day').toDate(),
                    end: moment(date).endOf('day').toDate(),
                };
            default:
                return {
                    start: moment(date).startOf('month').toDate(),
                    end: moment(date).endOf('month').toDate(),
                };
        }
    };

    const handleSelectSlot = useCallback(
        ({ start, end }: { start: Date, end: Date }) => {
            const scheduledStartTime = start.getTime()
            selectedNewJobStartTimeRef.current = start.getTime()
            setShowNewJobModal(true)
        },
        [selectedCompany]
    )


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// TRANSFORM JOBS TO EVENTS ////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const transformJobsToEvents = (jobs: Job[]) => {
        return jobs.map((job: Job) => {
            const j = new Job(job)
            const startTime = new Date(j.start_time)
            let endTime = new Date(j.start_time + (j.vendor_eta ?? 60) * 60 * 1000)
            if (j.pending_time && j.pending_time > 0) {
                endTime = new Date(j.pending_time)
            }
            // get client name
            const client = clients.find(c => c._id === j.client_id)
            return {
                title: `${client?.name} : ${j.getServicesNames()}`,
                start: startTime,
                end: endTime,
                job: j
            }
        })
    }





    const closeNewJobModal = () => {
        setShowNewJobModal(false)
        selectedNewJobStartTimeRef.current = null
    }

    const RenderMemoizedCreateJobModal = useMemo(() => {
        return (
            <Modal
                visible={showNewJobModal}
                backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
            >
                <CreateJobModal
                    fn_onCreateJob={closeNewJobModal}
                    fn_onClose={closeNewJobModal}
                    case_id={null}
                    scheduledStartTime={selectedNewJobStartTimeRef.current}
                />
            </Modal>
        )
    }, [showNewJobModal])






    const eventPropGetter = (event: ICalendarJobEvent) => {
        let borderColor = 'grey';

        const job = event.job
        let backgroundColor = 'green'
        if (job.status === JOB_STATUS.UNASSIGNED) {
            backgroundColor = 'red'
        } else if (job.status === JOB_STATUS.ASSIGNED) {
            backgroundColor = 'orange'
        } else if (job.status === JOB_STATUS.PENDING) {
            backgroundColor = 'blue'
        } else if (job.status === JOB_STATUS.COMPLETE) {
            backgroundColor = 'green'
        }
        else if (job.status === JOB_STATUS.CANCELLED) {
            backgroundColor = 'purple'
        }
        else if (job.status === JOB_STATUS.REQUEST) {
            backgroundColor = '#ff34fb'
        }
        else if (job.status === JOB_STATUS.TRANSFERRED_OUT) {
            backgroundColor = 'cyan'
        }

        return {
            style: {
                backgroundColor,
                color: 'white',
                borderColor,
                borderWidth: '2px',
                borderStyle: 'solid',
                textShadow: '1px 1px 1px rgba(0, 0, 0, 1)'
            },
        };
    };

    const titleAccessor = (event: ICalendarJobEvent) => {
        let job = event.job
        if (job.is_scheduled) {
            return (
                <View style={{ flexDirection: 'row' }}>
                    <Icon name='clock-outline' fill='white' width={20} height={20} />
                    <Text>
                        {event.title}
                    </Text>
                </View>
            )
        }
        return event.title
    }

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// CALENDAR RANGE CHANGE ///////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */


    const onCalendarRangeChange = async (range: CalendarRange) => {
        let startDate: string, endDate: string;
        setShowLoading(true)
        if (Array.isArray(range)) {
            // Weekly or multi-day view
            startDate = moment(range[0]).startOf('day').toISOString();
            endDate = moment(range[range.length - 1]).endOf('day').toISOString();
            setCurrentRange({ start: range[0], end: range[range.length - 1] })
        } else {
            // Monthly view
            startDate = moment(range.start).startOf('day').toISOString();
            endDate = moment(range.end).endOf('day').toISOString();
            setCurrentRange(range)
        }
        try {
            const company = selectedCompany;
            const statusArray = [
                JOB_STATUS.UNASSIGNED,
                JOB_STATUS.ASSIGNED,
                JOB_STATUS.PENDING,
                JOB_STATUS.COMPLETE,
                JOB_STATUS.CANCELLED,
                JOB_STATUS.REQUEST,
                JOB_STATUS.TRANSFERRED_OUT,
                JOB_STATUS.QUOTE,
                JOB_STATUS.REJECTED_BY_CLIENT,
                JOB_STATUS.REJECTED_BY_VENDOR,
            ]

            const jobs = await JobsController.getJobsByCompanyIdAndStatusBetweenDates(company._id, startDate, endDate, statusArray);
            onSetFetchedJobs(jobs)
            setShowLoading(false)
        } catch (error) {
            console.error("Error fetching jobs:", error);
            setShowLoading(false)
        }
    }



    return (
        <View style={{ flex: 1 }}>
            {showNewJobModal && RenderMemoizedCreateJobModal}
            {showLoading && <RenderLoadingSpinner />}
            {/* @ts-ignore */}
            <Calendar
                ref={calendarRef}
                eventPropGetter={eventPropGetter}
                titleAccessor={titleAccessor}
                localizer={localizer}
                events={calendarEvents}
                startAccessor="start"
                endAccessor="end"
                style={{ height: "100%" }}
                onSelectSlot={handleSelectSlot}
                selectable={true}
                onSelectEvent={(event: ICalendarJobEvent) => {
                    if (event.job) {
                        onSetSelectedJob(event.job)
                        // setShowJobDetailsModal(true)
                    }
                }}
                onView={(view: CalendarView) => {
                    setCurrentView(view);
                }}
                onRangeChange={onCalendarRangeChange}
            />
        </View>

    )
}

const RenderLoadingSpinner = () => {
    return (
        <View style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.5)',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 100
        }}>
            <Spinner size='giant' />
        </View>
    )
}