export enum VIEW_MODE {
    LIST = "LIST",
    MAP = "MAP",
    CALENDAR = "CALENDAR"
}/*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////// ENUMS
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/
export enum JOB_STATUS_FILTER {
    // ALL = "all",
    INCOMPLETE = "incomplete",//all
    REQUEST = "request",
    QUOTE = "quote",
    UNASSIGNED = "unassigned",
    ASSIGNED = "assigned",
    PENDING = "pending",
    COMPLETE = "complete",
    CANCELLED = "cancelled"
} export enum SEARCH_TYPE {
    JOB = "JOB",
    HOLDING = "HOLDING",
    CASE = "CASE"
}

