import React, { useState, useEffect, useRef, useReducer, useMemo, useCallback } from 'react';
//@ts-ignore
import { useWindowDimensions, StyleSheet, View, TouchableOpacity, ScrollView } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
//@ts-ignore
import { Divider, Icon, Layout, Text, TopNavigation, TopNavigationAction, Card, Button, Input, ButtonGroup, CheckBox, Modal, RangeDatepicker, Datepicker } from '@ui-kitten/components';
import moment from 'moment';
import * as StorageController from '../../functions/storageController'
import * as JobController from '../../functions/job.controller'
import * as InvoiceController from '../../functions/invoice.controller'
import * as UpdateController from '../../functions/update.controller'
import * as ServiceController from '../../functions/service.controller'
import ErrorBoundary from '../ErrorBoundary.component';

import { Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale, Title } from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";
import { JOB_STATUS, Job } from '../../models/Job.model';
import { Service } from '../../models/Service';
import { ReportDetailsCard } from '../job/details/reportDetailsCard.component';
import { InvoicedTotalCard } from '../job/details/jobInvoicedTotalDetailsCard.component';
import { DashboardMap } from './dashboardMap.component';
import { RenderSearchJobsByDateRange } from './searchArea.component';
import { Client } from '../../models/Client.model';
import { Member } from '../../models/Member.model';
import { DailySummary } from './DashboardScreen.component';
import { VendorProfile } from '../../models/VendorProfile.model';

interface DailyBreakdownProps {
    dailySummary: any[];
    clients: Client[];
    drivers: Member[];
    vendors: VendorProfile[];
    toggleDetails: (index: number) => void;
    detailsVisible: boolean[];
    onShowReportDetailsModal: (job: Job) => void;
    calculatePercentage: (partialValue: number, totalValue: number) => string;
    renderJobTime: (jobTime: number) => string;
    formatDateTime: (timestamp: number) => string;
}

export const DailyBreakdown: React.FC<DailyBreakdownProps> = ({
    dailySummary,
    clients,
    drivers,
    vendors,
    toggleDetails,
    detailsVisible,
    onShowReportDetailsModal,
    calculatePercentage,
    renderJobTime,
    formatDateTime,
}) => {
    console.log("🚀============== ~ file: dailyBreakdown.component.tsx:51 ~ vendors🚀==============", vendors)


    const RenderEtaInfo = ({ job, style }: { job: Job, style: any }) => {
        if (!job.vendor_eta) return <Text style={style}>--/--</Text>
        if (!job.start_time && !job.createdAt) return <Text style={style}>--/--</Text>

        const eta = job.vendor_eta // in minutes
        //@ts-ignore
        const startTime = job.start_time || new Date(job.createdAt).getTime() // unix timestamp
        const arrivalTime = job.arrived_time // unix timestamp

        if (job.status === JOB_STATUS.CANCELLED) {
            return (
                <View style={[style, { flexDirection: 'row', justifyContent: 'space-between' }]}>
                    <Text style={{ flex: 2 }}>{eta}/Cancelled</Text>
                    <Text category="p1" style={{ flex: 1, backgroundColor: 'purple' }}>Cancelled</Text>
                </View>
            )
        }

        if (!arrivalTime) {
            return (
                <View style={[style, { flexDirection: 'row', justifyContent: 'space-between' }]}>
                    <Text style={{ flex: 2 }}>{eta}/Not Recorded</Text>
                    <Text category="p1" style={{ flex: 1 }}>-- --</Text>
                </View>
            )
        }

        const diff = arrivalTime - startTime // in milliseconds
        const diffInMinutes = Math.floor(diff / 60000) // in minutes
        const etaArrivedMins = `${eta}/${diffInMinutes}Mins`
        const isLate = eta < diffInMinutes

        return (
            <View style={[style, { flexDirection: 'row', justifyContent: 'space-between' }]}>
                <Text style={{ flex: 2 }}>{etaArrivedMins}</Text>
                <Text style={{ flex: 1, backgroundColor: isLate ? 'red' : 'green' }}>{isLate ? 'Late' : 'On Time'}</Text>
            </View>
        )
    }
    return (
        <Layout style={{ flexDirection: 'column', justifyContent: 'space-between', padding: 10, borderWidth: 1, borderColor: '#ccc', borderRadius: 4, marginBottom: 10 }}>
            <Text category='h6'>Daily Breakdown</Text>
            <Layout style={{ flex: 1, flexDirection: 'column', justifyContent: 'space-between', padding: 10, borderWidth: 1, borderColor: '#ccc', borderRadius: 4, marginBottom: 10 }}>
                <Layout style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10, borderBottomWidth: 1, borderBottomColor: '#ccc' }}>
                    <Text category='s1' style={{ flex: 1, textAlign: 'center' }}>Date</Text>
                    <Text category='s1' style={{ flex: 1, textAlign: 'center' }}>Number Of Jobs</Text>
                    <Text category='s1' style={{ flex: 1, textAlign: 'center' }}>Average Job Arrival Time</Text>
                    <Text category='s1' style={{ flex: 1, textAlign: 'center' }}>Under 45 Mins</Text>
                    <Text category='s1' style={{ flex: 1, textAlign: 'center' }}>Under 60 Mins</Text>
                    <Text category='s1' style={{ flex: 1, textAlign: 'center' }}>Under 90 Mins</Text>
                    <Text category='s1' style={{ flex: 1, textAlign: 'center' }}>Callout Total</Text>
                </Layout>
                {dailySummary?.map((summary, index) => (
                    <Layout
                        key={index}
                        style={{
                            flexDirection: 'column',
                            borderWidth: 1,
                            borderColor: '#ccc',
                            borderRadius: 4,
                            backgroundColor: index % 2 === 0 ? 'rgba(100,200,100,0.3)' : 'rgba(100,200,200,0.3)',
                        }}
                    >
                        <TouchableOpacity onPress={() => toggleDetails(index)}>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingVertical: 10, borderBottomWidth: 1, borderBottomColor: '#ccc' }}>
                                <Icon style={{ position: 'absolute' }} name={detailsVisible[index] ? "arrow-ios-downward-outline" : "arrow-ios-forward-outline"} width={25} height={25} fill={"white"} />
                                <Text style={{ flex: 1, textAlign: 'center' }}>{summary.date.toLocaleString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</Text>
                                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <Text status='success' style={{ textAlign: 'right' }}>{summary.totalNumberOfCompletedJobs}</Text>
                                    {summary.numberOfCancelledOrTransferredJobs > 0 ? (
                                        <Text status='warning' style={{ marginLeft: 5 }}>
                                            ({summary.numberOfCancelledOrTransferredJobs})
                                        </Text>
                                    ) :
                                        <Text style={{ marginLeft: 5 }}>(0)</Text>
                                    }
                                </View>
                                <Text style={{ flex: 1, textAlign: 'center' }}>{renderJobTime(summary.averageJobArrivalTime)}</Text>
                                <Text style={{ flex: 1, textAlign: 'center' }}> {summary.jobsUnder45Mins} ({calculatePercentage(summary.jobsUnder45Mins, summary.totalNumberOfJobs)})</Text>
                                <Text style={{ flex: 1, textAlign: 'center' }}>{summary.jobsUnder60Mins} ({calculatePercentage(summary.jobsUnder60Mins, summary.totalNumberOfJobs)})</Text>
                                <Text style={{ flex: 1, textAlign: 'center' }}>{summary.jobsUnder90Mins} ({calculatePercentage(summary.jobsUnder90Mins, summary.totalNumberOfJobs)})</Text>
                                <Text style={{ flex: 1, textAlign: 'center' }}>${summary.totalCalloutFees.toFixed(2)}</Text>
                            </View>
                        </TouchableOpacity>
                        {detailsVisible[index] && (
                            <View style={{ flex: 1, flexDirection: 'row', gap: 10 }}>
                                <View style={{ flex: 1, flexDirection: 'row', gap: 10 }}>
                                    <View style={{ flexDirection: 'column' }}>
                                        <Text style={{ textAlign: 'left', borderBottomColor: 'white', borderBottomWidth: 1 }}>Average Job Arrival Time:</Text>
                                        <Text style={{ textAlign: 'left', borderBottomColor: 'white', borderBottomWidth: 1 }}>Average Job Complete Time:</Text>
                                        <Text style={{ textAlign: 'left', borderBottomColor: 'white', borderBottomWidth: 1 }}>Average Job Serivce Time:</Text>
                                        <Text style={{ textAlign: 'left', borderBottomColor: 'white', borderBottomWidth: 1 }}>Under 45 Mins:</Text>
                                        <Text style={{ textAlign: 'left', borderBottomColor: 'white', borderBottomWidth: 1 }}>Under 60 Mins:</Text>
                                        <Text style={{ textAlign: 'left', borderBottomColor: 'white', borderBottomWidth: 1 }}>Under 90 Mins:</Text>
                                    </View>
                                    <View style={{ flexDirection: 'column' }}>
                                        <Text style={{ textAlign: 'left', borderBottomColor: 'white', borderBottomWidth: 1 }}>{renderJobTime(summary.averageJobArrivalTime)}</Text>
                                        <Text style={{ textAlign: 'left', borderBottomColor: 'white', borderBottomWidth: 1 }}>{renderJobTime(summary.averageJobTime)}</Text>
                                        <Text style={{ textAlign: 'left', borderBottomColor: 'white', borderBottomWidth: 1 }}>{renderJobTime(summary.averageServiceTime)}</Text>
                                        <Text style={{ textAlign: 'left', borderBottomColor: 'white', borderBottomWidth: 1 }}>{summary.jobsUnder45Mins}</Text>
                                        <Text style={{ textAlign: 'left', borderBottomColor: 'white', borderBottomWidth: 1 }}>{summary.jobsUnder60Mins}</Text>
                                        <Text style={{ textAlign: 'left', borderBottomColor: 'white', borderBottomWidth: 1 }}>{summary.jobsUnder90Mins}</Text>
                                    </View>
                                </View>
                                <View style={{ flex: 4, flexDirection: 'column' }}>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', gap: 3 }}>
                                        <Text category='s1' style={{ flex: 1, textAlign: 'left' }}>Job ID:</Text>
                                        <Text category='s1' style={{ flex: 1, textAlign: 'left' }}>Client\Provider:</Text>
                                        <Text category='s1' style={{ flex: 1, textAlign: 'left' }}>ETA/Arrived:</Text>
                                        <Text category='s1' style={{ flex: 1, textAlign: 'left' }}>Created:</Text>
                                        <Text category='s1' style={{ flex: 1, textAlign: 'left' }}>Arrival Time:</Text>
                                        <Text category='s1' style={{ flex: 1, textAlign: 'left' }}>Complete Time:</Text>
                                    </View>
                                    {summary.jobs.map((job: Job, index: number) => {
                                        const client = clients?.find((c) => c._id === job.client_id || c.company_id === job.client_company_id);
                                        const vendor = vendors?.find((v) => v._id === job.vendor_profile_id || v.linkedVendorAccountId === job.vendor_company_id);
                                        const driver = drivers?.find((d) => d._id === job.member_id);
                                        // show vendor ID if the vendor_company_id is not the same as the company_id

                                        const showVendor = vendor?.company_id !== job.vendor_company_id;
                                        const showClient = client?.company_id !== job.client_company_id;
                                        return (
                                            <View key={job._id} style={{
                                                flexDirection: 'row',
                                                justifyContent: 'space-evenly',
                                                backgroundColor: index % 2 === 0 ? 'rgba(200,200,100,0.3)' : 'rgba(100,50,100,0.3)'
                                            }}>
                                                <View style={{ flex: 1, flexDirection: 'row' }}>
                                                    <Button size='tiny' appearance='outline' onPress={() => onShowReportDetailsModal(job)}><Icon name="eye-outline" width={15} height={15} /></Button>
                                                    <Text style={{ flex: 1, textAlign: 'left' }}>{job.friendly_id}</Text>
                                                </View>
                                                <Text style={{ flex: 1, textAlign: 'left' }}>{vendor?.name || ''} {client?.name || ''}</Text>
                                                <RenderEtaInfo job={job} style={{ flex: 1 }} />
                                                <Text style={{ flex: 1, textAlign: 'left' }}>{formatDateTime(job.start_time)}</Text>
                                                <Text style={{ flex: 1, textAlign: 'left' }}>{formatDateTime(job.arrived_time || 0)}</Text>
                                                <Text style={{ flex: 1, textAlign: 'left' }}>{formatDateTime(job.pending_time || 0)}</Text>
                                            </View>
                                        )
                                    })}
                                </View>
                            </View>
                        )}
                    </Layout>
                ))}
            </Layout>
        </Layout>
    );
};