export class Service {
    _id?: string;
    company_id: string;
    description: string;
    name: string;
    fields: ServiceFields;
    types: ServiceTypes;
    values: ServiceValues;
    options: ServiceOptions;
    other: Record<string, any>;
    tags: string[];
    invoiced: ServiceInvoiced;
    required: ServiceRequired;
    driver_editable: ServiceDriverEditable;
    createdAt: string;
    updatedAt: string;

    constructor(service: any = {}) {
        this._id = service._id || Math.random().toString(36).substring(7);
        this.company_id = service.company_id || "";
        this.description = service.description || "";
        this.name = service.name || "";
        this.fields = service.fields || {};
        this.types = service.types || {};
        this.values = service.values || {};
        this.options = service.options || {};
        this.other = service.other || {};
        this.tags = service.tags || [];
        this.invoiced = service.invoiced || {};
        this.required = service.required || {};
        this.driver_editable = service.driver_editable || {};
        this.createdAt = service.createdAt;
        this.updatedAt = service.updatedAt;
    }

    private capitalizeFirstLetter(field: string): string {
        return field.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    }

    addField(field: string) {
        const val = this.capitalizeFirstLetter(field);
        const fieldKey = field.replace(/[^a-zA-Z0-9]/g, "_").toLowerCase();

        this.fields[fieldKey] = val;
        this.values[fieldKey] = "";
    }

    removeField(field: string) {
        try {
            delete this.fields[field];
            delete this.values[field];
            delete this.types[field];
            delete this.options[field];
            delete this.invoiced[field];
            delete this.required[field];
            delete this.driver_editable[field];
            delete this.other[field];
        } catch (err) {
            console.log("🚀============== ~ file: Job.model.ts:1513 ~ Service ~ removeField ~ err🚀==============", err);
        }
    }

    getField(fieldName: string): ServiceField | null {
        if (!this.fields[fieldName]) {
            return null;
        }
        return ServiceField.fromLegacy(fieldName, this);
    }

    getFields(): ServiceField[] {
        return Object.keys(this.fields).map(fieldName =>
            ServiceField.fromLegacy(fieldName, this)
        );
    }

    addFieldFromServiceField(serviceField: ServiceField) {
        this.fields[serviceField.name] = serviceField.displayName;
        this.types[serviceField.name] = serviceField.type;
        this.values[serviceField.name] = serviceField.value;
        this.options[serviceField.name] = serviceField.options || [];
        this.required[serviceField.name] = serviceField.required;
        this.driver_editable[serviceField.name] = serviceField.editable;
        this.invoiced[serviceField.name] = serviceField.invoiced;
        if (serviceField.otherParams) {
            this.other[serviceField.name] = serviceField.otherParams;
        }
    }

    // Update a single field
    updateField(fieldName: string, updates: Partial<ServiceField>) {
        if (!this.fields[fieldName]) {
            return;
        }

        // Get current field state
        const field = this.getField(fieldName);
        if (!field) return;

        // Apply updates
        Object.assign(field, updates);

        // Update legacy format
        this.fields[fieldName] = field.displayName;
        this.types[fieldName] = field.type;
        this.values[fieldName] = field.value;
        this.options[fieldName] = field.options || [];
        this.required[fieldName] = field.required;
        this.driver_editable[fieldName] = field.editable;
        this.invoiced[fieldName] = field.invoiced;
    }

    // Update multiple fields at once
    updateFields(updates: { [fieldName: string]: Partial<ServiceField> }) {
        Object.entries(updates).forEach(([fieldName, fieldUpdates]) => {
            this.updateField(fieldName, fieldUpdates);
        });
    }

    // Set field value
    setFieldValue(fieldName: string, value: string | number | boolean | null) {
        if (this.fields[fieldName]) {
            this.values[fieldName] = value;
        }
    }

    // Helper method to set external parameters
    setFieldExternalParams(fieldName: string, params: ServiceFieldOtherParams) {
        if (this.fields[fieldName]) {
            this.other[fieldName] = {
                ...this.other[fieldName],
                ...params
            };
        }
    }
}


export class ServiceField {
    name: string;
    displayName: string;
    description?: string;
    type: SERVICE_FIELD_TYPE;
    value: string | number | boolean | null;
    options?: any[];
    required: boolean;
    editable: boolean;
    invoiced: boolean;
    otherParams?: ServiceFieldOtherParams;

    constructor(data: Partial<ServiceField> = {}) {
        this.name = data.name || '';
        this.displayName = data.displayName || '';
        this.description = data.description;
        this.type = data.type || SERVICE_FIELD_TYPE.INPUT;
        this.value = data.value ?? null;
        this.options = data.options || [];
        this.required = data.required || false;
        this.editable = data.editable || false;
        this.invoiced = data.invoiced || false;
        this.otherParams = data.otherParams;
    }

    // Create ServiceField from legacy format
    static fromLegacy(name: string, service: any): ServiceField {
        return new ServiceField({
            name,
            displayName: service.fields[name],
            type: service.types[name],
            value: service.values[name],
            options: service.options[name],
            required: service.required[name],
            editable: service.driver_editable[name],
            invoiced: service.invoiced[name],
            otherParams: service.other?.[name] || {}
        });
    }
}

export enum SERVICE_FIELD_TYPE {
    INPUT = "input",
    COST = "cost",
    DRIVER_REBATE = "driver_rebate",
    DRIVER_COMMISSION = "driver_commission",
    QUANTITY = "quantity",
    OPTIONS = "options",
    TOGGLE = "toggle",
    DATE = "date",
    TIME = "time",
    ADDRESS = "address",
    RADIO = "radio",
    IMAGE = "image"
}

export const ServiceTagsArr = [
    "Battery",
    "Battery EV",
    "Delivery",
    "Tow",
    "Tyre",
    "Lockout",
    "Fuel",
    "Winch",
    "Recovery",
    "Other",
    "Emergency"
];
// type ServiceFieldType = 'input' | 'cost' | 'driver_rebate' | 'driver_commission' | 'quantity' | 'options' | 'toggle' | 'date' | 'time' | 'address' | 'radio' | 'image';
export interface ServiceFields {
    [key: string]: string;
}

export interface ServiceTypes {
    [key: string]: SERVICE_FIELD_TYPE;
}

export interface ServiceValues {
    [key: string]: string | number | boolean | null;
}

export interface ServiceOptions {
    [key: string]: any[];
}

export interface ServiceInvoiced {
    [key: string]: boolean;
}

export interface ServiceRequired {
    [key: string]: boolean;
}

export interface ServiceDriverEditable {
    [key: string]: boolean;
}

export interface ServiceTags {
    [key: string]: string;
}


export const SERVICE_TAGS: Record<string, string> = {
    BATTERY: "Battery",
    BATTERY_EV: "Battery EV",
    DELIVERY: "Delivery",
    TOW: "Tow",
    TYRE: "Tyre",
    LOCKOUT: "Lockout",
    FUEL: "Fuel",
    WINCH: "Winch",
    RECOVERY: "Recovery",
    OTHER: "Other",
    EMERGENCY: "Emergency",
};

export const SERVICE_FIELD_TYPES: Record<string, SERVICE_FIELD_TYPE> = {
    input: SERVICE_FIELD_TYPE.INPUT,
    cost: SERVICE_FIELD_TYPE.COST,
    driver_rebate: SERVICE_FIELD_TYPE.DRIVER_REBATE,
    driver_commission: SERVICE_FIELD_TYPE.DRIVER_COMMISSION,
    quantity: SERVICE_FIELD_TYPE.QUANTITY,
    options: SERVICE_FIELD_TYPE.OPTIONS,
    toggle: SERVICE_FIELD_TYPE.TOGGLE,
    date: SERVICE_FIELD_TYPE.DATE,
    time: SERVICE_FIELD_TYPE.TIME,
    address: SERVICE_FIELD_TYPE.ADDRESS,
    radio: SERVICE_FIELD_TYPE.RADIO,
    image: SERVICE_FIELD_TYPE.IMAGE,
};

export interface ServiceFieldOtherParams {
    external_id?: string;        // For things like CIS_BATTDESC
    external_system?: string;    // e.g. "CIS"
    external_description?: string; // The description from the image
    validation?: {              // Optional validation rules
        type?: string;
        pattern?: string;
        options?: string[];
    };
}
