import React, { useState, useEffect } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { Button, Input, Text, Layout, Icon, useTheme, Modal, List, ListItem, Divider } from '@ui-kitten/components';
import { InventoryLocation, InventoryStock } from '../../models/Inventory.model';
import { JobAddress, JobLocation } from '../../models/Job.model';
import * as InventoryController from '../../functions/Inventory.controller';
import * as StorageController from '../../functions/storageController';
import Toast from 'react-native-toast-message';
import DMModal from '../common/Modal';
import { CompanySavedAddress, InventoryItem } from '../../models/Company.model';
import { IInventoryStockResponse } from '../../functions/Inventory.controller';
import { TextDetailPill } from '../common/TextDetailPill';
import AddLocationModal from './AddLocationModal.component';

interface LocationManagerProps {
    onClose: () => void;
}

interface IInventoryStockWithItems {
    _id?: string;
    company_id?: string;
    inventory_item_id?: string;
    quantity?: number;
    location_id?: string | null;
    createdAt?: string;
    updatedAt?: string;
    inventory_item?: InventoryItem;
}


export const LocationManager: React.FC<LocationManagerProps> = ({ onClose }) => {
    const [locations, setLocations] = useState<InventoryLocation[]>([]);
    const [loading, setLoading] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState<InventoryLocation | null>(null);
    const [selectedLocationStock, setSelectedLocationStocks] = useState<IInventoryStockWithItems[]>([]);
    const [selectedLocationItems, setSelectedLocationItems] = useState<InventoryItem[]>([]);
    const [loadingStock, setLoadingStock] = useState(false);
    const [deleteLocationModalVisible, setDeleteLocationModalVisible] = useState(false);
    const [locationToDelete, setLocationToDelete] = useState<InventoryLocation | null>(null);

    const theme = useTheme();



    useEffect(() => {
        fetchLocations();
    }, []);

    const fetchLocations = async () => {
        setLoading(true);
        const company = StorageController.getCurrentCompany();
        const fetchedLocations = await InventoryController.getAllInventoryLocationsByCompanyId(company._id);
        if (fetchedLocations) {
            setLocations(fetchedLocations);
        }
        setLoading(false);
    };

    const fetchLocationStock = async (locationId: string) => {
        setLoadingStock(true);
        const inventoryStockResponse = await InventoryController.getStockByLocation(locationId);

        if (inventoryStockResponse) {
            const stocks = inventoryStockResponse.stocks || [];
            const items = inventoryStockResponse.items || [];

            const inventoryStocksWithItems = stocks.map((stock) => {
                const matchingItem = items.find((item) => item._id === stock.inventory_item_id);
                return {
                    _id: stock._id,
                    company_id: stock.company_id,
                    inventory_item_id: stock.inventory_item_id,
                    quantity: stock.quantity,
                    location_id: stock.location_id,
                    createdAt: stock.createdAt,
                    updatedAt: stock.updatedAt,
                    inventory_item: matchingItem || new InventoryItem()
                } as IInventoryStockWithItems;
            });

            setSelectedLocationStocks(inventoryStocksWithItems);
        }

        setLoadingStock(false);
    };

    const openEditModal = (location: InventoryLocation) => {
        setSelectedLocation(location);
        setModalVisible(true);
    };

    const calculateTotalItems = (stocks: IInventoryStockWithItems[]): number => {
        return stocks.reduce((total, stock) => total + (stock.quantity || 0), 0);
    };

    const handleDeleteLocation = async (locationId: string) => {
        setDeleteLocationModalVisible(false);
        setLocationToDelete(null);

        try {
            const result = await InventoryController.deleteInventoryLocation(locationId);
            if (result) {
                Toast.show({
                    type: 'success',
                    text1: 'Location deleted successfully'
                });
                await fetchLocations();
                if (selectedLocation?._id === locationId) {
                    setSelectedLocation(null);
                    setSelectedLocationStocks([]);
                }
            } else {
                Toast.show({
                    type: 'error',
                    text1: 'Failed to delete location'
                });
            }
        } catch (error) {
            Toast.show({
                type: 'error',
                text1: 'Error deleting location',
                text2: 'Please try again'
            });
        }
    };

    const renderLocationItem = ({ item }: { item: InventoryLocation }) => (
        <ListItem
            title={item.name}
            description={`${item.description || 'No description'}\n${item.address ? new JobAddress(item.address).addressToString() : 'No address'}`}
            onPress={() => {
                setSelectedLocation(item);
                fetchLocationStock(item._id!);
            }}
            style={selectedLocation?._id === item._id ? { backgroundColor: theme['color-primary-100'] } : {}}
            accessoryRight={() => (
                <View style={{ flexDirection: 'row', gap: 10, alignItems: 'center' }}>
                    {selectedLocation?._id === item._id && (
                        <Text category="s1" appearance="hint">
                            Total Items: {calculateTotalItems(selectedLocationStock)}
                        </Text>
                    )}
                    <Button
                        size="small"
                        appearance="ghost"
                        status="info"
                        accessoryLeft={<Icon name="edit-outline" />}
                        onPress={() => openEditModal(item)}
                    >
                        Edit
                    </Button>
                    <Button
                        size="small"
                        appearance="ghost"
                        status="danger"
                        accessoryLeft={<Icon name="trash-2-outline" />}
                        onPress={() => {
                            setLocationToDelete(item);
                            setDeleteLocationModalVisible(true);
                        }}
                    >
                        Delete
                    </Button>
                </View>
            )}
        />
    );

    const renderStockItem = ({ item }: { item: IInventoryStockWithItems }) => (
        <ListItem
            title={item.inventory_item?.name || 'Unknown Item'}
            description={`Quantity: ${item.quantity || 0}`}
            accessoryLeft={<Icon name="cube-outline" />}
        />
    );

    const rightPanelContent = selectedLocation ? (
        <>
            <View style={styles.subHeaderContainer}>
                <Text category="h6">
                    Items at {selectedLocation.name}
                </Text>
                <TextDetailPill
                    label="Total Items"
                    value={calculateTotalItems(selectedLocationStock).toString()}
                    valueThemeColor={theme['color-primary-500']}
                />
            </View>
            {loadingStock ? (
                <Text>Loading stock...</Text>
            ) : selectedLocationStock.length > 0 ? (
                <List
                    data={selectedLocationStock}
                    renderItem={renderStockItem}
                    ItemSeparatorComponent={Divider}
                    style={styles.list}
                />
            ) : (
                <Text appearance="hint">No items in this location</Text>
            )}
        </>
    ) : (
        <Text appearance="hint">Select a location to view items</Text>
    );

    return (
        <Layout style={{ flex: 1 }}>
            <View style={styles.header}>
                <View style={{ flexDirection: 'row', gap: 40 }}>
                    <Text category="h5">Location Management</Text>
                    <Button
                        onPress={() => setModalVisible(true)}
                        size="small"
                        accessoryLeft={<Icon name="plus-outline" />}
                    >
                        Add Location
                    </Button>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <Button
                        appearance="ghost"
                        status="danger"
                        onPress={onClose}
                        accessoryLeft={<Icon name="close-outline" />}
                    />
                </View>
            </View>

            <View style={{ flex: 1, flexDirection: 'row' }}>
                <View style={{ flex: 1, marginRight: 10 }}>
                    {loading ? (
                        <Text>Loading...</Text>
                    ) : (
                        <List
                            data={locations}
                            renderItem={renderLocationItem}
                            ItemSeparatorComponent={Divider}
                            style={styles.list}
                        />
                    )}
                </View>

                <View style={{ flex: 1, marginLeft: 10 }}>
                    {rightPanelContent}
                </View>
            </View>

            <AddLocationModal
                visible={modalVisible}
                onClose={() => {
                    setModalVisible(false);
                    setSelectedLocation(null);
                }}
                onSuccess={fetchLocations}
                locationToEdit={selectedLocation}
            />

            <DMModal
                visible={deleteLocationModalVisible}
                onClose={() => {
                    setDeleteLocationModalVisible(false);
                    setLocationToDelete(null);
                }}
            >
                <Layout style={styles.deleteModalContainer}>
                    <Text category="h6" style={styles.deleteModalHeader}>
                        Delete Location
                    </Text>
                    <Text category="s1" status="danger" style={styles.deleteModalWarning}>
                        Warning: This action cannot be undone!
                    </Text>
                    <Text style={styles.deleteModalText}>
                        Are you sure you want to delete the location "{locationToDelete?.name || ""}"?
                    </Text>
                    <View style={styles.deleteModalButtons}>
                        <Button
                            status="danger"
                            onPress={() => handleDeleteLocation(locationToDelete?._id!)}
                        >
                            Delete Location
                        </Button>
                        <Button
                            appearance="ghost"
                            status="basic"
                            onPress={() => {
                                setDeleteLocationModalVisible(false);
                                setLocationToDelete(null);
                            }}
                        >
                            Cancel
                        </Button>
                    </View>
                </Layout>
            </DMModal>

            <View style={styles.footer}>
                <Button appearance="ghost" status="danger" onPress={onClose}>
                    Close
                </Button>
            </View>
        </Layout>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 16,
        height: '80%',
    },
    header: {
        marginBottom: 16,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    subHeader: {
        marginBottom: 16,
    },
    formContainer: {
        marginBottom: 16,
    },
    input: {
        marginBottom: 8,
    },
    divider: {
        marginVertical: 16,
    },
    list: {
        flex: 1,
        marginBottom: 16,
    },
    footer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    modalContainer: {
        padding: 20,
        borderRadius: 8,
        minWidth: 400,
        maxWidth: '80%',
    },
    modalHeader: {
        marginBottom: 16,
    },
    modalButtons: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 16,
    },
    subHeaderContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 16,
    },
    deleteModalContainer: {
        padding: 20,
        borderRadius: 8,
        minWidth: 400,
        maxWidth: '80%',
    },
    deleteModalHeader: {
        marginBottom: 16,
        textAlign: 'center',
    },
    deleteModalWarning: {
        marginBottom: 16,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    deleteModalText: {
        marginBottom: 24,
        textAlign: 'center',
    },
    deleteModalButtons: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        gap: 16,
    },
}); 