import React, { useState, useEffect, useRef, useReducer, useCallback, useMemo, createContext, useContext } from 'react';
//@ts-ignore
import { useWindowDimensions, StyleSheet, View, TouchableOpacity } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
//@ts-ignore
import { Divider, Icon, Layout, Text, TopNavigation, TopNavigationAction, Card, Button, Input, ButtonGroup, CheckBox, Modal, RangeDatepicker, Datepicker, useTheme, IndexPath, Select, SelectItem, Spinner, Toggle } from '@ui-kitten/components';
import 'react-data-grid/lib/styles.css';
import moment from 'moment';
//@ts-ignore
import DataGrid, { Row, RowRendererProps, SelectColumn, textEditor, useFocusRef, Too, Column, DataGridProps } from 'react-data-grid';
import * as StorageController from '../../functions/storageController'
import * as JobController from '../../functions/job.controller'
import * as InvoiceController from '../../functions/invoice.controller'
import * as ClientController from '../../functions/client.controller'
import * as UpdateController from '../../functions/update.controller'
import * as ServiceController from '../../functions/service.controller'
import * as GoogleController from '../../functions/google.controller'
import ErrorBoundary from '../ErrorBoundary.component';
import { ScrollView } from 'react-native-gesture-handler';
//@ts-ignore
import { CSVLink } from "react-csv";
import { ReportDetailsCard } from '../job/details/reportDetailsCard.component';
import { LogDetailsCard } from '../job/details/logDetailsCard.component';
import { Job, JobAddress, JobCustomerDetails, JobDetails, JobLocation, JobReport, JobTowingDetails, JobVehicleDetails } from '../../models/Job.model'
import { Service } from '../../models/Service';
import { LineItem } from '../../models/LineItem.model';
import { Case } from '../../models/Case.model';
import { Holding } from '../../models/Holding.model';
import { Invoice, InvoiceItem } from '../../models/Invoice.model';
import * as Utils from './invoiceUtils';
import * as Formatters from './invoiceFormatters';
import Tooltip from '../modals/Tooltip';
import { Member } from '../../models/Member.model';
import { Client } from '../../models/Client.model';
import { Company } from '../../models/Company.model';
import { FullJobReport } from '../dashboardComponents/jobReportModal.component';
import { JobInvoiceRow } from './invoicingTypes';
import { VendorProfile } from '../../models/VendorProfile.model';

export enum FILTER_TYPE {
    JOB = 'jobs',
    HOLDING = 'holdings',
}
interface FiltersProps {
    filterType: FILTER_TYPE;
    clients: React.MutableRefObject<Client[]>;
    drivers: React.MutableRefObject<Member[]>;
    services: React.MutableRefObject<Service[]>;
    statuses: string[];
    clientsChecked: Client[];
    driversChecked: Member[];
    servicesChecked: Service[];
    statusesChecked: string[];
    vendorProfilesChecked: VendorProfile[];
    setClientsChecked: (clients: Client[]) => void;
    setDriversChecked: (drivers: Member[]) => void;
    setServicesChecked: (services: Service[]) => void;
    setStatusesChecked: (statuses: string[]) => void;
    setVendorProfilesChecked: (vendorProfiles: VendorProfile[]) => void;
    clientsCheckedRef: React.MutableRefObject<Client[]>;
    driversCheckedRef: React.MutableRefObject<Member[]>;
    servicesCheckedRef: React.MutableRefObject<Service[]>;
    statusesCheckedRef: React.MutableRefObject<string[]>;
    vendorProfilesCheckedRef: React.MutableRefObject<VendorProfile[]>;
    jobs: Job[];
    filteredJobs: Job[];
    holdings: Holding[];
    filteredHoldings: Holding[];
    forceUpdate: () => void;
    filterJobsByCriteria: () => void;
    filterHoldingsByCriteria: () => void;
    vendorProfiles: React.MutableRefObject<VendorProfile[]>;
    dispatchableChecked: boolean;
    setDispatchableChecked: (checked: boolean) => void;
    dispatchableCheckedRef: React.MutableRefObject<boolean>;
    dispatchableOnlyChecked: boolean;
    setDispatchableOnlyChecked: (checked: boolean) => void;
    dispatchableOnlyCheckedRef: React.MutableRefObject<boolean>;
}

export const Filters: React.FC<FiltersProps> = ({
    filterType,
    clients,
    drivers,
    services,
    statuses,
    clientsChecked,
    driversChecked,
    servicesChecked,
    statusesChecked,
    vendorProfilesChecked,
    setClientsChecked,
    setDriversChecked,
    setServicesChecked,
    setStatusesChecked,
    setVendorProfilesChecked,
    clientsCheckedRef,
    driversCheckedRef,
    servicesCheckedRef,
    statusesCheckedRef,
    vendorProfilesCheckedRef,
    jobs,
    filteredJobs,
    holdings,
    filteredHoldings,
    forceUpdate,
    filterJobsByCriteria,
    filterHoldingsByCriteria,
    vendorProfiles,
    dispatchableChecked,
    setDispatchableChecked,
    dispatchableCheckedRef,
    dispatchableOnlyChecked,
    setDispatchableOnlyChecked,
    dispatchableOnlyCheckedRef,
}) => {

    const [_drivers, setDrivers] = useState(drivers.current || StorageController.getAppState().drivers);

    useEffect(() => {
        setDrivers(drivers.current || StorageController.getAppState().drivers);
    }, [drivers.current]);


    const allServicesCheckboxesData = useMemo(() => {
        //get all the services used in the jobs
        let servicesArr = [] as Service[]
        const services = jobs?.map(job => job.details?.selected_services).flat()
        // console.log("🚀============== ~ file: Dashboard.component.js:824 ~ allServicesCheckboxesData ~ services🚀==============", services)
        services.forEach(service => {
            if (!servicesArr.find(s => s._id === service._id)) {
                servicesArr.push(service)
            }
        })
        // console.log("🚀============== ~ file: Dashboard.component.js:823 ~ allServicesCheckboxesData ~ servicesArr🚀==============", servicesArr)
        // log all servicenames
        // console.log(servicesArr.map(s => s.name))
        return servicesArr
    }, [jobs]);

    const serviceJobCount = useMemo(() => {
        const count = {} as any;
        jobs?.forEach(job => {
            job.details.selected_services.forEach(service => {

                if (count[service._id as string]) {
                    count[service._id as string] += 1;
                } else {
                    count[service._id as string] = 1;
                }
            });
        });
        return count;
    }, [jobs]);

    const serviceJobCountRemaining = useMemo(() => {
        const count = {} as any;
        filteredJobs?.forEach(job => {
            job.details?.selected_services?.forEach(service => {
                if (count[service._id as string]) {
                    count[service._id as string] += 1;
                } else {
                    count[service._id as string] = 1;
                }
            });
        });
        return count;
    }, [filteredJobs]);

    const serviceHoldingCount = useMemo(() => {
        const count = {} as any;
        holdings?.forEach(holding => {
            holding.details?.selected_services.forEach(service => {
                if (count[service._id as string]) {
                    count[service._id as string] += 1;
                } else {
                    count[service._id as string] = 1;
                }
            });
        });
        return count;
    }, [holdings]);

    const serviceHoldingCountRemaining = useMemo(() => {
        const count = {} as any;
        filteredHoldings?.forEach(holding => {
            holding.details?.selected_services.forEach(service => {
                if (count[service._id as string]) {
                    count[service._id as string] += 1;
                } else {
                    count[service._id as string] = 1;
                }
            });
        });
        return count;
    }, [filteredHoldings]);

    const driversJobsCount = useMemo(() => {
        const count = {} as any;
        jobs?.forEach(job => {
            const memberId = job.member_id as any;
            if (!memberId) return;
            if (count[memberId]) {
                count[memberId] += 1;
            } else {
                count[memberId] = 1;
            }
        });
        return count;
    }, [jobs]);

    const driversJobsCountRemaining = useMemo(() => {
        const count = {} as any;
        filteredJobs?.forEach(job => {
            const memberId = job.member_id as any;
            if (!memberId) return;
            if (count[memberId]) {
                count[memberId] += 1;
            } else {
                count[memberId] = 1;
            }
        });
        return count;
    }, [filteredJobs]);

    const clientsJobsCount = useMemo(() => {
        const count = {} as any;
        jobs?.forEach(job => {
            const clientId = job.client_id as any;
            if (!clientId) return;
            if (count[clientId]) {
                count[clientId] += 1;
            } else {
                count[clientId] = 1;
            }
        });
        return count;
    }, [jobs] as any);

    const clientsJobsCountRemaining = useMemo(() => {
        const count = {} as any;
        filteredJobs?.forEach(job => {
            const clientId = job.client_id as any;
            if (!clientId) return;
            if (count[clientId]) {
                count[clientId] += 1;
            } else {
                count[clientId] = 1;
            }
        });
        return count;
    }, [filteredJobs] as any);

    const clientsHoldingCount = useMemo(() => {
        const count = {} as any;
        holdings?.forEach(holding => {
            const clientId = holding.client_id as any;
            if (!clientId) return;
            if (count[clientId]) {
                count[clientId] += 1;
            } else {
                count[clientId] = 1;
            }
        });
        return count;
    }, [holdings] as any);

    const clientsHoldingCountRemaining = useMemo(() => {
        const count = {} as any;
        filteredHoldings?.forEach(holding => {
            const clientId = holding.client_id as any;
            if (!clientId) return;
            if (count[clientId]) {
                count[clientId] += 1;
            } else {
                count[clientId] = 1;
            }
        });
        return count;
    }, [filteredHoldings] as any);

    const statusJobsCount = useMemo(() => {
        const count = {} as any;
        jobs?.forEach(job => {
            const status = job.status;
            if (!status) return;
            if (count[status]) {
                count[status] += 1;
            } else {
                count[status] = 1;
            }
        });
        return count;
    }, [jobs] as any);

    const statusJobsCountRemaining = useMemo(() => {
        const count = {} as any;
        filteredJobs?.forEach(job => {
            const status = job.status;
            if (!status) return;
            if (count[status]) {
                count[status] += 1;
            } else {
                count[status] = 1;
            }
        });
        return count;
    }, [filteredJobs] as any);

    const vendorProfilesJobCount = useMemo(() => {
        const count = {} as any;
        jobs?.forEach(job => {
            const vendorProfileId = job.vendor_profile_id as any;
            if (!vendorProfileId) return;
            if (count[vendorProfileId]) {
                count[vendorProfileId] += 1;
            } else {
                count[vendorProfileId] = 1;
            }
        });
        return count;
    }, [jobs]);

    const vendorProfilesJobCountRemaining = useMemo(() => {
        const count = {} as any;
        filteredJobs?.forEach(job => {
            const vendorProfileId = job.vendor_profile_id as any;
            if (!vendorProfileId) return;
            if (count[vendorProfileId]) {
                count[vendorProfileId] += 1;
            } else {
                count[vendorProfileId] = 1;
            }
        });
        return count;
    }, [filteredJobs]);


    const dispatchableJobsCount = useMemo(() => {
        return jobs?.filter(job => !job.dispatchable).length || 0;
    }, [jobs]);


    const dispatchableJobsCountRemaining = useMemo(() => {
        return filteredJobs?.filter(job => !job.dispatchable).length || 0;
    }, [filteredJobs]);

    const dispatchableOnlyJobsCount = useMemo(() => {
        return jobs?.filter(job => job.dispatchable).length || 0;
    }, [jobs]);

    const dispatchableOnlyJobsCountRemaining = useMemo(() => {
        return filteredJobs?.filter(job => job.dispatchable).length || 0;
    }, [filteredJobs]);

    const onClientCheckedChange = (client: Client, nextChecked: boolean) => {
        const nextClients = StorageController.getAppState().clients;

        // change clients checked state client.checked = nextChecked;
        // setClients(nextClients.filter(c => c._id == client._id ? c.checked = nextChecked : c.checked));
        if (nextChecked) {
            // add to checked clients
            clientsCheckedRef.current = [...clientsCheckedRef.current, client];
        } else {
            // remove from checked clients
            clientsCheckedRef.current = clientsCheckedRef.current.filter(c => c._id !== client._id);
        }
        forceUpdate();
        if (filterType === FILTER_TYPE.JOB) {
            filterJobsByCriteria();
        } else {
            filterHoldingsByCriteria();
        }
    }



    const onDriverCheckedChange = (driver: Member, nextChecked: boolean) => {
        const nextDrivers = StorageController.getAppState().drivers;
        // change drivers checked state driver.checked = nextChecked;
        // setDrivers(nextDrivers.filter(d => d._id == driver._id ? d.checked = nextChecked : d.checked));
        if (nextChecked) {
            // add to checked drivers
            driversCheckedRef.current = [...driversCheckedRef.current, driver];
        } else {
            // remove from checked drivers
            driversCheckedRef.current = driversCheckedRef.current.filter(d => d._id !== driver._id);
        }
        forceUpdate();
        if (filterType === FILTER_TYPE.JOB) {
            filterJobsByCriteria();
        } else {
            filterHoldingsByCriteria();
        }
    }


    const onServiceCheckedChange = (service: Service, nextChecked: boolean) => {
        const nextServices = StorageController.getAppState().services;
        // change services checked state service.checked = nextChecked;
        // setServices(nextServices.filter(s => s._id == service._id ? s.checked = nextChecked : s.checked));
        const serviceExists = servicesChecked.find(s => s._id === service._id);
        if (nextChecked && !serviceExists) {
            // add to checked services
            // setServicesChecked([...servicesChecked, service]);
            servicesCheckedRef.current = [...servicesCheckedRef.current, service];
        } else {
            // remove from checked services
            // setServicesChecked(servicesChecked.filter(s => s._id !== service._id));
            servicesCheckedRef.current = servicesCheckedRef.current.filter(s => s._id !== service._id);
        }
        forceUpdate();
        if (filterType === FILTER_TYPE.JOB) {
            filterJobsByCriteria();
        } else {
            filterHoldingsByCriteria();
        }
    }

    const onStatusCheckedChange = (status: any, nextChecked: boolean) => {
        try {
            status = status.toLowerCase();
            const statusExists = statusesChecked.find(s => s === status);

            // if it exists and nextChecked is false, remove it from statusesChecked
            if (statusExists && !nextChecked) {
                setStatusesChecked(statusesChecked.filter(s => s !== status));
                statusesCheckedRef.current = statusesCheckedRef.current.filter(s => s !== status);
            }
            // if it doesn't exist and nextChecked is true, add it to statusesChecked
            if (!statusExists && nextChecked) {
                setStatusesChecked([...statusesChecked, status]);
                statusesCheckedRef.current = [...statusesCheckedRef.current, status];
            }
            forceUpdate();
            if (filterType === FILTER_TYPE.JOB) {
                filterJobsByCriteria();
            } else {
                filterHoldingsByCriteria();
            }
        }
        catch (e) {
            console.log("Error onStatusCheckedChange: ", e);
        }
    }

    const onVendorProfileCheckedChange = (vendorProfile: VendorProfile, nextChecked: boolean) => {
        if (nextChecked) {
            vendorProfilesCheckedRef.current = [...vendorProfilesCheckedRef.current, vendorProfile];
        } else {
            vendorProfilesCheckedRef.current = vendorProfilesCheckedRef.current.filter(vp => vp._id !== vendorProfile._id);
        }
        forceUpdate();
        if (filterType === FILTER_TYPE.JOB) {
            filterJobsByCriteria();
        } else {
            filterHoldingsByCriteria();
        }
    }

    const onDispatchableCheckedChange = (nextChecked: boolean) => {
        dispatchableCheckedRef.current = nextChecked;
        setDispatchableChecked(nextChecked);
        forceUpdate();
        if (filterType === FILTER_TYPE.JOB) {
            filterJobsByCriteria();
        } else {
            filterHoldingsByCriteria();
        }
    }

    const onDispatchableOnlyCheckedChange = (nextChecked: boolean) => {
        dispatchableOnlyCheckedRef.current = nextChecked;
        setDispatchableOnlyChecked(nextChecked);
        forceUpdate();
        if (filterType === FILTER_TYPE.JOB) {
            filterJobsByCriteria();
        } else {
            filterHoldingsByCriteria();
        }
    }

    const RenderClientsList = () => (
        <Card
            disabled
            status='success'
            header={() => <Text category='h6'>Clients</Text>}
        >
            <View style={{ margin: -15 }}>
                <Text>Clients</Text>
                {clients.current?.map((client: Client, index: number) => {
                    const clientId = client._id;
                    if (!clientId) return null;
                    return (
                        <View key={client._id} style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <CheckBox style={{ flex: 4 }} checked={
                                clientsCheckedRef.current.find(c => c._id === client._id) ? true : false
                            } onChange={(nextChecked: boolean) => onClientCheckedChange(client, nextChecked)}>
                                <Text category='s2' numberOfLines={1}>
                                    {client.name}
                                </Text>
                            </CheckBox>
                            <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                {filterType == FILTER_TYPE.JOB && filteredJobs && <Text style={{ textAlign: 'right' }}>({clientsJobsCountRemaining[clientId] || 0})</Text>}
                                <Text style={{ textAlign: 'right' }}>/</Text>
                                {filterType == FILTER_TYPE.JOB && <Text status="success" style={{ textAlign: 'right' }}>({clientsJobsCount[clientId] || 0})</Text>}
                                {filterType == FILTER_TYPE.HOLDING && <Text style={{ textAlign: 'right' }}>({clientsHoldingCount[clientId] || 0})</Text>}
                            </View>
                        </View>
                    )
                })}
            </View>
        </Card>
    )

    const RenderDriversList = () => (
        <Card
            disabled
            status='warning'
            header={() => <Text category='h6'>Drivers</Text>}
        >
            <View style={{ margin: -15 }}>
                {_drivers?.map((driver, index) => {
                    if (driver.deleted) return null;
                    return (
                        <View key={driver._id} style={{ flexDirection: 'row', justifyContent: 'space-between' }}>

                            <CheckBox style={{ flex: 4 }} checked={
                                driversCheckedRef.current.find(d => d._id === driver._id) ? true : false
                            } onChange={(nextChecked: boolean) => onDriverCheckedChange(driver, nextChecked)}>
                                <Text category='s2' numberOfLines={1}>
                                    {driver.name}
                                </Text>
                            </CheckBox>
                            <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                {filteredJobs && <Text style={{ textAlign: 'right' }}>({driversJobsCountRemaining[driver._id] || 0})</Text>}
                                <Text style={{ textAlign: 'right' }}>/</Text>
                                <Text status="success" style={{ textAlign: 'right' }}>({driversJobsCount[driver._id] || 0})</Text>
                            </View>
                        </View>
                    )
                })}
            </View>
        </Card>
    )

    const RenderAllServiceCheckboxes = () => {
        if (!allServicesCheckboxesData) return <Text>None found</Text>
        const groups = {} as any;
        const keywords = {
            "Battery": ["battery", "jumpstart", "jump-start", "jump start", "jump", "start"],
            "Tyre": ["tyre", "tire"],
            "Tow": ["tow", "towing"],
            "Lockout": ["lockout", "locked", "keys", "key"],
            "Vehicle": ["vehicle", "car"],
            "Fuel": ["fuel", "gas"],
            "Other": ["other", "advice", "report", "inspect"]
        } as any;



        allServicesCheckboxesData.forEach(service => {
            const name = service.name;
            let groupFound = false;
            const group: any = Object.keys(keywords).find(key => {
                return keywords[key].some((k: any) => name.toLowerCase().includes(k))
            });
            if (group) {
                if (!groups[group]) groups[group] = [];
                groups[group].push(service);
                groupFound = true;
            }

            if (!groupFound) {
                const miscGroupName = "Misc"; // Define Misc group name
                if (!groups[miscGroupName]) groups[miscGroupName] = [];
                groups[miscGroupName].push(service);
            }
        });

        const checkAllInGroup = (group: any) => {
            const allChecked = groups[group].every((service: Service) => {
                return servicesCheckedRef.current.find(s => s._id === service._id)
            });
            if (allChecked) {
                groups[group].forEach((service: Service) => {
                    servicesCheckedRef.current = servicesCheckedRef.current.filter(s => s._id !== service._id)
                })
            } else {
                groups[group].forEach((service: Service) => {
                    servicesCheckedRef.current = [...servicesCheckedRef.current, service]
                })
            }
            forceUpdate();
            filterJobsByCriteria();
        }

        return (
            <Card
                disabled
                status='primary'
                header={() => <Text category='h6'>Services</Text>}
            >
                <View style={{ margin: -15 }}>
                    {Object.keys(groups).map((group, index) => {
                        return (
                            <Card key={index}
                                disabled
                                header={() => (
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Text category='s1'>{group}</Text>
                                        <CheckBox checked={groups[group].every((service: Service) => {
                                            return servicesCheckedRef.current.find(s => s._id === service._id)
                                        })} onChange={(nextChecked: boolean) => checkAllInGroup(group)}>All</CheckBox>
                                    </View>
                                )}
                            >
                                <View style={{ margin: -15 }}>
                                    {groups[group].map((service: Service, index: number) => {
                                        return (
                                            <View key={service._id} style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <CheckBox style={{ flex: 4 }} checked={
                                                    servicesCheckedRef.current.find(s => s._id === service._id) ? true : false
                                                } onChange={(nextChecked: boolean) => onServiceCheckedChange(service, nextChecked)}>
                                                    <Text category='s2' numberOfLines={1}>
                                                        {service.name}
                                                    </Text>
                                                </CheckBox>
                                                <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>

                                                    {/* <Text style={{ flex: 1, textAlign: 'right' }}>{`(${serviceJobCount[service._id as string] || 0})`}</Text> */}
                                                    {filterType === FILTER_TYPE.JOB && filteredJobs && <Text style={{ textAlign: 'right' }}>{`(${serviceJobCountRemaining[service._id as string] || 0})`}</Text>}
                                                    <Text style={{ textAlign: 'right' }}>/</Text>
                                                    {filterType === FILTER_TYPE.JOB && <Text status="success" style={{ textAlign: 'right' }}>{`(${serviceJobCount[service._id as string] || 0})`}</Text>}
                                                    {filterType === FILTER_TYPE.HOLDING && <Text style={{ textAlign: 'right' }}>{`(${serviceHoldingCount[service._id as string] || 0})`}</Text>}
                                                </View>
                                            </View>
                                        )
                                    })}
                                </View>
                            </Card>
                        )
                    })}
                </View>
            </Card>
        )
    }

    const defaultStatuses = [
        // { name: "Unassigned", value: "unassigned" },
        // { name: "Assigned", value: "assigned" },
        // { name: "Pending", value: "pending" },
        { name: "Complete", value: "complete" },
        { name: "Cancelled", value: "cancelled" },
        { name: "Transferred Out", value: "transferred_out" }
    ]
    const RenderStatusesList = () => (
        <Card
            disabled
            status='danger'
            header={() => <Text category='h6'>Statuses</Text>}
        >
            <View style={{ margin: -15 }}>
                {defaultStatuses.map((status, index) => {
                    return (
                        <View key={index} style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <CheckBox
                                style={{ flex: 4 }}
                                checked={statusesChecked.includes(status.value) || false}
                                onChange={(nextChecked: boolean) => onStatusCheckedChange(status.value, nextChecked)}>
                                {status.name}
                            </CheckBox>
                            <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                {filteredJobs && <Text style={{ textAlign: 'right' }}>({statusJobsCountRemaining[status.value] || 0})</Text>}
                                <Text style={{ textAlign: 'right' }}>/</Text>
                                <Text status="success" style={{ textAlign: 'right' }}>({statusJobsCount[status.value] || 0})</Text>
                            </View>
                        </View>
                    )
                })}
                <View style={{ flexDirection: 'column', gap: 10, marginBottom: 10 }}>
                    {/* Non-dispatchable filter */}
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <CheckBox
                            style={{ flex: 4 }}
                            checked={dispatchableChecked}
                            onChange={onDispatchableCheckedChange}>
                            <Text numberOfLines={1}>
                                Non-Dispatchable
                            </Text>
                        </CheckBox>
                        <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                            {filteredJobs &&
                                <Text numberOfLines={1} style={{ textAlign: 'right' }}>
                                    ({dispatchableJobsCountRemaining})
                                </Text>}
                            <Text numberOfLines={1} style={{ textAlign: 'right' }}>/</Text>
                            <Text numberOfLines={1} status="success" style={{ textAlign: 'right' }}>
                                ({dispatchableJobsCount})
                            </Text>
                        </View>
                    </View>

                    {/* Dispatchable filter */}
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <CheckBox
                            style={{ flex: 4 }}
                            checked={dispatchableOnlyChecked}
                            onChange={onDispatchableOnlyCheckedChange}>
                            Dispatchable
                        </CheckBox>
                        <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                            {filteredJobs &&
                                <Text style={{ textAlign: 'right' }}>
                                    ({dispatchableOnlyJobsCountRemaining})
                                </Text>}
                            <Text style={{ textAlign: 'right' }}>/</Text>
                            <Text status="success" style={{ textAlign: 'right' }}>
                                ({dispatchableOnlyJobsCount})
                            </Text>
                        </View>
                    </View>
                </View>


            </View>
        </Card>
    )

    const RenderVendorProfilesList = () => (
        <Card
            disabled
            status='info'
            header={() => <Text category='h6'>Vendor Profiles</Text>}
        >
            <View style={{ margin: -15 }}>
                {vendorProfiles.current?.map((vendorProfile: VendorProfile) => {
                    const vendorProfileId = vendorProfile._id;
                    if (!vendorProfileId) return null;

                    const jobCount = jobs?.filter(job =>
                        (job.vendor_company_id && vendorProfile.linkedVendorAccountId && job.vendor_company_id === vendorProfile.linkedVendorAccountId) ||
                        (job.vendor_profile_id && job.vendor_profile_id === vendorProfile._id)
                    ).length;

                    const filteredJobCount = filteredJobs?.filter(job =>
                        (job.vendor_company_id && vendorProfile.linkedVendorAccountId && job.vendor_company_id === vendorProfile.linkedVendorAccountId) ||
                        (job.vendor_profile_id && job.vendor_profile_id === vendorProfile._id)
                    ).length;

                    return (
                        <View key={vendorProfile._id} style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <CheckBox style={{ flex: 4 }} checked={
                                vendorProfilesCheckedRef.current.find(vp => vp._id === vendorProfile._id) ? true : false
                            } onChange={(nextChecked: boolean) => onVendorProfileCheckedChange(vendorProfile, nextChecked)}>
                                <Text category='s2' numberOfLines={1}>
                                    {vendorProfile.name}
                                </Text>
                            </CheckBox>
                            <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                {filterType == FILTER_TYPE.JOB && filteredJobs && <Text style={{ textAlign: 'right' }}>({filteredJobCount || 0})</Text>}
                                <Text style={{ textAlign: 'right' }}>/</Text>
                                {filterType == FILTER_TYPE.JOB && <Text status="success" style={{ textAlign: 'right' }}>({jobCount || 0})</Text>}
                            </View>
                        </View>
                    )
                })}
            </View>
        </Card>
    )

    const isClient = StorageController.getAppState().selectedMembership?.is_client;
    const hasClients = clients.current?.length > 0;
    const hasDrivers = _drivers?.length > 0;
    const hasVendorProfiles = vendorProfiles.current?.length > 0;

    if (filterType === FILTER_TYPE.HOLDING) {
        return (
            <ScrollView style={{ flex: 1 }}>
                <Card status='basic' disabled={true}>
                    <View style={{ flexDirection: 'column', gap: 35 }}>
                        {!isClient && hasClients && <RenderClientsList />}
                        <RenderAllServiceCheckboxes />
                    </View>
                </Card>
            </ScrollView>
        )
    } else {
        return (
            <ScrollView style={{ flex: 1 }}>
                <Card status='basic' disabled={true}>
                    <View style={{ flexDirection: 'column', gap: 35, margin: -15 }}>
                        {!isClient && (
                            <>
                                {hasClients && <RenderClientsList />}
                                {hasDrivers && <RenderDriversList />}
                                {hasVendorProfiles && <RenderVendorProfilesList />}
                            </>
                        )}
                        <RenderStatusesList />
                        <RenderAllServiceCheckboxes />
                    </View>
                </Card>
            </ScrollView>
        )
    }
};

const styles = StyleSheet.create({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 5,
        borderBottomWidth: 1,
        borderBottomColor: 'grey'
    }
});