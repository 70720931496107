import React, { useState, useEffect, useRef, useReducer, useCallback, Profiler, useMemo, memo, useContext } from 'react';
import {
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    Image,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Platform,
    useWindowDimensions,
    ScrollView,
    Dimensions,
    Alert
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Layout,
    Text,
    List,
    ListItem,
    Card,
} from "@ui-kitten/components";
import * as GoogleController from '../../../functions/google.controller';
import { Job, JobTowingDetails, TowingLeg, TowingLocation, JobNote, JobAddress, TOWING_TYPE, LINE_ITEM_BILLED_TO, JOB_LOCATION_TYPE, CLIENT_RATE_BILLED_TO } from '../../../models/Job.model';
import { Service } from '../../../models/Service';
import { LineItem } from '../../../models/LineItem.model';
import * as StorageController from '../../../functions/storageController';
import { Client } from '../../../models/Client.model';
import { Member } from '../../../models/Member.model';

interface ReportDetailsCardProps {
    job: Job;
    style?: object;
}


export const ReportDetailsCard = ({
    job = new Job({}),
    style = {}
}: ReportDetailsCardProps) => {

    const [showRoadsideOptions, setShowRoadsideOptions] = useState(false)
    const [showTowingOptions, setShowTowingOptions] = useState(false)
    const [showTransportOptions, setShowTransportOptions] = useState(false)

    const [JobState, setJobState] = useState(new Job(job))

    useEffect(() => {
        setJobState(new Job(job))
        if (JobState.details?.options) {
            setShowRoadsideOptions(JobState.details?.options.roadside_job_options)
            setShowTowingOptions(JobState.details?.options.towing_job_options)
            setShowTransportOptions(JobState.details?.options.transport_job_options)
        } else {
            setShowRoadsideOptions(true)
        }
    }, [job])

    const capitaliseAndRemoveSpecialChars = (str: string) => {
        // remove _ and replace with space
        str = str.replace(/_/g, " ")
        // capitalise first letter of each word
        str = str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
        return str
    }

    const renderNested = (object: any, index: number) => {
        const ignoredKeys = ["destinationTypes"]; // Add the keys you want to ignore here
        if (object === null || object === undefined) return null;
        return (
            <View key={index}>
                {Array.isArray(object) ? (
                    object.map((item, subIndex) => renderNested(item, subIndex))
                ) : typeof object === 'string' ? (
                    <Text selectable={true}>{object}</Text>
                ) : (
                    Object.keys(object)
                        .filter(key => !ignoredKeys.includes(key))
                        .map((key, subIndex) => (
                            <View key={subIndex} style={styles.column}>
                                {/* @ts-ignore */}
                                <Text selectable={true}>
                                    {capitaliseAndRemoveSpecialChars(key)}:
                                    {typeof object[key] === 'object' && !Array.isArray(object[key]) && typeof object[key] !== 'string'
                                        ? renderNested(object[key], subIndex)
                                        : ` ${object[key]}`}
                                </Text>
                            </View>
                        ))
                )}
            </View>
        );
    };


    const getCustomerDetails = () => {
        const result = {
            name: JobState.details?.customer_details?.name || "",
            phone: JobState.details?.customer_details?.phone || "",
            email: JobState.details?.customer_details?.email || "",
        }
        return result
    }

    const getVehicleDetails = () => {
        const result = {
            make: JobState.details?.vehicle_details?.make || "",
            model: JobState.details?.vehicle_details?.model || "",
            rego: JobState.details?.vehicle_details?.rego || "",
        }
        return result
    }

    const getClientDetails = () => {
        const clientId = JobState.client_id
        const company = StorageController.getCurrentCompany()
        const clients = company.clients
        const client = clients?.find(client => client._id === clientId) || {}
        return client as Client
    }

    const getMemberName = (memberId: string) => {
        const company = StorageController.getCurrentCompany()
        const driver = company.members.find(member => member._id === memberId)
        return driver?.name || ""
    }




    const getPaymentDetails = () => {
        const result = {
            clientRate: JobState.details?.client_rate,
            callout: JobState.details?.client_rate?.name || "",
            billing: JobState.details?.client_rate?.billed_to || "",
            customerPaid: JobState.details?.client_rate?.customer_paid || false,
            customerPaidCash: JobState.details?.report?.customerPaidCash || false,
            customerPaidCashAmount: JobState.details?.report?.customerPaidCashAmount || 0,
            customerPaidCard: JobState.details?.report?.customerPaidCard || false,
            customerPaidCardAmount: JobState.details?.report?.customerPaidCardAmount || 0,
        }
        return result
    }

    const getReportDetails = () => {
        // get the driver from the job member_id
        const company = StorageController.getCurrentCompany()
        if (!company.members) return
        const driver = company.members?.find(member => member._id === JobState.member_id)
        const result = {
            preInspectionComments: JobState.details?.report?.preInspectionComments || "",
            comments: JobState.details?.report?.comments || "",
            dispatchComments: JobState.details?.comments || "",
            driver: driver?.name || "",
        }
        return result
    }

    const getTimestamps = () => {
        const result = {
            start_time: JobState?.start_time || "",
            driver_accepted_time: JobState.driver_accepted_time || "",
            driver_on_route_time: JobState.driver_on_route_time || "",
            driver_arrived_time: JobState.driver_arrived_time || JobState.arrived_time || "",
            pending_time: JobState.pending_time || "",
        }
        return result
    }

    const getItems = () => {
        const result = {
            items: JobState.details?.report?.items || [],
        }
        return result
    }

    /*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//#region CUSTOMER DETAILS
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/
    const RenderCustomerDetails = () => {
        const customerDetails = getCustomerDetails()
        return (
            <View style={styles.container}>
                <View style={styles.column}>
                    <View style={styles.row}>
                        <View style={styles.column1}>
                            <Text style={styles.label} selectable={true}>
                                Name:
                            </Text>
                        </View>
                        <View style={styles.column}>
                            <Text selectable={true}>
                                {customerDetails.name}
                            </Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.column1}>
                            <Text style={styles.label} selectable={true}>
                                Phone:
                            </Text>
                        </View>
                        <View style={styles.column}>
                            <Text selectable={true}>
                                {customerDetails.phone}
                            </Text>
                        </View>

                    </View>
                    <View style={styles.row}>
                        <View style={styles.column1}>
                            <Text style={styles.label} selectable={true}>
                                Email:
                            </Text>
                        </View>
                        <View style={styles.column}>
                            <Text selectable={true}>
                                {customerDetails.email}
                            </Text>
                        </View>
                    </View>
                </View>
            </View>
        )
    }
    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //#region VEHICLE DETAILS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const RenderVehicleDetails = () => {
        const vehicleDetails = getVehicleDetails()
        return (
            <View style={styles.container}>
                <View style={styles.row}>
                    <View style={styles.column1}>
                        <Text style={styles.label} selectable={true}>
                            Make:
                        </Text>
                    </View>
                    <View style={styles.column}>
                        <Text selectable={true}>
                            {vehicleDetails.make}
                        </Text>
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={styles.column1}>
                        <Text style={styles.label} selectable={true}>
                            Model:
                        </Text>
                    </View>
                    <View style={styles.column}>
                        <Text selectable={true}>
                            {vehicleDetails.model}
                        </Text>
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={styles.column1}>
                        <Text style={styles.label} selectable={true}>
                            Rego:
                        </Text>
                    </View>
                    <View style={styles.column}>
                        <Text selectable={true}>
                            {vehicleDetails.rego}
                        </Text>
                    </View>
                </View>
            </View>
        )
    }

    const RenderClientDetails = () => {
        const clientDetails = getClientDetails()
        return (
            <View style={styles.container}>
                <View style={styles.row}>
                    <View style={styles.column1}>
                        <Text style={styles.label} selectable={true}>
                            Client:
                        </Text>
                    </View>
                    <View style={styles.column}>
                        <Text selectable={true}>
                            {clientDetails?.name}
                        </Text>
                    </View>
                </View>
            </View>
        )
    }

    /*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//#region ADDRESS
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/
    const RenderAddress = ({ address }: { address: JobAddress | null }) => {
        if (!address) return null
        const addressDetails = new JobAddress(address)
        return (
            <View style={styles.container}>
                <Text selectable={true}>
                    {GoogleController.addressBuilder(addressDetails)}
                </Text>
            </View>
        )
    }

    /*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//#region TOWING DETAILS
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/
    const RenderTowingDetails = ({ towingDetails }: { towingDetails: JobTowingDetails | null }) => {
        if (!towingDetails) return null
        const towing_details = new JobTowingDetails(towingDetails)
        const buildDescription = (details: any, fields: any) => {
            if (!details) return "";
            return fields
                .map((field: any) => details[field] || "")
                .filter((value: any) => value !== "")
                .join(" | ");
        };

        let description = ""
        if (towing_details?.towing_type?.toLowerCase() === "equipment" || towing_details?.towing_type == TOWING_TYPE.EQUIPMENT) {
            description = buildDescription(towing_details?.equipment_details, ["equipment", "serial", "size", "type", "weight"]);
        }

        if (towing_details?.towing_type?.toLowerCase() === "vehicle" || towing_details?.towing_type == TOWING_TYPE.VEHICLE) {
            description = buildDescription(towing_details?.vehicle_details, ["make", "model", "rego", "type", "year", "colour"]);
        }

        const getLocationDetail = (towingLocation: TowingLocation) => {
            const location = new TowingLocation(towingLocation)
            let result = ""
            if (location?.location_type == JOB_LOCATION_TYPE.ADDRESS) {
                result += `Address: ${location?.address_type}`
            }
            else if (location?.location_type == JOB_LOCATION_TYPE.HOLDING) {
                result += `Holding: ${location?.holding_reason}`
            }
            return result
        }

        const RenderLocation = ({ locationDetails, index }: { locationDetails: TowingLocation, index: number }) => {
            const location = new TowingLocation(locationDetails)
            // get towingLeg matching index
            /**
             * @type {TowingLeg}
             */
            let towingLeg = towing_details.towing_legs[index]
            if (towingLeg) towingLeg = new TowingLeg(towingLeg)

            return (
                <View style={{ flexDirection: 'column' }}>

                    <View style={styles.row}>
                        <View style={styles.column1}>
                            <Text style={styles.label} selectable={true}>
                                Location: {location.name}
                            </Text>
                        </View>
                        <View style={styles.column}>
                            <Text selectable={true}>
                                {getLocationDetail(location)}
                            </Text>
                        </View>
                        <View style={styles.column}>
                            <Text selectable={true}>
                                {GoogleController.addressBuilder(location.address)}
                            </Text>
                        </View>
                        <View style={styles.column}>
                            <Text selectable={true}>
                                {location.time && timestampToReadable(location.time) || "N/A"}
                            </Text>
                        </View>
                    </View>
                    {towingLeg &&
                        <View style={styles.row}>
                            <View style={styles.column1}>
                                <Text style={styles.label} selectable={true}>
                                    Towing Leg:
                                </Text>
                            </View>
                            <View style={styles.column}>
                                <Text selectable={true}>
                                    {towingLeg.directions.distance_text}
                                </Text>
                            </View>
                            <View style={styles.column}>
                                <Text selectable={true}>
                                    {towingLeg.directions.duration_text}
                                </Text>
                            </View>
                            <View style={styles.column}>
                                <Text selectable={true}>

                                </Text>
                            </View>
                        </View>
                    }
                </View>
            )
        }


        return (
            <View style={styles.container}>
                <View style={styles.row}>
                    <View style={styles.column1}>
                        <Text style={styles.label} selectable={true}>
                            Type:
                        </Text>
                    </View>
                    <View style={styles.column}>
                        <Text selectable={true}>
                            {towing_details?.towing_type}
                        </Text>
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={styles.column1}>
                        <Text style={styles.label} selectable={true}>
                            Description:
                        </Text>
                    </View>
                    <View style={styles.column}>
                        <Text selectable={true}>
                            {description}
                        </Text>
                    </View>
                </View>
                {(towing_details.towing_locations && towing_details.towing_locations.length > 0) &&
                    towing_details.towing_locations.map((location, index) => {
                        return (
                            <RenderLocation key={index} index={index} locationDetails={location} />
                        )
                    })
                }
            </View>
        )
    }

    /*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//#region SERVICE DETAILS
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/
    const RenderServiceDetails = () => {
        const serviceDetails = JobState.details?.selected_services || [] as Service[]
        return (
            <View style={styles.container}>
                {serviceDetails.map((service, index) => {
                    return (
                        <View key={index} style={styles.row}>
                            <View style={styles.column1}>
                                <Text selectable={true}>{service.name}</Text>
                            </View>
                            {service.fields && (
                                <View style={styles.column}>
                                    {Object.keys(service.fields).map((field, index) => {
                                        return (
                                            <View key={index} style={styles.column}>
                                                {/* @ts-ignore */}
                                                <Text selectable={true}>
                                                    {capitaliseAndRemoveSpecialChars(field) || ""}: {service.values[field] || "N/A"}
                                                </Text>
                                            </View>
                                        );
                                    })}
                                    {service.other &&
                                        Object.keys(service.other).map((field, index) => {
                                            //@ts-ignore
                                            return renderNested(service.other[field], index);
                                        })
                                    }
                                </View>
                            )}
                        </View>
                    );
                })}
            </View>
        )
    }

    /*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//#region ITEMS
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/
    const RenderItems = () => {
        const items = getItems()
        const jobState = new Job(JobState)
        const clientRate = JobState.getClientRate()
        if (clientRate) {
            const clientRateLineItem = clientRate.generateLineItem()
            jobState.addLineItem(clientRateLineItem)
        }
        const billBackItems = jobState.getTotalBillBackItems()
        const customerCostItems = jobState.getTotalCustomerCostItems()
        // add to bill back ro cuistomer cost the cliuent rate
        // if (clientRate) {
        //     if (clientRate.billed_to == 'bill_all_back') {
        //         billBackItems.push(new LineItem({
        //             name: clientRate.name,
        //             description: clientRate.name,
        //             quantity: 1,
        //             cost: clientRate.cost,
        //             billed_to: LINE_ITEM_BILLED_TO.BILL_ALL_BACK
        //         }))
        //     }
        //     else if (clientRate.billed_to == 'customer_cost') {
        //         customerCostItems.push(new LineItem({
        //             name: clientRate.name,
        //             description: clientRate.name,
        //             quantity: 1,
        //             cost: clientRate.cost,
        //             billed_to: LINE_ITEM_BILLED_TO.CUSTOMER_COST
        //         }))
        //     }
        // }

        const isGSTRegistered = StorageController.getCurrentCompany().settings?.business_info?.gst_registered
        return (
            <View style={styles.container}>
                <View style={styles.column2}>
                    <Text category='s1' selectable={true}>
                        Bill Back Items:
                    </Text>
                    {billBackItems.length > 0 && billBackItems.map((item, index) => {
                        item = new LineItem(item)
                        return (
                            <View key={index} style={styles.row}>
                                <View style={[styles.column1, { flex: 3 }]}>
                                    <Text selectable={true}>
                                        {item.name ? item.name : item.description}:
                                    </Text>
                                </View>
                                <View style={styles.column1}>
                                    <Text selectable={true}>
                                        {item.billed_to === LINE_ITEM_BILLED_TO.BILL_ALL_BACK ? "Bill All Back" : "Customer Cost"}
                                    </Text>
                                </View>
                                {isGSTRegistered &&
                                    <>

                                        {/* <View style={styles.column1}>
                                            <Text selectable={true}>
                                                {item.is_gst_inclusive ? "(GST INC)" : "(GST EXC)"}
                                            </Text>
                                        </View> */}
                                        <View style={styles.column1}>
                                            <Text selectable={true}>
                                                ${item.getCostWithGST().toFixed(2)}
                                            </Text>
                                        </View>
                                    </>
                                }
                                {!isGSTRegistered &&
                                    <View style={styles.column1}>
                                        <Text selectable={true}>
                                            ${item.getTotalCost().toFixed(2)}
                                        </Text>
                                    </View>
                                }
                            </View>
                        )
                    })
                    }
                </View>
                <View style={styles.column2}>
                    <Text category='s1' selectable={true}>
                        Customer Cost Items:
                    </Text>
                    {customerCostItems.length > 0 && customerCostItems.map((item, index) => {
                        return (
                            <View key={index} style={styles.row}>
                                <View style={[styles.column1, { flex: 3 }]}>
                                    <Text selectable={true}>
                                        {item.name ? item.name : item.description}:
                                    </Text>
                                </View>
                                <View style={styles.column1}>
                                    <Text selectable={true}>
                                        {item.billed_to === LINE_ITEM_BILLED_TO.CUSTOMER_COST ? "Customer Cost" : "Bill All Back"}
                                    </Text>
                                </View>
                                {isGSTRegistered &&
                                    <>

                                        {/* <View style={styles.column1}>
                                            <Text selectable={true}>
                                                {item.is_gst_inclusive ? "(GST INC)" : "(GST EXC)"}
                                            </Text>
                                        </View> */}
                                        <View style={styles.column1}>
                                            <Text selectable={true}>
                                                ${item.getCostWithGST().toFixed(2)}
                                            </Text>
                                        </View>

                                    </>
                                }
                                {!isGSTRegistered &&
                                    <View style={styles.column1}>
                                        <Text selectable={true}>
                                            ${item.getTotalCost().toFixed(2)}
                                        </Text>
                                    </View>
                                }
                            </View>
                        )
                    })
                    }
                </View>
                <View style={styles.row}>
                    <View style={[styles.column1, { flex: 3 }]}>
                        <Text category='s1' selectable={true}>
                            Total Bill Back Items:
                        </Text>
                    </View>
                    <View style={{ flex: 1, padding: 5, borderTopColor: "grey", borderBottomColor: 'grey', borderTopWidth: 1, borderBottomWidth: 1 }}>
                        <Text selectable={true}>
                        </Text>
                    </View>
                    <View style={{ flex: 1, padding: 5, borderTopColor: "grey", borderBottomColor: 'grey', borderTopWidth: 1, borderBottomWidth: 1 }}>
                        <Text selectable={true}>
                        </Text>
                    </View>
                    <View style={styles.column1}>
                        {isGSTRegistered &&
                            <Text selectable={true}>
                                GST: ${jobState?.details?.report?.calculateTotalAmountsWithGST("bill_all_back")?.GSTTotal.toFixed(2)}
                            </Text>
                        }
                    </View>
                    <View style={styles.column1}>
                        {isGSTRegistered &&
                            <Text selectable={true}>
                                ${jobState?.details?.report?.calculateTotalAmountsWithGST("bill_all_back")?.totalWithGST.toFixed(2)}
                            </Text>
                        }
                        {!isGSTRegistered &&
                            <Text selectable={true}>
                                ${jobState?.details?.report?.calculateTotalAmountsWithGST("bill_all_back")?.costNoGST.toFixed(2)}
                            </Text>
                        }
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={[styles.column1, { flex: 3 }]}>
                        <Text category='s1' selectable={true}>
                            Customer Cost Items:
                        </Text>
                    </View>
                    <View style={{ flex: 1, padding: 5, borderTopColor: "grey", borderBottomColor: 'grey', borderTopWidth: 1, borderBottomWidth: 1 }}>
                        <Text selectable={true}>
                        </Text>
                    </View>
                    <View style={{ flex: 1, padding: 5, borderTopColor: "grey", borderBottomColor: 'grey', borderTopWidth: 1, borderBottomWidth: 1 }}>
                        <Text selectable={true}>
                        </Text>
                    </View>
                    <View style={styles.column1}>
                        {isGSTRegistered &&
                            <Text selectable={true}>
                                GST: ${jobState?.details?.report?.calculateTotalAmountsWithGST("customer_cost")?.GSTTotal.toFixed(2)}
                            </Text>
                        }
                    </View>
                    <View style={styles.column1}>
                        {isGSTRegistered &&
                            <Text selectable={true}>
                                ${jobState?.details?.report?.calculateTotalAmountsWithGST("customer_cost")?.totalWithGST.toFixed(2)}
                            </Text>
                        }
                        {!isGSTRegistered &&
                            <Text selectable={true}>
                                ${jobState?.details?.report?.calculateTotalAmountsWithGST("customer_cost")?.costNoGST.toFixed(2)}
                            </Text>
                        }
                    </View>
                </View>

                <View style={styles.row}>
                    <View style={[styles.column1, { flex: 3 }]}>
                        <Text category='s1' selectable={true}>
                            Total Items:
                        </Text>
                    </View>
                    <View style={{ flex: 1, padding: 5, borderTopColor: "grey", borderBottomColor: 'grey', borderTopWidth: 1, borderBottomWidth: 1 }}>
                        <Text selectable={true}>
                        </Text>
                    </View>
                    <View style={{ flex: 1, padding: 5, borderTopColor: "grey", borderBottomColor: 'grey', borderTopWidth: 1, borderBottomWidth: 1 }}>
                        <Text selectable={true}>
                        </Text>
                    </View>
                    <View style={styles.column1}>
                        {isGSTRegistered &&
                            <Text selectable={true}>
                                GST: ${jobState?.details?.report?.calculateTotalAmountsWithGST("all")?.GSTTotal.toFixed(2)}
                            </Text>
                        }
                    </View>
                    <View style={styles.column1}>
                        {isGSTRegistered &&
                            <Text selectable={true}>
                                ${jobState?.details?.report?.calculateTotalAmountsWithGST("all")?.totalWithGST.toFixed(2)}
                            </Text>
                        }
                        {!isGSTRegistered &&
                            <Text selectable={true}>
                                ${jobState?.details?.report?.calculateTotalAmountsWithGST("all")?.costNoGST.toFixed(2)}
                            </Text>
                        }
                    </View>
                </View>
            </View>
        )
    }










    const RenderPaymentDetails = () => {
        const paymentDetails = getPaymentDetails()
        return (
            <View style={styles.container}>
                <View style={styles.row}>
                    <View style={styles.column1}>
                        <Text style={styles.label} selectable={true}>
                            Callout:
                        </Text>
                    </View>
                    <View style={styles.column}>
                        {paymentDetails.callout &&
                            <Text selectable={true}>
                                {paymentDetails.callout}
                            </Text>
                        }
                    </View>
                </View>
                {paymentDetails.billing &&
                    <View style={styles.row}>
                        <View style={styles.column1}>
                            <Text style={styles.label} selectable={true}>
                                Billing:
                            </Text>
                        </View>
                        <View style={styles.column}>
                            <Text selectable={true}>
                                {capitaliseAndRemoveSpecialChars(paymentDetails.billing)}
                            </Text>
                        </View>
                    </View>
                }
                {paymentDetails.customerPaid &&
                    <View style={styles.row}>
                        <View style={styles.column1}>
                            <Text style={styles.label} selectable={true}>
                                Customer Paid Callout:
                            </Text>
                        </View>
                        <View style={styles.column}>
                            <Text selectable={true}>
                                {paymentDetails.customerPaid ? "Yes" : "No"}
                            </Text>
                        </View>
                    </View>
                }
                {JobState.details?.client_rate && JobState.details?.client_rate?.eft_auth_number &&
                    <View style={styles.row}>
                        <View style={styles.column1}>
                            <Text style={styles.label} selectable={true}>
                                EFT Auth Number:
                            </Text>
                        </View>
                        <View style={styles.column}>
                            <Text selectable={true}>
                                {JobState.details?.client_rate?.eft_auth_number}
                            </Text>
                        </View>
                    </View>
                }
                <View style={styles.row}>
                    {paymentDetails.customerPaidCash && (
                        <>
                            <View style={styles.column}>
                                <Text style={styles.label} selectable={true}>
                                    Cash Amount:
                                </Text>
                            </View>
                            <View style={styles.column}>
                                <Text selectable={true}>
                                    ${paymentDetails.customerPaidCashAmount}
                                </Text>
                            </View>
                        </>
                    )}
                    {paymentDetails.customerPaidCard && (
                        <>
                            <View style={styles.column}>
                                <Text style={styles.label} selectable={true}>
                                    Card Amount:
                                </Text>
                            </View>
                            <View style={styles.column}>
                                <Text selectable={true}>
                                    ${paymentDetails.customerPaidCardAmount}
                                </Text>
                            </View>
                        </>
                    )}
                </View>
                {/* Paid total */}
                <View style={styles.row}>
                    <View style={styles.column1}>
                        <Text style={styles.label} selectable={true}>
                            Total Paid:
                        </Text>
                    </View>
                </View>
                <View style={styles.column}>
                    <Text selectable={true}>
                        ${(Number(paymentDetails.customerPaidCashAmount || 0) + Number(paymentDetails.customerPaidCardAmount || 0)).toFixed(2)}
                    </Text>
                </View>
            </View>
        )
    }

    const RenderComments = () => {
        const reportDetails = getReportDetails()
        return (
            <View style={styles.container}>
                <View style={styles.row}>
                    <View style={styles.column1}>
                        <Text style={styles.label} selectable={true}>
                            Driver:
                        </Text>
                    </View>
                    <View style={styles.column}>
                        <Text selectable={true}>
                            {reportDetails?.driver}
                        </Text>
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={styles.column1}>
                        <Text style={styles.label} selectable={true}>
                            Dispatch Comments:
                        </Text>
                    </View>
                    <View style={styles.column}>
                        <Text selectable={true}>
                            {reportDetails?.dispatchComments}
                        </Text>
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={styles.column1}>
                        <Text style={styles.label} selectable={true}>
                            Driver Pre-Inspection Comments:
                        </Text>
                    </View>
                    <View style={styles.column}>
                        <Text selectable={true}>
                            {reportDetails?.preInspectionComments}
                        </Text>
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={styles.column1}>
                        <Text style={styles.label} selectable={true}>
                            Driver Comments:
                        </Text>
                    </View>
                    <View style={styles.column}>
                        <Text selectable={true}>
                            {reportDetails?.comments}
                        </Text>
                    </View>
                </View>
            </View>
        )
    }

    const timestampToReadable = (timestamp: number | string | null) => {
        if (!timestamp) return "N/A"
        try {
            const date = new Date(timestamp);
            const dateFormatter = new Intl.DateTimeFormat('en-AU', {
                day: '2-digit',
                month: 'short',
                year: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
            });
            return dateFormatter.format(date);
        } catch (e) {
            return timestamp
        }
    };

    const RenderTimestamps = () => {
        // Render timestamps for each status change - start_time, accepted_time, pending_time
        const timestamps = getTimestamps()
        return (
            <View style={styles.container}>
                <View style={styles.row}>
                    <View style={styles.column1}>
                        <Text style={styles.label} selectable={true}>
                            Start Time:
                        </Text>
                    </View>
                    <View style={styles.column}>
                        <Text selectable={true}>
                            {timestampToReadable(timestamps.start_time)}
                        </Text>
                    </View>
                </View>
                {timestamps.driver_accepted_time &&
                    <View style={styles.row}>
                        <View style={styles.column1}>
                            <Text style={styles.label} selectable={true}>
                                Accepted Time:
                            </Text>
                        </View>
                        <View style={styles.column}>
                            <Text selectable={true}>
                                {timestampToReadable(timestamps.driver_accepted_time)}
                            </Text>
                        </View>
                    </View>
                }
                {timestamps.driver_on_route_time &&
                    <View style={styles.row}>
                        <View style={styles.column1}>
                            <Text style={styles.label} selectable={true}>
                                On Route Time:
                            </Text>
                        </View>
                        <View style={styles.column}>
                            <Text selectable={true}>
                                {timestampToReadable(timestamps.driver_on_route_time)}
                            </Text>
                        </View>
                    </View>
                }
                {timestamps.driver_arrived_time &&
                    <View style={styles.row}>
                        <View style={styles.column1}>
                            <Text style={styles.label} selectable={true}>
                                Arrived Time:
                            </Text>
                        </View>
                        <View style={styles.column}>
                            <Text selectable={true}>
                                {timestampToReadable(timestamps.driver_arrived_time)}
                            </Text>
                        </View>
                    </View>
                }
                {timestamps.pending_time &&
                    <View style={styles.row}>
                        <View style={styles.column1}>
                            <Text style={styles.label} selectable={true}>
                                Pending Time:
                            </Text>
                        </View>
                        <View style={styles.column}>
                            <Text selectable={true}>
                                {timestampToReadable(timestamps.pending_time)}
                            </Text>
                        </View>
                    </View>
                }
            </View>
        )
    }


    const RenderJobNotes = () => {
        return (
            <View style={styles.container}>
                <Text selectable={true}>Job Notes:</Text>
                {JobState.details?.notes?.map((note: JobNote, index: number) => {
                    return (
                        <View key={index} style={styles.row}>
                            <View style={styles.column1}>
                                <Text selectable={true}>
                                    {note.note}
                                </Text>
                            </View>
                            <View style={styles.column}>
                                <Text selectable={true}>
                                    {getMemberName(note.member_id)}
                                </Text>
                            </View>
                            <View style={styles.column}>
                                <Text selectable={true}>
                                    {timestampToReadable(note.created_time)}
                                </Text>
                            </View>
                        </View>
                    )
                })}
            </View>
        )
    }



    return (
        <Card
            disabled
            header={() => <Text style={{ alignSelf: 'center' }}>Job Report</Text>}
            style={style}
            status='primary'
        >
            <View style={styles.container1}>
                <RenderClientDetails />
                <RenderCustomerDetails />
                {showRoadsideOptions &&
                    <>
                        <RenderVehicleDetails />
                        <RenderAddress address={JobState?.details?.address} />
                    </>
                }
                {showTowingOptions &&
                    <RenderTowingDetails towingDetails={JobState?.details?.towing_details} />
                }
                <RenderServiceDetails />
                <RenderItems />
                <RenderPaymentDetails />
                <RenderTimestamps />
                <RenderComments />
                <RenderJobNotes />
            </View>
        </Card>
    )

}


const styles = StyleSheet.create({
    container1: {
        margin: 5,
        marginTop: 30,
        borderWidth: 1,
        borderColor: 'gray',
        borderRadius: 5,
        padding: 10,
    },
    header: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    row: {
        flexDirection: 'row',
        marginBottom: 0,
    },
    column: {
        flex: 2,
        borderWidth: 1,
        borderColor: 'gray',
        padding: 5,
    },
    column1: {
        flex: 1,
        borderWidth: 1,
        borderColor: 'gray',
        padding: 5,
    },
    column2: {
        borderWidth: 1,
        borderColor: 'gray',
        padding: 5,
    },
    label: {
        fontWeight: 'bold',
    },
    // table stuff ^_
    container: {
        margin: 2,
        marginTop: 5
        // maxHeight: "100%",
    },
    contentContainer: {
        paddingHorizontal: 0,
        paddingVertical: 0,
    },
    listContainer: {
        flexDirection: "column",
        height: "100%",
        width: "100%",
        padding: 0,
        margin: 0
    },
    item: {
        marginVertical: 4,
    },
    side_section: {
        flex: 1,
        justifyContent: 'center',
        display: 'flex'
    },
    tinyLogo: {
        width: 100,
        height: 100,
    },
    image: {
        width: 200,
        height: 200,
    },
    images: {
        flex: 1,
        flexDirection: 'column',
    },
}
);