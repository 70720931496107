import AsyncStorage from '@react-native-async-storage/async-storage';
import * as UpdateController from './update.controller';
import { Job } from '../models/Job.model';
import { Service } from '../models/Service';
import { Company } from '../models/Company.model'
import { Member } from '../models/Member.model';
import { Client } from '../models/Client.model'
import { User } from '../models/User.model';
import { setItem, getItem, removeItem } from '../services/indexdb';
//@ts-ignore
import CryptoJS from 'crypto-js';
import { AppStateModel } from '../models/AppStateModel.model';
import { performance } from 'universal-perf-hooks'

var access_token = null as string | null

export var appState = {
    user: {} as User,
    loggedIn: false,
    selectedMembership: null as Member | null,
    selectedCompany: {} as Company | null,
    selectedJob: {} as Job | null,
    selectedMemberToEdit: {} as Member | null,
    memberList: [] as Member[],
    members: [] as Member[],
    drivers: [] as Member[],
    selectedClientToEdit: {} as any,
    clients: [] as Client[],
    messageChannels: [],
    selectedChannel: null,
    unreadChannels: [],
    jobsList: [] as Job[],
    services: [] as Service[],
    vendorProfiles: [],
    // access_token: null as string | null,
    unreadMessageSenderIds: [] as string[],
    allMembershipUnreadMessages: [] as any[],
    companies: [] as Company[],
    selectedChatRecipient: null as Member | null,
    systemInfo: {} as any,
    jobs: [] as Job[],
    memberships: [] as Member[],
    use_test_api: false as boolean,
    showServiceAreas: false as boolean,
}

var defaultAppState = {
    user: {} as User,
    loggedIn: false,
    selectedMembership: null as Member | null,
    selectedCompany: {} as Company | null,
    selectedJob: {} as Job | null,
    selectedMemberToEdit: {} as Member | null,
    memberList: [] as Member[],
    members: [] as Member[],
    drivers: [] as Member[],
    selectedClientToEdit: {} as any,
    clients: [] as Client[],
    messageChannels: [],
    selectedChannel: null,
    unreadChannels: [],
    jobsList: [] as Job[],
    services: [] as Service[],
    vendorProfiles: [],
    // access_token: null as string | null,
    unreadMessageSenderIds: [] as string[],
    allMembershipUnreadMessages: [] as any[],
    companies: [] as Company[],
    selectedChatRecipient: null as Member | null,
    systemInfo: {} as any,
    jobs: [] as Job[],
    memberships: [] as Member[],
    use_test_api: false as boolean,
    showServiceAreas: false as boolean,
}


/**
 * Get the Users login Access Token
 * @returns  {string} - the access token
 */
export const getAccessToken = async () => {
    try {
        if (access_token) return access_token
        else {
            // const token = await AsyncStorage.getItem('access_token')
            const token = await getItem('access_token', false)
            return token || null
        }
    } catch (error) {
        console.log("🚀============== ~ file: storageController.ts:89 ~ getAccessToken ~ error🚀==============", error)

    }
    // return appState.access_token
}

export const setAccessToken = async (token: string) => {
    await setItem('access_token', token, false)
}

export const setRefreshAccessToken = async (token: string) => {
    await setItem('refresh_token', token, false)
}

export const getRefreshAccessToken = async () => {
    try {
        const token = await getItem('refresh_token', false)
        return token || null
    } catch (error) {
        console.log("🚀============== ~ file: storageController.ts:89 ~ getAccessToken ~ error🚀==============", error)

    }
}


const encryptData = (data: string, key: string) => {
    const encryptedData = CryptoJS.AES.encrypt(data, key).toString();
    return encryptedData;
}

const decryptData = (data: string, key: string) => {
    const bytes = CryptoJS.AES.decrypt(data, key);
    return bytes.toString(CryptoJS.enc.Utf8);
}

export const setDefaultAppState = async () => {
    try {
        // const access_token = await getAccessToken();
        appState = { ...defaultAppState };
        // const jsonValue = JSON.stringify(defaultAppState);
        const sanitisedAppState = sanitiseAppState(defaultAppState);
        await setItem('state', sanitisedAppState, false);
        try {
            // await AsyncStorage.removeItem('access_token');
            // await AsyncStorage.removeItem('selected_messages');
            await removeItem("use_test_api");
            await removeItem('access_token');
            await removeItem('refresh_token');
            await removeItem('selected_messages');
        } catch (e) {
            console.log("🚀============== ~ file: storageController.ts:117 ~ storeData ~ e🚀==============", e)
        }
        return
    } catch (e) {
        console.log("ERROR SAVING STATE", e);
    }
}


export function getAppState() {
    return appState as any as typeof defaultAppState;
}

function sanitiseAppState(state: any) {
    // return { ...state }
    return {
        user: state.user,
        loggedIn: state.loggedIn,
        selectedMembership: state.selectedMembership, // menus dont load correctly if this is not here
        selectedCompany: state.selectedCompany, // jobs dont load correctly if not here
        systemInfo: state.systemInfo,
        unreadMessageSenderIds: state.unreadMessageSenderIds,
    }
}

export async function loadStateFromDisk(overrideCurrentState = true) {
    try {
        let stateValue = await getItem('state', false);
        let use_test_api = await getUseTestApi();
        var res = appState;
        // if the appState is not an object, then it failed to decrypt. default to defaultAppState
        if (typeof stateValue !== 'object') {
            stateValue = defaultAppState;
        }
        if (stateValue != null) {
            // Always restore the saved state, but only override current state if specified
            if (overrideCurrentState) {
                appState = stateValue;
            } else {
                // When not overriding, still restore critical auth/session data
                appState = {
                    ...appState,
                    user: stateValue.user,
                    loggedIn: stateValue.loggedIn,
                    selectedMembership: stateValue.selectedMembership,
                    selectedCompany: stateValue.selectedCompany,
                };
            }
        } else {
            const sanitisedAppState = sanitiseAppState(defaultAppState);
            await setItem('state', sanitisedAppState, false);
        }
        if (use_test_api != null) {
            appState.use_test_api = use_test_api;
        }
        UpdateController.dispatchEventStateChange({ type: "SET_APP_STATE", data: res } as any);
        return appState;
    } catch (e) {
        console.warn("cant get", e);
        return null;
    }
}




export const saveStateToDisk = async () => {
    const storeData = async () => {
        try {
            const sanitisedAppState = sanitiseAppState(appState)
            await setItem('state', sanitisedAppState, false);
            return
        } catch (e) {
            console.log("ERROR SAVING STATE", e);
        }
    }
    // dispatch event state change to update the app state in the UI - Update in the next tick
    setTimeout(() => {
        UpdateController.dispatchEventStateChange({ type: "SET_APP_STATE", data: appState });
    }, 0);
    return storeData();
}


//////////////////////////////////////
//////////////////////////////////////
// Companies
//////////////////////////////////////
//////////////////////////////////////
export function getCurrentCompany(): Company {
    try {
        if (!getAppState().companies || getAppState().companies?.length == 0) return new Company({})
        const company = getAppState()?.companies?.find(c => c._id == getAppState()?.selectedCompany?._id)
        if (!company) {
            if (getAppState().companies && getAppState().companies.length > 0) {
                return getAppState()?.companies[0] || new Company({})
            }
        }
        return company || new Company({})
    }
    catch (e) {
        console.log("🚀============== ~ file: storagecontroller:143 ~ getCurrentCompany ~ e🚀==============", e)
        return new Company({})
    }
}





//////////////////////////////////////
//////////////////////////////////////
// Messaging
//////////////////////////////////////
//////////////////////////////////////

export const getSelectedMessages = async () => {
    try {
        // const messages = await AsyncStorage.getItem('selected_messages')
        const messages = await getItem('selected_messages', true)
        // console.log("MESSAGES", messages)
        if (messages != null) {
            return JSON.parse(messages)
        }
        else {
            return []
        }
    }
    catch (e) {
        console.log(e)
    }
}

export const setSelectedMessages = async (messages: any) => {
    try {
        // await AsyncStorage.setItem('selected_messages', JSON.stringify(messages))
        await setItem('selected_messages', JSON.stringify(messages), true)
    }
    catch (e) {
        console.log(e)
    }
}

// update messages
export const updateMessages = async (message: any) => {
    try {
        const m = await getSelectedMessages()
        // console.log("update MESSAGES", m)
        m.push(message)
        setSelectedMessages(m)
    }
    catch (e) {
        console.log(e)
    }
}


/*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////// USE TEST API
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/

export const getUseTestApi = async () => {
    try {
        // const use_test_api = await AsyncStorage.getItem('use_test_api')
        const use_test_api = await getItem('use_test_api', false)
        if (use_test_api != null) {
            return JSON.parse(use_test_api)
        }
        else {
            return false
        }
    }
    catch (e) {
        console.log(e)
    }
}

export const setUseTestApi = async (use_test_api: boolean) => {
    try {
        // await AsyncStorage.setItem('use_test_api', JSON.stringify(use_test_api))
        await setItem('use_test_api', JSON.stringify(use_test_api), false)
        appState.use_test_api = use_test_api
    }
    catch (e) {
        console.log(e)
    }
}
