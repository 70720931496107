import axios from 'axios';
import polyline from '@mapbox/polyline';
import { Route, RouteLocation, RouteLeg, ROUTE_START_END_OPTION, ROUTE_LOCATION_TYPE, IGoogleRouteLeg, RouteDetails } from '../models/Route.model';
import config from '../config/config.js';
import * as StorageController from './storageController';
import { Job, JobTowingDetails, TowingLocation, JobDirections, TowingLeg, JobLocation, Polyline as JobPolyLine, JobAddress, JOB_LOCATION_TYPE, Polyline, POLYLINE_TYPE } from '../models/Job.model';
import {
    OsrmLocation,
    VroomRequest,
    Job as VroomJob,
    Shipment,
    ShipmentStep,
    VroomResponseWithRoutes,
    VroomResponse,
    VroomRoute,
    Step,
    CombinedStep,
    Vehicle,
    VroomSummary,
} from '../types/route.types';
import { RouteOptimizationRequest } from '../types/route.types';
import { CompanySavedAddress } from '../models/Company.model';
import * as GoogleController from './google.controller';
const api = config.api;
const testApi = config.test_api;

function getApi() {
    if (StorageController.getAppState().use_test_api) {
        return testApi;
    }
    return api;
}


/*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////// OSRM SOLVE FUNCTIONS
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/

export async function getRouteDirections(route: Route): Promise<{ legs: RouteLeg[], routePolyline: any }> {
    const waypoints = getWaypoints(route.details.locations);
    if (waypoints.length < 2) return { legs: [], routePolyline: null };

    const osrmDirections = await getStaticRouteRequest(waypoints.map(waypoint => ({
        lat: waypoint.location.location.lat,
        lon: waypoint.location.location.lng
    })));

    if (!osrmDirections?.routes) return { legs: [], routePolyline: null };

    const routeResult = osrmDirections.routes[0];
    const legs = convertStepsToRouteLegs(routeResult.steps);
    const encodedPolyline = routeResult.overview_polyline;
    const routePolyline = polyline.decode(encodedPolyline as string, 6).map((point: any) => ({
        lat: point[0],
        lng: point[1]
    }));

    return { legs, routePolyline };
}



//#region Optimize Routes
/**
 * Setting the number of routes to 1 will make the function return a single route
 * Have set a towing "shipment" capacity to 100 to ensure all jobs are included in the same route and not split across multiple routes
 * It also ensures that the towing jobs are in order and not split up
 */
export async function getOptimizeRoutes(request: RouteOptimizationRequest): Promise<{ routes: Route[], summary: VroomSummary }> {
    console.log("🚀============== ~ file: osrm.controller.ts:72 ~ getOptimizeRoutes ~ request🚀==============", request)
    console.log("Starting route optimization for:", request);

    // Step 1: Generate all waypoints
    const waypoints = getWaypoints(request.locations);
    if (waypoints.length < 2) {
        console.log("Not enough waypoints for optimization");
        return { routes: [], summary: {} as VroomSummary };
    }

    const jobs: VroomJob[] = [];
    const shipments: Shipment[] = [];

    // Group waypoints by job_id
    const jobGroups = waypoints.reduce((groups, waypoint) => {
        const jobId = waypoint.location.job_id;
        if (!groups[jobId]) groups[jobId] = [];
        groups[jobId].push(waypoint);
        return groups;
    }, {} as { [key: string]: any[] });

    for (const [jobId, jobWaypoints] of Object.entries(jobGroups)) {
        if (jobWaypoints[0].location.is_towing_location) {
            // Add shipment for towing job
            shipments.push({
                id: jobId,
                amount: [100],
                pickup: createShipmentStep(jobWaypoints[0], 'pickup'),
                delivery: createShipmentStep(jobWaypoints[jobWaypoints.length - 1], 'delivery')
            });
        } else {
            // Regular job
            jobs.push(createRegularJob(jobWaypoints[0]));
        }
    }

    // Step 4: Generate vehicles
    const vehicles = generateVehicles(request.routeGroups, waypoints, jobs.length + shipments.length * 2, shipments, jobs);

    // Step 5: Create VROOM request
    const vroomRequest: VroomRequest = {
        jobs,
        shipments,
        vehicles: vehicles as Vehicle[],
        options: {
            g: true,
        }
    };

    console.log("🚀 VROOM Request:", vroomRequest);

    // Step 6: Make the API request to solve the routing problem
    const response = await solveVroomProblemWithRoutesRequest(vroomRequest);
    console.log("🚀 VROOM Response:", response);

    if (!response.success || !response.routes) {
        console.error("Error in getOptimizeRoute:", response.message || "No routes found");
        return { routes: [], summary: {} as VroomSummary };
    }

    // Step 7: Process the returned routes
    const processedRoutes = response.routes.map((vroomRoute) => {
        console.log("🚀============== ~ file: osrm.controller.ts:136 ~ processedRoutes ~ vroomRoute🚀==============", vroomRoute)
        const legs = convertStepsToRouteLegs(vroomRoute.steps);
        const vehicleId = vroomRoute.vehicle;

        const vehicle = vehicles.find((v) => v.id === vehicleId);
        let routeLocations: RouteLocation[] = []

        routeLocations = vroomRoute.steps
            .filter((step: any) => step.type === 'job' || step.type === 'pickup' || step.type === 'delivery')
            .map((step: any, stepIndex: number) => createRouteLocation(step, stepIndex, vehicle?.start, vehicle?.end))
            .filter((location): location is RouteLocation => location !== null);

        const jobIds = routeLocations.map((location: RouteLocation) => location.job_id).filter((jobId: string) => !!jobId);
        let vehicleDescription: any = ''
        try {
            vehicleDescription = JSON.parse(vehicle?.description || '{name: ""}')
        } catch (error) {
            console.log("Error parsing vehicle description:", error);
        }
        let day_month = new Date().toLocaleDateString('en-AU', { month: 'long', day: 'numeric' });
        let routeName = `R-${vroomRoute.vehicle}-${vehicleDescription?.name}-${day_month}`
        return new Route({
            _id: undefined, // Clear _id as this is a new route
            company_id: StorageController.getCurrentCompany()._id,
            name: routeName,
            job_ids: jobIds,
            details: new RouteDetails({
                legs,
                // overview_polyline: vroomRoute.geometry,
                overview_polyline: vroomRoute.overview_polyline,
                locations: routeLocations,
                distance_kms: vroomRoute.distance ? vroomRoute.distance / 1000 : 0, // Convert meters to kilometers
                duration_seconds: vroomRoute.duration,
            })
        });
    });

    console.log("🚀 Processed Routes:", processedRoutes);
    return {
        routes: processedRoutes,
        summary: response.summary as VroomSummary
    };
}

// Helper functions

function getWaypoints(locations: RouteLocation[]) {
    const waypoints = locations.map((location, index) => ({
        index: index + 1,
        location: location as RouteLocation
    }));
    // console.log("🚀============== ~ file: osrm.controller.ts:223 ~ getWaypoints ~ waypoints🚀==============", waypoints)
    return waypoints
}

function createRegularJob(waypoint: any): VroomJob {
    const locationJson = JSON.stringify(waypoint.location);
    return {
        amount: [1],
        id: waypoint.index,
        location: [waypoint.location.location.lng, waypoint.location.location.lat],
        description: locationJson
    };
}


/*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
// #region OSRM ROUTES - VROOM
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/
// router.post("/osrm/vroom/full-routes", OsrmService.solveVroomProblemWithRoutesRequest);

export async function solveVroomProblemWithRoutesRequest(request: VroomRequest): Promise<VroomResponseWithRoutes> {
    try {
        const response = await axios.post(getApi() + "/osrm/vroom/full-routes", request);
        return response.data;
    } catch (error) {
        console.log(error);
        return {
            success: false,
            message: "Error solving VROOM problem",
            code: 500
        }
    }
}


// router.post("/osrm/static-route", OsrmService.getStaticRouteRequest);

export async function getStaticRouteRequest(locations: OsrmLocation[]): Promise<VroomResponseWithRoutes> {
    try {
        const data = {
            locations: locations
        }
        const response = await axios.post(getApi() + "/osrm/static-route", data);
        return response.data;
    } catch (error) {
        console.log(error);
        return {
            success: false,
            message: "Error solving VROOM problem",
            code: 500
        }
    }
}




/*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
// #region UTILS
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/



export const convertLegsToRouteLegs = (legs: IGoogleRouteLeg[]) => {
    return legs.map((leg, index) => {
        const newLeg = new RouteLeg({
            index: index,
            origin: leg.startLocation.latLng,
            destination: leg.endLocation.latLng,
            polyline: leg.polyline.encodedPolyline,
            actual_distance_kms: leg.distanceMeters / 1000,
            actual_duration_seconds: parseInt(leg.duration.replace('s', '')),
        });
        return newLeg;
    });
};


export const convertOSRMPolylineStringToGooglePolylineObject = (polylineString: string) => {
    if (!polylineString || polylineString.length === 0) return [];
    const decoded = polyline.decode(polylineString, 6)
    const combinedDecoded = decoded.map((point: any) => {
        return { lat: point[0], lng: point[1] }
    })
    return combinedDecoded
}


export const convertStepsToRouteLegs = (steps: CombinedStep[]) => {
    return steps.map((step, index) => {
        const googlePolyline = convertOSRMPolylineToGooglePolylineObject(step.polyline as string[]);
        const newLeg = new RouteLeg({
            index: index,
            origin: step.startLocation,
            destination: step.endLocation,
            polyline: googlePolyline,
            actual_distance_kms: step.distance / 1000,
            actual_duration_seconds: step.duration,
        });
        return newLeg;
    });
};
/**
 * Util function to convert OSRM polylines to Google polyline objects
 * @param geometry Array of lat/lng objects
 * @returns Google Polyline encoded object {lat: number, lng: number}[]
 */
export const convertOSRMPolylineToGooglePolylineObject = (geometry: string[]) => {
    if (!geometry) return [];
    const decodedPolyline = geometry?.map((polylineString: string) => {
        if (polylineString.length > 0) {
            const decoded = polyline.decode(polylineString, 6)
            const combinedDecoded = decoded.map((point: any) => {
                return { lat: point[0], lng: point[1] }
            })
            return combinedDecoded
        }
        return null
    }) || []
    const combinedPolyline = decodedPolyline.filter((point: any) => point !== null).flat();
    return combinedPolyline
};

/**
 * Util function to convert OSRM polylines to an array of lat/lng objects
 * @param geometry Array of encoded polyline strings
 * @returns Array of {lat: number, lng: number} objects
 */
export const convertOSRMPolylineToArray = (geometry: string[]): [number, number][] => {
    if (!geometry) return [];
    const decodedPolyline = geometry?.map((polylineString: string) => {
        if (polylineString.length > 0) {
            const decoded = polyline.decode(polylineString, 6)
            const combinedDecoded = decoded.map((point: any) => {
                return [point[0], point[1]]
            })
            return combinedDecoded
        }
        return null
    }) || []
    const combinedPolyline = decodedPolyline.filter((point: any) => point !== null).flat();
    return combinedPolyline as [number, number][]
};



async function generateTowingJobRoute(towingJob: RouteLocation[]): Promise<RouteLeg[]> {
    const waypoints = towingJob.map(location => ({
        lat: location.location.lat,
        lon: location.location.lng
    }));


    const osrmDirections = await getStaticRouteRequest(waypoints);
    console.log("🚀============== ~ file: osrm.controller.ts:856 ~ generateTowingJobRoute ~ osrmDirections🚀==============", osrmDirections)

    if (!osrmDirections?.routes) return [];

    return convertStepsToRouteLegs(osrmDirections.routes[0].steps);
}

function createShipmentStep(waypoint: any, type: 'pickup' | 'delivery'): ShipmentStep {
    const locationJson = JSON.stringify(waypoint.location);
    return {
        id: waypoint.index,
        location: [waypoint.location.location.lng, waypoint.location.location.lat],
        description: locationJson
    };
}



function createRouteLocation(step: any, index: number, vehicleStart?: [number, number], vehicleEnd?: [number, number]): RouteLocation | null {
    let locationData: Partial<RouteLocation> = {};
    try {
        locationData = JSON.parse(step.description);
    } catch (error) {
        console.log(`Error parsing location data for step ${index}:`, error);
    }

    if (!locationData) {
        console.log(`Location data not found for step index ${index}`);
        return null;
    }

    const baseLocation = {
        index,
        job_id: locationData.job_id || null,
        location: new JobLocation({ lat: step.location[1], lng: step.location[0] }),
        name: locationData.name || '',
        address: locationData.address || '',
        is_towing_location: locationData.is_towing_location || false,
        job_location_index: locationData.job_location_index || null
    };

    if (step.type === 'start') {
        return new RouteLocation({
            ...baseLocation,
            route_location_type: ROUTE_LOCATION_TYPE.PICKUP,
            name: 'Start',
            location: vehicleStart ? new JobLocation({ lat: vehicleStart[1], lng: vehicleStart[0] }) : baseLocation.location
        });
    } else if (step.type === 'end') {
        return new RouteLocation({
            ...baseLocation,
            route_location_type: ROUTE_LOCATION_TYPE.RETURN,
            name: 'End',
            location: vehicleEnd ? new JobLocation({ lat: vehicleEnd[1], lng: vehicleEnd[0] }) : baseLocation.location
        });
    }

    return new RouteLocation({
        ...baseLocation,
        route_location_type: step.type === 'pickup' ? ROUTE_LOCATION_TYPE.PICKUP :
            step.type === 'delivery' ? ROUTE_LOCATION_TYPE.DELIVERY :
                locationData.route_location_type || ROUTE_LOCATION_TYPE.JOB
    });
}


function createRouteLocation_old(step: any, index: number, startLocation: RouteLocation, endLocation: RouteLocation): RouteLocation | null {
    let locationData = new RouteLocation();
    try {
        locationData = JSON.parse(step.description);
    } catch (error) {
        // console.log(`Error parsing location data for step ${index}:`, error);
        // return null;
    }

    if (!locationData) {
        console.log(`Location data not found for step index ${index}`);
        return null;
    }



    if (step.type === 'start' || step.type === 'end') {
        return new RouteLocation({
            index,
            job_id: null,
            location: new JobLocation({ lat: step.location[1], lng: step.location[0] }),
            route_location_type: step.type === 'start' ? ROUTE_LOCATION_TYPE.PICKUP : ROUTE_LOCATION_TYPE.RETURN,
            name: step.type === 'start' ? 'Start' : 'End',
            address: step.type === 'start' ? startLocation.address : endLocation.address,
            is_towing_location: false,
            job_location_index: null
        });
    }

    return new RouteLocation({
        index,
        job_id: locationData.job_id,
        location: new JobLocation({ lat: step.location[1], lng: step.location[0] }),
        route_location_type: step.type === 'pickup' ? ROUTE_LOCATION_TYPE.PICKUP :
            step.type === 'delivery' ? ROUTE_LOCATION_TYPE.DELIVERY :
                locationData.route_location_type || ROUTE_LOCATION_TYPE.JOB,
        name: locationData.name,
        address: locationData.address,
        is_towing_location: locationData.is_towing_location,
        job_location_index: locationData.job_location_index
    });
}



//#region NEW 
function injectTowingJobRoutes(vroomSteps: any[], towingJobRoutes: { [key: string]: RouteLeg[] }): any[] {
    const result = vroomSteps.map(step => {
        const jobId = step.location.job_id;
        if (jobId && towingJobRoutes[jobId]) {
            const towingRoute = towingJobRoutes[jobId];
            const firstLeg = towingRoute[0];
            const lastLeg = towingRoute[towingRoute.length - 1];

            if (step.type === 'pickup') {
                return {
                    ...step,
                    location: {
                        ...step.location,
                        job_id: jobId
                    }
                };
            }
            return {
                ...step,
                location: {
                    ...step.location,
                    job_id: jobId
                }
            };
        }
        return step;
    });
    return result;
}


/*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
// #region GENERATE VEHICLES
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/
function generateVehicles(routeGroups: any[], waypoints: any[], totalTasks: number, shipments: any[], jobs: any[]): Vehicle[] {
    const vehicles: Vehicle[] = [];
    let vehicleId = 1;
    const totalVehicles = routeGroups.reduce((sum, group) => sum + group.numberOfVehicles, 0);

    const baseTasksPerVehicle = Math.floor(totalTasks / totalVehicles);
    const extraTasks = totalTasks % totalVehicles;

    let assignedTasks = 0;

    for (const group of routeGroups) {
        console.log("🚀============== ~ file: osrm.controller.ts:485 ~ generateVehicles ~ group🚀==============", group)
        for (let i = 0; i < group.numberOfVehicles; i++) {
            const { startLocation, endLocation } = getStartEndLocations(group, waypoints);

            const extraTask = i < extraTasks ? 1 : 0;
            let taskCount = baseTasksPerVehicle + extraTask;

            // Ensure even `max_tasks` if shipments are present
            if (shipments.length > 0) {
                const baseShipmentsPerVehicle = Math.floor(shipments.length / totalVehicles);
                const extraShipment = i < (shipments.length % totalVehicles) ? 1 : 0;
                const shipmentCount = baseShipmentsPerVehicle + extraShipment;

                const minTaskCount = shipmentCount * 2;
                if (taskCount < minTaskCount) {
                    taskCount = minTaskCount;
                    // If jobs are present, add them to `taskCount` as needed
                    const remainingTasks = totalTasks - (totalVehicles * minTaskCount);
                    if (remainingTasks > 0) {
                        taskCount += remainingTasks >= extraTask ? extraTask : 0;
                    }
                }
            }

            const getTripName = (group: any) => {
                let name = ""
                switch (group.startEndOption) {
                    case ROUTE_START_END_OPTION.DEFAULT:
                    case ROUTE_START_END_OPTION.START_FIRST_END_LAST:
                        name = `${group.startAddress?.name} - ${group.endAddress?.name}`
                        break
                    case ROUTE_START_END_OPTION.ROUND_TRIP:
                        name = group.roundTripAddress?.name
                        break
                    case ROUTE_START_END_OPTION.CUSTOM:
                        name = `${group.startAddress?.name} - ${group.endAddress?.name}`
                        break
                }
                return name
            }

            const description = {
                start: new RouteLocation(startLocation),
                end: new RouteLocation(endLocation),
                groupStartEndOption: group.startEndOption,
                name: getTripName(group)
            };

            const vehicle: Vehicle = {
                id: vehicleId++,
                start: [startLocation.lng, startLocation.lat],
                end: [endLocation.lng, endLocation.lat],
                profile: 'car',
                description: JSON.stringify(description),
                capacity: [100],
                max_tasks: taskCount
            };

            vehicles.push(vehicle);
            assignedTasks += taskCount;
        }
    }

    // Ensure all vehicles have at least 2 max_tasks if shipments are present
    if (shipments.length > 0) {
        vehicles.forEach(vehicle => {
            if (vehicle.max_tasks && vehicle.max_tasks < 2) {
                vehicle.max_tasks = 2;
            }
        });
    }

    return vehicles;
}

function getStartEndLocations(group: any, waypoints: any[]): { startLocation: any, endLocation: any } {
    let startLocation: any, endLocation: any;

    switch (group.startEndOption) {
        case ROUTE_START_END_OPTION.DEFAULT:
        case ROUTE_START_END_OPTION.START_FIRST_END_LAST:
            startLocation = group.startAddress ? group.startAddress.location : waypoints[0].location.location;
            endLocation = group.endAddress ? group.endAddress.location : waypoints[waypoints.length - 1].location.location;
            break;
        case ROUTE_START_END_OPTION.ROUND_TRIP:
            startLocation = group.roundTripAddress ? group.roundTripAddress.location : waypoints[0].location.location;
            endLocation = startLocation;
            break;
        case ROUTE_START_END_OPTION.CUSTOM:
            startLocation = group.startAddress ? group.startAddress.location : waypoints[0].location.location;
            endLocation = group.endAddress ? group.endAddress.location : waypoints[waypoints.length - 1].location.location;
            break;
    }

    return { startLocation, endLocation };
}

/**
 * Gets directions for towing jobs using OSRM static route request
 * Returns a JobDirections object to maintain compatibility with existing code
 * @param origin JobLocation object with lat/lng
 * @param destination JobLocation object with lat/lng
 * @returns JobDirections object or null if request fails
 */
export async function getJobDirections(origin: JobLocation, destination: JobLocation): Promise<JobDirections | null> {
    try {
        const locations = [
            { lat: origin.lat, lon: origin.lng },
            { lat: destination.lat, lon: destination.lng }
        ];

        const osrmResponse = await getStaticRouteRequest(locations);
        console.log("🚀============== ~ file: osrm.controller.ts:612 ~ getTowingDirections ~ osrmResponse🚀==============", osrmResponse)
        if (!osrmResponse?.routes?.[0]) {
            return null;
        }

        const route = osrmResponse.routes[0].standard_route;
        if (!route) {
            console.log("Missing required route properties in OSRM response");
            return null;
        }

        // Convert meters to text format like "X km"
        const distanceKm = route.details.distance_kms || 0
        const distanceText = `${distanceKm.toFixed(1)} km`;

        // Convert seconds to text format like "X mins"
        const durationMins = (route.details.duration_seconds || 0) / 60 || 0
        const durationText = `${Math.round(durationMins)} mins`;

        // lets encode this as a google polyine string
        const osrmPolylineString = route.details.overview_polyline as string
        const decodedGooglePolylineObject = convertOSRMPolylineStringToGooglePolylineObject(osrmPolylineString) // google maps compatible polyline
        const googlePolylineString = GoogleController.encodePolyline(decodedGooglePolylineObject)
        // const polyline = new Polyline({ polyline: route.details.overview_polyline, type: POLYLINE_TYPE.OSRM })

        return new JobDirections({
            distance_text: distanceText,
            distance_value: distanceKm * 1000, // OSRM returns distance in meters
            duration_text: durationText,
            duration_value: route.details.duration_seconds || 0, // OSRM returns duration in seconds
            polyline: googlePolylineString,
        });
    } catch (error) {
        console.log("Error in getTowingDirections:", error);
        return null;
    }
}