import React, { useState, useEffect, useRef, useReducer, useMemo, useCallback } from 'react';
//@ts-ignore
import { useWindowDimensions, StyleSheet, View, TouchableOpacity, ScrollView } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
//@ts-ignore
import { Divider, Icon, Layout, Text, TopNavigation, TopNavigationAction, Card, Button, Input, ButtonGroup, CheckBox, Modal, RangeDatepicker, Datepicker } from '@ui-kitten/components';
import moment from 'moment';
import * as StorageController from '../../functions/storageController'
import * as JobController from '../../functions/job.controller'
import * as InvoiceController from '../../functions/invoice.controller'
import * as UpdateController from '../../functions/update.controller'
import * as ServiceController from '../../functions/service.controller'
import ErrorBoundary from '../ErrorBoundary.component';

import { Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale, Title } from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";
import { JOB_STATUS, Job } from '../../models/Job.model';
import { Service } from '../../models/Service';
import { ReportDetailsCard } from '../job/details/reportDetailsCard.component';
import { InvoicedTotalCard } from '../job/details/jobInvoicedTotalDetailsCard.component';
import { DashboardMap } from './dashboardMap.component';
import { RenderSearchJobsByDateRange } from './searchArea.component';
import { Client } from '../../models/Client.model';
import { Member } from '../../models/Member.model';
import { DailySummary, DashboardWeeklySummary, DashboardReport } from './DashboardScreen.component';
import { formatTime, calculatePercentage } from "./dashboardUtils"

export const WeeklySummaries = ({
    weeklySummaries
}: {
    weeklySummaries: DashboardWeeklySummary[]
}) => {
    if (!weeklySummaries) return (<></>)
    return (
        <Layout style={{ flex: 1, flexDirection: 'column', justifyContent: 'space-between', padding: 10, borderWidth: 1, borderColor: '#ccc', borderRadius: 4, marginBottom: 10 }}>
            <Layout style={{ flexDirection: 'column', justifyContent: 'space-between', padding: 10, borderWidth: 1, borderColor: '#ccc', borderRadius: 4, marginBottom: 10 }}>
                <Layout style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10, borderBottomWidth: 1, borderBottomColor: '#ccc' }}>
                    <Text category='s1' style={{ flex: 1, textAlign: 'center' }}>Dates</Text>
                    <Text category='s1' style={{ flex: 1, textAlign: 'center' }}>Number Of Jobs</Text>
                    <Text category='s1' style={{ flex: 1, textAlign: 'center' }}>Average Time To Arrive</Text>
                    <Text category='s1' style={{ flex: 1, textAlign: 'center' }}>Under 45 Mins</Text>
                    <Text category='s1' style={{ flex: 1, textAlign: 'center' }}>Under 60 Mins</Text>
                    <Text category='s1' style={{ flex: 1, textAlign: 'center' }}>Under 90 Mins</Text>
                    <Text category='s1' style={{ flex: 1, textAlign: 'center' }}>Average Job Time</Text>
                    <Text category='s1' style={{ flex: 1, textAlign: 'center' }}>Callout Total</Text>
                </Layout>
                {weeklySummaries?.map((summary: DashboardWeeklySummary, index: number) => (
                    <View key={index}>
                        {index > 0 &&
                            <Layout style={{ backgroundColor: index % 2 == 0 ? 'rgba(150,200,200,0.3)' : 'rgba(100,100,200,0.3)' }}>
                                <WeeklySummary
                                    startDate={summary.startDate}
                                    endDate={summary.endDate}
                                    averageJobTime={summary.averageJobTime}
                                    averageTimeOfArrival={summary.averageTimeOfArrival}
                                    totalCalloutFees={summary.totalCalloutFees}
                                    totalNumberOfJobs={summary.totalNumberOfJobs}
                                    jobsUnder45Mins={summary.jobsUnder45Mins}
                                    jobsUnder60Mins={summary.jobsUnder60Mins}
                                    jobsUnder90Mins={summary.jobsUnder90Mins}
                                />
                            </Layout>
                        }
                    </View>
                ))}
            </Layout>
        </Layout>
    )
}

export const WeeklySummary = ({
    startDate,
    endDate,
    averageJobTime,
    averageTimeOfArrival,
    totalCalloutFees,
    totalNumberOfJobs,
    jobsUnder45Mins,
    jobsUnder60Mins,
    jobsUnder90Mins,
}: DashboardWeeklySummary) => {



    const renderJobTime = (jobTime: number) => {
        if (isNaN(Number(jobTime))) return "00:00:00";
        return formatTime(jobTime);
    }

    return (
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', padding: 10, borderBottomWidth: 1, borderBottomColor: '#ccc' }}>
            <Text style={{ flex: 1, textAlign: 'center' }}>{startDate.toLocaleString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })} - {endDate.toLocaleString('en-AU', { day: 'numeric', month: 'short', year: 'numeric' })}</Text>
            <Text style={{ flex: 1, textAlign: 'center' }}>{totalNumberOfJobs}</Text>
            <Text style={{ flex: 1, textAlign: 'center' }}>{renderJobTime(averageTimeOfArrival)}</Text>
            <Text style={{ flex: 1, textAlign: 'center' }}>{jobsUnder45Mins} ({calculatePercentage(jobsUnder45Mins, totalNumberOfJobs)})</Text>
            <Text style={{ flex: 1, textAlign: 'center' }}>{jobsUnder60Mins} ({calculatePercentage(jobsUnder60Mins, totalNumberOfJobs)})</Text>
            <Text style={{ flex: 1, textAlign: 'center' }}>{jobsUnder90Mins} ({calculatePercentage(jobsUnder90Mins, totalNumberOfJobs)})</Text>
            <Text style={{ flex: 1, textAlign: 'center' }}>{renderJobTime(averageJobTime)}</Text>
            <Text style={{ flex: 1, textAlign: 'center' }}>${totalCalloutFees.toFixed(2)}</Text>
        </View>
    )

}
