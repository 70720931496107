import React, { useState, useEffect } from 'react';
import { View, StyleSheet, ScrollView, useWindowDimensions } from 'react-native';
import { Button, Text, Layout, Input, Card, Select, SelectItem, Spinner } from '@ui-kitten/components';
import { Order, OrderDetails, OrderItem, OrderStatus } from '../../models/Order.model';
import { Company, CompanySavedAddress } from '../../models/Company.model';
import { JobAddress, JobCustomerDetails, JobLocation, JobVehicleDetails, LINE_ITEM_BILLED_TO } from '../../models/Job.model';
import { LineItem } from '../../models/LineItem.model';
import { AddressDetailsCard } from '../common/address/AddressComponents';
import * as ClientController from '../../functions/client.controller';
import { Client } from '../../models/Client.model';
import { createOrder } from '../../functions/order.controller';
import * as StorageController from '../../functions/storageController';
import { InventoryDetailsCard } from '../job/details/inventory/inventoryDetailsCard.component';
import { VendorProfile } from '../../models/VendorProfile.model';

interface CreateOrderComponentProps {

    onOrderCreated: (order: Order) => void;
    onClose: () => void;
}

export const CreateOrderComponent: React.FC<CreateOrderComponentProps> = ({
    onOrderCreated,
    onClose,
}) => {
    const currentCompany = StorageController.getCurrentCompany();
    const [isLoading, setIsLoading] = useState(false);
    const [clients, setClients] = useState<Client[]>([]);
    const [selectedClient, setSelectedClient] = useState<Client | null>(null);
    const [selectedItems, setSelectedItems] = useState<LineItem[]>([]);
    const [orderDetails, setOrderDetails] = useState<OrderDetails>({
        dispatch_comments: '',
        vehicle_details: new JobVehicleDetails({}),
        customer_details: new JobCustomerDetails({}),
        address: new JobAddress({}),
        location: new JobLocation({}),
        delivery_fee: 0,
        scheduled_date: null,
        client_company_name: '',
        vendor_company_name: currentCompany?.name ?? '',
    });

    const { width, height } = useWindowDimensions();

    const IS_SMALL_SCREEN = width < 768;

    useEffect(() => {
        loadClients();
    }, []);

    const loadClients = async () => {
        setIsLoading(true);
        try {
            const fetchedClients = await ClientController.getClientsByCompanyId(currentCompany?._id);
            setClients(fetchedClients.filter((client: Client) => !client.deleted));
        } catch (error) {
            console.error('Error loading clients:', error);
        }
        setIsLoading(false);
    };

    const handleClientSelect = async (clientId: string) => {
        const client = clients.find(c => c._id === clientId);
        if (client) {
            setSelectedClient(client);
            setOrderDetails(prev => ({
                ...prev,
                customer_details: new JobCustomerDetails({
                    name: client.name,
                    phone: client.contact_info.phone,
                    email: client.contact_info.email,
                }),
                address: new JobAddress(client.contact_info.address),
                location: new JobLocation(client.contact_info.location),
                client_company_name: client.name,
            }));
        }
    };

    const calculateTotal = () => {
        return selectedItems.reduce((sum, item) => sum + (item.cost * item.quantity), 0);
    };

    const handleCreateOrder = async () => {
        if (!selectedClient) return;

        setIsLoading(true);
        try {
            // Convert LineItems to OrderItems
            const orderItems = selectedItems.map(item => {
                return new OrderItem({
                    inventory_item_id: item.inventoryItemId,
                    quantity: item.quantity,
                    price: item.cost,
                    billed_to: item.billed_to,
                    // is_gst_inclusive: item.is_gst_inclusive
                })
            });

            const order = new Order({
                company_id: currentCompany?._id,
                client_company_id: selectedClient.linkedClientAccountId || null,
                vendor_company_id: currentCompany?._id,
                client_profile_id: selectedClient._id,
                // vendor_profile_id: selectedVendor?._id,
                items: orderItems as OrderItem[],
                total_amount: calculateTotal(),
                status: OrderStatus.REQUEST,
                details: orderDetails,
            });

            const createdOrder = await createOrder(order);
            if (createdOrder) {
                console.log("🚀============== ~ file: CreateOrderComponent.tsx:114 ~ handleCreateOrder ~ createdOrder🚀==============", createdOrder)
                onOrderCreated(createdOrder);
            }
        } catch (error) {
            console.error('Error creating order:', error);
        }
        setIsLoading(false);
    };

    const renderClientSelect = () => (
        <Card style={styles.card}>
            <Text category="h6">Select Client</Text>
            <Select
                label="Client"
                value={selectedClient?.name}
                // @ts-ignore
                onSelect={index => handleClientSelect(clients[index.row]._id as string)}
            >
                {clients.map(client =>
                    <SelectItem key={client._id} title={client.name} />
                )}
            </Select>
        </Card>
    );

    const renderCustomerDetails = () => (
        <Card style={styles.card}>
            <Text category="h6">Customer Details</Text>
            <Input
                label="Name"
                value={orderDetails.customer_details.name}
                onChangeText={value =>
                    setOrderDetails(prev => ({
                        ...prev,
                        customer_details: { ...prev.customer_details, name: value }
                    }))
                }
            />
            <Input
                label="Phone"
                value={orderDetails.customer_details.phone}
                onChangeText={value =>
                    setOrderDetails(prev => ({
                        ...prev,
                        customer_details: { ...prev.customer_details, phone: value }
                    }))
                }
            />
            <Input
                label="Email"
                value={orderDetails.customer_details.email}
                onChangeText={value =>
                    setOrderDetails(prev => ({
                        ...prev,
                        customer_details: { ...prev.customer_details, email: value }
                    }))
                }
            />
        </Card>
    );

    if (isLoading) {
        return (
            <Layout style={styles.loadingContainer}>
                <Spinner size="large" />
            </Layout>
        );
    }

    return (
        <Layout style={[styles.container, { width: width * 0.9, height: height * 0.9 }]}>
            <View style={styles.header}>
                <Text category="h5">Create New Order</Text>
                <Button appearance="ghost" status="danger" onPress={onClose}>X</Button>
            </View>

            <ScrollView style={styles.content}>
                <View style={styles.columns}>
                    <View style={styles.leftColumn}>
                        {renderClientSelect()}
                        {renderCustomerDetails()}
                        <AddressDetailsCard
                            address={orderDetails.address}
                            onSetAddress={address => setOrderDetails(prev => ({ ...prev, address }))}
                            onSetLocation={location => setOrderDetails(prev => ({ ...prev, location }))}
                            onSetSavedAddress={(savedAddress: CompanySavedAddress) => {
                                let _orderDetails = { ...orderDetails }
                                _orderDetails.address = new JobAddress(savedAddress.address)
                                _orderDetails.location = new JobLocation(savedAddress.location)
                                setOrderDetails(_orderDetails)
                            }}
                            addressDisplayValue={orderDetails.address.formatted_address}
                            allowEdit={true}
                        />
                    </View>

                    <View style={styles.rightColumn}>
                        {selectedClient &&
                            <InventoryDetailsCard
                                selectedItems={selectedItems}
                                onChangeSelectedItems={setSelectedItems}
                                vendorProfile={null}
                                jobSource="vendor"
                                allowEdit={true}
                                selectedClient={selectedClient}
                                showInventoryList={!IS_SMALL_SCREEN}
                            />
                        }
                    </View>
                </View>
            </ScrollView>

            <Button
                onPress={handleCreateOrder}
                disabled={!selectedClient || selectedItems.length === 0}
                style={styles.createButton}
            >
                Create Order
            </Button>
        </Layout>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 16,
    },
    loadingContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 16,
    },
    content: {
        flex: 1,
    },
    columns: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 16,
    },
    leftColumn: {
        flex: 1,
        flexWrap: 'wrap',
        minWidth: 300,
    },
    rightColumn: {
        flex: 2,
        flexWrap: 'wrap',
        minWidth: 300,
    },
    card: {
        marginBottom: 16,
        padding: 16,
    },
    createButton: {
        marginTop: 16,
    },
}); 