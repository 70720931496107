import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import {
    StyleSheet,
    View,
} from "react-native";
import {
    Card,
    Input,
    Text,
    Toggle,
    Select,
    SelectItem,
    IndexPath,
    Button,
    Icon,
} from "@ui-kitten/components";
import { LineItem, LINE_ITEM_TYPE } from '../../../models/LineItem.model';
import { LINE_ITEM_BILLED_TO } from '../../../models/Job.model';
import { SURCHARGE_TYPE, SurchargeDefinition, DEFAULT_SURCHARGE_DEFINITIONS } from '../../../models/Surcharge.model';

interface SurchargeDetailsCardProps {
    style?: any;
    lineItems: LineItem[];
    onUpdateLineItems: (items: LineItem[]) => void;
    allowEdit?: boolean;
    surchargeDefinitions?: SurchargeDefinition[];
    distance_m?: number;
    isNewJob?: boolean;
}

export const SurchargesDetailsCard = ({
    style,
    lineItems = [],
    onUpdateLineItems,
    allowEdit = true,
    surchargeDefinitions = DEFAULT_SURCHARGE_DEFINITIONS,
    distance_m = 0,
    isNewJob = false,
}: SurchargeDetailsCardProps) => {
    const [localSurcharges, setLocalSurcharges] = useState(() => {
        return surchargeDefinitions.map(def => ({
            ...def,
            enabled: false,
            rate: def.defaultRate,
            distance_km: distance_m / 1000,
            billed_to: LINE_ITEM_BILLED_TO.CUSTOMER_COST
        }));
    });

    const [editingValues, setEditingValues] = useState<{
        [key: string]: {
            rate?: string;
            distance_km?: string;
        }
    }>({});

    const editingValuesRef = useRef(editingValues);

    const onSetEditingValues = useCallback((newValues: typeof editingValues) => {
        editingValuesRef.current = newValues;
        setEditingValues(newValues);
    }, []);

    // useEffect(() => {
    //     console.log("distance_m", distance_m);
    //     setTimeout(() => {
    //         setLocalSurcharges(prev => prev.map(surcharge => ({
    //             ...surcharge,
    //             distance_km: distance_m / 1000
    //         })));
    //     }, 0);
    //     // now update the line items
    //     setTimeout(() => {
    //         localSurcharges.forEach(surcharge => {
    //             updateLineItems(surcharge);
    //         });
    //     }, 1000);
    // }, [distance_m]);

    // initial load of surcharges from the line items
    useEffect(() => {
        const surcharges = lineItems.filter(item => item.type === LINE_ITEM_TYPE.SURCHARGE);
        if (isNewJob) {
            // check those surcharges against the surchargeDefinitions and set the enabled property with the correct values
            const updatedSurcharges = surchargeDefinitions.map((def: SurchargeDefinition) => {
                const surcharge = surcharges.find(surcharge => surcharge._id === def.id);
                return {
                    ...def,
                    enabled: def.default_enabled || false,
                    rate: surcharge?.cost ?? def.defaultRate,
                    distance_km: (distance_m / 1000) ?? 0
                } as SurchargeDefinition;
            });
            setTimeout(() => {
                console.log("🚀============== ~ file: surchargesDetailsCard.component.tsx:78 ~ updatedSurcharges ~ updatedSurcharges🚀==============", updatedSurcharges)
                // @ts-ignore
                setLocalSurcharges(updatedSurcharges);
            }, 1000);
            return;
        }
        else {

            const updatedSurcharges = surchargeDefinitions.map((def: SurchargeDefinition) => {
                const surcharge = surcharges.find(surcharge => surcharge._id === def.id);
                return {
                    ...def,
                    enabled: !!surcharge,
                    rate: surcharge?.cost ?? def.defaultRate,
                    distance_km: (distance_m / 1000) ?? 0
                } as SurchargeDefinition;
            });
            // @ts-ignore
            setLocalSurcharges(updatedSurcharges);
        }
    }, []);

    const [inputErrors, setInputErrors] = useState<{ [key: string]: string }>({});

    const calculateTotal = useCallback((surcharge: typeof localSurcharges[0]) => {
        if (surcharge.type === SURCHARGE_TYPE.PERCENTAGE) {
            const totalWithoutPercentageSurcharges = lineItems
                .filter(item => !(item.type === LINE_ITEM_TYPE.SURCHARGE && item.name.includes('%')))
                .reduce((sum, item) => sum + item.cost, 0);
            return (surcharge.rate / 100) * totalWithoutPercentageSurcharges;
        }
        switch (surcharge.type) {
            case SURCHARGE_TYPE.PER_KM:
                return surcharge.rate * surcharge.distance_km;
            default:
                return surcharge.rate;
        }
    }, [lineItems]);

    const updateLineItems = useCallback((surcharge: typeof localSurcharges[0]) => {
        if (!surcharge.enabled) {
            const filteredItems = lineItems.filter(item =>
                !(item.type === LINE_ITEM_TYPE.SURCHARGE && item._id === surcharge.id)
            );
            onUpdateLineItems(filteredItems);
            return;
        }

        const updatedItems = lineItems.filter(item =>
            !(item.type === LINE_ITEM_TYPE.SURCHARGE && item._id === surcharge.id)
        );

        let lineItemName = surcharge.name;
        if (surcharge.type === SURCHARGE_TYPE.PER_KM) {
            lineItemName = `${lineItemName} (${surcharge.distance_km.toFixed(2)} km) At $${surcharge.rate.toFixed(2)}/km`;
        }
        if (surcharge.type === SURCHARGE_TYPE.PERCENTAGE) {
            lineItemName = `${lineItemName} (${surcharge.rate.toFixed(2)}%)`;
        }
        console.log("🚀============== ~ file: surchargesDetailsCard.component.tsx:130 ~ updateLineItems ~ surcharge🚀==============", surcharge)
        console.log("🚀============== ~ file: surchargesDetailsCard.component.tsx:130 ~ updateLineItems ~ lineItemName🚀==============", lineItemName)

        const newLineItem = new LineItem({
            _id: surcharge.id,
            name: lineItemName,
            description: surcharge.description,
            cost: calculateTotal(surcharge),
            quantity: 1,
            billed_to: surcharge.billed_to,
            type: LINE_ITEM_TYPE.SURCHARGE
        });

        setTimeout(() => {
            onUpdateLineItems([...updatedItems, newLineItem]);
        }, 0);
    }, [lineItems, onUpdateLineItems, calculateTotal]);

    // Call updateLineItems whenever lineItems change to recalculate percentage surcharges
    // useEffect(() => {
    //     console.log("localSurcharges : 162", localSurcharges);
    //     localSurcharges.forEach(surcharge => {
    //         updateLineItems(surcharge);
    //     });
    // }, [localSurcharges]);

    const handleSaveChanges = useCallback((surcharge: typeof localSurcharges[0], index: number) => {
        const editingValue = editingValuesRef.current[surcharge.id] || {};
        const updates: Partial<typeof localSurcharges[0]> = {};

        if (editingValue.rate) {
            const rate = parseFloat(editingValue.rate);
            if (!isNaN(rate)) {
                updates.rate = rate;
            }
        }

        if (editingValue.distance_km) {
            const distance = parseFloat(editingValue.distance_km);
            if (!isNaN(distance)) {
                updates.distance_km = distance;
            }
        }

        setLocalSurcharges(prev => {
            const updated = [...prev];
            updated[index] = { ...updated[index], ...updates };
            updateLineItems(updated[index]);
            return updated;
        });
        let newEditingValues = { ...editingValuesRef.current };
        delete newEditingValues[surcharge.id];
        onSetEditingValues(newEditingValues);

        setInputErrors(prev => {
            const next = { ...prev };
            delete next[surcharge.id];
            return next;
        });
    }, [editingValues, updateLineItems, localSurcharges]);

    const validateInput = useCallback((surcharge: typeof localSurcharges[0], value: string, type: 'rate' | 'distance') => {
        const key = `${surcharge.id}_${type}`;
        try {
            const parsedValue = parseFloat(value);
            if (isNaN(parsedValue)) {
                setInputErrors(prev => ({ ...prev, [key]: 'Invalid input' }));
                return false;
            } else {
                setInputErrors(prev => ({ ...prev, [key]: '' }));
                return true;
            }
        } catch (error) {
            setInputErrors(prev => ({ ...prev, [key]: 'Invalid input' }));
            return false;
        }
    }, []);


    return (
        <Card
            disabled={true}
            status="basic"
            header={() => <Text category="h6">Surcharges</Text>}
            style={style}
        >
            <View style={styles.surchargeContainer}>
                {localSurcharges.map((surcharge, index) => (
                    <View style={styles.surchargeItem} key={surcharge.id}>
                        <View style={styles.surchargeHeader}>
                            <Text category="s1">{surcharge.name}</Text>
                            <Text category="c1" appearance="hint">{surcharge.description}</Text>
                            <Toggle
                                checked={surcharge.enabled}
                                onChange={(checked) => {
                                    setLocalSurcharges(prev => {
                                        const updated = [...prev];
                                        updated[index] = { ...updated[index], enabled: checked };
                                        updateLineItems(updated[index]);
                                        return updated;
                                    });
                                }}
                                disabled={!allowEdit}
                            />
                        </View>
                        {surcharge.enabled && (
                            <View style={styles.surchargeInputs}>
                                <View style={{ flexDirection: 'row', gap: 8 }}>
                                    <Input
                                        size='small'
                                        label={surcharge.type === SURCHARGE_TYPE.PERCENTAGE ? 'Percentage %' : 'Rate $'}
                                        style={{ flex: 1 }}
                                        value={editingValues[surcharge.id]?.rate ?? surcharge.rate.toFixed(2)}
                                        keyboardType="numeric"
                                        caption={() => (
                                            <Text category="c1" status={inputErrors[`${surcharge.id}_rate`] ? 'danger' : 'basic'} appearance="hint">{inputErrors[`${surcharge.id}_rate`]}</Text>
                                        )}
                                        disabled={!allowEdit}
                                        onChangeText={(text) => {
                                            const isValid = validateInput(surcharge, text, 'rate');
                                            let newEditingValues = { ...editingValuesRef.current };
                                            newEditingValues[surcharge.id] = { ...newEditingValues[surcharge.id], rate: text };
                                            onSetEditingValues(newEditingValues);
                                            if (isValid) {
                                            }
                                        }}
                                        placeholder="Rate"
                                    />
                                    {surcharge.type === SURCHARGE_TYPE.PER_KM && (
                                        <View style={{ flex: 1, flexDirection: 'row', gap: 4 }}>
                                            <Input
                                                size='small'
                                                label='Distance (km)'
                                                style={{ flex: 1 }}
                                                value={editingValues[surcharge.id]?.distance_km ?? surcharge.distance_km.toString()}
                                                keyboardType="numeric"
                                                disabled={!allowEdit}
                                                caption={() => (
                                                    <Text category="c1" status={inputErrors[`${surcharge.id}_distance`] ? 'danger' : 'basic'} appearance="hint">{inputErrors[`${surcharge.id}_distance`]}</Text>
                                                )}
                                                onChangeText={(text) => {
                                                    const isValid = validateInput(surcharge, text, 'distance');
                                                    let newEditingValues = { ...editingValuesRef.current };
                                                    newEditingValues[surcharge.id] = { ...newEditingValues[surcharge.id], distance_km: text };
                                                    onSetEditingValues(newEditingValues);
                                                    if (isValid) {
                                                    }
                                                }}
                                                placeholder="Distance"
                                            />
                                            <Button
                                                size="small"
                                                appearance="ghost"
                                                status="info"
                                                style={{ alignSelf: 'flex-end', marginBottom: 6 }}
                                                accessoryLeft={(props) => <Icon {...props} name="undo-outline" />}
                                                onPress={() => {
                                                    if (distance_m > 0) {
                                                        let newEditingValues = { ...editingValuesRef.current };
                                                        newEditingValues[surcharge.id] = { ...newEditingValues[surcharge.id], distance_km: (distance_m / 1000).toString() };
                                                        onSetEditingValues(newEditingValues);
                                                        handleSaveChanges(surcharge, index);
                                                    }
                                                }}
                                            />
                                        </View>
                                    )}
                                </View>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', gap: 8 }}>
                                    <View style={styles.billedToSelect}>
                                        <Button
                                            size="tiny"
                                            appearance={surcharge.billed_to === LINE_ITEM_BILLED_TO.CUSTOMER_COST ? 'filled' : 'outline'}
                                            onPress={() => {
                                                setLocalSurcharges(prev => {
                                                    const updated = [...prev];
                                                    updated[index] = {
                                                        ...updated[index],
                                                        billed_to: LINE_ITEM_BILLED_TO.CUSTOMER_COST
                                                    };
                                                    updateLineItems(updated[index]);
                                                    return updated;
                                                });
                                            }}
                                            disabled={!allowEdit}
                                        >
                                            Customer Cost
                                        </Button>
                                        <Button
                                            size="tiny"
                                            appearance={surcharge.billed_to === LINE_ITEM_BILLED_TO.BILL_ALL_BACK ? 'filled' : 'outline'}
                                            onPress={() => {
                                                setLocalSurcharges(prev => {
                                                    const updated = [...prev];
                                                    updated[index] = {
                                                        ...updated[index],
                                                        billed_to: LINE_ITEM_BILLED_TO.BILL_ALL_BACK
                                                    };
                                                    updateLineItems(updated[index]);
                                                    return updated;
                                                });
                                            }}
                                            disabled={!allowEdit}
                                        >
                                            Bill Back
                                        </Button>
                                    </View>
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text category="s2" style={styles.totalText}>
                                            {`Total: $${calculateTotal(surcharge).toFixed(2)}`}
                                        </Text>
                                    </View>
                                    <View style={{ flexDirection: 'row' }}>
                                        <Button
                                            size="small"
                                            status="success"
                                            onPress={() => handleSaveChanges(surcharge, index)}
                                            accessoryLeft={(props) => <Icon {...props} name="refresh" />}
                                        // disabled={!editingValues[surcharge.id]}
                                        >
                                            OK
                                        </Button>
                                    </View>
                                </View>
                            </View>
                        )}
                    </View>
                ))}
            </View>
        </Card>
    );
};

const styles = StyleSheet.create({
    surchargeContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        gap: 8,
    },
    surchargeItem: {
        marginBottom: 16,
        padding: 8,
        borderRadius: 4,
        borderColor: 'rgba(0, 0, 0, 0.25)',
        borderWidth: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        minWidth: 400,
        flex: 1,
    },
    surchargeHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 4,
    },
    surchargeInputs: {
        flexDirection: 'column',
        gap: 8,
    },
    billedToSelect: {
        flex: 1,
        marginTop: 8,
        flexDirection: 'row',
        gap: 8,
    },
    totalText: {
        marginTop: 8,
        textAlign: 'right',
    },
    inputError: {
        borderColor: 'red',
    },
    inputContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8,
    },
}); 