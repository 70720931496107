import React, { useState, useEffect, useRef, useReducer, useCallback } from 'react';
import {
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    Image,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Platform,
    useWindowDimensions
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Layout,
    Text,
    List,
    ListItem,
    Card,
    Input,
    useTheme,
    //@ts-ignore
    IndexPath,
    Select,
    SelectItem,
    Toggle,
    OverflowMenu,
    MenuItem,
    Modal,
    Radio,
    RadioGroup
} from "@ui-kitten/components"
//@ts-ignore
import DatePicker from 'react-datepicker';
import * as GoogleController from '../../../functions/google.controller'
//@ts-ignore
import debounce from 'lodash.debounce';
import * as StorageController from "../../../functions/storageController"
import * as ServiceController from "../../../functions/service.controller"
import * as UpdateController from "../../../functions/update.controller"
import ErrorBoundary from '../../ErrorBoundary.component';
import { Job } from '../../../models/Job.model';
import { Service } from '../../../models/Service';
import { G } from 'react-native-svg';



const multiDestinationDefaultTypes = [
    "Home",
    "Carpark",
    "Roadside",
    "Mechanic",
    "Dealership",
    "Tow to safety",
    "Holding Yard",
    "Other",
]

interface ServiceContainerProps {
    var_selectedServices: Service[],
    var_randUpdate: any,
    fn_updateJobServices: (services: Service[]) => void,
    style: any,
    vendorCompanyId?: string | null,
    allowEdit: any,
}

export const ServicesContainer = ({
    var_selectedServices,
    var_randUpdate,
    fn_updateJobServices,
    style,
    vendorCompanyId,
    allowEdit,
}: ServiceContainerProps) => {
    const [selectedServices, setSelectedServices] = useState<Service[]>([]);
    const selectedServicesRef = useRef(selectedServices);
    const [selectedService, setSelectedService] = useState(null)
    const [selectedServiceIndex, setSelectedServiceIndex] = useState(new IndexPath(0));
    const [serviceDisplayText, setServiceDisplayText] = useState("")
    const [serviceDefinitions, setServiceDefinitions] = useState([] as Service[])
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    const [addressSearch, setAddressSearch] = React.useState({ term: '', fetchPredictions: false })
    const [predictions, setPredictions] = React.useState([] as google.maps.places.AutocompleteResponse["predictions"])
    const [showPredictions, setShowPredictions] = React.useState(false)
    const [eventsRegistered, setEventsRegistered] = React.useState(false)


    useEffect(() => {
        // console.log("🚀============== ~ file: servicesContainer.component.tsx:87 ~ useEffect ~ selectedServices🚀==============", selectedServices)
        if (selectedServices && selectedServices.length > 0) {
            let displayText = ""
            selectedServices.forEach((service, index) => {
                if (index === 0) {
                    displayText = service.name
                } else {
                    displayText = displayText + ", " + service.name
                }
            })
            setServiceDisplayText(displayText)
        } else {
            setServiceDisplayText("")
        }
    }, [selectedServices])

    useEffect(() => {
        // console.log("===Service component use effect selectedServices===")
        if (var_selectedServices && var_selectedServices.length > 0) {
            var_selectedServices.map((service: any) => new Service(service))
            setSelectedServices(var_selectedServices)
            selectedServicesRef.current = var_selectedServices
        }
    }, [var_selectedServices])

    useEffect(() => {
        // console.log("===Service component use effect=== update service list")
        updateServiceList();
        return () => {
            // console.log("===Service component use effect=== return update service list")
        }
    }, [])

    useEffect(() => {
        // console.log("===Service component use effect=== rand update service list")
        if (var_randUpdate) {
            onSetSelectedServices([])
            updateServiceList();
        }
    }, [var_randUpdate])


    const updateServiceList = async () => {
        let id = null
        let services = [] as Service[]
        if (vendorCompanyId) {
            const vendorServices = await ServiceController.getServicesByCompanyId(vendorCompanyId)
            if (vendorServices) {
                services = [...services, ...vendorServices]
            }
        }
        const company = StorageController.getCurrentCompany()
        if (company) {
            id = company._id
        }
        const companyServices = await ServiceController.getServicesByCompanyId(company._id)
        if (companyServices) {
            services = [...services, ...companyServices]
        }
        // console.log("Services: ", _services)
        services.map((service: Service) => new Service(service))
        setServiceDefinitions(services);
        StorageController.appState.services = companyServices;
        StorageController.saveStateToDisk();
        forceUpdate();
    }

    const onSetSelectedServices = (services: Service[]) => {
        // console.log("🚀============== ~ file: servicesContainer.component.tsx:145 ~ onSetSelectedServices ~ services🚀==============", services)
        setSelectedServices(services)
        selectedServicesRef.current = services
        updateProp()
    }
    const updateProp = () => {
        fn_updateJobServices(selectedServicesRef.current)
    }




    const onChangeText = async (term: string) => {
        await GoogleController.getpredictionsAutocomplete(term).then(res => {
            if (!res) return
            setPredictions(res.predictions)
            setShowPredictions(true)
        })
    }
    const debouncedSave = useDebounce((nextValue: any) => onChangeText(nextValue), 1000);

    function useDebounce(callback: any, delay: number) {
        const debouncedFn = useCallback(
            debounce((...args: any) => callback(...args), delay),
            [delay] // will recreate if delay changes
        );
        return debouncedFn;
    }


    const AddressSearch = () => {
        return (
            <Input
                placeholder='Search Address'
                style={{ padding: 10 }}
                label='Search Address'
                value={addressSearch.term}
                onChangeText={(text: string) => {
                    setAddressSearch({ term: text, fetchPredictions: true })
                    debouncedSave(addressSearch.term)
                }}
            />
        )
    }





    const addServiceToList = (_selectedService: Service) => {
        // console.log("addServiceToList", _selectedService, selectedServices)
        if (_selectedService) {
            if (selectedServices.length > 0) {
                let serviceExists = false;
                selectedServices.forEach(service => {
                    if (service._id === _selectedService._id) {
                        serviceExists = true;
                    }
                })
                if (serviceExists) {
                    // remove the service from the list
                    let newSelectedServices = selectedServices.filter(service => service._id !== _selectedService._id)
                    onSetSelectedServices(newSelectedServices)
                    fn_updateJobServices(newSelectedServices) // have to do it this dumb way because of the way the state is set
                }
                if (!serviceExists) {
                    onSetSelectedServices([...selectedServices, _selectedService])
                    fn_updateJobServices([...selectedServices, _selectedService])
                }
            }
            if (selectedServices.length === 0) {
                onSetSelectedServices([...selectedServices, _selectedService])
                fn_updateJobServices([...selectedServices, _selectedService])
            }
            let names = selectedServices.map(service => service.name)
            setServiceDisplayText(names.join(", "))

        }
    }


    const handleInputChange = (serviceIndex: number, field: any, value: any) => {
        // Create a new copy of selectedServices
        const updatedServices = [...selectedServices];
        const updatedService = new Service({ ...updatedServices[serviceIndex] });

        // Ensure values is defined
        updatedService.values = { ...updatedService.values, [field]: value };

        updatedServices[serviceIndex] = updatedService;
        onSetSelectedServices(updatedServices);
    };


    const selectRef = useRef();

    const render = () => {
        return (
            <View style={style}>
                <View
                    style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Select
                        label='Select Services'
                        placeholder='Select Services'
                        value={serviceDisplayText}
                        multiSelect={true}
                        ref={selectRef as any}



                        onSelect={(index: any) => {
                            // close the select menu when an item is selected
                            // this is a hack because the select menu doesn't close when an item is selected
                            //@ts-ignore
                            selectRef.current?.blur()


                            // get last item in index array
                            let indexPath = new IndexPath(index);
                            addServiceToList(serviceDefinitions[indexPath.row - 1])
                        }}
                        style={{ flex: 1 }}
                    >
                        {serviceDefinitions.map((service: Service, index) => (
                            <SelectItem
                                key={service._id}
                                title={service.name}
                                //@ts-ignore
                                value={service}
                                selected={selectedServices.includes(service)}
                            />
                        ))}
                    </Select>
                </View>
                <View style={{ flexDirection: 'column', justifyContent: 'space-between', flex: 1 }}>
                    <ErrorBoundary>
                        {selectedServices &&
                            selectedServices.map((service, index) => (

                                <View key={index} style={{
                                    // flex: 1,
                                    margin: 5,
                                    border: "1px solid",
                                    // flexGrow: 5,
                                    // flexShrink: 1,
                                }}>
                                    {typeof (service) == "object" &&
                                        <>
                                            <Text category="h6">{service?.name}</Text>
                                            {Object.keys(service.fields).map((field: any, fieldIndex) => {
                                                //@ts-ignore
                                                let fieldType = service?.types?.[field]
                                                //@ts-ignore
                                                var fieldValue = service?.values?.[field] as any
                                                //@ts-ignore
                                                let fieldOptions = service?.options?.[field]
                                                //@ts-ignore
                                                let fieldOther = service?.other?.[field]
                                                //@ts-ignore
                                                let fieldName = service?.fields?.[field]
                                                return (

                                                    <View key={fieldIndex} style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", margin: 5 }}>
                                                        <View style={{ flex: 1 }}>
                                                            <Text category="s1">{fieldName}</Text>
                                                            {fieldOther?.externalDescription && (
                                                                <Text
                                                                    category="c1"
                                                                    style={{
                                                                        fontSize: 10,
                                                                        color: 'grey',
                                                                        fontStyle: 'italic',
                                                                        marginTop: 2
                                                                    }}
                                                                >
                                                                    {fieldOther.externalDescription}
                                                                </Text>
                                                            )}
                                                        </View>
                                                        <View style={{ flex: 2 }}>
                                                            {fieldType === "input" && <Input
                                                                size='small'
                                                                disabled={!allowEdit}
                                                                //@ts-ignore
                                                                value={fieldValue}
                                                                onChangeText={(value: any) => {
                                                                    handleInputChange(index, field, value)
                                                                    // onSetSelectedServices([...selectedServices])
                                                                    //updateProp()
                                                                }}
                                                            />}
                                                            {fieldType === "driver_rebate" && <Input
                                                                size='small'
                                                                //@ts-ignore
                                                                value={fieldValue}
                                                                disabled={!allowEdit}
                                                                onChangeText={(value: any) => {
                                                                    // ensure is a number
                                                                    if (isNaN(value)) {
                                                                        return
                                                                    }
                                                                    handleInputChange(index, field, value)
                                                                }}
                                                            />}
                                                            {fieldType === "driver_commission" && <Input
                                                                size='small'
                                                                //@ts-ignore
                                                                value={fieldValue}
                                                                disabled={!allowEdit}
                                                                onChangeText={(value: any) => {
                                                                    if (isNaN(value)) {
                                                                        return
                                                                    }
                                                                    handleInputChange(index, field, value)
                                                                }}
                                                            />}
                                                            {fieldType === "cost" && <Input
                                                                size='small'
                                                                //@ts-ignore
                                                                value={fieldValue}
                                                                disabled={!allowEdit}
                                                                keyboardType="numeric"
                                                                onChangeText={(value: any) => {
                                                                    if (isNaN(value)) {
                                                                        return
                                                                    }
                                                                    handleInputChange(index, field, value)
                                                                }}
                                                            />}
                                                            {fieldType === "quantity" && <Input
                                                                size='small'
                                                                //@ts-ignore
                                                                value={fieldValue}
                                                                disabled={!allowEdit}
                                                                keyboardType="numeric"
                                                                onChangeText={(value: any) => {
                                                                    if (isNaN(value)) {
                                                                        return
                                                                    }
                                                                    handleInputChange(index, field, value)
                                                                }}
                                                            />}
                                                            {fieldType === "date" && <Input
                                                                size='small'
                                                                //@ts-ignore
                                                                value={fieldValue}
                                                                disabled={!allowEdit}
                                                                onChangeText={(value: string) => {
                                                                    handleInputChange(index, field, value)
                                                                }}

                                                            />}
                                                            {fieldType === "time" && <Input
                                                                size='small'
                                                                //@ts-ignore
                                                                value={fieldValue}
                                                                disabled={!allowEdit}
                                                                onChangeText={(value: any) => {
                                                                    handleInputChange(index, field, value)
                                                                }}

                                                            />}
                                                            {fieldType === "address" &&
                                                                <View style={{ flex: 1, flexDirection: 'column' }}>

                                                                    <View
                                                                        style={{
                                                                            backgroundColor: "rgba(0, 224, 150, 0.08)",
                                                                            borderColor: "rgb(0, 224, 150)",
                                                                            borderRadius: 4,
                                                                            borderWidth: 1,
                                                                            margin: 10,
                                                                            flex: 1
                                                                        }}
                                                                    >
                                                                        <OverflowMenu
                                                                            anchor={AddressSearch}
                                                                            visible={showPredictions}
                                                                            onBackdropPress={() => setShowPredictions(false)}
                                                                            style={{ width: '100%' }}
                                                                            fullWidth={true}
                                                                        >
                                                                            {showPredictions ? predictions.map((prediction: any, index) => (
                                                                                //@ts-ignore
                                                                                <MenuItem
                                                                                    key={prediction.place_id}
                                                                                    title={prediction.description}
                                                                                    onPress={() => {
                                                                                        // fieldValue = prediction.description
                                                                                        handleInputChange(index, field, prediction.description)
                                                                                        setShowPredictions(false)
                                                                                    }}
                                                                                />
                                                                            )) : <></>}
                                                                        </OverflowMenu>
                                                                    </View>
                                                                    <Input
                                                                        size='small'
                                                                        style={{ flex: 2 }}
                                                                        //@ts-ignore
                                                                        disabled={!allowEdit}
                                                                        //@ts-ignore
                                                                        defaultValue={fieldValue}
                                                                        placeholder="Address"
                                                                        label="Address:"
                                                                        onChange={(value: any) => {
                                                                            handleInputChange(index, field, value)
                                                                        }}
                                                                    />
                                                                </View>
                                                            }
                                                            {fieldType === "options" && <Select
                                                                size='small'
                                                                //@ts-ignore
                                                                value={fieldValue || "-- Select Option --"}
                                                                disabled={!allowEdit}
                                                                onSelect={(selectedIndexPath: any) => {
                                                                    let indexPath = new IndexPath(selectedIndexPath);
                                                                    if (!service.values) {
                                                                        service.values = {}
                                                                    }
                                                                    handleInputChange(index, field, fieldOptions[indexPath.row - 1])
                                                                }}
                                                                //@ts-ignore
                                                                selectedIndex={new IndexPath(fieldValue)}
                                                            >
                                                                {fieldOptions.map((option: any, index: any) => (
                                                                    <SelectItem
                                                                        key={index}
                                                                        title={option}
                                                                        //@ts-ignore
                                                                        value={option}
                                                                    />
                                                                ))}
                                                            </Select>}
                                                            {fieldType === "toggle" && <Toggle
                                                                // style={{ flex: 1}}
                                                                checked={fieldValue as boolean}
                                                                onChange={(value: any) => {
                                                                    handleInputChange(index, field, value)
                                                                }}
                                                            />}
                                                            {fieldType === "radio" && <RadioGroup
                                                                //@ts-ignore
                                                                size='small'
                                                                // style={{ flex: 1}}
                                                                style={{
                                                                    border: "1px solid blue",
                                                                    borderRadius: 5,
                                                                    padding: 5,
                                                                    backgroundColor: "rgba(0, 0, 255, 0.2)",
                                                                }}
                                                                // selected index is value of the radio button
                                                                // get the index of the fieldValue in the service.options[field] array
                                                                selectedIndex={fieldOptions.indexOf(fieldValue)}
                                                                onChange={(selectedIndexPath: any) => {
                                                                    // console.log(index)
                                                                    // let indexPath = new IndexPath(index);
                                                                    const value = fieldOptions[selectedIndexPath]
                                                                    if (!service.values) {
                                                                        service.values = {}
                                                                    }
                                                                    handleInputChange(index, field, value)
                                                                    //updateProp()
                                                                }}
                                                            >
                                                                {fieldOptions.map((option: any, index: any) => (
                                                                    <Radio key={index}>{option}</Radio>
                                                                ))}
                                                            </RadioGroup>}
                                                            {fieldType === "image" &&
                                                                <View style={{ flex: 1 }}>
                                                                    {fieldValue ?
                                                                        <Icon name="checkmark-circle-outline" width={30} height={30} fill='green' />
                                                                        :
                                                                        <Icon name="close-outline" width={30} height={30} fill='red' />
                                                                    }
                                                                </View>
                                                            }
                                                        </View>
                                                    </View>
                                                )
                                            })}
                                        </>
                                    }
                                </View>
                            ))}
                    </ErrorBoundary>
                </View>

                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View style={{ flex: 1 }}>

                    </View>
                    <View style={{ flexGrow: 2 }}>

                    </View>
                </View>
            </View>
        )
    }

    // if selectedServices is not an array of objects then handle the case where there is only one service
    if (selectedServices.length > 0 && typeof selectedServices[0] === "string") {
        onSetSelectedServices([])
        return (null)
    }
    else {
        try {
            return render()
        } catch (err) {
            console.log(err)
            return null
        }
    }



}
