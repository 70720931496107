import * as JobController from '../functions/job.controller';
import * as GoogleController from '../functions/google.controller';
import * as Utils from '../functions/utils';
import * as StorageController from '../functions/storageController';
import { Member } from './Member.model';
import { LINE_ITEM_TYPE, LineItem } from './LineItem.model';
import { Service } from './Service';
import { Surcharge } from './Company.model';
import * as OsrmController from '../functions/osrm.controller';
import polyline from '@mapbox/polyline';
export enum JOB_STATUS {
    UNASSIGNED = 'unassigned',
    ASSIGNED = 'assigned',
    PENDING = 'pending',
    COMPLETE = 'complete',
    CANCELLED = 'cancelled',
    REQUEST = 'request',
    QUOTE = 'quote',
    CANCELLED_QUOTE = 'cancelled_quote',
    TRANSFERRED_OUT = 'transferred_out',
    REJECTED_BY_VENDOR = 'rejected_by_vendor',
    REJECTED_BY_CLIENT = 'rejected_by_client',
}

// 0022 -> 0022R1
// 0022 -> 0022R2
// 0022 -> 0022A
// 0022 -> 0022C


/**
 * Job Class Model
 */
export class Job {
    _id: string = "";
    details: JobDetails;
    job_count: number = 0;
    case_id: string | null = null;
    contract_id: string | null = null;
    friendly_id: string = "";
    company_id: string | null = null;
    member_id: string | null = null;
    client_id: string | null = null;
    vendor_profile_id: string | null = null;
    client_company_id: string | null = null;
    vendor_company_id: string | null = null;
    status: JOB_STATUS = JOB_STATUS.UNASSIGNED;
    accepted: boolean = false;
    driver_accepted: boolean = false;
    driver_accepted_time: number | null = null;
    driver_arrived: boolean = false;
    driver_arrived_time: number | null = null;
    driver_on_route: boolean = false;
    driver_on_route_time: number | null = null;
    accepted_time: number | null = null;
    arrived_time: number | null = null;
    pending_time: number | null = null;
    dispatchable: boolean = false;
    is_scheduled: boolean = false;
    client_reference_id: string = "";
    start_time: number = new Date().getTime();
    is_client_created: boolean = false; // if the job is created by a client - local client or client created
    is_vendor_created: boolean = false; // if the job is created by a vendor - vendor created
    vendor_accepted: boolean = false;
    client_eta: number | null = null;
    vendor_eta: number | null = null;
    is_cancelled: boolean = false;
    created_by: string = "";
    vendor_declined: boolean = false;
    driver_declined: boolean = false;
    job_logs: any[] = [];
    log: any = [];
    version_history: any[] = [];
    createdAt: string | null = null;
    updatedAt: string | null = null;
    invoice_details: any = null;
    item: any;
    route_id: string | null = null;
    order_id: string | null = null;




    constructor(job: any = {}) {
        this._id = job._id;
        this.details = job.details ? new JobDetails(job.details) : new JobDetails({});
        this.job_count = job.job_count;
        this.case_id = job.case_id;
        this.contract_id = job.contract_id;
        this.friendly_id = job.friendly_id;
        this.company_id = job.company_id;
        this.member_id = job.member_id;
        this.client_id = job.client_id;
        this.vendor_profile_id = job.vendor_profile_id;
        this.client_company_id = job.client_company_id;
        this.vendor_company_id = job.vendor_company_id;
        this.status = job.status;
        this.accepted = job.accepted;
        this.driver_accepted = job.driver_accepted;
        this.driver_accepted_time = job.driver_accepted_time;
        this.driver_arrived = job.driver_arrived;
        this.driver_arrived_time = job.driver_arrived_time;
        this.driver_on_route = job.driver_on_route;
        this.driver_on_route_time = job.driver_on_route_time;
        this.accepted_time = job.accepted_time;
        this.arrived_time = job.arrived_time;
        this.pending_time = job.pending_time;
        this.dispatchable = job.dispatchable ?? true;
        this.is_scheduled = job.is_scheduled ?? false;
        this.client_reference_id = job.client_reference_id;
        this.start_time = job.start_time;
        this.is_client_created = job.is_client_created;
        this.is_vendor_created = job.is_vendor_created;
        this.vendor_accepted = job.vendor_accepted;
        this.client_eta = job.client_eta;
        this.vendor_eta = job.vendor_eta ? job.vendor_eta : 60;
        this.is_cancelled = job.is_cancelled;
        this.created_by = job.created_by;
        this.vendor_declined = job.vendor_declined;
        this.driver_declined = job.driver_declined;
        this.job_logs = job.job_logs;
        this.log = job.log;
        this.version_history = job.version_history;
        this.createdAt = job.createdAt;
        this.updatedAt = job.updatedAt;
        this.invoice_details = job.invoice_details || {};
        this.route_id = job.route_id;
        this.order_id = job.order_id;
    }

    /**
     * Save Job
     */
    save = async (silent = true) => {
        try {
            if (!silent) {
                const response = await JobController.updateJob(this)
                console.log("🚀============== ~ file: Job.model.js:57 ~ Job ~ save= ~ response🚀==============", response)
                return response;
            } else {
                const response = await JobController.silentUpdateJob(this)
                console.log("🚀============== ~ file: Job.model.js:64 ~ Job ~ save= ~ response🚀==============", response)
                return response;
            }
        } catch (error) {
            console.error(error);
        }
    }


    toJson() {
        const simpleObject: any = {};
        for (const key in this) {
            if (this.hasOwnProperty(key)) {
                simpleObject[key] = this[key];
            }
        }
        return simpleObject;
    }

    /**
     * To CSV
     * Prepare job data for CSV export
     * @returns {Array} job data
     */
    toCsvArray() {
        const job = this.toJson();

        function escapeForCsv(jsonData: any) {
            try {
                let jsonString = JSON.stringify(jsonData);
                jsonString = jsonString?.replace(/,/g, ";") || "";
                // Enclose in double quotes to handle any special characters like commas or new lines
                return jsonString;
            } catch (error) {
                console.error('Failed to stringify JSON:', error);
                return '""';
            }
        }

        let customer_details = escapeForCsv(this.details.customer_details)
        let vehicle_details = escapeForCsv(this.details.vehicle_details)
        let towing_details = escapeForCsv(this.details.towing_details) || ""
        let clientRate = escapeForCsv(this.details.client_rate)
        let services = this.details.selected_services.map((service: Service) => {
            // map each field and value
            let result = {} as any
            Object.keys(service.fields).forEach((key: any) => {
                // get field name
                let field = key
                // get field value
                let value = (service.values as any)?.[key] as any
                result[field] = value
            })
            return result
        })
        let lineItems = this.details.report.items.map((item: LineItem) => {
            return item
        })





        const jobObj = {
            _id: job._id,
            job_count: job.job_count,
            case_id: job.case_id,
            friendly_id: job.friendly_id,
            company_id: job.company_id,
            member_id: job.member_id,
            client_id: job.client_id,
            vendor_profile_id: job.vendor_profile_id,
            client_company_id: job.client_company_id,
            vendor_company_id: job.vendor_company_id,
            status: job.status,
            accepted: job.accepted,
            driver_accepted: job.driver_accepted,
            driver_accepted_time: job.driver_accepted_time,
            driver_arrived: job.driver_arrived,
            driver_arrived_time: job.driver_arrived_time,
            driver_on_route: job.driver_on_route,
            driver_on_route_time: job.driver_on_route_time,
            accepted_time: job.accepted_time,
            arrived_time: job.arrived_time,
            pending_time: job.pending_time,
            dispatchable: job.dispatchable,
            is_scheduled: job.is_scheduled,
            client_reference_id: job.client_reference_id,
            start_time: job.start_time,
            is_client_created: job.is_client_created,
            is_vendor_created: job.is_vendor_created,
            vendor_accepted: job.vendor_accepted,
            client_eta: job.client_eta,
            vendor_eta: job.vendor_eta,
            is_cancelled: job.is_cancelled,
            created_by: job.created_by,
            vendor_declined: job.vendor_declined,
            driver_declined: job.driver_declined,
            version_history: job.version_history,
            createdAt: job.createdAt,
            updatedAt: job.updatedAt,
            invoice_details: job.invoice_details,
            services: escapeForCsv(services),
            customer_details: escapeForCsv(customer_details),
            vehicle_details: escapeForCsv(vehicle_details),
            towing_details: towing_details,
            clientRate: clientRate,
            lineItems: escapeForCsv(lineItems),
            route_id: job.route_id,
            order_id: job.order_id
        };
        return jobObj;
    }


    /**
     * Get total cost of all items
     * @returns {number} total cost
     */
    getTotalLineItemCostCost() {
        return this.details.report.getTotalCost();
    }

    /**
     * Get total cost including client rate
     */
    getTotalLineItemCostCostIncludingClientRate() {
        let total = 0;
        // get callout fee if billed to company
        if (this.details.client_rate) {
            total += Number(this.details.client_rate.cost);
        }
        total += this.details.report.getTotalCost();
        return total
    }

    /**
     * Get total amount customer paid
     */
    getTotalCustomerPaidAmount() {
        return this.details.report.getTotalCustomerPaidAmount();
    }

    /**
     * Get total customer paid amount including client rate
     */
    getTotalCustomerPaidAmountIncludingClientRate() {
        let total = 0;
        // get callout fee if customer has paid
        if (this.details.client_rate && this.details.client_rate.customer_paid) {
            total += Number(this.details.client_rate.cost);
        }
        total += this.details.report.getTotalCustomerPaidAmount();
        return total
    }

    /**
     * Get total amount outstanding from customer
     *  @returns {number} total amount outstanding
     */
    getTotalAmountOutstanding() {
        return this.details.report.getTotalAmountOutstanding();
    }

    /**
     * get total amount outstanding including Client rate
     */
    getTotalAmountOutstandingIncludingClientRate() {
        let total = 0
        // get callout fee if customer has not paid
        if (
            this.details.client_rate &&
            this.details.client_rate.billed_to == "customer_cost" &&
            !this.details.client_rate.customer_paid
        ) {
            total += Number(this.details.client_rate.cost);
        }
        total += this.details.report.getTotalAmountOutstanding();
        return total
    }
    /**
     * mark all customer cost items as paid including client rate
     */
    setAllCustomerCostItemsPaidIncludingClientRate() {
        this.details.report.setAllCustomerCostItemsPaid();
        if (this.details.client_rate && this.details.client_rate.billed_to == "customer_cost") {
            this.details.client_rate.customer_paid = true;
        }
    }

    /**
     * Get total cost of all items that are billed to customer
     * @returns {number} total cost
     */

    getTotalCustomerCost() {
        return this.details.report.getTotalCustomerCost();
    }

    /**
     * get total customer cost including client rate
     */
    getTotalCustomerCostIncludingClientRate() {
        let total = 0;
        // get callout fee if billed to customer
        if (this.details.client_rate && this.details.client_rate.billed_to == "customer_cost") {
            total += Number(this.details.client_rate.cost);
        }
        total += this.details.report.getTotalCustomerCost();
        return total
    }

    /**
     * Get total cost of all items that are billed to company
     * @returns {number} total cost
     */
    getTotalBillback() {
        return this.details.report.getTotalBillback();
    }

    /**
     * Get total bill back cost including client rate
     */
    getTotalBillbackIncludingClientRate() {
        let total = 0;
        // get callout fee if billed to company
        if (this.details.client_rate && this.details.client_rate.billed_to == CLIENT_RATE_BILLED_TO.BILL_ALL_BACK) {
            total += Number(this.details.client_rate.cost);
        }
        total += this.details.report.getTotalBillback();
        return total
    }

    /**
 * Get Customer cost line items that are not paid
 * Also create a line item from a callout that is also customer cost unpaid
 */
    getCustomerCostItemsUnpaidIncludingClientRate() {
        const customerCostItems = this.getTotalCustomerCostItems();
        const customerCostItemsUnpaid = customerCostItems.filter((item: LineItem) => !item.paid);
        let lineItems: LineItem[] = [...customerCostItemsUnpaid]
        if (
            this.details.client_rate &&
            this.details.client_rate.billed_to == CLIENT_RATE_BILLED_TO.CUSTOMER_COST &&
            !this.details.client_rate.customer_paid
        ) {
            let description = `Callout - ${this.details.client_rate.name}`
            if (this.details.client_rate.eft_auth_number && this.details.client_rate.eft_auth_number.length > 0) {
                description += ` (${this.details.client_rate.eft_auth_number})`
            }
            const calloutItem = new LineItem({
                name: description,
                description: description,
                quantity: 1,
                cost: this.details.client_rate.cost,
                billed_to: LINE_ITEM_BILLED_TO.CUSTOMER_COST,
                paid: false,
                account_code: this.details.client_rate.account_code || null
            });
            lineItems.push(calloutItem);
        }
        return lineItems;
    }

    /**
     * Get all items billed to customer
     */
    getTotalCustomerCostItems() {
        return this.details.report.getTotalCustomerCostItems();
    }

    /**
     * Get all items billed to company
     */
    getTotalBillBackItems() {
        return this.details.report.getTotalBillBackItems();
    }

    /**
     * Get all line items
     */
    getLineItems() {
        return this.details.report.items;
    }

    /**
     * Add Line item
     */
    addLineItem(item: LineItem) {
        this.details.report.addItem(item);
    }

    /**
     * Remove Line item
     */
    removeLineItem(item: LineItem) {
        this.details.report.removeItem(item);
    }

    /**
     * Remove Line item by index
     */
    removeLineItemByIndex(index: number) {
        this.details.report.removeItemByIndex(index);
    }

    /**
     * Get services names, separate by comma
     * @returns {string} services names
     */
    getServicesNames() {
        return this.details?.getServicesNames();
    }

    /**
     * Get service names array
     */
    getServicesNamesArray() {
        return this.details?.getServicesNamesArray();
    }

    /**
     * Add service
     */
    addService(service: Service) {
        this.details?.addService(service);
    }

    /**
     * Remove service
     */
    removeService(service: Service) {
        this.details?.removeService(service);
    }

    /**
     * Get address
     * @returns {string} address
     */
    getAddressToString() {
        return this.details.address?.addressToString();
    }


    /**
     * Get pickup address
     * @returns {string} address
     */
    getTowingPickupAddressToString() {
        const location = this.details.towing_details?.towing_locations[0] as TowingLocation;
        return location.address?.addressToString();
    }

    /**
     * Get dropoff address
     * @returns {string} address
     */
    getTowingDropoffAddressToString() {
        const location = this.details.towing_details?.towing_locations[
            this.details.towing_details?.towing_locations.length - 1
        ] as TowingLocation;
        return location.address?.addressToString();

    }

    /**
     * Get pickup location
     * @returns {string} location
     */
    getTowingPickupLocation() {
        return this.details.towing_details?.towing_locations[0].location;
    }

    /**
     * Get dropoff location
     * @returns {string} location
     */
    getTowingDropoffLocation() {
        return this.details.towing_details?.towing_locations[
            this.details.towing_details?.towing_locations.length - 1
        ].location;
    }


    /**
     * Get Towing Details
     */
    getTowingDetails() {
        return this.details.towing_details;
    }

    /**
     * Set Towing Details
     */
    setTowingDetails(towing_details: JobTowingDetails) {
        this.details.towing_details = new JobTowingDetails(towing_details);
    }

    /**
     * Get vehicle details
     */
    getVehicleDetails() {
        return this.details.vehicle_details;
    }

    /**
     * Set vehicle details
     */
    setVehicleDetails(vehicle_details: JobVehicleDetails) {
        this.details.vehicle_details = new JobVehicleDetails(vehicle_details);
    }

    /**
     * Get customer details
     */

    getCustomerDetails() {
        return this.details.customer_details;
    }

    /**
     * Set customer details
     */
    setCustomerDetails(customer_details: JobCustomerDetails) {
        this.details.customer_details = new JobCustomerDetails(customer_details);
    }

    /**
     * Set Client Rate
     */
    setClientRate(client_rate: JobClientRate) {
        this.details.client_rate = new JobClientRate(client_rate);
    }

    /**
     * Get Client Rate
     */
    getClientRate() {
        return this.details.client_rate;
    }


    /**
     * Build secription string
     */
    buildDescriptionString() {
        const hasRoadsideOptions = this.details?.options?.roadside_job_options || true
        const hasTowingOptions = this.details?.options?.towing_job_options
        const hasTransportOptions = this.details?.options?.transport_job_options

        let str = ``
        if (hasRoadsideOptions) {
            let vehicle = this.details.vehicle_details
            str += Utils.buildDescription(vehicle, ["make", "model", "rego", "year", "colour"])
        }
        if (hasTowingOptions) {
            let towing = this.details.towing_details
            if (towing?.towing_type.toLowerCase() == "vehicle" || towing?.towing_type == TOWING_TYPE.VEHICLE) {
                let vehicle = towing?.vehicle_details
                str += Utils.buildDescription(vehicle, ["make", "model", "rego", "year", "colour"])
            }
            if (towing?.towing_type.toLowerCase() == "equipment" || towing?.towing_type == TOWING_TYPE.EQUIPMENT) {
                let equipment = towing?.equipment_details
                str += Utils.buildDescription(equipment, ["equipment", "type", "weight", "size", "serial"])
            }
        }
        if (hasTransportOptions) {
            //TODO:
        }
        const customerDetails = this.details.customer_details
        str += Utils.buildDescription(customerDetails, ["name", "phone", "email"])
        const members = StorageController.getAppState().members as Member[] || []
        const services = this.getServicesNames()
        str += `Services: ${services} |`
        const clientRefId = this.client_reference_id
        str += `Client Ref ID: ${clientRefId} | `
        const driverName = StorageController.getAppState().members.find((member: Member) => member._id == this.member_id)?.name
        str += `Driver: ${driverName} |`
        const clientRate = this.details.client_rate
        str += `Client Rate: ${clientRate.name} at ${clientRate.cost} |`
        return str
    }
}
export class JobDetails {
    address: JobAddress;
    report: JobReport;
    client_rate: JobClientRate;
    customer_details: JobCustomerDetails;
    vehicle_details: JobVehicleDetails;
    towing_details!: JobTowingDetails | null;
    priority: JobPriority;
    location: JobLocation;
    comments: string;
    options: JobOptions;
    selected_services: Service[];
    notes: JobNote[]
    transport_details: JobTransportDetails;
    surcharges?: Surcharge[];



    constructor(details: any = {}) {
        this.address = details.address ? new JobAddress(details.address) : new JobAddress({});
        this.report = details.report ? new JobReport(details.report) : new JobReport({});
        this.client_rate = details.client_rate ? new JobClientRate(details.client_rate) : new JobClientRate({});
        this.customer_details = details.customer_details ? new JobCustomerDetails(details.customer_details) : new JobCustomerDetails({});
        this.vehicle_details = details.vehicle_details ? new JobVehicleDetails(details.vehicle_details) : new JobVehicleDetails({});
        this.priority = details.priority ? new JobPriority(details.priority) : JOB_PRIORITY.NORMAL;
        this.location = details.location ? new JobLocation(details.location) : new JobLocation({});
        this.comments = details.comments; // Assuming comments is always present
        this.options = details.options ? new JobOptions(details.options) : new JobOptions({});

        if (this.options.towing_job_options) {
            this.towing_details = details.towing_details ? new JobTowingDetails(details.towing_details) : new JobTowingDetails({});
        }

        // Directly expose the services array
        // this._selected_services = new JobSelectedServices(details.selected_services);
        // this.selected_services = this._selected_services.services;
        this.selected_services = details.selected_services ? details.selected_services.map((service: Service) => new Service(service)) : []
        this.notes = details.notes ? details.notes.map((note: JobNote) => new JobNote(note)) : [] as JobNote[];
        this.transport_details = details.transport_details ? new JobTransportDetails(details.transport_details) : new JobTransportDetails({});
    }

    /**
 * Add service
 */
    addService(service: Service) {
        this.selected_services.push(new Service(service));
    }

    /**
     * Remove service
     */
    removeService(service: Service) {
        this.selected_services = this.selected_services.filter(s => s._id !== service._id);
    }

    /**
     * Get services names, separate by comma
     */
    getServicesNames() {
        return this.selected_services.map(service => service.name).join(", ");
    }

    /**
     * Get service names array
     */
    getServicesNamesArray() {
        return this.selected_services.map(service => service.name);
    }


    addNote(note: JobNote) {
        this.notes.push(note);
    }

    deleteNote(note: JobNote) {
        this.notes = this.notes.filter(n => n._id !== note._id);
    }

    updateNote(note: JobNote) {
        this.notes = this.notes.map(n => {
            if (n._id === note._id) {
                return note;
            }
            return n;
        });
    }
}

export class JobAddress {
    unit_number!: string;
    street_number!: string;
    street_name!: string;
    suburb!: string;
    city!: string;
    state!: string;
    country!: string;
    post_code!: string;
    formatted_address!: string;
    description!: string;

    constructor(address: any = {}) {
        if (typeof address == 'string') {
            this.formatted_address = address
        } else {
            this.unit_number = address?.unit_number ? address.unit_number : "";
            this.street_number = address?.street_number ? address.street_number : "";
            this.street_name = address?.street_name ? address.street_name : "";
            this.suburb = address?.suburb ? address.suburb : "";
            this.city = address?.city ? address.city : "";
            this.state = address?.state ? address.state : "";
            this.country = address?.country ? address.country : "";
            this.post_code = address?.post_code ? address.post_code : "";
            this.formatted_address = address?.formatted_address ? address.formatted_address : "";
            this.description = address?.description ? address.description : "";
        }
    }

    /**
     * 
     * @returns {string} address
     */
    addressToString() {
        var address = ""
        // address is a google places api result
        // need street number/name, usburb, city, state, country, post code
        try {
            // console.log(addressObj)
            this.unit_number ? address += this.unit_number + "/" : null
            this.street_number ? address += this.street_number + " " : null
            this.street_name ? address += this.street_name + ", " : null
            this.suburb ? address += this.suburb + ", " : null
            this.city ? address += this.city + ", " : null
            this.state ? address += this.state + ", " : null
            this.country ? address += this.country + ", " : null
            this.post_code ? address += this.post_code : null
            return address
        } catch (error) {
            console.log(error)
            return ""
        }
    }
}

export enum LINE_ITEM_BILLED_TO {
    CUSTOMER_COST = "customer_cost",
    BILL_ALL_BACK = "bill_all_back",
}


export class JobReport {
    items: LineItem[];
    preInspectionComments: string;
    comments: string;
    customerPaidCard: boolean;
    customerPaidCardAmount: number;
    customerPaidCash: boolean;
    customerPaidCashAmount: number;



    constructor(report: any = {}) {
        // this._items = new JobReportItems(report.items);
        this.items = report.items ? report.items.map((item: LineItem) => new LineItem(item)) : [];
        this.preInspectionComments = report.preInspectionComments;
        this.comments = report.comments;
        this.customerPaidCard = report.customerPaidCard;
        this.customerPaidCardAmount = report.customerPaidCardAmount;
        this.customerPaidCash = report.customerPaidCash;
        this.customerPaidCashAmount = report.customerPaidCashAmount;
    }

    /**
     * Get total cost of all items
     */
    getTotalCost() {
        return this.items.reduce((total, item) => {
            return total += Number(item.cost) * Number(item.quantity);
        }, 0)
    }

    /**
     * Get total cost of all items that are billed to customer
     */
    getTotalCustomerCost() {
        return this.items.reduce((total, item) => {
            if (item.billed_to == LINE_ITEM_BILLED_TO.CUSTOMER_COST) {
                total += Number(item.cost) * Number(item.quantity);
            }
            return total;
        }, 0);
    }

    /**
     * Get total customer paid amount
     * @returns {number} total customer paid amount
     */
    getTotalCustomerPaidAmount() {
        let total = 0;
        let items = this.getTotalCustomerCostItems();
        items.forEach(item => {
            if (item.paid) {
                total += Number(item.cost) * Number(item.quantity);
            }
        })
        return total;
    }


    /**
     * Calculate total amounts with GST
     * @param type - "customer_cost" | "bill_all_back" | "all"
     * @returns {object} - { GSTTotal: number, totalWithoutGST: number, totalWithGST: number, costNoGST: number }
     */
    calculateTotalAmountsWithGST(type: "customer_cost" | "bill_all_back" | "all") {
        try {
            let gstAmount = 0;
            let totalWithoutGST = 0;
            let totalWithGST = 0;
            let costNoGST = 0;
            let items = this.items;
            if (type == "customer_cost") {
                items = this.getTotalCustomerCostItems();
            }
            else if (type == "bill_all_back") {
                items = this.getTotalBillBackItems();
            }
            else if (type == "all") {
                items = this.items;
            }
            items.forEach(item => {
                gstAmount += item.getGSTOnlyAmount();
                totalWithoutGST += item.getCostWithoutGST();
                totalWithGST += item.getCostWithGST();
                costNoGST += item.getTotalCost(); // if the company is not GST registered
            });

            return {
                GSTTotal: gstAmount,
                totalWithoutGST: totalWithoutGST,
                totalWithGST: totalWithGST,
                costNoGST: costNoGST,
            }
        } catch (error) {
            console.log(error);
            return {
                GSTTotal: 0,
                totalWithoutGST: 0,
                totalWithGST: 0,
                costNoGST: 0,
            }
        }
    }



    /**
 * Set All Customer cost items to paid
 */
    setAllCustomerCostItemsPaid() {
        //@ts-ignore
        this.items.forEach((item) => {
            if (item.billed_to == LINE_ITEM_BILLED_TO.CUSTOMER_COST) {
                item.paid = true;
            }
        });
    }

    /**
     * Get total amount outstanding from customer
     * @returns {number} total amount outstanding
     */
    getTotalAmountOutstanding() {
        let total = 0
        let itemsTotalCost = this.getTotalCustomerCost();
        let itemsTotalPaid = this.getTotalCustomerPaidAmount();
        total = itemsTotalCost - itemsTotalPaid;
        return total
    }

    /**
     * Get total cost of all items that are billed to company
     */
    getTotalBillback() {
        return this.items.reduce((total, item) => {
            if (item.billed_to == LINE_ITEM_BILLED_TO.BILL_ALL_BACK) {
                total += Number(item.cost) * Number(item.quantity);
            }
            return total;
        }, 0);
    }

    /**
     * Get all items billed to customer
     * @returns {Array<LineItem>}
     */
    getTotalCustomerCostItems() {
        // add GST to the cost if GST is included, otherwise exclude GST
        return this.items.filter(item => item.billed_to == LINE_ITEM_BILLED_TO.CUSTOMER_COST);
    }

    /**
     * Get all items billed to company
     * @returns {Array<LineItem>}
     */
    getTotalBillBackItems() {
        return this.items.filter(item => item.billed_to == LINE_ITEM_BILLED_TO.BILL_ALL_BACK);
    }

    /**
     * Add item
     */
    addItem(item: LineItem) {
        this.items.push(new LineItem(item));
    }

    /**
     * Remove item
     */
    removeItem(item: LineItem) {
        this.items = this.items.filter(i => i.name !== item.name);
    }

    /**
     * Remove item by index
     */
    removeItemByIndex(index: number) {
        this.items.splice(index, 1);
    }

    /**
     * Get items names, separate by comma
     */
    getItemsNames() {
        return this.items.map(item => item.name).join(", ");
    }

}

/**
 *  Job Client Rate
 */

export class JobClientRate {
    name: string;
    cost: number;
    customer_paid: boolean;
    billed_to: CLIENT_RATE_BILLED_TO;
    is_gst_inclusive: boolean;
    eft_auth_number: string;
    account_code: string;

    constructor(rate: Partial<JobClientRate> = {}) {
        this.name = rate?.name || "";
        this.cost = rate?.cost || 0;
        this.customer_paid = rate?.customer_paid ? rate?.customer_paid : false;
        this.billed_to = rate?.billed_to ? rate?.billed_to : CLIENT_RATE_BILLED_TO.BILL_ALL_BACK;
        this.is_gst_inclusive = rate?.is_gst_inclusive ?? true
        this.eft_auth_number = rate?.eft_auth_number || "";
        this.account_code = rate?.account_code || "";
    }


    generateLineItem() {
        let description = `Callout - ${this.name}`
        if (this.eft_auth_number) {
            description += ` - ${this.eft_auth_number}`
        }
        return new LineItem({
            name: description,
            description: description,
            cost: this.cost,
            billed_to: this.billed_to == CLIENT_RATE_BILLED_TO.CUSTOMER_COST ? LINE_ITEM_BILLED_TO.CUSTOMER_COST : LINE_ITEM_BILLED_TO.BILL_ALL_BACK,
            quantity: 1,
            paid: this.customer_paid,
            type: LINE_ITEM_TYPE.CALLOUT,
            account_code: this.account_code
        });
    }
}

export enum CLIENT_RATE_BILLED_TO {
    CUSTOMER_COST = "customer_cost",
    BILL_ALL_BACK = "bill_all_back"
}

export class JobCustomerDetails {
    name: string;
    phone: string;
    email: string;

    constructor(details: any = {}) {
        this.name = details.name;
        this.phone = details.phone;
        this.email = details.email;
    }
}

export class JobVehicleDetails {
    rego: string;
    make: string;
    model: string;
    year: string;
    colour: string;

    constructor(details: any = {}) {
        this.rego = details.rego ? details.rego : null;
        this.make = details.make ? details.make : null;
        this.model = details.model ? details.model : null;
        this.year = details.year ? details.year : null;
        this.colour = details.colour ? details.colour : null;
    }
}

export class JobEquipmentDetails {
    equipment: string;
    type: string;
    weight: string;
    size: string;
    serial: string;


    constructor(details: any = {}) {
        this.equipment = details.equipment ? details.equipment : null;
        this.type = details.type ? details.type : null;
        this.weight = details.weight ? details.weight : null;
        this.size = details.size ? details.size : null;
        this.serial = details.serial ? details.serial : null;
    }
}

export enum TOWING_TYPE {
    EQUIPMENT = "Equipment",
    VEHICLE = "Vehicle"
}

export enum JOB_LOCATION_TYPE {
    ADDRESS = "Address",
    HOLDING = "Holding"
}

export class JobTowingDetails {
    public towing_type: TOWING_TYPE;
    public equipment_details: JobEquipmentDetails | null;
    public vehicle_details: JobVehicleDetails | null;
    public holding_item_id: string | null;
    public _pickup_towing_location: any;
    public _dropoff_towing_location: any;
    public original_dropoff_towing_location: any;
    public towing_locations: TowingLocation[];
    public towing_legs: TowingLeg[];
    public distance_kms: any;
    public duration_seconds: any;
    public cost_per_km: any;
    public covered_distance_kms: any;
    public total_cost: any;
    public directions_polyline: any;
    public direction_polylines: any;

    constructor(details: any = {}) {
        this.towing_type = details?.towing_type || TOWING_TYPE.VEHICLE;
        this.equipment_details = details?.equipment_details ? new JobEquipmentDetails(details?.equipment_details) : null;
        this.vehicle_details = details?.vehicle_details ? new JobVehicleDetails(details?.vehicle_details) : null;
        this.holding_item_id = details?.holding_item_id || null;

        ////////////////// NEWWW ONEESSSS
        this._pickup_towing_location = details?.pickup_towing_location ? new TowingLocation(details?.pickup_towing_location) : null;
        this._dropoff_towing_location = details?.dropoff_towing_location ? new TowingLocation(details?.dropoff_towing_location) : null;
        this.original_dropoff_towing_location = details?.original_dropoff_towing_location ? new TowingLocation(details?.original_dropoff_towing_location) : null;
        // this.towing_locations = details?.towing_locations ? details?.towing_locations.map((location: TowingLocation) => new TowingLocation(location)) : [
        //     this._pickup_towing_location ? this._pickup_towing_location : new TowingLocation({ index: 0, name: "Pickup", location_type: "Address", address_type: "Home", holding_reason: "Holding", address: new JobAddress({}) }),
        //     this._dropoff_towing_location ? this._dropoff_towing_location : new TowingLocation({ index: 1, name: "Dropoff", location_type: "Address", address_type: "Home", holding_reason: "Holding", address: new JobAddress({}) }),
        // ];
        this.towing_locations = details?.towing_locations ? details?.towing_locations.map((location: any) => new TowingLocation(location)) :
            [
                this._pickup_towing_location
                    ? this._pickup_towing_location
                    : new TowingLocation({
                        index: 0,
                        name: "Pickup",
                        location_type: JOB_LOCATION_TYPE.ADDRESS,
                        address_type: "Home",
                        holding_reason: "Holding",
                        address: new JobAddress({}),
                    }),
                this._dropoff_towing_location
                    ? this._dropoff_towing_location
                    : new TowingLocation({
                        index: 1,
                        name: "Dropoff",
                        location_type: JOB_LOCATION_TYPE.ADDRESS,
                        address_type: "Home",
                        holding_reason: "Holding",
                        address: new JobAddress({}),
                    }),
            ];
        this.towing_legs = details?.towing_legs ? details?.towing_legs.map((leg: TowingLeg) => new TowingLeg(leg)) : [];

        this.distance_kms = details?.distance_kms ? details?.distance_kms : null;
        this.duration_seconds = details?.duration_seconds ? details?.duration_seconds : null;
        this.cost_per_km = details?.cost_per_km ? details?.cost_per_km : null;
        this.covered_distance_kms = details?.covered_distance_kms ? details?.covered_distance_kms : null;
        this.total_cost = details?.total_cost ? details?.total_cost : null;


        //check if used now..
        this.directions_polyline = details?.directions_polyline ? new Polyline(details?.directions_polyline) : null;
        this.direction_polylines = details?.direction_polylines ? details?.direction_polylines.map((polyline: Polyline) => new Polyline(polyline)) : [];


        // This here supports the old legacy implementation of towing details
        if (!details?.towing_locations && details?.pickup_address && details?.dropoff_address) {
            this.towing_legs = [
                new TowingLeg({
                    index: 0,
                    origin_towing_location_index: 0,
                    destination_towing_location_index: 1,
                    origin: new JobLocation(details.pickup_address),
                    destination: new JobLocation(details.dropoff_address),
                    directions: new JobDirections({})
                })
            ]
            this.towing_locations = [
                new TowingLocation({
                    index: 0,
                    name: "Pickup",
                    location_type: details.pickup_location_type,
                    address_type: details.pickup_address_type,
                    holding_reason: details.pickup_holding_reason,
                    address: new JobAddress(details.pickup_address),
                    location: new JobLocation(details.pickup_location),
                    time: details.pickup_time
                }),
                new TowingLocation({
                    index: 1,
                    name: "Dropoff",
                    location_type: details.dropoff_location_type,
                    address_type: details.dropoff_address_type,
                    holding_reason: details.dropoff_holding_reason,
                    address: new JobAddress(details.dropoff_address),
                    location: new JobLocation(details.dropoff_location),
                    time: details.dropoff_time
                })
            ]
        }
    }


    //////////////////////////////////////
    // LOCATIONS
    //////////////////////////////////////

    updateLocationAtIndex(index: number, location: TowingLocation) {
        this.towing_locations[index] = new TowingLocation(location);
        return this.towing_locations;
    }

    addNewLocationAtIndex(index: number) {
        this.towing_locations.splice(index, 0, new TowingLocation({ index: index, name: "Location", location_type: JOB_LOCATION_TYPE.ADDRESS, address_type: "Home", holding_reason: "Holding", address: new JobAddress({}) }));
        // reindex locations
        return this.reIndexTowingLocations()
    }

    removeLocationAtIndex(index: number) {
        this.towing_locations.splice(index, 1);
        // reindex locations
        return this.reIndexTowingLocations()
    }

    reIndexTowingLocations() {
        this.towing_locations.forEach((location: TowingLocation, index: number) => {
            location.index = index;
        })
        return this.towing_locations;
    }

    removeLocationAndSetAsOriginalDropoffAtIndex(index: number) {
        this.original_dropoff_towing_location = new TowingLocation(this.towing_locations[index]);
        this.removeLocationAtIndex(index);
        return this.towing_locations;
    }

    removeOriginalDropoffLocation() {
        this.original_dropoff_towing_location = null;
        return this.towing_locations;
    }

    //////////////////////////////////////
    // LEGS
    //////////////////////////////////////

    addTowingLegAtIndex(leg: TowingLeg, index = 0) {
        this.towing_legs.splice(index, 0, new TowingLeg(leg));
        // reindex legs
        return this.reIndexTowingLegs();

    }

    removeTowingLegAtIndex(index: number) {
        this.towing_legs.splice(index, 1);
        // reindex legs
        return this.reIndexTowingLegs();
    }
    reIndexTowingLegs() {
        this.towing_legs.forEach((leg, index) => {
            leg.index = index;
        })
        return this.towing_legs;
    }

    /**
     * Get total distance of legs
     */
    getTotalDistance() {
        let total = 0;
        this.towing_legs.forEach(leg => {
            if (leg.directions) {
                total += leg.directions.distance_value;
            }
        })
        return total;
    }

    /**
     * Get total duration of legs
     * @returns {number} total duration
     */
    getTotalDuration() {
        let total = 0;
        this.towing_legs.forEach(leg => {
            if (leg.directions) {
                total += leg.directions.duration_value;
            }
        })
        return total;
    }

    get pickup_address() {
        return this.towing_locations[0].address;
    }

    get dropoff_address() {
        return this.towing_locations[this.towing_locations.length - 1].address;
    }

    get pickup_location() {
        return this.towing_locations[0].location;
    }

    get dropoff_location() {
        return this.towing_locations[this.towing_locations.length - 1].location;
    }

    get pickup_time() {
        return this.towing_locations[0].time;
    }

    get dropoff_time() {
        return this.towing_locations[this.towing_locations.length - 1].time;
    }

    get pickup_address_type() {
        return this.towing_locations[0].address_type;
    }

    get dropoff_address_type() {
        return this.towing_locations[this.towing_locations.length - 1].address_type;
    }

    get pickup_location_type() {
        return this.towing_locations[0].location_type;
    }

    get dropoff_location_type() {
        return this.towing_locations[this.towing_locations.length - 1].location_type;
    }

    get pickup_holding_reason() {
        return this.towing_locations[0].holding_reason;
    }

    get dropoff_holding_reason() {
        return this.towing_locations[this.towing_locations.length - 1].holding_reason;
    }




    get pickup_towing_location() {
        if (this.towing_locations.length == 1) {
            this.towing_locations.unshift(new TowingLocation({ index: 0, name: "Pickup", location_type: JOB_LOCATION_TYPE.ADDRESS, address_type: "Home", holding_reason: "Holding", address: new JobAddress({}) }));
        }
        return new TowingLocation(this.towing_locations[0]);
    }

    get dropoff_towing_location() {
        if (this.towing_locations?.length == 1) {
            this.towing_locations.push(new TowingLocation({ index: 1, name: "Dropoff", location_type: JOB_LOCATION_TYPE.ADDRESS, address_type: "Home", holding_reason: "Holding", address: new JobAddress({}) }));
        }
        return new TowingLocation(this.towing_locations[this.towing_locations.length - 1]);
    }

    set pickip_towing_location(location: TowingLocation) {
        if (this.towing_locations.length == 1) {
            this.towing_locations.unshift(new TowingLocation({ index: 0, name: "Pickup", location_type: JOB_LOCATION_TYPE.ADDRESS, address_type: "Home", holding_reason: "Holding", address: new JobAddress({}) }));
        }
        this.towing_locations[0] = new TowingLocation(location);
    }

    set dropoff_towing_location(location: TowingLocation) {
        if (this.towing_locations.length == 1) {
            this.towing_locations.push(new TowingLocation({ index: 1, name: "Dropoff", location_type: JOB_LOCATION_TYPE.ADDRESS, address_type: "Home", holding_reason: "Holding", address: new JobAddress({}) }));
        }
        this.towing_locations[this.towing_locations.length - 1] = new TowingLocation(location);
    }
}

export class TowingLocation {
    index: number;
    location_type: JOB_LOCATION_TYPE;
    address_type: string;
    holding_reason: string;
    address: JobAddress;
    location: JobLocation;
    time: number | null;
    name: string;
    note: string;

    constructor(details: Partial<TowingLocation> = {}) {
        this.index = details?.index ? details?.index : 0;
        this.location_type = details?.location_type ? details?.location_type : JOB_LOCATION_TYPE.ADDRESS;
        this.address_type = details?.address_type ? details?.address_type : "Home";
        this.holding_reason = details?.holding_reason ? details?.holding_reason : "Holding";
        this.address = details?.address ? new JobAddress(details?.address) : new JobAddress({});
        this.location = details?.location ? new JobLocation(details?.location) : new JobLocation({});
        this.time = details?.time ? details?.time : null;
        this.name = details?.name ? details?.name : "Location";
        this.note = details?.note ? details?.note : "";
    }
}

export class TowingLeg {

    index: number;
    origin_towing_location_index: number;
    destination_towing_location_index: number;
    origin: JobLocation | null;
    destination: JobLocation | null;
    directions: JobDirections;
    cost_per_km: number;
    covered_distance_kms: number;
    total_cost: number;
    actual_distance_kms: number;
    actual_duration_seconds: number;

    constructor(details: any = {}) {
        this.index = details?.index ? details?.index : 0;
        this.origin_towing_location_index = details?.origin_towing_location_index ? details?.origin_towing_location_index : 0;
        this.destination_towing_location_index = details?.destination_towing_location_index ? details?.destination_towing_location_index : 0;
        this.origin = details?.origin ? new JobLocation(details?.origin) : null;
        this.destination = details?.destination ? new JobLocation(details?.destination) : null;
        this.directions = details?.directions ? new JobDirections(details?.directions) : new JobDirections({});
        this.cost_per_km = details?.cost_per_km ? details?.cost_per_km : 0;
        this.covered_distance_kms = details?.covered_distance_kms ? details?.covered_distance_kms : 0;
        this.total_cost = details?.total_cost ? details?.total_cost : 0;
        this.actual_distance_kms = details?.actual_distance_kms ? details?.actual_distance_kms : 0;
        this.actual_duration_seconds = details?.actual_duration_seconds ? details?.actual_duration_seconds : 0;
    }
}

export enum POLYLINE_TYPE {
    GOOGLE = "google",
    OSRM = "osrm"
}

/**
 * Holds an encoded polyline -- Note: this seems to be only used within routes, towing directions are stored as a google polyline string
 */
export class Polyline {
    index: number;
    polyline: any;
    type: POLYLINE_TYPE;
    constructor(polyline: any) {
        this.index = polyline?.index ? polyline?.index : 0;
        this.polyline = polyline?.polyline ? polyline?.polyline : null;
        this.type = polyline?.type ? polyline?.type : POLYLINE_TYPE.GOOGLE;
    }

    toGoogleObject() {
        if (this.type == POLYLINE_TYPE.OSRM) {
            return OsrmController.convertOSRMPolylineStringToGooglePolylineObject(this.polyline);
        }
        else {
            return this.decode();
        }
    }

    toOsrmString() {
        if (this.type == POLYLINE_TYPE.OSRM) {
            return this.polyline;
        }
        else {
            return this.encode();
        }
    }

    toGoogleString() {
        return GoogleController.encodePolyline(this.polyline);
    }

    decode() {
        return GoogleController.decodePolyline(this.polyline);
    }

    encode() {
        return GoogleController.encodePolyline(this.polyline);
    }


}


export class JobDirections {
    index: number;
    distance_text: string;
    duration_text: string;
    distance_value: number;
    duration_value: number;
    polyline: Polyline | string | null;

    constructor(directions: any = {}) {
        this.index = directions?.index ? directions?.index : 0;
        this.distance_text = directions?.distance_text ? directions?.distance_text : "";
        this.duration_text = directions?.duration_text ? directions?.duration_text : "";
        this.distance_value = directions?.distance_value ? directions?.distance_value : 0;
        this.duration_value = directions?.duration_value ? directions?.duration_value : 0;
        this.polyline = directions?.polyline ? directions?.polyline : null;
    }

    decodePolyline() {
        return GoogleController.decodePolyline(this.polyline as string);
    }

    encodePolyline() {
        return GoogleController.encodePolyline(this.polyline as string);
    }
}

export enum JOB_TYPE {
    TOWING = "towing",
    ROADSIDE = "roadside",
    TRANSPORT = "transport",
    OTHER = "other"
}

class JobPriority {
    name = "";
    value = 0;
    constructor(priority: any = {}) {
        this.name = priority.name ? priority.name : "Normal";
        this.value = priority.value ? priority.value : 0;
    }
}

export const JOB_PRIORITY = {
    NORMAL: new JobPriority({ name: "Normal", value: 0 }),
    LOW: new JobPriority({ name: "Low", value: 1 }),
    HIGH: new JobPriority({ name: "High", value: 2 }),
    URGENT: new JobPriority({ name: "Urgent", value: 3 }),
    EMERGENCY: new JobPriority({ name: "Emergency", value: 4 })
}

export class JobLocation {
    lat: number;
    lng: number;
    constructor(location: any = {}) {
        // Check if location is null and use an empty object if it is
        location = location || {};

        // Destructure with default values directly in the parameters
        const {
            lat = 0,
            latitude = 0,
            lng = 0,
            lon = 0,
            longitude = 0
        } = location;

        // Use the first truthy value or 0 if all are falsy
        this.lat = lat || latitude || 0;
        this.lng = lng || lon || longitude || 0;

        // this.lat = location?.lat ? location.lat : 0;
        // this.lng = location?.lng ? location.lng : 0;

        // location.lon ? this.lng = location.lon : 0;
        // location.latitude ? this.lat = location.latitude : 0;
        // location.longitude ? this.lng = location.longitude : 0;


    }

    get latitude() {
        return this.lat;
    }

    get longitude() {
        return this.lng;
    }

    get lon() {
        return this.lng;
    }

    array() {
        return [this.lat, this.lng];
    }

    latLng() {
        return { lat: this.lat, lng: this.lng };
    }
}

export class JobOptions {
    roadside_job_options: boolean;
    towing_job_options: boolean;
    transport_job_options: boolean;

    constructor(options: any = {}) {
        this.roadside_job_options = options.roadside_job_options;
        this.towing_job_options = options.towing_job_options;
        this.transport_job_options = options.transport_job_options;

        // if there are no option properties, default to roadside job options
        const hasRoadsideProperty = options.hasOwnProperty("roadside_job_options");
        const hasTowingProperty = options.hasOwnProperty("towing_job_options");
        const hasTransportProperty = options.hasOwnProperty("transport_job_options");

        if (!hasRoadsideProperty && !hasTowingProperty && !hasTransportProperty) {
            this.roadside_job_options = true;
            this.towing_job_options = false;
            this.transport_job_options = false;
        }
    }
}

export class JobNote {
    _id!: string;
    member_id: string;
    note: string;
    type: JOB_NOTE_TYPE;
    created_time: number;

    constructor(note: any = {}) {
        this._id = note._id ? note._id : Math.random().toString(36).substr(2, 9);
        this.member_id = note.member_id;
        this.note = note.note;
        this.type = note.type;
        this.created_time = note.created_time || Date.now();
    }
}

export enum JOB_NOTE_TYPE {
    GENERAL = "general",
    WARNING = "warning",
    PRIORITY = "priority"
}


export class JobTransportDetails {
    route_id: string;

    constructor(details: any = {}) {
        this.route_id = details.route_id || null;
    }
}