import React, { useState, useEffect, useRef, useReducer } from 'react';
//@ts-ignore
import { StyleSheet, View } from 'react-native';
import { Layout, Text, Button, Divider, List, Card } from '@ui-kitten/components';
import * as RootNavigation from '../../routes';
import * as UpdateController from '../../functions/update.controller';
import * as UserController from '../../functions/user.controller';
import * as StorageController from "../../functions/storageController"
import * as CompanyController from "../../functions/company.controller"
import * as MqttService from "../../services/mqtt.service"
import { Member } from '../../models/Member.model';
import { Company } from '../../models/Company.model';
var mqtt = require('../../services/mqtt.service');




export const CompanySelectScreen = ({ navigation }: any) => {

    const [companies, setCompanies] = useState([] as Company[])

    //get user id then get membership from api
    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            load()
        });
        return unsubscribe;
    }, [navigation])

    async function load() {
        const memberships = await UpdateController.updateMembership()
        if (!memberships) return
        const filteredMemberships = memberships.filter((membership: Member) => {
            return !membership.deleted && !membership.locked
        })
        const companies = await CompanyController.getCompaniesFromMemberships(filteredMemberships)
        // StorageController.appState.companies = companies
        // StorageController.saveStateToDisk()
        setCompanies(companies)
    }


    async function nav(company: Company) {
        if (!company) return
        // StorageController.appState.selectedMembership = company.membership
        // StorageController.appState.selectedCompany = company
        // StorageController.appState.clients = company.clients
        // StorageController.appState.members = company.members
        // StorageController.appState.vendorProfiles = company.vendorProfiles
        // StorageController.appState.services = company.services
        // StorageController.saveStateToDisk();
        // if (company.membership) {
        //     UserController.setMembershipOnlineStatus(company.membership._id, true)
        // }
        // if (StorageController.getAppState().selectedMembership != null) {
        //     mqtt.connect();
        // }
        await CompanyController.switchCompany(company)
        // UpdateController.dispatchEventStateChange({ type: 'UPDATED_SELECTED_COMPANY', data: company })
        // UpdateController.dispatchEventStateChange({ type: 'UPDATED_SELECTED_MEMBERSHIP', data: company.membership })
        const data = await UpdateController.getAllCompanyDashboardData()
        MqttService.createACLSubscriptions()
        RootNavigation.navigate('Overview', { data: data })
    }

    const renderItemHeader = (headerProps: any, company: any) => (
        <View {...headerProps}>
            <Text category='h6'>
                {company.item.name}
            </Text>
        </View>
    );

    const renderItemFooter = (footerProps: any, company: any) => (
        <Text {...footerProps}>
            <Button style={{ flex: 1 }} onPress={() => { nav(company.item) }}>Select</Button>
        </Text>
    );

    const renderItem = (company: any) => (
        <Card
            style={styles.item}
            status='basic'
            header={(headerProps: any) => renderItemHeader(headerProps, company)}
            footer={(footerProps: any) => renderItemFooter(footerProps, company)}>
            <Text>
                {company.item.details}
            </Text>
        </Card>
    );


    return (
        <Layout style={{ flex: 1, alignItems: 'center' }}>
            <Text category='h1'>Select Membership</Text>
            <Divider />
            <List
                style={styles.container}
                contentContainerStyle={styles.contentContainer}
                data={companies}
                renderItem={renderItem}
            />
        </Layout>
    );
};

const styles = StyleSheet.create({
    container: {
        maxHeight: "100%",
        width: "100%",
    },
    contentContainer: {
        paddingHorizontal: 8,
        paddingVertical: 4,
    },
    item: {
        marginVertical: 4,
    },
});