import React, { useState, useEffect, useRef, useReducer, useContext } from 'react';
import {
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Platform,
    Alert,
    useWindowDimensions
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Layout,
    Text,
    List,
    ListItem,
    Select,
    SelectItem,
    Card,
    Input,
    Modal,
    Toggle,
    Radio,
    RadioGroup
} from "@ui-kitten/components";
import { SafeAreaView } from 'react-native-safe-area-context';
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import { mapping, light, dark } from "@eva-design/eva";
// import NewJob from './newJob.component';
import CreateJob from '../newJobComponents/createJobContainer.component';
import * as JobController from '../../../functions/job.controller';
import * as StorageController from '../../../functions/storageController';
import * as UpdateController from '../../../functions/update.controller';
import * as GoogleController from '../../../functions/google.controller';
import { FullWindowOverlay } from 'react-native-screens';
import { ServerContainer } from '@react-navigation/native';
import { ServicesContainer } from '../services/servicesContainer.component';
import NotificationContext from '../../../context/notifications.context';
import { Job } from '../../../models/Job.model';
import { Service } from '../../../models/Service';
import { Company } from '../../../models/Company.model';
import { Member } from '../../../models/Member.model';

interface INewJobContainerScreenProps {
    fn_onCreateJob: () => void;
    case_id?: string | null;
    scheduledStartTime?: number | null;
}

const NewJobContainerScreen = ({
    fn_onCreateJob,
    case_id,
    scheduledStartTime
}: INewJobContainerScreenProps) => {
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [Job, setJob] = useState<Job | null>(null)
    const [services, setServices] = useState([])
    // const [selectedServices, setSelectedServices] = useState([])
    const [jobCreatedVisible, setJobCreatedVisible] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    // const [showServicesArea, setShowServicesArea] = React.useState(false);
    const [showErrorModal, setShowErrorModal] = React.useState(false);
    const [errorModalText, setErrorModalText] = React.useState("");
    const [randUpdate, setRandUpdate] = React.useState(0);

    const [selectedCompany, setSelectedCompany] = React.useState<Company | null>(null)
    const [selectedMembership, setSelectedMembership] = React.useState<Member | null>(null)

    const { windowWidth, windowHeight } = useWindowDimensions();

    React.useEffect(() => {
        // setSelectedServices([])
        setLoaded(false)
        load()
        return () => {
            // setSelectedServices([])
            // setShowServicesArea(false)
        }
    }, []);




    const load = async () => {
        try {
            setSelectedCompany(StorageController.getCurrentCompany())
            setSelectedMembership(StorageController.getAppState().selectedMembership)
            if (StorageController.getCurrentCompany().services.length == 0) {
                setErrorModalText("Please add services to your company before creating a job")
                setShowErrorModal(true)
                return
            }
            if (StorageController.getCurrentCompany().clients.length == 0) {
                setErrorModalText("Please add clients before creating a job")
                setShowErrorModal(true)
                return
            }
            setTimeout(() => {
                // setShowServicesArea(true)
                // setRandUpdate(Math.random())
            }, 200);
            setLoaded(true)
        } catch (err) {
            console.log(err)
        }
    }

    const [showNoServiceModal, setShowNoServiceModal] = React.useState(false);

    const RenderNoServiceModal = () => (
        <Modal
            visible={showNoServiceModal}
            backdropStyle={styles.backdrop}
            onBackdropPress={() => setShowNoServiceModal(false)}>
            <Card disabled={true}>
                <Text category='h6'>No Services</Text>
                <Text category='s1'>Please add services to your company before creating a job</Text>
                <Button appearance='outline' onPress={() => setShowNoServiceModal(false)}>OK</Button>
            </Card>
        </Modal>
    );

    const RenderErrorModal = ({ text }: any) => (
        <Modal
            visible={showErrorModal}
            backdropStyle={styles.backdrop}
        >
            <Card disabled={true}>
                <Text category='h6'>Notice:</Text>
                <Text category='s1'>{text}</Text>
                <Button appearance='outline' onPress={() => {
                    setShowErrorModal(false)
                    // setSelectedServices([])
                    // setShowServicesArea(false)
                }}
                >OK</Button>
            </Card>
        </Modal>
    );


    const createJob = async (jobObj: Job) => {
        try {

            // console.log("🚀============== ~ file: newJobContainerScreen.js:141 ~ createJob ~ jobObj🚀==============", jobObj)
            if (!selectedMembership) return


            const selectedServices = jobObj.details.selected_services

            if (selectedServices.length == 0) {
                setShowNoServiceModal(true)
                return
            }

            if (case_id) {
                jobObj.case_id = case_id
            }
            jobObj.details.selected_services = selectedServices
            jobObj.created_by = selectedMembership?._id
            jobObj.log = [{
                time: new Date().getTime(),
                type: "info",
                message: "Job created by " + selectedMembership.name + " on " + new Date().toLocaleDateString()
            }]
            // // console.log("🚀============== ~ file: newJobContainerScreen.js:162 ~ createJob ~ jobObj🚀==============", jobObj)
            const newJob = await JobController.createJob(jobObj)
            if (!newJob) {
                console.error("Error creating job")
                return
            }
            // console.log(newJob)
            // setShowServicesArea(false)
            setJob(newJob)
            setJobCreatedVisible(true)
            // setSelectedServices([])
            setServices([])
            forceUpdate();
            UpdateController.dispatchEventStateChange({ type: "CREATED_JOB", data: newJob })
            fn_onCreateJob()
        } catch (err) {
            console.log("🚀============== ~ file: newJobContainerScreen.tsx:178 ~ createJob ~ err🚀==============", err)
        }
    }

    return (
        <Layout style={{ flex: 1 }}>
            <RenderNoServiceModal />
            <RenderErrorModal text={errorModalText} />
            <Modal
                visible={jobCreatedVisible}
                backdropStyle={styles.backdrop}
                onBackdropPress={() => setJobCreatedVisible(false)}>
                <Card disabled={true}>
                    <Text>Job has been created</Text>
                    <Button onPress={() => {
                        // setSelectedServices([])
                        setJobCreatedVisible(false)
                    }}>
                        DISMISS
                    </Button>
                </Card>
            </Modal>
            <Layout style={{ flex: 1, flexDirection: 'column' }}>
                {loaded &&
                    <Layout style={{ justifyContent: 'center', flexDirection: "row" }}>
                        <Layout style={{
                            flex: 1,
                            justifyContent: 'center',
                            height: "100%",
                            width: "100%",
                            // minWidth: 600
                        }}>
                            <CreateJob
                                jobSource={selectedMembership?.is_client ? "local_client" : "vendor"}
                                fn_createJob={createJob}
                                case_id={case_id}
                                selectedCompany={selectedCompany}
                                selectedMembership={selectedMembership}
                                scheduledStartTime={scheduledStartTime}
                                vendorProfile={undefined}
                                clientProfile={undefined}
                            />
                        </Layout>
                    </Layout>
                }
            </Layout>
        </Layout>
    );

};


const styles = StyleSheet.create(
    {
        container: {
            maxHeight: "100%",
        },
        contentContainer: {
            paddingHorizontal: 0,
            paddingVertical: 0,
        },
        listContainer: {
            flexDirection: "column",
            height: "100%",
            width: "100%",
            padding: 0,
            margin: 0
        },
        safe_view_area: {
            flex: 1,
            height: "100%",
            width: "100%",
            padding: 0,
            margin: 0
        },
        item: {
            marginVertical: 4,
        },
        side_section: {
            flex: 1
        },
        backdrop: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        service: {
            flexGrow: 5,
            flexShrink: 1,
            borderBottomColor: "black",
            borderBottomWidth: "1px"
        }

    }
);

export default NewJobContainerScreen;