import React, { useState, useEffect, useRef, useReducer, useContext } from 'react';
import {
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    ScrollView,
    View,
    Text as RNText,
    Platform,
    Alert
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Layout,
    Text,
    List,
    ListItem,
    Select,
    SelectItem,
    Card,
    Input,
    Modal,
    Toggle,
    Radio,
    RadioGroup,
    Divider
} from "@ui-kitten/components";
import { SafeAreaView } from 'react-native-safe-area-context';
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import { mapping, light, dark } from "@eva-design/eva";
import CreateJob from '../newJobComponents/createJobContainer.component';
import * as JobController from '../../../functions/job.controller';
import * as StorageController from '../../../functions/storageController';
import * as UpdateController from '../../../functions/update.controller';
import * as ClientController from '../../../functions/client.controller';
import * as GoogleController from '../../../functions/google.controller';
import { FullWindowOverlay } from 'react-native-screens';
import { ServerContainer } from '@react-navigation/native';
import { ServicesContainer } from '../services/servicesContainer.component';
import NotificationContext from '../../../context/notifications.context';
import { VendorProfile } from '../../../models/VendorProfile.model';
import { CompanyContract } from '../../../models/Company.model';
import { Job } from '../../../models/Job.model';
import { Service } from '../../../models/Service';



interface NewJobForVendorContainerScreenProps {
    vendorProfile: VendorProfile;
    contract: CompanyContract | null;
    onCreateJob: (job: any) => void;
}

const NewJobForVendorContainerScreen = ({
    vendorProfile,
    contract,
    onCreateJob,
}: NewJobForVendorContainerScreenProps) => {

    // const { showNotification } = useContext(NotificationContext);
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [jobState, setJob] = useState(new Job({}) as Job)
    const [services, setServices] = useState([] as Service[])
    const [selectedServices, setSelectedServices] = useState([] as Service[])
    const [jobCreatedVisible, setJobCreatedVisible] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const [showServicesArea, setShowServicesArea] = React.useState(false);
    const [showErrorModal, setShowErrorModal] = React.useState(false);
    const [errorModalText, setErrorModalText] = React.useState("");
    const [randUpdate, setRandUpdate] = React.useState(0);
    const [vendorProfileIdForServices, setVendorProfileIdForServices] = React.useState(null as string | null);

    React.useEffect(() => {
        setSelectedServices([])
        setLoaded(false)
        load()
        // const unsubscribe = navigation.addListener('focus', () => {
        // });
        return () => {
            setSelectedServices([])
            setShowServicesArea(false)
            // unsubscribe();
        }
    }, []);

    const load = async () => {
        try {
            if (vendorProfile) {
                if (vendorProfile.linkedVendorAccountId) {
                    setVendorProfileIdForServices(vendorProfile.linkedVendorAccountId)
                }
            }
            setTimeout(() => {
                setShowServicesArea(true)
                setRandUpdate(Math.random())
            }, 200);
            setLoaded(true)
            const _job = new Job({})
            setJob(_job)
        } catch (err) {
            console.log(err)
        }
    }

    const getClientProfileId = async () => {
        if (vendorProfile.linkedVendorAccountId) {
            const clientCompanyId = StorageController.getCurrentCompany()._id
            const vendor_id = vendorProfile.linkedVendorAccountId
            const clientProfile = await ClientController.getClientByCompanyAndClientLinkingId(vendor_id, clientCompanyId)
            // console.log("🚀 ~ file: newJobForVendorContainerScreen.component.js:130 ~ getClientProfileId ~ clientProfile", clientProfile)
            return clientProfile?._id || null
        } else {
            return StorageController.getCurrentCompany()._id
        }
    }


    const [showNoServiceModal, setShowNoServiceModal] = React.useState(false);

    const RenderNoServiceModal = () => (
        <Modal
            visible={showNoServiceModal}
            backdropStyle={styles.backdrop}
            onBackdropPress={() => setShowNoServiceModal(false)}>
            <Card disabled={true}>
                <Text category='h6'>No Services</Text>
                <Text category='s1'>Please add services to your company before creating a job</Text>
                <Button appearance='outline' onPress={() => setShowNoServiceModal(false)}>OK</Button>
            </Card>
        </Modal>
    );

    const RenderErrorModal = ({ text }: any) => (
        <Modal
            visible={showErrorModal}
            backdropStyle={styles.backdrop}
        // onBackdropPress={() => setShowErrorModal(false)}
        >
            <Card disabled={true}>
                <Text category='h6'>Notice:</Text>
                <Text category='s1'>{text}</Text>
                <Button appearance='outline' onPress={() => {
                    setShowErrorModal(false)
                    setSelectedServices([])
                    setShowServicesArea(false)
                }
                }
                >OK</Button>
            </Card>
        </Modal>
    );


    const createJob = async (jobObj: Job) => {
        if (jobObj.details.selected_services.length == 0) {
            setShowNoServiceModal(true)
            return
        }
        if (vendorProfile.linkedVendorAccountId) {
            jobObj.details.selected_services = selectedServices // these are the ones that are objects
            jobObj.vendor_company_id = vendorProfile.linkedVendorAccountId
            jobObj.company_id = StorageController.getCurrentCompany()._id
            jobObj.client_id = await getClientProfileId()
        } else {
            jobObj.company_id = StorageController.getCurrentCompany()._id
        }
        jobObj.details.selected_services = selectedServices
        jobObj.contract_id = contract?._id || "no-contract"
        // jobObj.client_id = StorageController.getCurrentCompany()._id
        jobObj.vendor_profile_id = vendorProfile._id
        jobObj.created_by = StorageController.getAppState().selectedMembership?._id || ""
        jobObj.log = [{
            time: new Date().getTime(),
            type: "info",
            message: "Job created by " + StorageController.getAppState().selectedMembership?.name || "-" + " on " + new Date().toLocaleDateString()
        }]
        const job = await JobController.createJob(jobObj)
        if (!job) {
            setErrorModalText("Error creating job")
            setShowErrorModal(true)
            return
        }
        // console.log(job)
        setShowServicesArea(false)
        setJob(job)
        setJobCreatedVisible(true)
        setSelectedServices([])
        setServices([])
        forceUpdate();
        UpdateController.dispatchEventStateChange({ type: "CREATED_JOB", data: job })
        onCreateJob(job)

    }

    const updateJobServices = (services: Service[]) => {
        // console.log("updated services", services)
        setSelectedServices(services);
    }



    return (
        <Layout style={{ height: "100%", width: "100%" }}>
            <RenderNoServiceModal />
            <RenderErrorModal text={errorModalText} />
            <Modal
                visible={jobCreatedVisible}
                backdropStyle={styles.backdrop}
                onBackdropPress={() => setJobCreatedVisible(false)}>
                <Card disabled={true}>
                    <Text>Job has been created</Text>
                    <Button onPress={() => {
                        setSelectedServices([])
                        setJobCreatedVisible(false)
                    }}>
                        DISMISS
                    </Button>
                </Card>
            </Modal>
            <Layout style={{ flex: 1, flexDirection: 'column', height: "100%", width: "100%" }}>
                <Text category="h5" style={{ textAlign: "center", padding: 10 }}>{vendorProfile.name}</Text>
                <Divider />
                {loaded &&
                    <Layout style={{ flex: 15, justifyContent: 'center', flexDirection: "row" }}>
                        <Layout style={{ flex: 3, justifyContent: 'center', height: "100%", width: "100%" }}>
                            <ScrollView style={{ height: "100%", width: "100%" }}>

                                <CreateJob
                                    fn_createJob={createJob}
                                    vendorProfile={vendorProfile}
                                    jobSource={"client"}
                                    selectedCompany={undefined}
                                    selectedMembership={undefined}
                                />
                            </ScrollView>
                        </Layout>
                    </Layout>
                }
            </Layout>
        </Layout>
    );

};


const styles = StyleSheet.create(
    {
        container: {
            maxHeight: "100%",
        },
        contentContainer: {
            paddingHorizontal: 0,
            paddingVertical: 0,
        },
        listContainer: {
            flexDirection: "column",
            height: "100%",
            width: "100%",
            padding: 0,
            margin: 0
        },
        safe_view_area: {
            flex: 1,
            height: "100%",
            width: "100%",
            padding: 0,
            margin: 0
        },
        item: {
            marginVertical: 4,
        },
        side_section: {
            flex: 1
        },
        backdrop: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        service: {
            flexGrow: 5,
            flexShrink: 1,
            borderBottomColor: "black",
            borderBottomWidth: 1
        }
    }
);

export default NewJobForVendorContainerScreen;