import React, { useState, useRef, useEffect } from 'react';
import { View } from 'react-native';
import { Layout, Input, Button, Icon } from "@ui-kitten/components";
//@ts-ignore
import debounce from 'lodash.debounce';
import * as JobsController from '../../functions/job.controller';
import * as HoldingController from '../../functions/holding.controller';
import { Job } from '../../models/Job.model';
import { Holding } from '../../models/Holding.model';
import { SEARCH_TYPE } from '../overview/types/overview.types';

export const SearchJobsHoldingsCases = ({
    onSearchResultsReturned = (items: Job[] | Holding[], searchTerm: string) => { },
    resetSearch = 1 as any,
    searchType = SEARCH_TYPE.JOB as SEARCH_TYPE
}: any) => {
    var defaultLimit = 30;

    const [searchQuery, setSearchQuery] = useState('');
    const searchVal = useRef('');
    const limitRef = useRef(defaultLimit as any);

    const debouncedJobSearch = debounce((nextValue: any) => searchJobsRemote(nextValue), 1000);
    const debouncedHoldingsSearch = debounce((nextValue: any) => searchHoldingsRemote(nextValue), 1000);

    useEffect(() => {
        setSearchQuery("")
        searchVal.current = ""
    }, [resetSearch])

    const onSetSearchQuery = (value: any) => {
        searchVal.current = value
        setSearchQuery(value)
        if (value.length == 0) {
            onSearchResultsReturned([], value)
        }
    }

    // search jobs async
    const searchJobsRemote = async (term: string) => {
        if (limitRef.current == "" || limitRef.current == null || limitRef.current == 0) {
            limitRef.current = defaultLimit
        }
        if (term.length < 4) return
        var jobs = await JobsController.searchJobs(term, limitRef.current)
        onSearchResultsReturned(jobs, term)
    }

    const searchHoldingsRemote = async (term: string) => {
        if (limitRef.current == "" || limitRef.current == null || limitRef.current == 0) {
            limitRef.current = defaultLimit
        }
        if (term.length < 4) return
        var holdings = await HoldingController.searchHoldings(term, limitRef.current)
        onSearchResultsReturned(holdings, term)
    }

    const validateResultsLimitIsNumber = async (value: any) => {
        if (!isNaN(value)) {
            limitRef.current = Number(value)
            return
        }
    }

    return (
        <View style={{ padding: 0, flexDirection: 'row', gap: 5, marginRight: 10 }}>
            <Input
                style={{ flex: 8 }}
                placeholder='Search # | Client # | Rego'
                value={searchQuery}
                onChangeText={(nextValue: any) => {
                    onSetSearchQuery(nextValue);
                    if (searchType == SEARCH_TYPE.JOB) debouncedJobSearch(nextValue);
                    if (searchType == SEARCH_TYPE.HOLDING) debouncedHoldingsSearch(nextValue);
                }}
            />
            {searchQuery != "" ?
                <Button onPress={() => {
                    setSearchQuery('')
                    onSearchResultsReturned([], '')
                }} size="small" status="warning" style={{ width: "5%", margin: 2, height: 20 }}>
                    <Icon name="close-outline" width={20} height={20} fill="white" />
                </Button>
                :
                <></>
                // <View style={{ flex: 1, padding: 0 }}>
                //     <Input style={{ minWidth: 50 }}
                //         value={limitRef.current}
                //         onChangeText={(nextValue: any) => {
                //             validateResultsLimitIsNumber(nextValue)
                //         }} />
                // </View>
            }
        </View>
    );
}
