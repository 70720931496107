import { Company } from '../../../models/Company.model';
import { Member } from '../../../models/Member.model';
import { Case } from '../../../models/Case.model';

export const getDriversOnlineCount = (company: Company): number => {
    try {
        let count = 0;
        if (!company?.members) return count;
        const drivers = company.members?.filter((member: Member) => member.is_driver);
        drivers?.forEach((driver: Member) => {
            if (driver.online == true) {
                let lastUpdated = new Date(driver.updatedAt);
                let now = new Date();
                let diff = now.getTime() - lastUpdated.getTime();
                if (diff < 1000 * 60 * 60) count++;
            }
        });
        return count;
    } catch (error) {
        console.error("Error getting drivers online count:", error);
        return 0;
    }
};

export const getOngoingCasesCount = (company: Company): number => {
    let count = 0;
    company.cases.forEach((c: Case) => {
        if (c.status == "ongoing") count++;
    });
    return count;
}; 