import React, { useState, useEffect, useReducer, useCallback, useRef } from 'react';
//@ts-ignore
import { StyleSheet, Text as RNText, Platform, useWindowDimensions, View, Alert, ScrollView } from "react-native";
import {
    Button,
    Icon,
    Layout,
    Card,
    Input,
    Modal,
    Text,
    //@ts-ignore
    Spinner,
    List,
    ListItem,
    //@ts-ignore
    IndexPath,
    Select,
    SelectItem,
    Toggle,
    //@ts-ignore
    CheckBox,
    Radio,
    RadioGroup
} from "@ui-kitten/components";
import * as UpdateController from '../../functions/update.controller'
import * as StorageController from '../../functions/storageController'
import * as ServiceController from '../../functions/service.controller'
import * as MembershipController from '../../functions/membership.controller'
import ErrorBoundary from '../ErrorBoundary.component';
import { Job } from '../../models/Job.model'
import { Service, ServiceTagsArr, SERVICE_TAGS, SERVICE_FIELD_TYPE, ServiceFields, SERVICE_FIELD_TYPES } from '../../models/Service';
import { useNotification } from '../../context/notifications.context';
import { Company } from '../../models/Company.model';
import { useAppStateChange, IAction, STATE_ACTIONS } from '../../hooks/appStateChange.hook';
import DMModal from '../common/Modal';
import ServiceEditor from './serviceEditor.component';
// import { FILE } from 'dns';


// screen to manage company services
export const ServicesScreen = ({ navigation }: any) => {

    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [services, setServices] = useState<Service[]>([]);
    const [loading, setLoading] = useState(false);

    const [selectedService, setSelectedService] = useState<Service | null>(null);

    const [registeredEvents, setRegisteredEvents] = useState(false);
    const [showNewServiceModal, setShowNewServiceModal] = useState(false);

    const [canEditField, setCanEditField] = useState(false);
    const [enableEditField, setEnableEditField] = useState(false);

    const [canCreateService, setCanCreateService] = useState(false);
    const [enableCreateService, setEnableCreateService] = useState(false);

    const unsavedChangesRef = useRef(false);
    const [showConfirmUnsavedServiceChangeModal, setShowConfirmUnsavedServiceChangeModal] = useState(false);

    const { width, height } = useWindowDimensions();
    const { showNotification } = useNotification();



    const [selectedCompany, setSelectedCompany] = useState<Company | null>(null)


    const onSetSelectedService = (service: Service | null) => {
        if (unsavedChangesRef.current) {
            setShowConfirmUnsavedServiceChangeModal(true);
        }
        else {
            setSelectedService(service);
        }
    }






    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            load();
            // get member permissions for this screen

        });
        return unsubscribe;
    }, [navigation])


    const handleCompanyChangeEvent = useCallback((action: IAction) => {
        setSelectedCompany(new Company(action.data))
        setServices([])
        setSelectedService(null)
        setTimeout(async () => {
            load()
        }, 100);
    }, [selectedCompany]);


    const { dispatchEventStateChange } = useAppStateChange({
        onUpdatedSelectedCompany: handleCompanyChangeEvent,
    })


    const load = async () => {
        setLoading(true);
        const company = StorageController.getCurrentCompany()
        ServiceController.getServicesByCompanyId(company._id).then((_services: Service[]) => {
            //console.log("Services: ", _services)
            setServices(_services);
            StorageController.appState.services = _services;
            StorageController.saveStateToDisk();
            setLoading(false);
            getMemberPermissions();
            // forceUpdate();
        })
    }


    const getMemberPermissions = () => {
        MembershipController.getMemberPermissions().then((permissions) => {
            //console.log("Permissions: ", permissions);
            const selectedMembership = StorageController.getAppState().selectedMembership;
            if (!selectedMembership) return;
            setCanCreateService(selectedMembership?.is_admin);
            setCanEditField(selectedMembership?.is_admin);
        })
    }




    const handleServiceSelect = (service: Service) => {
        //console.log("Selected service: ", service);
        if (!service.other) {
            service.other = {};
        }
        onSetSelectedService(service);
    }

    const handleServiceDelete = (service: Service) => {
        //console.log("Deleting service: ", service);
        ServiceController.deleteService(service).then(() => {
            //console.log("Deleted service: ", service);
            onSetSelectedService(null);
            forceUpdate();
            showNotification("Service deleted", "success");
        })
    }

    const handleServiceSave = async (service: Service) => {
        //console.log("Saving service: ", service);
        await ServiceController.updateService(service)
        unsavedChangesRef.current = false;
        handleServiceSelect(service);
        showNotification(`${service.name} Saved`, "success");
        // forceUpdate();
    }

    const handleServiceCreate = async (name: string) => {
        // Check that a name was provided
        if (name.length == 0) {
            return;
        }
        // Create a new service definition
        try {

            const company = StorageController.getCurrentCompany();
            let s = capitalizeFirstLetter(name);
            let company_id = company._id;
            let _service = await ServiceController.tempGenerateServiceDefinition(s, company_id);
            let _sObj = await ServiceController.createService(_service) as Service;
            StorageController.appState.services.push(_sObj);
            StorageController.saveStateToDisk();
            onSetSelectedService(_sObj);
            showNotification(`${_sObj.name} Created`, "success");
        } catch (e) {
            console.log("Error creating service: ", e);
        }
    }

    const handleServiceCancel = () => {
        unsavedChangesRef.current = false;
        onSetSelectedService(null);
    }

    function capitalizeFirstLetter(str: string) {
        var words = str.split(" ");
        var capitalizedWords = words.map(function (word) {
            return word[0].toUpperCase() + word.slice(1);
        });
        return capitalizedWords.join(" ");
    }




    // confirm delete service modal
    const [showServiceDeleteModal, setShowServiceDeleteModal] = useState(false);
    const ServiceDeleteModal = () => {
        return (
            <Modal
                visible={showServiceDeleteModal}
                backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                onBackdropPress={() => setShowServiceDeleteModal(false)}
            >
                <Card disabled={true}>
                    <Text>Are you sure you want to delete this service?</Text>
                    <Button
                        status="danger"
                        accessoryLeft={(props: any) => <Icon {...props} name="trash-2-outline" />}
                        onPress={() => {
                            setShowServiceDeleteModal(false);
                            handleServiceDelete(selectedService as Service);
                        }}
                    >
                        Delete
                    </Button>
                    <Button
                        onPress={() => {
                            setShowServiceDeleteModal(false);
                        }}
                    >
                        Cancel
                    </Button>
                </Card>
            </Modal>
        );
    }



    const RenderConfirmUnsavedServiceChangeModal = () => {
        return (
            <Modal
                visible={showConfirmUnsavedServiceChangeModal}
                backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                onBackdropPress={() => setShowConfirmUnsavedServiceChangeModal(false)}
            >
                <Card disabled={true}>
                    <Text category="h6">Unsaved Changes</Text>
                    <Text>There are unsaved changes. Are you sure you want to continue?</Text>
                    <Layout style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Button onPress={() => setShowConfirmUnsavedServiceChangeModal(false)}>Cancel</Button>
                        <Button onPress={() => {
                            setShowConfirmUnsavedServiceChangeModal(false);
                            unsavedChangesRef.current = false;
                            setSelectedService(null);
                        }}>Continue</Button>
                    </Layout>
                </Card>
            </Modal>
        )
    }

    const onSetUnsavedChangesRef = (value: boolean) => {
        unsavedChangesRef.current = value;
    }


    return (
        <Layout style={{ flex: 1, borderColor: 'yellow', borderWidth: 1 }}>
            <NewServiceModal
                visible={showNewServiceModal}
                handleServiceCreate={handleServiceCreate}
                setShowNewServiceModal={setShowNewServiceModal}
            />
            <RenderConfirmUnsavedServiceChangeModal />
            <ServiceDeleteModal />
            <Layout style={{ flex: 1, flexDirection: 'row' }}>
                <Layout style={{ flex: 1, flexDirection: 'column' }}>
                    <Layout style={{ flex: 1, flexDirection: 'row' }}>
                        <Button
                            style={{ flex: 1 }}
                            status="success"
                            disabled={!canCreateService}
                            onPress={() => { setShowNewServiceModal(true) }}
                            accessoryLeft={(props: any) => <Icon {...props} name="plus-outline" />}
                        />
                        <Button
                            style={{ flex: 1 }}
                            status="danger"
                            disabled={!canCreateService}
                            onPress={() => { setShowServiceDeleteModal(true) }}
                            accessoryLeft={(props: any) => <Icon {...props} name="minus-outline" />}
                        />
                    </Layout>
                    <Layout style={{ flex: 15, border: '1px solid' }}>
                        {loading && <Spinner />}
                        <List
                            data={services}
                            renderItem={({ item }: { item: Service }) => (
                                <ListItem
                                    key={item._id}
                                    title={item.name}
                                    style={{
                                        backgroundColor: selectedService && selectedService._id === item._id ? 'green' : '',
                                        border: '1px solid'
                                    }}
                                    description={`Service ID: ${item._id}`}
                                    onPress={() => handleServiceSelect(item)}
                                />
                            )}
                        />
                    </Layout>
                </Layout>
                <Layout style={{ flex: 4 }}>
                    {selectedService &&
                        <ServiceEditor
                            service={selectedService}
                            availableServices={services}
                            // _unsavedChanges={unsavedChangesRef.current}
                            canEditField={canEditField}
                            selectedService={selectedService}
                            unsavedChangesRef={unsavedChangesRef}
                            handleServiceCancel={handleServiceCancel}
                            handleServiceSave={handleServiceSave}
                            onSetUnsavedChangesRef={onSetUnsavedChangesRef}
                            capitalizeFirstLetter={capitalizeFirstLetter}
                        />
                    }
                </Layout>
            </Layout>
        </Layout>
    )
}



const NewServiceModal = ({
    visible,
    handleServiceCreate,
    setShowNewServiceModal,
}: any) => {
    const [name, setName] = useState("");

    const handleSave = () => {
        if (name.length == 0) {
            return;
        }
        handleServiceCreate(name);
        setShowNewServiceModal(false);
    }
    const handleCancel = () => {
        setShowNewServiceModal(false);
    }

    return (
        <DMModal
            visible={visible}
            onClose={handleCancel}
        >
            <Card
                disabled
                status="primary"
                header={() => <Text>Create New Service</Text>}
                style={{ justifyContent: 'center', alignItems: 'center' }}>
                <View style={{ flexDirection: 'column', gap: 10 }}>
                    <Input
                        placeholder="Service Name"
                        onChangeText={(text: string) => setName(text)}
                        value={name}
                    />
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Button status="success" onPress={() => handleSave()}>Save</Button>
                        <Button status="warning" appearance="ghost" onPress={() => handleCancel()}>Cancel</Button>
                    </View>
                </View>
            </Card>
        </DMModal>

    )
}


