import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import {
    Image,
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Animated,
    ScrollView,
    Dimensions,
    useWindowDimensions,
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Input,
    Modal,
    Layout,
    //@ts-ignore
    Spinner,
    Text,
    List,
    ListItem,
    Divider,
    Card
} from "@ui-kitten/components";
import { SafeAreaView } from 'react-native-safe-area-context';
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import * as HoldingController from "../../../functions/holding.controller"
import * as StorageController from "../../../functions/storageController"
import * as JobController from "../../../functions/job.controller"
import { Holding } from "../../../models/Holding.model";
import { Job, JobAddress, JobClientRate, JobCustomerDetails, JobLocation, JobVehicleDetails, JobNote, JOB_NOTE_TYPE } from "../../../models/Job.model";
import { Service } from '../../../models/Service';
import { LineItem } from '../../../models/LineItem.model';
import { ReportDetailsCard } from '../details/reportDetailsCard.component';
import { CreateJobFromHolding } from '../modals/createJobFromHoldingModal.component';
import { InventoryDetailsCard } from '../details/inventory/inventoryDetailsCard.component';
import { InvoicedTotalCard } from '../details/jobInvoicedTotalDetailsCard.component';
import * as ClientController from '../../../functions/client.controller'
import { LocationDetailsCard } from '../details/locationDetailsCard.component';
import { Client } from '../../../models/Client.model';
import * as ImageController from '../../../functions/image.controller';

import { CustomerDetailsCard } from '../details/customerDetailsCard.component';
import { VehicleDetailsCard } from '../details/vehicleDetailsCard.component';
import { ClientDetailsCard } from '../details/ClientDetailsCard.component';
import { ImageDetailsCard } from '../details/imageDetailsCard.component';

import { useNotification } from '../../../context/notifications.context';
import * as ImagePicker from 'expo-image-picker';
import { formatTimestamp } from '../../../functions/utils';
import { JobNotesList } from '../details/jobNotes.component';
import { ServiceDetailsCard } from '../details/serviceDetailsCard.component';


enum DEVICE_MODE {
    MOBILE = 'mobile',
    DESKTOP = 'desktop'
}

interface CreateHoldingContainerProps {
    onCreate: (holding: Holding) => void;
    onClose: () => void;
    case_id?: string | null;
}

export const CreateHoldingContainer = ({ onCreate, onClose, case_id }: CreateHoldingContainerProps) => {

    const [holding, setHolding] = useState(new Holding());
    const m_holding = useMemo(() => holding, [holding]);
    const [images, setImages] = useState<any[]>([]);
    const [localImages, setLocalImages] = useState<ImagePicker.ImageInfo[]>([]);
    const localImagesRef = useRef(localImages);
    const holdingRef = useRef(holding);
    const { showNotification } = useNotification();
    const [renderSpinner, setRenderSpinner] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    const [disableCreateButton, setDisableCreateButton] = useState(false);
    const [viewMode, setViewMode] = React.useState(DEVICE_MODE.DESKTOP as DEVICE_MODE)
    const windowWidth = useWindowDimensions().width;
    const windowHeight = useWindowDimensions().height;

    const [selectedCaseId, setSelectedCaseId] = useState<string | null>(null);

    useEffect(() => {
        if (case_id) {
            console.log("🚀============== ~ file: createHolding.component.tsx:92 ~ useEffect ~ case_id🚀==============", case_id)
            setSelectedCaseId(case_id);
        }
        return () => {
            setSelectedCaseId(null);
        }
    }, [case_id])

    const onSetHolding = (holding: Holding | any) => {
        const h = new Holding(holding);
        setHolding(h);
        holdingRef.current = h;
    }

    useEffect(() => {
        if (windowWidth < 800) {
            setViewMode(DEVICE_MODE.MOBILE)
        }
    }, [windowWidth])


    const onSetCustomerDetails = (customerDetails: JobCustomerDetails) => {
        onSetHolding({ ...holdingRef.current, details: { ...holdingRef.current.details, customer_details: customerDetails } });
    }

    const onSetVehicleDetails = (vehicleDetails: JobVehicleDetails) => {
        onSetHolding({ ...holdingRef.current, details: { ...holdingRef.current.details, vehicle_details: vehicleDetails } });
    }

    const onSetClient = (clientDetails: Client) => {
        const h = new Holding({ ...holdingRef.current });
        h.client_id = clientDetails._id as string;
        // h.client_company_id = clientDetails.linkedClientAccountId ?? null;
        onSetHolding(h);
    }

    const onSetClientRate = (rate: JobClientRate) => {
        onSetHolding({ ...holdingRef.current, details: { ...holdingRef.current.details, client_rate: rate } });
    }

    const onSetServices = (services: Service[]) => {
        onSetHolding({ ...holdingRef.current, details: { ...holdingRef.current.details, selected_services: services } });
    }

    const onSetLocalImages = (images: ImagePicker.ImageInfo[]) => {
        setLocalImages(images);
        localImagesRef.current = images;
    }


    const uploadedImagesCount = useRef(0);
    const uploadImages = async (holding: Holding) => {
        uploadedImagesCount.current = 0;
        for (let i = 0; i < localImagesRef.current.length; i++) {
            uploadedImagesCount.current++;
            let imagesUploaded = await ImageController.uploadImage(localImagesRef.current[i], ImageController.IMAGE_TYPE.HOLDING, holding._id);
            setLoadingText(`Uploading ${uploadedImagesCount.current}/${localImagesRef.current.length} Images`);
        }
        // let imagesUploaded = await ImageController.uploadImages(localImages, ImageController.IMAGE_TYPE.HOLDING, holding._id);
    }

    const validateHolding = () => {
        let isValid = false;
        if (!holdingRef.current.client_id) {
            showNotification("Client Required", "Please select a client and reference-id for this holding");
            return false;
        }
        if (!holdingRef.current.details.customer_details) {
            showNotification("Customer Details Required", "Please enter customer details");
            return false;
        }
        if (!holdingRef.current.details.vehicle_details) {
            showNotification("Vehicle Details Required", "Please enter vehicle details");
            return false;
        }
        if (!holdingRef.current.details.address) {
            showNotification("Address Required", "Please enter address details");
            return false;
        }
        if (!holdingRef.current.details.location) {
            showNotification("Location Required", "Please enter location details");
            return false;
        }
        return true
    }



    const createHolding = useCallback(async (case_id = null as string | null) => {
        if (!validateHolding()) return;
        setDisableCreateButton(true);
        setRenderSpinner(true);
        const company = StorageController.getCurrentCompany()
        let holdingObj = new Holding({
            ...holdingRef.current,
            company_id: company._id,
            status: 'held',
            fees: 0,
            holding_time: new Date().getTime(),
            case_id: case_id
        });
        let holdingCreated = await HoldingController.create(holdingObj);
        if (holdingCreated && localImagesRef.current.length > 0) {
            const images = await uploadImages(holdingCreated);
        }
        if (holdingCreated) {
            setRenderSpinner(false);
            setDisableCreateButton(false)
            showNotification("Holding created successfully", "success");
            onCreate(holdingCreated);
            return
        }
        setRenderSpinner(false);
        setDisableCreateButton(false)
        showNotification("Failed to create holding", "Something went wrong");
    }, [case_id])

    const onSetAddress = (address: JobAddress) => {
        onSetHolding({ ...holdingRef.current, details: { ...holdingRef.current.details, address: address } });
    }

    const onSetLocation = (location: JobLocation) => {
        onSetHolding({ ...holdingRef.current, details: { ...holdingRef.current.details, location: location } });
    }

    const onAddNote = (jobNote: JobNote) => {
        holdingRef.current.details.addNote(jobNote);
        onSetHolding(holdingRef.current);
    }

    const onDeleteNote = (jobNote: JobNote) => {
        holdingRef.current.details.deleteNote(jobNote);
        onSetHolding(holdingRef.current);
    }

    const onUpdateNote = (jobNote: JobNote) => {
        holdingRef.current.details.updateNote(jobNote);
        onSetHolding(holdingRef.current);
    }



    return (
        // <Modal
        //     visible={true}
        //     backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        //     onBackdropPress={() => onClose()}
        // >
        <View style={{
            // width: windowWidth * 0.9,
            // height: windowHeight * 0.9,
            flex: 1
        }}>
            <Layout style={{ flex: 1, padding: 20 }}>
                <Button
                    onPress={() => onClose()}
                    status='danger'
                    style={{ position: 'absolute', top: 0, right: 10, zIndex: 100 }}
                >
                    <Icon name='close-outline' fill='white' width={20} height={20} />
                </Button>
                <RenderSpinnerOverlay renderSpinner={renderSpinner} loadingText={loadingText} />
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category="h6">Create Holding</Text>
                    <View style={{ flex: 1 }}></View>
                    {case_id &&
                        <Text>
                            Case ID: {case_id}
                        </Text>
                    }
                    <View style={{ flex: 1 }}></View>
                </View>
                <ScrollView style={styles.scrollContainer}>

                    <View style={{ flexDirection: 'row', flexWrap: 'wrap', flex: 1 }}>
                        <View style={styles.imageAreaStyle}>
                            <ImageDetailsCard
                                propStyle={{ flex: 1, marginBottom: 20 }}
                                images={images}
                                onSetLocalImages={onSetLocalImages}
                                onSetImages={() => { }}
                            />
                        </View>
                        <View style={styles.mainAreaStyle}>
                            <ScrollView style={{}}>
                                <View style={{ flexDirection: 'row', gap: 5, flexWrap: 'wrap' }}>
                                    <CustomerDetailsCard
                                        style={[styles.groupContainer, { flexBasis: "33%" }]}
                                        customerDetails={holding.details.customer_details}
                                        onChangeCustomerDetails={onSetCustomerDetails}
                                        allowEdit={true}
                                    />
                                    <VehicleDetailsCard
                                        style={[styles.groupContainer, { flexBasis: "33%" }]}
                                        vehicleDetails={holding.details.vehicle_details}
                                        onChangeVehicleDetails={onSetVehicleDetails}
                                        allowEdit={true}
                                    />
                                    <ClientDetailsCard
                                        job={null}
                                        clientReferenceId={holding.details.client_reference_id}
                                        fn_onSetClient={(client: Client) => {
                                            onSetClient(client)
                                        }}
                                        fn_onSetClientRate={(rate: JobClientRate) => {
                                            onSetClientRate(rate)
                                        }}
                                        fn_onSetClientRefrerence={(clientReferenceId: string) => {
                                            onSetHolding({ ...holding, details: { ...holding.details, client_reference_id: clientReferenceId } })
                                        }}
                                        style={[styles.groupContainer, { flexBasis: "33%" }]}
                                        isNewJob={true}
                                        jobCreateSource={'vendor'}
                                        allowEdit={true}
                                        onInputFocus={() => { }}
                                        jobCreatedBy={'vendor'}
                                        setRefs={() => { }}
                                    />
                                </View>
                                <Divider />
                                <View style={{ flexDirection: 'row', gap: 5, flexWrap: 'wrap' }}>
                                    <LocationDetailsCard
                                        style={[styles.groupContainerLarge, { flex: 2 }]}
                                        address={holding.details.address}
                                        location={holding.details.location}
                                        onSetAddress={onSetAddress}
                                        onSetLocation={onSetLocation}
                                        allowEdit={true}
                                        searchDisplayValue={''}
                                        cardTitle='Holding Location'
                                    />
                                </View>
                                <View style={{ flexDirection: 'row' }}>
                                    <JobNotesList
                                        notes={holding.details.notes}
                                        onAddNote={onAddNote}
                                        onDeleteNote={onDeleteNote}
                                        onUpdateNote={onUpdateNote}
                                    />
                                </View>
                                <View style={{ flexDirection: 'row' }}>
                                    <InventoryDetailsCard
                                        style={styles.groupContainer}
                                        jobSource={'vendor'}
                                        onChangeSelectedItems={(items) => { onSetHolding({ ...holding, line_items: items } as Holding); }}
                                        selectedItems={holding.line_items}
                                        vendorProfile={null} />
                                </View>
                            </ScrollView>
                        </View>
                        <View style={styles.serviceAreaStyle}>
                            <ScrollView style={{}}>
                                <ServiceDetailsCard
                                    style={[styles.groupContainerLarge]}
                                    allowEdit={true}
                                    selectedServices={holding.details.selected_services}
                                    updateJobServices={onSetServices}
                                    canSetTimes={true}
                                />
                            </ScrollView>
                        </View>
                    </View>
                </ScrollView>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Card style={{ flex: 1, margin: 5 }}>
                        <Button status="success" onPress={() => createHolding(case_id)} style={{ flex: 1, margin: 5 }}>Create</Button>
                    </Card>
                </View>
            </Layout>
        </View>
        // </Modal>
    )
}


const RenderSpinnerOverlay = ({ renderSpinner, loadingText }: any) => {
    if (!renderSpinner) return <></>
    return (
        <Layout style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1000, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <Spinner size='giant' />
            <Text>{loadingText}</Text>
        </Layout>
    )
}




const styles = StyleSheet.create({
    groupContainer: {
        margin: 0,
        flex: 1,
        minWidth: 250,
    },
    groupContainerLarge: {
        margin: 0,
        minWidth: 250,
    },
    imageAreaStyle: {
        flexBasis: "20%",
        minWidth: 250,
        // height: '100%',
        flexGrow: 1,
        flexShrink: 1,
    },
    mainAreaStyle: {
        flexBasis: "60%",
        minWidth: 450,
        // height: '100%',
        flexGrow: 1,
        flexShrink: 1,
    },
    serviceAreaStyle: {
        flexBasis: "20%",
        minWidth: 250,
        // height: '100%',
        flexGrow: 1,
        flexShrink: 1,
    },
    scrollContainer: {
        // flex: 1,
        // overflow: 'hidden',
        height: '100%',
        width: '100%',
    },
});