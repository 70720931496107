import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import {
    Image,
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Animated,
    ScrollView,
    Dimensions,
    useWindowDimensions,
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Input,
    Modal,
    Layout,
    //@ts-ignore
    Spinner,
    Text,
    List,
    ListItem,
    Divider,
    Card
} from "@ui-kitten/components";
import { SafeAreaView } from 'react-native-safe-area-context';
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import { Case } from '../../models/Case.model';
import { Client } from '../../models/Client.model';
import { Job, JobCustomerDetails, JobNote } from '../../models/Job.model';
import { Service } from '../../models/Service';
import { Company } from '../../models/Company.model';
import { Member } from '../../models/Member.model';
import * as CaseController from '../../functions/case.controller';
import * as ImageController from '../../functions/image.controller';
import * as StorageController from '../../functions/storageController';



import { ServiceDetailsCard } from '../job/details/serviceDetailsCard.component';
import { JobNotesList } from '../job/details/jobNotes.component';
import { ImageDetailsCard } from '../job/details/imageDetailsCard.component';
import { CustomerDetailsCard } from '../job/details/customerDetailsCard.component';
import { useNotification } from '../../context/notifications.context';
import * as ImagePicker from 'expo-image-picker';

interface CreateCaseContainerProps {
    onCreate: (caseObj: Case) => void;
    onClose: () => void;
}

export const CreateCaseContainer = ({
    onCreate,
    onClose,
}: CreateCaseContainerProps) => {
    const [caseObj, setCaseObj] = useState(new Case({}));
    const [client, setClient] = useState(new Client({}));
    const [company, setCompany] = useState(new Company());
    const [localImages, setLocalImages] = useState<Image[]>([]);
    const [images, setImages] = useState<Image[]>([]);
    const localImagesRef = useRef(localImages);
    const caseRef = useRef(caseObj);
    const notification = useNotification();
    const [disableCreateButton, setDisableCreateButton] = useState(false);
    const [renderSpinner, setRenderSpinner] = useState(false);
    const [loadingText, setLoadingText] = useState("Creating Case...");

    useEffect(() => {
        load();
    }, [])

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// INIT
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const load = async () => {
        try {
            const company = StorageController.getCurrentCompany();
            setCompany(company);
            const caseObj = new Case({
                company_id: company._id
            });
            onSetCase(caseObj);
        } catch (error) {
            console.error(error);
        }
    }


    const onSetCase = (caseObj: Case) => {
        caseRef.current = caseObj;
        setCaseObj(caseObj);
    }

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// NOTES
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const onAddNote = (jobNote: JobNote) => {
        caseRef.current.details.addNote(jobNote);
        onSetCase(caseRef.current);
    }
    const onDeleteNote = (jobNote: JobNote) => {
        caseRef.current.details.deleteNote(jobNote);
        onSetCase(caseRef.current);
    }
    const onUpdateNote = (jobNote: JobNote) => {
        caseRef.current.details.updateNote(jobNote);
        onSetCase(caseRef.current);
    }

    const onSetServices = (services: Service[]) => {
        caseRef.current.details.selected_services = services;
        onSetCase(caseRef.current);
    }

    const onSetCustomerDetails = (customerDetails: any) => {
        caseRef.current.details.customer_details = customerDetails;
        onSetCase(caseRef.current);
    }

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// IMAGES
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const onSetLocalImages = (images: Image[]) => {
        setLocalImages(images);
        localImagesRef.current = images;
    }

    const uploadedImagesCount = useRef(0);

    const uploadImages = async (caseObj: Case) => {
        try {
            const uploadedImages = await ImageController.uploadImages(localImagesRef.current, ImageController.IMAGE_TYPE.CASE, caseObj._id);
            uploadedImagesCount.current = uploadedImages.length;
            return uploadedImages;
        } catch (error) {
            console.error(error);
        }
    }

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// CREATE CASE
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const createCase = async () => {
        try {
            setDisableCreateButton(true);
            setRenderSpinner(true);
            const company = StorageController.getCurrentCompany();
            console.log("🚀============== ~ file: createCaseContainer.component.tsx:174 ~ createCase ~ caseRef.current🚀==============", caseRef.current)
            console.log("🚀============== ~ Case Details================", caseRef.current.details);
            console.log("🚀============== ~ Job IDs================", caseRef.current.job_ids);
            console.log("🚀============== ~ Holding IDs================", caseRef.current.holding_ids);
            console.log("🚀============== ~ Invoice IDs================", caseRef.current.invoice_ids);
            const newCase = await CaseController.createCase(caseRef.current);
            console.log("🚀============== ~ file: createCaseContainer.component.tsx:179 ~ createCase ~ newCase🚀==============", newCase)
            if (newCase) {
                if (localImagesRef.current.length > 0) {
                    setLoadingText("Uploading Images...");
                    const uploadedImages = await uploadImages(newCase);
                }
                onCreate(newCase);
            }
            setRenderSpinner(false);
            setDisableCreateButton(false);
        } catch (error) {
            console.error(error);
            setRenderSpinner(false);
            setDisableCreateButton(false);
        }
    }



    return (
        <Layout style={{ flex: 1 }}>
            <RenderSpinnerOverlay renderSpinner={renderSpinner} loadingText={loadingText} />
            <Button
                onPress={() => onClose()}
                status='danger'
                style={{ position: 'absolute', top: 0, right: 10, zIndex: 100 }}
            >
                <Icon name='close-outline' fill='white' width={20} height={20} />
            </Button>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text category="h6">Create Case</Text>
                <View style={{ flex: 1 }}></View>
                <View style={{ flex: 1 }}></View>
            </View>
            <ScrollView style={styles.scrollContainer}>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', flex: 1 }}>
                    <View style={styles.imageAreaStyle}>
                        <ImageDetailsCard
                            propStyle={{ flex: 1, marginBottom: 20 }}
                            images={images}
                            onSetLocalImages={onSetLocalImages}
                            onSetImages={() => { }}
                        />
                    </View>
                    <View style={styles.mainAreaStyle}>
                        <ScrollView style={{}}>
                            <View style={{ flexDirection: 'row', gap: 5, flexWrap: 'wrap' }}>
                                <CustomerDetailsCard
                                    style={[styles.groupContainer, { flexBasis: "33%" }]}
                                    customerDetails={caseObj.details.customer_details || new JobCustomerDetails({})}
                                    onChangeCustomerDetails={onSetCustomerDetails}
                                    allowEdit={true}
                                />
                            </View>
                            <Divider />
                            <View style={{ flexDirection: 'row' }}>
                                <JobNotesList
                                    notes={caseObj?.details?.notes}
                                    onAddNote={onAddNote}
                                    onDeleteNote={onDeleteNote}
                                    onUpdateNote={onUpdateNote}
                                />
                            </View>
                        </ScrollView>
                    </View>
                    <View style={styles.serviceAreaStyle}>
                        <ScrollView style={{}}>
                            <ServiceDetailsCard
                                style={[styles.groupContainerLarge]}
                                allowEdit={true}
                                selectedServices={caseObj.details.selected_services}
                                updateJobServices={onSetServices}
                                canSetTimes={true}
                            />
                        </ScrollView>
                    </View>
                </View>
            </ScrollView>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Card style={{ flex: 1, margin: 5 }}>
                    <Button disabled={disableCreateButton} status="success" onPress={() => createCase()} style={{ flex: 1, margin: 5 }}>Create</Button>
                </Card>
            </View>
        </Layout>
    )

}

const RenderSpinnerOverlay = ({ renderSpinner, loadingText }: any) => {
    if (!renderSpinner) return <></>
    return (
        <Layout style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1000, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <Spinner size='giant' />
            <Text>{loadingText}</Text>
        </Layout>
    )
}

const styles = StyleSheet.create({
    groupContainer: {
        margin: 0,
        flex: 1,
        minWidth: 250,
    },
    groupContainerLarge: {
        margin: 0,
        minWidth: 250,
    },
    imageAreaStyle: {
        flexBasis: "20%",
        minWidth: 250,
        // height: '100%',
        flexGrow: 1,
        flexShrink: 1,
    },
    mainAreaStyle: {
        flexBasis: "60%",
        minWidth: 450,
        // height: '100%',
        flexGrow: 1,
        flexShrink: 1,
    },
    serviceAreaStyle: {
        flexBasis: "20%",
        minWidth: 250,
        // height: '100%',
        flexGrow: 1,
        flexShrink: 1,
    },
    scrollContainer: {
        // flex: 1,
        // overflow: 'hidden',
        height: '100%',
        width: '100%',
    },
});