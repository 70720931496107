import { JobAddress, JobCustomerDetails, JobLocation, JobVehicleDetails, LINE_ITEM_BILLED_TO } from "./Job.model";

export class Order {
  _id?: string;
  company_id: string | null = null;
  client_company_id: string | null = null;
  vendor_company_id: string | null = null;
  client_profile_id: string | null = null;
  vendor_profile_id: string | null = null;
  items: OrderItem[] = [];
  total_amount: number = 0;
  payment_details: any;
  related_job_id: string | null = null;
  related_job_friendly_id: string | null = null;
  status: OrderStatus = OrderStatus.DRAFT;
  details: OrderDetails = {
    dispatch_comments: "",
    customer_details: new JobCustomerDetails({}),
    vehicle_details: new JobVehicleDetails({}),
    address: new JobAddress({}),
    location: new JobLocation({}),
    delivery_fee: 0,
    scheduled_date: null,
    client_company_name: "",
    vendor_company_name: "",
  };
  friendly_id: string = "";
  createdAt: Date = new Date();
  updatedAt: Date = new Date();

  constructor(data: Partial<Order>) {
    Object.assign(this, data);
    this.items = data.items ? data.items.map((item: OrderItem) => new OrderItem(item)) : [];
  }

  getTotalItemsAmount() {
    let total = 0;
    this.items.forEach(item => {
      total += item.price * item.quantity;
    })
    return total;
  }


  getTotalAmountWithGST() {
    let total = 0
    this.items.forEach(item => {
      let itemTotal = item.price * item.quantity;
      if (item.is_gst_inclusive) {
        total += itemTotal;
      } else {
        total += itemTotal * 1.1;
      }
    })
    return total;
  }

  getTotalAmountWithoutGST() {
    let total = 0
    this.items.forEach(item => {
      let itemTotal = item.price * item.quantity;
      if (item.is_gst_inclusive) {
        total += itemTotal / 1.1;
      } else {
        total += itemTotal;
      }
    })
    return total;
  }

  getGSTOnlyAmount() {
    let gstAmount = 0;
    this.items.forEach(item => {
      gstAmount += item.getGSTOnlyAmount();
    })
    return gstAmount;
  }

}

export interface OrderDetails {
  dispatch_comments: string;
  customer_details: JobCustomerDetails;
  vehicle_details: JobVehicleDetails;
  address: JobAddress;
  location: JobLocation;
  delivery_fee: number;
  scheduled_date: Date | null;
  client_company_name: string;
  vendor_company_name: string;
}

export class OrderItem {
  inventory_item_id: string | null = null;
  quantity: number = 0;
  price: number = 0;
  is_gst_inclusive: boolean = true;
  billed_to: LINE_ITEM_BILLED_TO = LINE_ITEM_BILLED_TO.CUSTOMER_COST;
  name: string = '';
  description: string = '';

  constructor(data: Partial<OrderItem>) {
    Object.assign(this, data);
  }

  getTotalCost() {
    return this.price * this.quantity;
  }

  getTotalCostWithGST() {
    if (this.is_gst_inclusive) {
      return this.getTotalCost();
    } else {
      return this.getTotalCost() * 1.1;
    }
  }

  getGSTOnlyAmount() {
    let gstAmount = 0;
    const totalCost = (this.price * this.quantity);
    if (this.is_gst_inclusive) {
      gstAmount = totalCost / 1.1;
      gstAmount = totalCost - gstAmount;
    } else {
      gstAmount = totalCost * 0.1;
    }

    return gstAmount;
  }
}


export enum OrderStatus {
  DRAFT = 'draft',
  REQUEST = 'request',
  AWAITING_PAYMENT = 'awaiting_payment',
  PROCESSING = 'processing',
  READY = 'ready',
  SHIPPED = 'shipped',
  DELIVERED = 'delivered',
  PENDING = 'pending',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
}

export interface OrderSearchParams {
  search_value?: string; // search by friendly_id or other fields
  client_company_id?: string;
  vendor_company_id?: string;
  client_profile_id?: string;
  vendor_profile_id?: string;
  item_sale_id?: string;
  status?: OrderStatus | OrderStatus[];
  min_total_price?: number;
  max_total_price?: number;
  start_date?: Date;
  end_date?: Date;
  limit?: number;
  offset?: number;
}

