import React, { useMemo, useState } from 'react';
import { View, StyleSheet, FlatList } from 'react-native';
import { Input, Button, Layout, Text } from '@ui-kitten/components';
import { TouchableWithoutFeedback } from 'react-native';
import { RenderInventoryItem } from './RenderInventoryItem.component';
import { Client } from '../../../../models/Client.model';
import { ExtendedInventoryItem, ExtendedInventoryCategory } from '../../../../models/Inventory.model';
import { LineItem } from '../../../../models/LineItem.model';
import DMModal from '../../../common/Modal';

interface InventoryListModalProps {
    visible: boolean;
    onClose: () => void;
    searchQuery: string;
    setSearchQuery: (text: string) => void;
    filteredInventoryList: ExtendedInventoryItem[];
    selectedClient: Client;
    theme: any;
    onSetSelectedItems: (items: LineItem[]) => void;
    selectedItems: LineItem[];
    companyInventoryList: ExtendedInventoryCategory[];
    getCategoryNameFromId: (id: string) => string;
    getPricingTier: (item: ExtendedInventoryItem) => any;
    isSmallScreen?: boolean;
    itemStockLevels: { [key: string]: number };
}

export const InventoryListModal = ({
    visible,
    onClose,
    searchQuery,
    setSearchQuery,
    filteredInventoryList,
    selectedClient,
    theme,
    onSetSelectedItems,
    selectedItems,
    companyInventoryList,
    getCategoryNameFromId,
    getPricingTier,
    isSmallScreen = false,
    itemStockLevels,
}: Omit<InventoryListModalProps, 'sortCriterion' | 'setSortCriterion'>) => {
    const [sortCriterion, setSortCriterion] = useState<'name' | 'stock' | 'price'>('stock');

    const sortedInventoryList = useMemo(() => {
        const list = [...filteredInventoryList];
        switch (sortCriterion) {
            case 'name':
                return list.sort((a, b) => a.name.localeCompare(b.name));
            case 'stock':
                return list.sort((a, b) => {
                    const stockA = itemStockLevels[a._id as string] || 0;
                    const stockB = itemStockLevels[b._id as string] || 0;
                    return stockB - stockA;
                });
            case 'price':
                return list.sort((a, b) => a.default_price - b.default_price);
            default:
                return list;
        }
    }, [filteredInventoryList, itemStockLevels, sortCriterion]);

    const renderInventoryList = () => (
        <View style={styles.modalContent}>
            <View style={styles.sortButtonsContainer}>
                <Input
                    size='small'
                    label="Search items..."
                    placeholder="Search items..."
                    value={searchQuery}
                    onChangeText={text => setSearchQuery(text)}
                    style={{ flex: 1 }}
                />
                <View style={{ flex: 1, flexDirection: 'row', gap: 8, height: 30, alignSelf: 'flex-end' }}>
                    <Button
                        style={{ flex: 1 }}
                        size='tiny'
                        onPress={() => setSortCriterion('stock')}
                        appearance={sortCriterion === 'stock' ? 'filled' : 'outline'}
                        status={sortCriterion === 'stock' ? 'primary' : 'basic'}
                    >
                        Order by Stock
                    </Button>
                    <Button
                        style={{ flex: 1 }}
                        size='tiny'
                        onPress={() => setSortCriterion('name')}
                        appearance={sortCriterion === 'name' ? 'filled' : 'outline'}
                        status={sortCriterion === 'name' ? 'primary' : 'basic'}
                    >
                        Order by Name
                    </Button>
                </View>
                {/* <Button
                    size='tiny'
                    onPress={() => setSortCriterion('price')}
                    appearance={sortCriterion === 'price' ? 'filled' : 'outline'}
                    status={sortCriterion === 'price' ? 'primary' : 'basic'}
                >
                    Order by Price
                </Button> */}
            </View>
            <FlatList
                data={sortedInventoryList}
                renderItem={({ item }) => <RenderInventoryItem
                    selectedClient={selectedClient}
                    item={item}
                    theme={theme}
                    onSetSelectedItems={onSetSelectedItems}
                    selectedItems={selectedItems}
                    companyInventoryList={companyInventoryList}
                    getCategoryNameFromId={getCategoryNameFromId}
                    pricingTier={getPricingTier(item)}
                    itemStockLevel={itemStockLevels[item._id as string] || 0}
                />}
                keyExtractor={(item) => item._id as string}
                style={{ maxHeight: 500 }}
            />
            {isSmallScreen && (
                <Button onPress={onClose} style={styles.closeButton}>Close</Button>
            )}
        </View>
    );

    if (isSmallScreen) {
        return (
            <DMModal
                visible={visible}
                onClose={onClose}
            >
                <TouchableWithoutFeedback onPress={onClose}>
                    <Layout style={styles.modalOverlay}>
                        <TouchableWithoutFeedback>
                            <Layout style={styles.modalContainer}>
                                {renderInventoryList()}
                            </Layout>
                        </TouchableWithoutFeedback>
                    </Layout>
                </TouchableWithoutFeedback>
            </DMModal>
        );
    }

    return visible ? renderInventoryList() : null;
};

const styles = StyleSheet.create({
    modalContent: {
        flex: 1,
    },
    modalOverlay: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContainer: {
        padding: 20,
        borderRadius: 10,
        width: '90%',
        maxHeight: '80%',
    },
    closeButton: {
        marginTop: 20,
    },
    itemInfo: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8,
    },
    stockLevel: {
        fontSize: 12,
        color: '#666',
        backgroundColor: '#f0f0f0',
        paddingHorizontal: 8,
        paddingVertical: 2,
        borderRadius: 4,
    },
    sortButtonsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        padding: 10,
        gap: 8,
    },
}); 