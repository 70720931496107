import React, { useState } from 'react';
import {
    StyleSheet,
    View,
} from "react-native";
import {
    Card,
    Text,
    Button,
    Toggle,
    Modal,
    Input,
    Select,
    SelectItem,
    IndexPath,
} from "@ui-kitten/components";
import { SurchargeDefinition, SURCHARGE_TYPE, DEFAULT_SURCHARGE_DEFINITIONS } from '../../models/Surcharge.model';
import { LINE_ITEM_BILLED_TO } from '../../models/Job.model';
import { SurchargeModal } from './surchargeModal.component';
import { CompanyJobSurchargeDefinitions } from '../../models/Company.model';
interface JobSurchargesProps {
    style?: any;
    jobSurcharges: CompanyJobSurchargeDefinitions;
    onUpdateJobSurcharges: (settings: CompanyJobSurchargeDefinitions) => void;
}

export const JobSurcharges = ({
    style,
    jobSurcharges,
    onUpdateJobSurcharges,
}: JobSurchargesProps) => {
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [editingSurcharge, setEditingSurcharge] = useState<SurchargeDefinition | null>(null);

    const handleToggleSurcharges = (enabled: boolean) => {
        if (enabled && (!jobSurcharges.surcharges || jobSurcharges.surcharges.length === 0)) {
            // If enabling and no surcharges exist, use defaults
            onUpdateJobSurcharges({
                enabled,
                surcharges: DEFAULT_SURCHARGE_DEFINITIONS
            });
        } else {
            onUpdateJobSurcharges({
                ...jobSurcharges,
                enabled
            });
        }
    };

    const handleResetToDefaults = () => {
        onUpdateJobSurcharges({
            enabled: true,
            surcharges: DEFAULT_SURCHARGE_DEFINITIONS
        });
    };

    const handleDeleteSurcharge = (id: string) => {
        onUpdateJobSurcharges({
            ...jobSurcharges,
            surcharges: jobSurcharges.surcharges.filter(s => s.id !== id)
        });
    };

    const handleToggleSurcharge = (id: string, enabled: boolean) => {
        onUpdateJobSurcharges({
            ...jobSurcharges,
            surcharges: jobSurcharges.surcharges.map(s =>
                s.id === id ? { ...s, enabled } : s
            )
        });
    };

    return (
        <Card
            disabled={true}
            status="basic"
            header={() => <Text category="h6">Job Surcharges</Text>}
            style={style}
        >
            <View style={styles.container}>
                <View style={styles.header}>
                    <Toggle
                        checked={jobSurcharges.enabled}
                        onChange={handleToggleSurcharges}
                    >
                        Enable Job Surcharges
                    </Toggle>
                    <Button
                        size="small"
                        onPress={handleResetToDefaults}
                        status="info"
                    >
                        Reset to Defaults
                    </Button>
                </View>

                {jobSurcharges.enabled && (
                    <>
                        <Button
                            style={styles.addButton}
                            onPress={() => setShowCreateModal(true)}
                        >
                            Add New Surcharge
                        </Button>

                        <View style={styles.surchargesList}>
                            {jobSurcharges.surcharges.map((surcharge) => (
                                <View key={surcharge.id} style={styles.surchargeItem}>
                                    <View style={styles.surchargeHeader}>
                                        <Text category="s1">{surcharge.name}</Text>
                                        <View style={styles.surchargeActions}>
                                            <Toggle
                                                checked={surcharge.enabled}
                                                onChange={(checked) => handleToggleSurcharge(surcharge.id, checked)}
                                            />
                                            <Button
                                                size="small"
                                                status="info"
                                                onPress={() => setEditingSurcharge(surcharge)}
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                size="small"
                                                status="danger"
                                                onPress={() => handleDeleteSurcharge(surcharge.id)}
                                            >
                                                Delete
                                            </Button>
                                        </View>
                                    </View>
                                    <Text category="c1" appearance="hint">
                                        {surcharge.description}
                                    </Text>
                                    <Text category="c1">
                                        Type: {surcharge.type} | Default Rate: ${surcharge.defaultRate} |
                                        Enable by Default on Job Creation: {surcharge.default_enabled ? 'Yes' : 'No'}
                                    </Text>
                                </View>
                            ))}
                        </View>

                        <SurchargeModal
                            visible={showCreateModal || !!editingSurcharge}
                            surcharge={editingSurcharge || undefined}
                            onClose={() => {
                                setShowCreateModal(false);
                                setEditingSurcharge(null);
                            }}
                            onSave={(newSurcharge: SurchargeDefinition) => {
                                if (editingSurcharge) {
                                    // Update existing surcharge
                                    onUpdateJobSurcharges({
                                        ...jobSurcharges,
                                        surcharges: jobSurcharges.surcharges.map(s =>
                                            s.id === editingSurcharge.id ? newSurcharge : s
                                        )
                                    });
                                } else {
                                    // Add new surcharge
                                    onUpdateJobSurcharges({
                                        ...jobSurcharges,
                                        surcharges: [...jobSurcharges.surcharges, newSurcharge]
                                    });
                                }
                                setShowCreateModal(false);
                                setEditingSurcharge(null);
                            }}
                        />
                    </>
                )}
            </View>
        </Card>
    );
};

const styles = StyleSheet.create({
    container: {
        gap: 16,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    addButton: {
        alignSelf: 'flex-start',
    },
    surchargesList: {
        gap: 8,
    },
    surchargeItem: {
        padding: 12,
        borderRadius: 4,
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        gap: 4,
    },
    surchargeHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    surchargeActions: {
        flexDirection: 'row',
        gap: 8,
        alignItems: 'center',
    },
}); 