import React from 'react';
// import { OverviewProvider } from '../../contexts/OverviewContext';
import OverviewRender from './overviewRender.component';

export const OverviewScreen = ({ navigation, route }: any) => {
    return (
        // <OverviewProvider>
        <OverviewRender navigation={navigation} route={route} />
        // </OverviewProvider>
    );
};