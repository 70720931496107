import React from 'react';
import { View } from 'react-native';
import { Card, Text, Input } from '@ui-kitten/components';
import { CompanyContactInfo } from '../../models/Company.model';

interface ContactInfoProps {
    contactInfo: CompanyContactInfo;
    onUpdateContactInfo: (updatedInfo: CompanyContactInfo) => void;
    style?: any;
}

export const ContactInfoComponent: React.FC<ContactInfoProps> = ({
    contactInfo,
    onUpdateContactInfo,
    style = {}
}) => {
    const updateContactInfo = (key: keyof CompanyContactInfo, value: string) => {
        const updatedInfo = new CompanyContactInfo({
            ...contactInfo,
            [key]: value
        });
        onUpdateContactInfo(updatedInfo);
    };

    return (
        <Card style={[style, { flex: 1 }]} disabled header={() => <Text category='h6'>Contact Info</Text>} status="basic">
            <View style={{ flexDirection: 'column', gap: 10 }}>
                {/* <Input
                    label="Company Name"
                    placeholder="Enter company name"
                    value={contactInfo.name}
                    onChangeText={(nextValue) => updateContactInfo('name', nextValue)}
                /> */}
                <Input
                    label="Company Phone"
                    placeholder="Enter company phone"
                    value={contactInfo.phone}
                    onChangeText={(nextValue) => updateContactInfo('phone', nextValue)}
                />
                <Input
                    label="Company Email"
                    placeholder="Enter company email"
                    value={contactInfo.email}
                    onChangeText={(nextValue) => updateContactInfo('email', nextValue)}
                />
                <View style={{ flexDirection: 'row', gap: 10 }}>
                    <View style={{ flex: 1 }}>

                        <Text category='h6' style={{ marginTop: 10 }}>Primary Contact</Text>
                        <Input
                            label="Name"
                            placeholder="Enter primary contact name"
                            value={contactInfo.primary_contact_name}
                            onChangeText={(nextValue) => updateContactInfo('primary_contact_name', nextValue)}
                        />
                        <Input
                            label="Phone"
                            placeholder="Enter primary contact phone"
                            value={contactInfo.primary_contact_phone}
                            onChangeText={(nextValue) => updateContactInfo('primary_contact_phone', nextValue)}
                        />
                        <Input
                            label="Email"
                            placeholder="Enter primary contact email"
                            value={contactInfo.primary_contact_email}
                            onChangeText={(nextValue) => updateContactInfo('primary_contact_email', nextValue)}
                        />
                    </View>
                    <View style={{ flex: 1 }}>
                        <Text category='h6' style={{ marginTop: 10 }}>Secondary Contact</Text>
                        <Input
                            label="Name"
                            placeholder="Enter secondary contact name"
                            value={contactInfo.secondary_contact_name}
                            onChangeText={(nextValue) => updateContactInfo('secondary_contact_name', nextValue)}
                        />
                        <Input
                            label="Phone"
                            placeholder="Enter secondary contact phone"
                            value={contactInfo.secondary_contact_phone}
                            onChangeText={(nextValue) => updateContactInfo('secondary_contact_phone', nextValue)}
                        />
                        <Input
                            label="Email"
                            placeholder="Enter secondary contact email"
                            value={contactInfo.secondary_contact_email}
                            onChangeText={(nextValue) => updateContactInfo('secondary_contact_email', nextValue)}
                        />
                    </View>
                </View>
            </View>
        </Card>
    );
};
