import React from 'react';
import { Button, Icon, useTheme } from '@ui-kitten/components';
import * as UpdateController from '../../../functions/update.controller';

interface RefreshButtonProps {
    onRefreshStart: () => void;
    onRefreshComplete: () => void;
    onRefreshError: () => void;
    initLoad: () => void;
}

export const RefreshButton = ({
    onRefreshStart,
    onRefreshComplete,
    onRefreshError,
    initLoad
}: RefreshButtonProps) => {
    const theme = useTheme();

    const handleRefresh = async () => {
        try {
            onRefreshStart();
            const result = await UpdateController.getAllCompanyDashboardData();
            if (result) {
                initLoad();
            }
            onRefreshComplete();
        } catch (error) {
            console.error("Error refreshing data:", error);
            onRefreshError();
        }
    };

    return (
        <Button
            size="small"
            style={{
                padding: 5,
                margin: 5,
                width: 40,
                height: 30,
                alignSelf: 'center',
                borderColor: theme['color-success-500'],
            }}
            status="warning"
            onPress={handleRefresh}
            accessoryLeft={<Icon name="refresh" />}
        />
    );
}; 