import { User } from '../models/User.model';
import { Company } from '../models/Company.model';
import { Member } from '../models/Member.model';
import { Job } from '../models/Job.model';
import { Client } from '../models/Client.model';
import { Service } from '../models/Service';
import { Case } from '../models/Case.model';
import { Holding } from '../models/Holding.model';
import { Route } from '../models/Route.model';
import { CompanySubscription } from '../models/CompanySubscription.model';
import { Order } from '../models/Order.model';

// New simplified store type that organizes items by company_id
export type CompanyStore<T> = {
    [companyId: string]: {
        [itemId: string]: T;
    };
};

export interface AppStateData {
    user: User;
    loggedIn: boolean;
    selectedMembership: Member | null;
    selectedCompany: Company | null;
    selectedJob: Job | null;
    selectedMemberToEdit: Member | null;
    memberList: Member[];
    activeCompanyId: string | null;
    companySubscription: CompanySubscription | null;
    systemInfo: any;

    // Company stores
    companies: { [key: string]: Company };
    allJobsStore: CompanyStore<Job>;
    allMembersStore: CompanyStore<Member>;
    allDriversStore: CompanyStore<Member>;
    allClientsStore: CompanyStore<Client>;
    allServicesStore: CompanyStore<Service>;
    allCasesStore: CompanyStore<Case>;
    allHoldingsStore: CompanyStore<Holding>;
    allRoutesStore: CompanyStore<Route>;
    allOrdersStore: CompanyStore<Order>;
}

export const initialAppState: AppStateData = {
    user: new User({}),
    loggedIn: false,
    selectedMembership: null,
    selectedCompany: null,
    selectedJob: null,
    selectedMemberToEdit: null,
    memberList: [],
    activeCompanyId: null,
    companySubscription: null,
    systemInfo: null,

    // Initialize empty company stores
    companies: {},
    allJobsStore: {},
    allMembersStore: {},
    allDriversStore: {},
    allClientsStore: {},
    allServicesStore: {},
    allCasesStore: {},
    allHoldingsStore: {},
    allRoutesStore: {},
    allOrdersStore: {},
};
