import React, { createContext, useContext, useCallback, useEffect, ReactNode, useState, useMemo, useRef } from 'react';
import { AppStateData, initialAppState, CompanyStore } from '../types/AppState.types';
import { addToStore, updateInStore, getCompanyItems, bulkAddToStore } from '../utils/stateUtils';
import { DashboardCompanyData, eventAppStateChange, eventDriverLocationChanged, eventJobDriverLocationChanged, STATE_ACTIONS } from '../functions/update.controller';
import * as StorageController from '../functions/storageController';
import { Company } from '../models/Company.model';
import { Job } from '../models/Job.model';
import { Member } from '../models/Member.model';
import { Client } from '../models/Client.model';
import { Service } from '../models/Service';
import { Case } from '../models/Case.model';
import { Holding } from '../models/Holding.model';
import { Route } from '../models/Route.model';
import Toast from 'react-native-toast-message';
import { CompanySubscription } from '../models/CompanySubscription.model';
import { User } from '../models/User.model';
import { useAppStateChange } from '../hooks/appStateChange.hook';
import { Order } from '../models/Order.model';


export interface IAction {
    type?: string;
    data?: any;
    job?: Job;
}

interface AppStateContextType extends AppStateData {
    // Core functionality
    // dispatchEventStateChange: (action: IAction) => void;

    getAppState: () => AppStateData | any;
    saveStateToDisk: () => Promise<void>;
    loadStateFromDisk: (overrideCurrentState?: boolean) => Promise<any>;
    getCurrentCompany: () => Company;
    updateJobInCompany: (job: Job) => void;
    getSelectedMembership: () => Member | null;
    getCurrentJobs: (company_id?: string) => Job[];
    getCurrentDrivers: (company_id?: string) => Member[];
    getCurrentClients: (company_id?: string) => Client[];
    getCurrentMembers: (company_id?: string) => Member[];
    getCurrentServices: (company_id?: string) => Service[];
    getCurrentCases: (company_id?: string) => Case[];
    getCurrentHoldings: (company_id?: string) => Holding[];
    getCurrentRoutes: (company_id?: string) => Route[];
    getCurrentOrders: (company_id?: string) => Order[];
    // Callback methods

    // Direct access to current company's data
    currentJobs: Job[];
    currentDrivers: Member[];
    currentClients: Client[];
    currentMembers: Member[];
    currentServices: Service[];
    currentCases: Case[];
    currentHoldings: Holding[];
    currentRoutes: Route[];
    currentOrders: Order[];
    selectedCompany: Company | null;
}

const AppStateContext = createContext<AppStateContextType | undefined>(undefined);

export const useAppStateContext = () => {
    const context = useContext(AppStateContext);
    if (!context) {
        throw new Error('useAppState must be used within an AppStateProvider');
    }

    return context;
};

export const AppStateProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [state, setState] = useState<AppStateData>(initialAppState);
    const stateRef = useRef(state)
    const [callbacks, setCallbacksState] = useState<Partial<AppStateContextType>>({});

    const onSetState = (newState: AppStateData) => {
        stateRef.current = newState
        setState(newState)
    }

    // Add setCallbacks method
    const setCallbacks = useCallback((newCallbacks: Partial<AppStateContextType>) => {
        setCallbacksState(prevCallbacks => {
            // Merge the new callbacks with existing ones, preserving existing callbacks
            const mergedCallbacks = {
                ...prevCallbacks,
                ...newCallbacks
            };

            // Log the callback names being set for debugging
            console.log("Setting callbacks:", Object.keys(newCallbacks));
            console.log("Total callbacks after merge:", Object.keys(mergedCallbacks));

            return mergedCallbacks;
        });
    }, []);

    // Use the existing hook with our callbacks


    // Memoize storage controller methods
    const getAppState = useCallback(() => {
        const appState = StorageController.getAppState()
        return appState
    }, [state]);

    const saveStateToDisk = useCallback(async () => {
        return StorageController.saveStateToDisk();
    }, []);

    const loadStateFromDisk = useCallback(async (overrideCurrentState = true) => {
        return StorageController.loadStateFromDisk(overrideCurrentState);
    }, []);

    const getCurrentCompany = useCallback(() => {
        return StorageController.getCurrentCompany();
    }, []);

    const getSelectedMembership = () => {
        return StorageController.getAppState().selectedMembership;
    };


    const onJobsUpdate = (action: IAction) => {
        // console.log("🚀============== ~ file: AppStateContext.tsx:419 ~ onJobsUpdate ~ overview action🚀==============", action)
        updateJobInCompany(action.data.job);
    }

    // updates from any job come here
    const onUpdatedJobs = (action: IAction) => {
        // console.log("🚀============== ~ file: AppStateContext.tsx:420 ~ onJobsUpdate ~ overview action🚀==============", action)
        updateJobInCompany(action.data.job);
    }


    // on updated jobs, update the job in the company
    const updateJobInCompany = useCallback((job: Job) => {
        // get all companies
        const companies = getAppState().companies;
        // find the companies that the job belongs to, it may belong to the company, the client, and the vendor
        const companiesWithJob = companies?.filter(c => c._id === job.company_id || c._id === job.client_company_id || c._id === job.vendor_company_id);
        // remove duplicate companies
        const uniqueCompanies = [...new Set(companiesWithJob)];

        // Create a new reference for allJobsStore
        const newJobs = { ...stateRef.current.allJobsStore };
        uniqueCompanies.forEach(company => {
            // Initialize company jobs object if it doesn't exist
            if (!newJobs[company._id]) {
                newJobs[company._id] = {};
            }

            // Create a new reference for the company's jobs
            newJobs[company._id] = {
                ...newJobs[company._id],
                [job._id]: job
            };
        });

        const newState = {
            ...stateRef.current,
            allJobsStore: newJobs  // Use the new reference
        }

        // console.log("🚀 ~ file: AppStateContext.tsx:110 ~ updateJobInCompany ~ newState:", newState)

        onSetState(newState);
    }, []);



    const onSetSelectedCompany = (action: IAction) => {
        console.log("🚀============== ~ file: AppStateContext.tsx:220 ~ callbacks.onSetSelectedCompany ~ action🚀==============", action)
        const company = action.data as Company
        const newState = {
            ...stateRef.current,
            activeCompanyId: company._id,
            selectedCompany: company
        }
        onSetState(newState);
    }

    const onUpdatedSelectedCompany = (action: IAction) => {
        console.log("🚀============== ~ file: AppStateContext.tsx:221 ~ callbacks.onUpdatedSelectedCompany ~ action🚀==============", action)
        const company = action.data as Company
        const newState = {
            ...stateRef.current,
            activeCompanyId: company._id,
            selectedCompany: company
        }
        onSetState(newState);
    }

    const onUpdatedAllData = (action: IAction) => {
        console.log("🚀============== ~ file: AppStateContext.tsx:241 ~ callbacks.onUpdatedAllData ~ action🚀==============", action)
        const data = action.data as DashboardCompanyData[]
        const activeCompanyId = stateRef.current.activeCompanyId || getCurrentCompany()._id
        if (data) {
            // for each company in the data, create a new company and update the state
            let currentJobs = stateRef.current.allJobsStore
            let currentDrivers = stateRef.current.allDriversStore
            let currentClients = stateRef.current.allClientsStore
            let currentMembers = stateRef.current.allMembersStore
            let currentServices = stateRef.current.allServicesStore
            let currentCases = stateRef.current.allCasesStore
            let currentHoldings = stateRef.current.allHoldingsStore
            let currentRoutes = stateRef.current.allRoutesStore
            data.forEach(companyData => {
                // set the jobs
                currentJobs[companyData._id] = companyData.jobs.reduce((acc, job) => {
                    acc[job._id] = new Job(job);
                    return acc;
                }, {} as Record<string, Job>)
                // set the drivers
                currentDrivers[companyData._id] = companyData.members.reduce((acc, member) => {
                    // where member.is_driver is true
                    if (member.is_driver) {
                        acc[member._id] = new Member(member);
                    }
                    return acc;
                }, {} as Record<string, Member>)
                // set the clients
                currentClients[companyData._id] = companyData.clients.reduce((acc, client) => {
                    acc[client._id || ''] = new Client(client);
                    return acc;
                }, {} as Record<string, Client>)
                // set the members
                currentMembers[companyData._id] = companyData.members.reduce((acc, member) => {
                    acc[member._id] = new Member(member);
                    return acc;
                }, {} as Record<string, Member>)
                // set the services
                currentServices[companyData._id] = companyData.services.reduce((acc, service) => {
                    acc[service._id || ''] = new Service(service);
                    return acc;
                }, {} as Record<string, Service>)
                // set the cases
                currentCases[companyData._id] = companyData.cases.reduce((acc, caseItem) => {
                    acc[caseItem._id] = new Case(caseItem);
                    return acc;
                }, {} as Record<string, Case>)
                // set the holdings
                currentHoldings[companyData._id] = companyData.holdings.reduce((acc, holding) => {
                    acc[holding._id || ''] = new Holding(holding);
                    return acc;
                }, {} as Record<string, Holding>)
                // set the routes
                currentRoutes[companyData._id] = companyData.routes.reduce((acc, route) => {
                    acc[route._id || ''] = new Route(route);
                    return acc;
                }, {} as Record<string, Route>)
            });
            const updates: Partial<AppStateData> = {
                allJobsStore: currentJobs,
                allDriversStore: currentDrivers,
                allClientsStore: currentClients,
                allMembersStore: currentMembers,
                allServicesStore: currentServices,
                allCasesStore: currentCases,
                allHoldingsStore: currentHoldings,
                allRoutesStore: currentRoutes,
                activeCompanyId: activeCompanyId
            }
            const newState = {
                ...stateRef.current,
                ...updates
            }
            onSetState(newState);
        }
    }

    const { dispatchEventStateChange } = useAppStateChange({
        onSetSelectedCompany: onSetSelectedCompany,
        onUpdatedSelectedCompany: onUpdatedSelectedCompany,
        onUpdatedAllData: onUpdatedAllData,
        onJobsUpdate: onJobsUpdate,
        onUpdatedJobs: onUpdatedJobs
    })


    // Get current company's data
    const currentCompanyData = useMemo(() => {

        return {
            currentJobs: stateRef.current.activeCompanyId ? stateRef.current.allJobsStore[stateRef.current.activeCompanyId] || {} : {},
            currentDrivers: stateRef.current.activeCompanyId ? stateRef.current.allDriversStore[stateRef.current.activeCompanyId] || {} : {},
            currentClients: stateRef.current.activeCompanyId ? stateRef.current.allClientsStore[stateRef.current.activeCompanyId] || {} : {},
            currentMembers: stateRef.current.activeCompanyId ? stateRef.current.allMembersStore[stateRef.current.activeCompanyId] || {} : {},
            currentServices: stateRef.current.activeCompanyId ? stateRef.current.allServicesStore[stateRef.current.activeCompanyId] || {} : {},
            currentCases: stateRef.current.activeCompanyId ? stateRef.current.allCasesStore[stateRef.current.activeCompanyId] || {} : {},
            currentHoldings: stateRef.current.activeCompanyId ? stateRef.current.allHoldingsStore[stateRef.current.activeCompanyId] || {} : {},
            currentRoutes: stateRef.current.activeCompanyId ? stateRef.current.allRoutesStore[stateRef.current.activeCompanyId] || {} : {},
            currentOrders: stateRef.current.activeCompanyId ? stateRef.current.allOrdersStore[stateRef.current.activeCompanyId] || {} : {}
        };
    }, [
        state,
    ]);

    // Get the current state of the jobs
    const getCurrentJobs = useCallback((company_id?: string) => {
        if (!stateRef.current.activeCompanyId || !stateRef.current.allJobsStore) {
            return [];
        }
        const jobs = stateRef.current.allJobsStore[company_id || stateRef.current.activeCompanyId] || {};
        const jobsArray = Object.values(jobs);
        return jobsArray;
    }, [state.activeCompanyId, state.allJobsStore]);

    // Get the current state of the drivers
    const getCurrentDrivers = useCallback((company_id?: string) => {
        if (!stateRef.current.activeCompanyId || !stateRef.current.allDriversStore) {
            return [];
        }
        const drivers = stateRef.current.allDriversStore[company_id || stateRef.current.activeCompanyId] || {};
        const driversArray = Object.values(drivers);
        return driversArray;
    }, [state.activeCompanyId, state.allDriversStore]);

    // Get the current state of the clients
    const getCurrentClients = useCallback((company_id?: string) => {
        if (!stateRef.current.activeCompanyId || !stateRef.current.allClientsStore) {
            return [];
        }
        const clients = stateRef.current.allClientsStore[company_id || stateRef.current.activeCompanyId] || {};
        const clientsArray = Object.values(clients);
        return clientsArray;
    }, [state.activeCompanyId, state.allClientsStore]);

    // Get the current state of the members
    const getCurrentMembers = useCallback((company_id?: string) => {
        if (!stateRef.current.activeCompanyId || !stateRef.current.allMembersStore) {
            return [];
        }
        const members = stateRef.current.allMembersStore[company_id || stateRef.current.activeCompanyId] || {};
        const membersArray = Object.values(members);
        return membersArray;
    }, [state.activeCompanyId, state.allMembersStore]);

    // Get the current state of the services
    const getCurrentServices = useCallback((company_id?: string) => {
        if (!stateRef.current.activeCompanyId || !stateRef.current.allServicesStore) {
            return [];
        }
        const services = stateRef.current.allServicesStore[company_id || stateRef.current.activeCompanyId] || {};
        const servicesArray = Object.values(services);
        return servicesArray;
    }, [state.activeCompanyId, state.allServicesStore]);

    // Get the current state of the cases
    const getCurrentCases = useCallback((company_id?: string) => {
        if (!stateRef.current.activeCompanyId || !stateRef.current.allCasesStore) {
            return [];
        }
        const cases = stateRef.current.allCasesStore[company_id || stateRef.current.activeCompanyId] || {};
        const casesArray = Object.values(cases);
        return casesArray;
    }, [state.activeCompanyId, state.allCasesStore]);

    // Get the current state of the holdings
    const getCurrentHoldings = useCallback((company_id?: string) => {
        if (!stateRef.current.activeCompanyId || !stateRef.current.allHoldingsStore) {
            return [];
        }
        const holdings = stateRef.current.allHoldingsStore[company_id || stateRef.current.activeCompanyId] || {};
        const holdingsArray = Object.values(holdings);
        return holdingsArray;
    }, [state.activeCompanyId, state.allHoldingsStore]);

    // Get the current state of the routes
    const getCurrentRoutes = useCallback((company_id?: string) => {
        if (!stateRef.current.activeCompanyId || !stateRef.current.allRoutesStore) {
            return [];
        }
        const routes = stateRef.current.allRoutesStore[company_id || stateRef.current.activeCompanyId] || {};
        const routesArray = Object.values(routes);
        return routesArray;
    }, [state.activeCompanyId, state.allRoutesStore]);

    // Get the current state of the orders
    const getCurrentOrders = useCallback((company_id?: string) => {
        if (!stateRef.current.activeCompanyId || !stateRef.current.allOrdersStore) {
            return [];
        }
        const orders = stateRef.current.allOrdersStore[company_id || stateRef.current.activeCompanyId] || {};
        const ordersArray = Object.values(orders);
        return ordersArray;
    }, [state.activeCompanyId, state.allOrdersStore]);



    // Memoize the currentJobs, used for state change detection
    const currentJobs = useMemo(() => {
        return getCurrentJobs()
    }, [state.activeCompanyId, state.allJobsStore]);

    const currentDrivers = useMemo(() => {
        return getCurrentDrivers()
    }, [state.activeCompanyId, state.allDriversStore]);

    const currentClients = useMemo(() => {
        return getCurrentClients()
    }, [state.activeCompanyId, state.allClientsStore]);

    const currentMembers = useMemo(() => {
        return getCurrentMembers()
    }, [state.activeCompanyId, state.allMembersStore]);

    const currentServices = useMemo(() => {
        return getCurrentServices()
    }, [state.activeCompanyId, state.allServicesStore]);

    const currentCases = useMemo(() => {
        return getCurrentCases()
    }, [state.activeCompanyId, state.allCasesStore]);

    const currentHoldings = useMemo(() => {
        return getCurrentHoldings()
    }, [state.activeCompanyId, state.allHoldingsStore]);

    const currentRoutes = useMemo(() => {
        return getCurrentRoutes()
    }, [state.activeCompanyId, state.allRoutesStore]);

    const currentOrders = useMemo(() => {
        return getCurrentOrders()
    }, [state.activeCompanyId, state.allOrdersStore]);

    const selectedCompany = useMemo(() => {
        return stateRef.current.selectedCompany as Company || getCurrentCompany() as Company;
    }, [state.selectedCompany]);

    // Memoize the context value to prevent unnecessary re-renders
    const value = useMemo(() => ({
        ...state,
        ...stateRef.current,
        ...currentCompanyData,
        ...callbacks,
        currentJobs,
        currentDrivers,
        currentClients,
        currentMembers,
        currentServices,
        currentCases,
        currentHoldings,
        currentRoutes,
        currentOrders,
        getSelectedMembership,
        getAppState,
        saveStateToDisk,
        loadStateFromDisk,
        getCurrentCompany,
        updateJobInCompany,
        getCurrentJobs,
        getCurrentDrivers,
        getCurrentClients,
        getCurrentMembers,
        getCurrentServices,
        getCurrentCases,
        getCurrentHoldings,
        getCurrentRoutes,
        getCurrentOrders,
        selectedCompany
    }), [state, currentCompanyData, callbacks, getAppState, saveStateToDisk,
        loadStateFromDisk, getCurrentCompany, updateJobInCompany, setCallbacks, currentJobs, currentDrivers, currentClients, currentMembers, currentServices, currentCases, currentHoldings, currentRoutes, currentOrders]);

    return (
        <AppStateContext.Provider value={value}>
            {children}
        </AppStateContext.Provider>
    );
};

export default AppStateProvider;
