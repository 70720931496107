import React, { useState, useRef, useCallback, useEffect, useReducer, useMemo } from 'react';
//@ts-ignore
import { StyleSheet, View } from 'react-native';
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Divider,
    Input,
    Select,
    SelectItem,
    useTheme,
    Layout,
    Text,
    List,
    ListItem,
    OverflowMenu,
    MenuItem,
    Card,
    Toggle,
    //@ts-ignore
    IndexPath,
} from "@ui-kitten/components";
import { SafeAreaView } from 'react-native-safe-area-context';
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import { mapping, light, dark } from "@eva-design/eva";
import * as StorageController from '../../../functions/storageController';
import * as GoogleController from '../../../functions/google.controller';
import * as ClientController from '../../../functions/client.controller';
import * as VendorProfileController from '../../../functions/vendorProfile.controller';
import * as UpdateController from '../../../functions/update.controller';
import * as CompanyController from '../../../functions/company.controller';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, TransitLayer, DirectionsService, DirectionsRenderer, TrafficLayer, MarkerClusterer, Polygon, Polyline } from '@react-google-maps/api';
import "react-datepicker/dist/react-datepicker.css";
import { Job, JobTowingDetails, TowingLocation, JobDirections, TowingLeg, JobLocation, Polyline as JobPolyLine, JobAddress, JOB_LOCATION_TYPE, POLYLINE_TYPE } from '../../../models/Job.model';
import { LineItem } from '../../../models/LineItem.model';
import { ScrollView } from 'react-native-gesture-handler';
import { TowingAddressDetailsCard } from './towingAddressDetailsCard.component';
import { CompanySavedAddress } from '../../../models/Company.model';
import * as OsrmController from '../../../functions/osrm.controller';


// })
const mapLibs = ["places", "visualization"]
const mapPanelWidth = 200
const mapHeight = 500

const mapContainerStyle = {
    width: "100%",
    height: `${mapHeight}px`
}

interface TowingLocationDetailsCardProps {
    style?: any
    allowEdit?: boolean
    onChangeTowingLocations?: (t: any) => void
    onAddCostToItems?: (c: any) => void
    defaultTowingLocationObj?: JobTowingDetails
    validateOnLoad?: boolean
    pickupAddressDisplayValue?: string | null
    dropoffAddressDisplayValue?: string | null
}

export const TowingLocationDetailsCard = ({
    style = {},
    allowEdit = true,
    onChangeTowingLocations = (t: any) => { }, // use this for parent component to get the towing locations
    onAddCostToItems = (c: any) => { }, // use this for parent component to add the cost to the items
    defaultTowingLocationObj = new JobTowingDetails({}), // JobTowingDetails
    validateOnLoad = false,
    pickupAddressDisplayValue = null as string | null,
    dropoffAddressDisplayValue = null as string | null,
}: TowingLocationDetailsCardProps) => {

    const [jobTowingDetails, setJobTowingDetails] = useState(defaultTowingLocationObj || new JobTowingDetails({}))
    const jobTowingDetailsRef = useRef(jobTowingDetails || new JobTowingDetails({}))

    //===============================
    //===============================
    //TOWING LOCATIONS
    //===============================
    const [pickupTowingLocation, setPickupTowingLocation] = useState(
        jobTowingDetails.pickup_towing_location ||
        new TowingLocation({
            address: jobTowingDetails.pickup_address,
            location: jobTowingDetails.pickup_location,
            address_type: jobTowingDetails.pickup_address_type,
            location_type: jobTowingDetails.pickup_location_type,
            holding_reason: jobTowingDetails.pickup_holding_reason,
        }) ||
        null
    );
    const [dropoffTowingLocation, setDropoffTowingLocation] = useState(
        jobTowingDetails.dropoff_towing_location ||
        new TowingLocation({
            address: jobTowingDetails.dropoff_address,
            location: jobTowingDetails.dropoff_location,
            address_type: jobTowingDetails.dropoff_address_type,
            location_type: jobTowingDetails.dropoff_location_type,
            holding_reason: jobTowingDetails.dropoff_holding_reason,
        }) ||
        null);
    const [original_dropoff_towing_location, setOriginalDropoffTowingLocation] = useState(jobTowingDetails.original_dropoff_towing_location || null);

    const [costPerKm, setCostPerKm] = useState(jobTowingDetails.cost_per_km || "")
    const [coveredDistance, setCoveredDistance] = useState(jobTowingDetails.covered_distance_kms || "")
    const [directionsPolyline, setDirectionsPolyline] = useState(jobTowingDetails.directions_polyline as JobPolyLine[] || null)
    const [directionsPolylines, setDirectionsPolylines] = useState([] as JobPolyLine[])

    const [distanceText, setDistanceText] = useState(jobTowingDetails.distance_kms ? `${jobTowingDetails.distance_kms?.toFixed(2)} kms` : "")
    const [durationText, setDurationText] = useState(jobTowingDetails.duration_seconds ? `${(jobTowingDetails.duration_seconds / 60).toFixed(2)} mins` : "")

    const [addCoveredCostToBillBack, setAddCoveredCostToBillBack] = useState(false)

    //===============================
    //===============================
    //REFS
    //===============================
    const distanceValueRef = useRef(0)
    const durationValueRef = useRef(0)
    const costPerKmRef = useRef(costPerKm)
    const coveredDistanceRef = useRef(coveredDistance)
    const totalCostRef = useRef(0)
    const directionsPolylineRef = useRef(directionsPolyline || null)





    //=============================== OLD STATES ===============================
    const companyLocation = StorageController.getCurrentCompany().settings?.location?.coords || { lat: 0, lng: 0, lon: 0 };
    //===============================
    //===============================
    //SELECT INDEX STATES
    //===============================
    // get company address/ location

    useEffect(() => {
        const companySettings = StorageController.getCurrentCompany().settings
        if (companySettings?.job_options?.towingOptions?.default_cost_per_km) {
            setCostPerKm(companySettings.job_options.towingOptions.default_cost_per_km.toString())
        }
        if (companySettings?.job_options?.towingOptions?.default_covered_distance_km) {
            setCoveredDistance(companySettings.job_options.towingOptions.default_covered_distance_km.toString())
        }
    }, [])


    //===============================
    //===============================
    //USE EFFECTS
    //===============================
    useEffect(() => {
        let _towingLocationObj = new JobTowingDetails(defaultTowingLocationObj)
        setJobTowingDetails(_towingLocationObj)
        if (_towingLocationObj.towing_legs && _towingLocationObj.towing_legs.length > 0) {
            const polylines = _towingLocationObj.towing_legs.map((towingLeg: TowingLeg) => {
                console.log("🚀============== ~ file: towingLocationDetailsCard.component.tsx:160 ~ polylines ~ towingLeg🚀==============", towingLeg)
                return towingLeg.directions.decodePolyline()
                // return OsrmController.convertOSRMPolylineToGooglePolylineObject(towingLeg.directions.polyline)
                // return GoogleController.decodePolyline(towingLeg.directions.polyline)
            }) || []
            console.log("🚀============== ~ file: towingLocationDetailsCard.component.tsx:167 ~ useEffect ~ polylines🚀==============", polylines)
            setDirectionsPolylines(polylines as any)
        }
        jobTowingDetailsRef.current = _towingLocationObj
        //@ts-ignore
    }, [defaultTowingLocationObj])

    const checkAndUpdateTowingLocations = (caller: any = null) => {
        try {
            const towingDetailsObj = new JobTowingDetails({
                ...jobTowingDetails,
                distance_kms: distanceValueRef.current,
                duration_seconds: durationValueRef.current,
                cost_per_km: costPerKmRef.current,
                covered_distance_kms: coveredDistanceRef.current,
                total_cost: totalCostRef.current
            })
            onChangeTowingLocations(towingDetailsObj)
        } catch (e) {
        }
    }

    useEffect(() => {
        // check if theres also no towing legs but there is a pickup and dropoff location
        const hasPickupAndDropoff = pickupTowingLocation && dropoffTowingLocation
        const hasTowingLegs = jobTowingDetails.towing_legs && jobTowingDetails.towing_legs.length > 0
        const towingLegDistance = jobTowingDetails.towing_legs[0]?.directions?.distance_value
        if (validateOnLoad) {
            validateTowingLegs()
        }
        else if (hasPickupAndDropoff && !hasTowingLegs) {
            validateTowingLegs()
        }
        else if (hasPickupAndDropoff && hasTowingLegs && towingLegDistance == 0) {
            validateTowingLegs()
        }
    }, [])


    //===============================
    //===============================
    //HANDLE STATE UPDATES
    //===============================

    const onSetTowingDetails = (towingDetailsObj: JobTowingDetails, caller: any = null) => {
        const towingDetails = new JobTowingDetails(towingDetailsObj)
        // rename first and last towing_location to pickup and dropoff
        if (towingDetails.towing_locations && towingDetails.towing_locations.length > 0) {
            towingDetails.towing_locations[0].name = "Pickup"
            towingDetails.towing_locations[towingDetails.towing_locations.length - 1].name = "Dropoff"
        }
        setJobTowingDetails(towingDetails)
        jobTowingDetailsRef.current = towingDetails
        setPickupTowingLocation(towingDetailsObj.pickup_towing_location)
        setDropoffTowingLocation(towingDetailsObj.dropoff_towing_location)
        setOriginalDropoffTowingLocation(towingDetailsObj.original_dropoff_towing_location)
        checkAndUpdateTowingLocations("onSetTowingDetails")
    }

    // const [towingLegsState, setTowingLegsState] = useState(jobTowingDetails.towing_legs || [])




    const mapRef = React.useRef(null);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDySIh3VZu-dtIFF9lzZC2RLJa_bLKtsvM",
        //@ts-ignore
        libraries: mapLibs  // Required for heatmap
    })
    const [center, setCenter] = useState(companyLocation);




    const onSetLegs = (legs: TowingLeg[]) => {
        const validLegs = getValidTowingLegs(legs)

        jobTowingDetails.towing_legs = validLegs
        jobTowingDetails.reIndexTowingLegs()
        if (validLegs && validLegs.length > 0) {
            const polylines = validLegs.map((towingLeg: TowingLeg) => {
                return GoogleController.decodePolyline(towingLeg.directions.polyline)
            })
            setDirectionsPolylines(polylines as any)
        }
        onSetTowingDetails(jobTowingDetails, "onSetLegs")
    }


    const findFirstNullLocationIndex = () => {
        let index = 0
        for (const location of jobTowingDetails.towing_locations) {
            if (!location.location.lat || !location.location.lng) {
                return index
            }
            index++
        }
        return -1
    }

    // Simplified comparison of coordinates
    const areCoordinatesEqual = (coord1: any, coord2: any) => {
        return coord1?.lat === coord2?.lat && coord1?.lng === coord2?.lng;
    }



    const updateOrCreateTowingLeg = async (fromIndex: number, toIndex: number) => {
        const fromLocation = jobTowingDetails.towing_locations[fromIndex]?.location;
        const toLocation = jobTowingDetails.towing_locations[toIndex]?.location;
        if (!fromLocation || !toLocation) return null
        if (toLocation.lat == 0 && toLocation.lng == 0) return null

        let existingLeg = null
        for (const leg of jobTowingDetails.towing_legs) {
            if (leg.origin_towing_location_index === fromIndex && leg.destination_towing_location_index === toIndex) {
                existingLeg = leg
                break
            }
        }
        if (!existingLeg ||
            existingLeg.origin?.lat !== fromLocation.lat || existingLeg.origin?.lng !== fromLocation?.lng ||
            existingLeg.destination?.lat !== toLocation.lat || existingLeg.destination?.lng !== toLocation?.lng
        ) {

            // Locations have changed, update or create leg
            // const directions = await GoogleController.getDirections(fromLocation, toLocation);
            const osrmDirections = await OsrmController.getJobDirections(fromLocation, toLocation);
            console.log("🚀============== ~ file: towingLocationDetailsCard.component.tsx:291 ~ updateOrCreateTowingLeg ~ osrmDirections🚀==============", osrmDirections)

            const newTowingLeg = new TowingLeg({
                index: jobTowingDetails.towing_legs?.length || 0,
                origin_towing_location_index: fromIndex ?? Number(0),
                destination_towing_location_index: toIndex,
                origin: fromLocation,
                destination: toLocation,
                directions: osrmDirections,
                cost_per_km: costPerKmRef.current,
                covered_distance_kms: coveredDistanceRef.current,
                total_cost: totalCostRef.current,
            })
            return newTowingLeg
        }
        return null
    }


    /**
     * Returns a list of legs with orphaned legs removed
     */
    const getValidTowingLegs = (legs: TowingLeg[]) => {
        if (!legs) return []
        const validTowingLegs = legs.filter((leg: TowingLeg) => {
            const originExists = jobTowingDetails.towing_locations.some(location =>
                location.index === leg.origin_towing_location_index
            );
            const destinationExists = jobTowingDetails.towing_locations.some(location =>
                location.index === leg.destination_towing_location_index
            );
            // validate origin and destination coords
            const origin = jobTowingDetails.towing_locations[leg.origin_towing_location_index]?.location
            const destination = jobTowingDetails.towing_locations[leg.destination_towing_location_index]?.location
            // if the leg matches the origin and destination, it is valid
            let coordsMatch = false
            if (origin && destination) {
                coordsMatch = areCoordinatesEqual(origin, leg.origin) && areCoordinatesEqual(destination, leg.destination);
            }
            return originExists && destinationExists && coordsMatch;
        });
        return validTowingLegs
    }

    /**
     * Validate towing legs, ensure that there is a towing leg between each location
     * if there is not, create a new towing leg
     * if there is a towing leg without a valid towing_location at either the origin or destination, remove it
     * @param {*} index 
     */
    const validateTowingLegs = async () => {
        try {
            // check if there is a towing leg between each location
            let shouldUpdate = false
            for (let i = 0; i < jobTowingDetails.towing_locations.length - 1; i++) {
                const newLeg = await updateOrCreateTowingLeg(i, i + 1);
                if (newLeg) {
                    shouldUpdate = true
                    jobTowingDetails.addTowingLegAtIndex(newLeg, i);
                }
                if (i == jobTowingDetails.towing_locations.length - 2) {
                    if (shouldUpdate) {
                        onSetLegs(jobTowingDetails.towing_legs);
                    }
                }
            }
        } catch (e) {
            console.log("Error in validateTowingLegs:", e);
        }
    }


    const onMapClick = async (event: any) => {
        try {
            const location = event.latLng.toJSON()
            const addressObj = await getAddressFromLatLng(event.latLng.toJSON().lat, event.latLng.toJSON().lng)
            const address = new JobAddress(addressObj?.address)
            // get first towing_location where location is null
            let index = findFirstNullLocationIndex()
            if (index == -1) {
                jobTowingDetails.addNewLocationAtIndex(jobTowingDetails.towing_locations.length)
                index = jobTowingDetails.towing_locations.length - 1
            }
            jobTowingDetails.towing_locations[index].location = location
            jobTowingDetails.towing_locations[index].address = address
            onSetTowingDetails(jobTowingDetails, "onMapClick")
            validateTowingLegs()
        } catch (e) {
        }
    }

    const onDragMarker = async (location: any, index: number) => {
        setSelectedLocationIndex(index)
        const locations = await GoogleController.getAddressFromLatLngAllResults(location.lat, location.lng)
        setLocationSearchResults(locations as any)
        setShowLocationSelectInfoWindow(true)
    }

    const [selectedLocationIndex, setSelectedLocationIndex] = useState(0)
    const [showLocationSelectInfoWindow, setShowLocationSelectInfoWindow] = useState(false)
    const [InfoWindowListSelectedIndex, setInfoWindowListSelectedIndex] = useState(0)
    const [locationSearchResults, setLocationSearchResults] = useState([])
    const locationsListRef = useRef(null)

    const RenderSelectPlaceResultsSideBar = ({ locations, style }: any) => {
        return (
            <View
                style={style}
            >
                <Card
                    status="info"
                    header={() =>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Icon name='pin-outline' width={24} height={24} fill="white" />
                            <Text style={{ alignSelf: 'center' }}>Select a place</Text>
                            <Button
                                size="tiny"
                                appearance="filled"
                                status="danger"
                                onPress={() => {
                                    setInfoWindowListSelectedIndex(0)
                                    setShowLocationSelectInfoWindow(false)
                                }}>
                                <Icon
                                    name='close-outline'
                                    width={24}
                                    height={24}
                                    fill="white"
                                />
                            </Button>
                        </View>
                    }
                    disabled={true}
                    style={{ flex: 1 }}
                >
                    <View style={{ margin: -15, height: style.height }}>
                        <List
                            data={locations}
                            renderItem={({ item, index }: any) => {
                                const description = `${item.geometry.location_type} - ${item.types.join(", ")}`
                                return (
                                    <ListItem
                                        style={{ backgroundColor: InfoWindowListSelectedIndex == index ? "#222" : "transparent" }}
                                        title={item.formatted_address}
                                        description={description}
                                        // accessoryLeft={() => <Icon name='pin-outline' width={24} height={24} fill="white" />}
                                        onPress={() => {
                                            setInfoWindowListSelectedIndex(index)
                                            const location = item.geometry.location
                                            let address = GoogleController.sortAddress(item.address_components) as JobAddress
                                            address.formatted_address = item.formatted_address
                                            address.description = description
                                            try {
                                                jobTowingDetails.towing_locations[selectedLocationIndex].location = location
                                                jobTowingDetails.towing_locations[selectedLocationIndex].address = address
                                            } catch (e) {
                                            }
                                            // setShowLocationSelectInfoWindow(false)
                                            onSetTowingDetails(jobTowingDetails, "RenderSelectPlaceResultsInfoWindow")
                                            validateTowingLegs()
                                        }}
                                    />
                                )
                            }}
                        />
                    </View>
                </Card>
            </View>
        )
    }



    /**
     * 
     * @param {*} lat 
     * @param {*} lng 
     * @param {*} type 
     * @returns addressObject formattedAddress
     */
    const getAddressFromLatLng = async (lat: any, lng: any, type: string = "none") => {
        const address = await GoogleController.getAddressFromLatLng(lat, lng)
        return address
    }


    // const [costResult, setCostResult] = useState(null)

    const [showCalculationError, setShowCalculationError] = useState(false)
    const [calculationErrorText, setCalculationErrorText] = useState("")
    const [totalCostResult, setTotalCostResult] = useState("")
    const firstLoadRef = useRef(true)
    const memo_uncoveredCostResult = useMemo(() => {
        // get directions
        try {
            let dist = jobTowingDetails.getTotalDistance() / 1000 // in metres
            let duration = jobTowingDetails.getTotalDuration() / 60 // in seconds
            distanceValueRef.current = dist
            durationValueRef.current = duration
            let costStr = ""
            let isError = false
            let errorText = ""
            // offset distance by covered distance
            const offsetDistance = dist - Number(coveredDistance)
            if (isNaN(offsetDistance)) {
                isError = true
                errorText += "Offset distance is not a number "
                // return
            }
            if (isNaN(Number(costPerKm))) {
                isError = true
                errorText += "Cost per km is not a number "
            }
            // calculate cost
            const cost = offsetDistance * Number(costPerKm)
            if (isError) {
                setShowCalculationError(true)
                setCalculationErrorText(errorText)
            } else {
                setShowCalculationError(false)
                setCalculationErrorText("")
            }

            if (isNaN(cost) || cost < 0) {
                costStr += "0.00"
            } else {
                costStr += cost.toFixed(2)

            }
            costPerKmRef.current = Number(costPerKm)
            coveredDistanceRef.current = Number(coveredDistance)
            totalCostRef.current = parseFloat(costStr)
            setTotalCostResult((costPerKmRef.current * dist).toFixed(2))
            setDistanceText(`${dist.toFixed(2)} kms`)
            setDurationText(`${(duration).toFixed(2)} mins`)
            // if (firstLoadRef.current) {
            //     firstLoadRef.current = false
            // } else {
            //     checkAndUpdateTowingLocations("memo_uncoveredCostResult")
            // }
            return costStr
        } catch (e) {
            console.log(e)
        }
    }, [coveredDistance, costPerKm, jobTowingDetails])


    const onAddTowingCostToItems = () => {
        const totalDistance = distanceValueRef.current < 0 ? 0 : distanceValueRef.current
        const coveredDistance = coveredDistanceRef.current < 0 ? 0 : coveredDistanceRef.current
        const costPerKm = costPerKmRef.current < 0 ? 0 : costPerKmRef.current

        // Cap the covered distance to not exceed the total distance
        let coveredDistanceValidated = Math.min(coveredDistance, totalDistance);

        // Calculate the uncovered distance
        let uncoveredDistance = totalDistance - coveredDistanceValidated;
        uncoveredDistance = Math.max(uncoveredDistance, 0); // Prevent negative values


        let coveredCost = coveredDistance * costPerKm
        let uncoveredCost = uncoveredDistance * costPerKm

        let isCappedString = ""
        if (coveredDistanceValidated < coveredDistance) {
            isCappedString = ` (Capped at ${coveredDistanceValidated.toFixed(2)}km)`
        }

        const billBackName = `Towing COVERED - ${coveredDistance.toFixed(2)}km of ${totalDistance.toFixed(2)}km @ $${costPerKm.toFixed(2)}/km ${isCappedString}`
        const customerCostName = `Towing Customer Cost - ${uncoveredDistance.toFixed(2)}km of ${totalDistance.toFixed(2)}km @ $${costPerKm.toFixed(2)}/km`
        const billAllBackLineItem = new LineItem({
            name: billBackName,
            description: billBackName,
            quantity: 1,
            cost: Number(coveredCost.toFixed(2)),
            billAllBack: true,
            billing: "Bill All Back",
        })

        const customerCostLineItem = new LineItem({
            name: customerCostName,
            description: customerCostName,
            quantity: 1,
            cost: Number(uncoveredCost.toFixed(2)),
            billAllBack: false,
            customerCost: true,
            billing: "Customer Cost",
        })
        let result = []
        if (addCoveredCostToBillBack && coveredCost > 0) {
            result.push(billAllBackLineItem)
        }
        if (uncoveredCost > 0) {
            result.push(customerCostLineItem)
        }
        onAddCostToItems(result)
        return result
    }

    // Create draggableHandle
    const DraggableHandle = () => (
        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 30 }}>
            <Icon name='menu-outline' width={24} height={24} fill="white" />
        </View>
    );


    const onAddressDetailsCardSetAddress = async (address: JobAddress, index: number) => {
        jobTowingDetails.towing_locations[index].address = address
        onSetTowingDetails(jobTowingDetails, "onAddressDetailsCardSetAddress")
        validateTowingLegs()
    }

    const onAddressDetailsCardSetLocation = async (location: any, index: number) => {
        jobTowingDetails.towing_locations[index].location = location
        onSetTowingDetails(jobTowingDetails, "onAddressDetailsCardSetLocation")
        validateTowingLegs()
    }

    const onAddressDetailsCardSetSavedAddress = (savedAddress: CompanySavedAddress, index: number) => {
        jobTowingDetails.towing_locations[index].address = savedAddress.address
        jobTowingDetails.towing_locations[index].location = new JobLocation(savedAddress.location)
        jobTowingDetails.towing_locations[index].location_type = savedAddress.type
        console.log("🚀============== ~ file: towingLocationDetailsCard.component.tsx:578 ~ onAddressDetailsCardSetSavedAddress ~ jobTowingDetails🚀==============", jobTowingDetails.towing_locations[index].location_type)
        onSetTowingDetails(jobTowingDetails, "onAddressDetailsCardSetSavedAddress")
        validateTowingLegs()
    }

    const onAddressDetailsCardSetLocationObject = async (locationObject: any, index: number) => {
        jobTowingDetails.updateLocationAtIndex(index, locationObject)
        onSetTowingDetails(jobTowingDetails, "onAddressDetailsCardSetLocationObject")
        validateTowingLegs()
    }

    const onAddressDetailsCardRemoveAndSaveAsOriginalDestination = async (index: number) => {
        jobTowingDetails.removeLocationAndSetAsOriginalDropoffAtIndex(index)
        onSetTowingDetails(jobTowingDetails, "onAddressDetailsCardRemoveAndSaveAsOriginalDestination")
        validateTowingLegs()
    }


    const addNewLocation = (index: number) => {
        const newTowingLocations = jobTowingDetails.addNewLocationAtIndex(index)
        onSetTowingDetails(jobTowingDetails, "addNewLocation")
        validateTowingLegs()
    }

    const removeTowingLocation = (index: number) => {
        const newTowingLocations = jobTowingDetails.removeLocationAtIndex(index)
        onSetTowingDetails(jobTowingDetails, "removeTowingLocation")
        validateTowingLegs()
    }

    const onRemoveAndSaveAsOriginalDestination = (index: number) => {
        const newTowingLocations = jobTowingDetails.removeLocationAndSetAsOriginalDropoffAtIndex(index)
        setOriginalDropoffTowingLocation(jobTowingDetails.original_dropoff_towing_location)
        onSetTowingDetails(jobTowingDetails, "onRemoveAndSaveAsOriginalDestination")
        validateTowingLegs()
    }

    const removeOriginalDropoffLocation = () => {
        setOriginalDropoffTowingLocation(null)
        const newTowingLocations = jobTowingDetails.removeOriginalDropoffLocation()
        onSetTowingDetails(jobTowingDetails, "removeOriginalDropoffLocation")
        validateTowingLegs()
    }


    const [cardsToCollapseIndexState, setCardsToCollapseIndexState] = useState({
        TOWING_0: true,
        TOWING_1: true,
    })
    const cardsToCollapseIndexRef = useRef(cardsToCollapseIndexState as any)


    const expandCardByName = (cardName: string) => {
        let newObj = {} as any
        for (const card in cardsToCollapseIndexRef.current) {
            newObj[card] = true
        }
        newObj[cardName] = false
        setCardsToCollapseIndexState(newObj)
    }

    const collapseCardByName = (cardName: string) => {
        let newObj = {} as any
        for (const card in cardsToCollapseIndexRef.current) {
            newObj[card] = true
        }
        newObj[cardName] = true
        setCardsToCollapseIndexState(newObj)
    }

    const polylineStrokeColourSet = (index: number) => {
        const colours = [
            "#0000FF",
            "#4B0082",
            "#9400D3",
            "#FF7F00",
        ]
        return colours[index % colours.length]
    }

    const formatDate = (date: any) => {
        return Intl.DateTimeFormat('en-AU', {
            day: '2-digit',
            month: 'short',
            year: '2-digit',
            hour: "2-digit",
            minute: '2-digit',
            hour12: true,
        }).format(date)
    }

    const RenderTowingLocationInfoOnlyCard = ({ towing_location }: any) => {
        return (
            <Card
                status="info"
                header={() => <Text style={{ alignSelf: 'center' }}>{towing_location.name} - COMPLETE</Text>}
                disabled={true}
                style={style}
            >
                <View style={{ flexDirection: 'column', justifyContent: "space-between", margin: -15 }}>
                    <View style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
                        <View style={{ flexDirection: 'row' }}>
                            <Icon name='clock-outline' width={24} height={24} fill="white" />
                            <Text style={{ margin: 5 }}>{formatDate(new Date(towing_location.time))}</Text>
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <Icon name='pin-outline' width={24} height={24} fill="white" />
                            <Text style={{ margin: 5 }}>{GoogleController.addressBuilder(towing_location.address)}</Text>
                        </View>
                        {/* Address */}
                        {/* Location type */}
                        <View style={{ flexDirection: 'row' }}>
                            <Icon name='pin-outline' width={24} height={24} fill="white" />
                            <Text style={{ margin: 5 }}>{towing_location.location_type}: </Text>
                            {towing_location.location_type == JOB_LOCATION_TYPE.HOLDING &&
                                <Text style={{ margin: 5 }}>{towing_location.holding_reason}</Text>
                            }
                            {towing_location.location_type == JOB_LOCATION_TYPE.ADDRESS &&
                                <Text style={{ margin: 5 }}>{towing_location.address_type}</Text>
                            }
                        </View>
                    </View>
                </View>
            </Card>
        )
    }



    return (
        <Card
            status="info"
            header={() => <Text style={{ alignSelf: 'center' }}>{"Towing Locations"}</Text>}
            disabled={true}
            style={style}
        >
            <View style={{ flexDirection: 'row', justifyContent: "space-between", margin: -15, flexWrap: 'wrap' }}>
                <View style={styles.addressContainer}>
                    {(jobTowingDetails.towing_locations && jobTowingDetails.towing_locations.length > 0) &&
                        <>
                            {jobTowingDetails.towing_locations.map((towingLocation, index) => {
                                let showAddLocationButton = true
                                let previousLocationTimeSet = jobTowingDetails.towing_locations[index - 1]?.time
                                let nextLocationTimeSet = jobTowingDetails.towing_locations[index + 1]?.time
                                if (previousLocationTimeSet && nextLocationTimeSet) {
                                    showAddLocationButton = false
                                }
                                if (index == 0 && towingLocation.time && nextLocationTimeSet) {
                                    showAddLocationButton = false
                                }
                                cardsToCollapseIndexRef.current[`TOWING_${index}`] = true
                                let cardName = ""
                                if (index == 0) {
                                    cardName = "Pickup"
                                } else if (index == jobTowingDetails.towing_locations.length - 1) {
                                    cardName = "Dropoff"
                                } else {
                                    cardName = `Location ${index}`
                                }
                                return (
                                    <React.Fragment key={index}>
                                        {towingLocation.time && towingLocation.time != 0 ?
                                            <RenderTowingLocationInfoOnlyCard towing_location={towingLocation} />
                                            :
                                            <TowingAddressDetailsCard
                                                isDropoff={index == jobTowingDetails.towing_locations.length - 1}
                                                key={index}
                                                index={index}
                                                // collapsed={cardsToCollapseIndexState[`TOWING_${index}`]}
                                                collapsed={false}
                                                towingLocationObject={towingLocation}
                                                cardName={cardName}
                                                allowEdit={allowEdit}
                                                style={{}}
                                                onRemoveTowingLocation={() => { removeTowingLocation(index) }}
                                                onToggleCollapse={(state) => {
                                                    state ? collapseCardByName(`TOWING_${index}`) : expandCardByName(`TOWING_${index}`)
                                                }}
                                                onSetTowingLocationObject={(updatedTowingLocation) => {
                                                    onAddressDetailsCardSetLocationObject(updatedTowingLocation, index)
                                                }}
                                                onSetAddress={(address) => {
                                                    onAddressDetailsCardSetAddress(address, index)
                                                }}
                                                onSetLocation={(location) => {
                                                    onAddressDetailsCardSetLocation(location, index)
                                                }}
                                                onRemoveAndSaveAsOriginalDestination={() => {
                                                    onAddressDetailsCardRemoveAndSaveAsOriginalDestination(index)
                                                }}
                                                defaultAddressDisplayValue={
                                                    index === 0 && pickupAddressDisplayValue
                                                        ? pickupAddressDisplayValue
                                                        : index === jobTowingDetails.towing_locations.length - 1 && dropoffAddressDisplayValue
                                                            ? dropoffAddressDisplayValue
                                                            : ""
                                                }
                                                onSetAddressType={(type: JOB_LOCATION_TYPE) => { }}
                                                onSetSavedAddress={(savedAddress: CompanySavedAddress) => {
                                                    onAddressDetailsCardSetSavedAddress(savedAddress, index)
                                                }}
                                            />
                                        }
                                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: 30 }}>
                                            {showAddLocationButton ?
                                                <Button
                                                    style={{ margin: 5 }}
                                                    size='small'
                                                    status='success'
                                                    appearance='outline'
                                                    onPress={() => {
                                                        addNewLocation(index + 1)
                                                    }}
                                                    accessoryLeft={() => <Icon name='plus-outline' width={24} height={24} fill="white" />}
                                                >
                                                    Add location
                                                </Button>
                                                :
                                                <View></View>
                                            }
                                            {jobTowingDetails.towing_locations[index].location_type == JOB_LOCATION_TYPE.HOLDING &&
                                                index == jobTowingDetails.towing_locations.length - 1 &&
                                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                    <Icon name='cube-outline' width={24} height={24} fill="orange" />
                                                    <Text category='s1' style={{ margin: 5 }}>This will create a Holding when the job is complete</Text>
                                                </View>
                                            }
                                            {/* Get distnace between this one and the last location */}
                                            {/* Only visible if this is not the last card */}
                                            {index != jobTowingDetails.towing_locations.length - 1 &&
                                                <>
                                                    {jobTowingDetails.towing_legs[index] &&
                                                        <>
                                                            <View style={{ flexDirection: 'row' }}>
                                                                <Icon name='arrow-right-outline' width={24} height={24} fill="white" />
                                                                <Text>{jobTowingDetails.towing_legs[index]?.directions?.distance_text}</Text>
                                                            </View>
                                                            <View style={{ flexDirection: 'row' }}>
                                                                <Icon name='clock-outline' width={24} height={24} fill="white" />
                                                                <Text>{jobTowingDetails.towing_legs[index]?.directions?.duration_text}</Text>
                                                            </View>
                                                        </>
                                                    }
                                                </>
                                            }
                                        </View>
                                    </React.Fragment>
                                )
                            })}
                        </>
                    }
                    {original_dropoff_towing_location &&
                        <View >
                            {/* Divider */}
                            <View style={{ width: "100%", height: 4, backgroundColor: 'white' }}>
                            </View>
                            <Text category='s1'>
                                Original Dropoff - {GoogleController.addressBuilder(original_dropoff_towing_location?.address)}
                                When removed from holding, the job created will retain this as the dropoff location
                            </Text>
                            <TowingAddressDetailsCard
                                defaultAddressDisplayValue=''
                                index={0}
                                isDropoff={true}
                                collapsed={true}
                                towingLocationObject={original_dropoff_towing_location}
                                cardName={"Original Dropoff"}
                                allowEdit={false}
                                style={{}}
                                onRemoveTowingLocation={() => {
                                    removeOriginalDropoffLocation()
                                }}
                                onToggleCollapse={() => { }}
                                onSetTowingLocationObject={(updatedTowingLocation) => {
                                    jobTowingDetails.original_dropoff_towing_location = updatedTowingLocation
                                    onSetTowingDetails(jobTowingDetails, "OG dest addressCardToggleCollapse")
                                }}
                                onSetAddress={(address) => {
                                    jobTowingDetails.original_dropoff_towing_location.address = address
                                    onSetTowingDetails(jobTowingDetails, "OG dest addressCardOnSetAddress")
                                }}
                                onSetLocation={(location) => {
                                    jobTowingDetails.original_dropoff_towing_location.location = location
                                    onSetTowingDetails(jobTowingDetails, "OG dest addressCardOnSetLocation")
                                }}
                                onSetAddressType={(type: JOB_LOCATION_TYPE) => { }}
                                onSetSavedAddress={(savedAddress: CompanySavedAddress) => {
                                    console.log("🚀============== ~ file: towingLocationDetailsCard.component.tsx:845 ~ savedAddress🚀==============", savedAddress)
                                    jobTowingDetails.original_dropoff_towing_location = savedAddress.address
                                    jobTowingDetails.original_dropoff_towing_location.location = savedAddress.location
                                    jobTowingDetails.original_dropoff_towing_location.location_type = savedAddress.type
                                    onSetTowingDetails(jobTowingDetails, "OG dest addressCardOnSetSavedAddress")
                                }}
                                onRemoveAndSaveAsOriginalDestination={() => { }}
                            />
                        </View>
                    }
                </View>
                {/* Google map card */}
                <Card
                    status="info"
                    header={() => <Text style={{ alignSelf: 'center' }}>{"Map"}</Text>}
                    disabled={true}
                    style={[styles.mapContainer]}
                >
                    <View style={{ flexDirection: 'column', margin: -15, marginBottom: 0, gap: 20 }}>
                        <View style={{ flexDirection: 'row' }}>
                            {showLocationSelectInfoWindow ?
                                <RenderSelectPlaceResultsSideBar
                                    locations={locationSearchResults}
                                    style={{
                                        width: mapPanelWidth,
                                        height: mapHeight,
                                    }}
                                />
                                :
                                <></>
                            }
                            {isLoaded &&
                                <GoogleMap
                                    // remove controls
                                    options={{
                                        disableDefaultUI: true,
                                        zoomControl: true,
                                        fullscreenControl: false,
                                        streetViewControl: false,
                                        mapTypeControl: true,
                                        rotateControl: false,
                                    }}
                                    mapContainerStyle={mapContainerStyle}
                                    center={center}
                                    zoom={10}
                                    onLoad={(map: any) => { mapRef.current = map; }}
                                    onUnmount={() => { mapRef.current = null; }}
                                    onClick={onMapClick}
                                >
                                    {/* Button at top to validate towing legs/locations validateTowingLegs*/}
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <View style={{ flex: 1 }}>

                                        </View>
                                        <Button
                                            status='success'
                                            accessoryLeft={(props: any) => <Icon {...props} name='refresh-outline' width={24} height={24} />}
                                            onPress={() => validateTowingLegs()}
                                        >Re-Calculate Route</Button>
                                    </View>
                                    {/* Marker for each location */}
                                    {jobTowingDetails.towing_locations.map((towingLocation, index) => {
                                        return (
                                            <Marker
                                                key={index}
                                                //@ts-ignore
                                                position={towingLocation.location ? towingLocation.location : null}
                                                label={index == 0 ? "Pickup" : index == jobTowingDetails.towing_locations.length - 1 ? "Dropoff" : `Location ${index}`}
                                                draggable={true}
                                                onDragEnd={(event: any) => {
                                                    const location = event?.latLng.toJSON()
                                                    onDragMarker(location, index)
                                                }}
                                            />
                                        )
                                    })}
                                    {/* {directions &&
                                        <DirectionsRenderer
                                            options={{
                                                directions: directions
                                            }}
                                        />
                                    } */}
                                    {(directionsPolylines && directionsPolylines.length > 0) &&
                                        directionsPolylines.map((polyline, index) => {
                                            return (
                                                //@ts-ignore
                                                <Polyline
                                                    key={index}
                                                    path={polyline}
                                                    options={{
                                                        strokeColor: polylineStrokeColourSet(index),
                                                        strokeOpacity: 0.8,
                                                        strokeWeight: 4,
                                                        icons: [
                                                            {
                                                                icon: "hello",
                                                                offset: "0",
                                                                repeat: "10px",
                                                            },
                                                        ],
                                                    }}
                                                />
                                            )
                                        }
                                        )
                                    }
                                    {directionsPolyline &&
                                        <Polyline
                                            path={GoogleController.decodePolyline(directionsPolyline)}
                                            options={{
                                                strokeColor: "#252e56",
                                                strokeOpacity: 0.8,
                                                strokeWeight: 4,
                                                icons: [
                                                    {
                                                        //@ts-ignore
                                                        icon: "hello",
                                                        offset: "0",
                                                        repeat: "10px",
                                                    },
                                                ],
                                            }}
                                        />
                                    }
                                </GoogleMap>
                            }
                        </View>
                        {/* Inputs and cost table for calculations */}
                        <Card
                            status="success"
                            header={() => <Text style={{ alignSelf: 'center' }}>Calculated Result</Text>}
                            disabled={true}
                            style={[{}, { margin: 0, marginTop: 0 }]}
                        >
                            <View style={{ flexDirection: 'column', justifyContent: "space-between" }}>
                                {/* Distance result */}
                                <View style={{ flexDirection: 'row', justifyContent: "space-between" }}>
                                    <View style={{ flexDirection: 'column', borderBottomColor: 'black', borderBottomWidth: 1 }}>
                                        <Text
                                            style={{ margin: 5 }}
                                            category='label'
                                        >
                                            Distance:
                                        </Text>
                                        <Text
                                            style={{ margin: 5 }}
                                        >{distanceText}
                                        </Text>
                                    </View>
                                    {/* Duration result */}
                                    <View style={{ flexDirection: 'column', borderBottomColor: 'black', borderBottomWidth: 1 }}>
                                        <Text
                                            style={{ margin: 5 }}
                                            category='label'
                                        >
                                            Duration:
                                        </Text>
                                        <Text
                                            style={{ margin: 5 }}
                                        >
                                            {durationText}
                                        </Text>
                                    </View>
                                </View>
                                <View style={{ flexDirection: 'row', gap: 20, justifyContent: 'space-between' }}>
                                    {/* Cost per km */}
                                    <Input
                                        textStyle={{ maxWidth: 130 }}
                                        disabled={!allowEdit}
                                        style={{ maxWidth: 130 }}
                                        label='Cost per km ($)'
                                        size='small'
                                        placeholder='Cost per km'
                                        value={costPerKm}
                                        onChangeText={(nextValue: any) => setCostPerKm(nextValue)}
                                    />
                                    {/* Covered distance */}
                                    <Input
                                        // make input max length 50
                                        textStyle={{ maxWidth: 150 }}
                                        disabled={!allowEdit}
                                        style={{ maxWidth: 150 }}
                                        label='Covered distance (km)'
                                        size='small'
                                        placeholder='Covered distance'
                                        value={coveredDistance}
                                        onChangeText={(nextValue: any) => setCoveredDistance(nextValue)}
                                    />
                                </View>

                                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <View style={{ flex: 1, flexDirection: 'column' }}>
                                        <Text
                                            style={{ margin: 5 }}
                                            category='label'
                                        >
                                            Cost:
                                        </Text>
                                        <Text
                                            style={{ margin: 5 }}
                                            category='p1'
                                        >
                                            Excess: ${memo_uncoveredCostResult || ""}
                                        </Text>
                                        <Text
                                            style={{ margin: 5 }}
                                            category='p1'
                                        >
                                            Total: ${totalCostResult}
                                        </Text>
                                        {showCalculationError &&
                                            <Text
                                                style={{ margin: 5, color: 'red' }}
                                            >
                                                {calculationErrorText}
                                            </Text>
                                        }
                                    </View>
                                    <View style={{ flex: 1, flexDirection: 'column' }}>
                                        <Toggle
                                            style={{ margin: 5 }}
                                            checked={addCoveredCostToBillBack}
                                            onChange={(nextChecked: boolean) => setAddCoveredCostToBillBack(nextChecked)}
                                        >
                                            Add covered cost to bill back
                                        </Toggle>
                                        {/* Button to add item */}
                                        <Text style={{ margin: 5 }}>Add to Items</Text>
                                        <Button

                                            style={{ margin: 5 }}
                                            size='small'
                                            appearance='outline'
                                            status='success'
                                            disabled={showCalculationError || !allowEdit}
                                            onPress={() => {
                                                onAddTowingCostToItems()
                                            }}
                                        >
                                            <Icon
                                                name='plus-outline'
                                                width={20}
                                                height={20}
                                                fill='white'
                                            />
                                        </Button>
                                    </View>
                                </View>

                            </View>
                        </Card>
                    </View>
                </Card>
            </View>
        </Card>
    );
}



const styles = StyleSheet.create({
    addressContainer: {
        flexDirection: 'column',
        flex: 1,
        margin: 5,
        gap: 10,
        minWidth: 350
    },
    mapContainer: {
        // margin: 10,
        flex: 1,
        minWidth: 350,
        // backgroundColor: '#252e56',
    },
    groupContainer: {
        margin: 0,
        flex: 1,
        minWidth: 350,
        // backgroundColor: '#252e56',
    },
    greenContainer: {
        margin: 10,
        // flex: 1,
        minWidth: 350,
        backgroundColor: '#009362a1',
    },
    orangeContainer: {
        margin: 10,
        // flex: 1,
        minWidth: 350,
        backgroundColor: '#6f2910a1',
    },


});