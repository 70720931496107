import React, { useState, useEffect, useCallback } from 'react';
import { View } from "react-native";
import { Text, Card, Button, Input, Layout, Toggle } from '@ui-kitten/components';
import { CLIENT_RATE_BILLED_TO, JobClientRate } from '../../models/Job.model';
import DMModal from '../common/Modal';

interface ClientRateModalProps {
    visible: boolean;
    onClose: () => void;
    onSave: (rate: JobClientRate) => void;
    isEditMode: boolean;
    initialRate?: JobClientRate;
    accountCode?: string;
}

export const ClientRateModal = ({
    visible,
    onClose,
    onSave,
    isEditMode,
    initialRate,
    accountCode,
}: ClientRateModalProps) => {
    const [rate, setRate] = useState<JobClientRate>(new JobClientRate());
    const [error, setError] = useState("");

    const [cost, setCost] = useState<string>("");

    useEffect(() => {
        if (initialRate) {
            initialRate.account_code = accountCode || "";
            setCost(initialRate.cost.toString());
            setRate(new JobClientRate(initialRate));
        } else {
            // Reset form when not editing
            setRate(new JobClientRate({
                name: "",
                cost: 0,
                customer_paid: false,
                billed_to: CLIENT_RATE_BILLED_TO.BILL_ALL_BACK,
                is_gst_inclusive: true,
                eft_auth_number: "",
                account_code: accountCode || ""
            }));
            setCost("");
        }
        setError("");
    }, [initialRate, visible]);

    const validateForm = () => {
        if (!rate.name) {
            setError("Name is required");
            return false;
        }
        const costNum = Number(cost);
        if (isNaN(costNum) || costNum < 0) {
            setError("Cost must be a positive number");
            return false;
        }

        setError("");
        return true;
    };

    const updateRate = (updates: Partial<JobClientRate>) => {
        setRate(prev => {
            const newRate = new JobClientRate({ ...prev, ...updates });
            return newRate;
        });
    };

    const handleCostChange = (value: string) => {
        // we need to convert the value to a number, later
        //@ts-ignore
        // updateRate({ cost: value });
        setCost(value);
    };

    const handleSave = useCallback(() => {
        if (validateForm()) {
            rate.cost = Number(cost);
            onSave(rate);
        }
    }, [rate, cost, onSave]);

    return (
        <DMModal
            visible={visible}
            onClose={onClose}
        >
            <Card disabled={true}>
                <Input
                    label='Name'
                    value={rate.name}
                    onChangeText={(value) => updateRate({ name: value })}
                />
                <Input
                    label='Cost'
                    defaultValue={cost}
                    onChangeText={handleCostChange}
                    keyboardType="numeric"
                />
                <Input
                    label='Account Code'
                    value={rate.account_code}
                    onChangeText={(value) => updateRate({ account_code: value })}
                />
                <Text category='label' style={{ marginTop: 10, marginBottom: 5 }}>Billed To</Text>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
                    <Button
                        onPress={() => updateRate({ billed_to: CLIENT_RATE_BILLED_TO.CUSTOMER_COST })}
                        appearance={rate.billed_to === CLIENT_RATE_BILLED_TO.CUSTOMER_COST ? 'filled' : 'outline'}
                        size='small'
                    >
                        Customer Cost
                    </Button>
                    <Button
                        onPress={() => updateRate({ billed_to: CLIENT_RATE_BILLED_TO.BILL_ALL_BACK })}
                        appearance={rate.billed_to === CLIENT_RATE_BILLED_TO.BILL_ALL_BACK ? 'filled' : 'outline'}
                        size='small'
                    >
                        Bill All Back
                    </Button>
                </View>
                {error && <Text style={{ color: "red" }}>{error}</Text>}
                <Layout style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                    <Button
                        appearance='ghost'
                        onPress={onClose}>
                        Cancel
                    </Button>
                    <Button
                        status='success'
                        onPress={handleSave}>
                        {isEditMode ? 'Save' : 'Add'}
                    </Button>
                </Layout>
            </Card>
        </DMModal>
    );
}; 