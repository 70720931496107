import React from 'react';
import { useWindowDimensions, View, ViewStyle } from 'react-native';
import { RefreshButton } from './RefreshButton';
import { CreateJobButton, CreateCaseButton, CreateHoldingButton, CreateRouteButton, CreateOrderButton } from './CreateButtons';
import { ViewModeButtons } from '../../job/ViewModeButtons';
import { SearchJobsHoldingsCases } from '../../job/SearchJobsHoldingsCases';
import { SEARCH_TYPE } from '../types/overview.types';
import { LIST_TABS } from '../../job/ListPanelTabs';
import { styles } from '../styles/overview.styles';
import { VIEW_MODE } from '../types/overview.types';
import { FilterButtons } from './FilterButtons';

interface OverviewToolbarProps {
    initLoad: () => void;
    setRenderSpinner: (show: boolean) => void;
    setLoadingText: (text: string) => void;
    enableRoutes: boolean;
    enableHoldings: boolean;
    enableCases: boolean;
    enableOrders: boolean;
    showCreateHolding: boolean;
    openNewJobModal: () => void;
    openCreateRouteContainer: () => void;
    openCreateHoldingContainer: () => void;
    openCreateCaseContainer: () => void;
    openCreateOrderModal: () => void;
    selectedListPanelTab: LIST_TABS;
    viewMode: VIEW_MODE;
    setViewMode: (mode: VIEW_MODE) => void;
    onSetJobSearchResults: (results: any[], term: string) => void;
    onSetHoldingSearchResults: (results: any[], term: string) => void;
    resetSearch: number;
    jobFilterApplied: any;
    onSetFilterApplied: (filter: any) => void;
    setShowFilters: (show: boolean) => void;
    fetchedJobsRef: React.MutableRefObject<any[]>;
    caseFilterApplied: any;
    setCaseFilterApplied: (filter: any) => void;
    holdingFilterApplied: any;
    setHoldingFilterApplied: (filter: any) => void;
    onClearHoldingSearchResults: () => void;
    holdingSearchResults: any[];
    RenderMemoizedRoutesFilters: React.ReactNode;
    RenderMemoizedOrdersFilters: React.ReactNode;
}

export const OverviewToolbar: React.FC<OverviewToolbarProps> = ({
    initLoad,
    setRenderSpinner,
    setLoadingText,
    enableRoutes,
    enableHoldings,
    enableCases,
    enableOrders,
    showCreateHolding,
    openNewJobModal,
    openCreateRouteContainer,
    openCreateHoldingContainer,
    openCreateCaseContainer,
    openCreateOrderModal,
    selectedListPanelTab,
    viewMode,
    setViewMode,
    onSetJobSearchResults,
    onSetHoldingSearchResults,
    resetSearch,
    jobFilterApplied,
    onSetFilterApplied,
    setShowFilters,
    fetchedJobsRef,
    caseFilterApplied,
    setCaseFilterApplied,
    holdingFilterApplied,
    setHoldingFilterApplied,
    onClearHoldingSearchResults,
    holdingSearchResults,
    RenderMemoizedRoutesFilters,
    RenderMemoizedOrdersFilters
}) => {

    const createButtonsBasis = "30%";
    const filterButtonsBasis = "30%";
    const viewModeButtonsBasis = "20%";
    const searchButtonsBasis = "20%";

    const { width: windowWidth } = useWindowDimensions();
    const isSmallScreen = windowWidth < 800;

    let createButtonRowStyle = {
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row',
        gap: 5,
        marginVertical: 2,
    } as ViewStyle;

    if (isSmallScreen) {
        createButtonRowStyle = {
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'row',
            gap: 5,
            marginVertical: 2,
            flex: 1,
            maxWidth: windowWidth,
        }
    }

    let createButtonStyle = {
    } as ViewStyle;

    if (isSmallScreen) {
        createButtonStyle = {
            flex: 1,
            minWidth: 100,
        }
    }

    return (
        <View style={[{
            marginLeft: 10,
            flexWrap: 'wrap',
            maxWidth: windowWidth,
            flexDirection: "row",
            justifyContent: "space-between",
            marginVertical: 2,
        }]}>
            <View style={createButtonRowStyle}>
                <RefreshButton
                    onRefreshStart={() => {
                        setRenderSpinner(true);
                        setLoadingText("Refreshing data...");
                    }}
                    onRefreshComplete={() => {
                        setRenderSpinner(false);
                        setLoadingText("");
                    }}
                    onRefreshError={() => {
                        setRenderSpinner(false);
                        setLoadingText("");
                    }}
                    initLoad={initLoad}
                />

                <CreateJobButton openNewJobModal={openNewJobModal} style={createButtonStyle} />
                {enableRoutes && (
                    <CreateRouteButton openCreateRouteContainer={openCreateRouteContainer} style={createButtonStyle} />
                )}
                {enableHoldings && showCreateHolding && (
                    <CreateHoldingButton openCreateHoldingContainer={openCreateHoldingContainer} style={createButtonStyle} />
                )}
                {enableCases && (
                    <CreateCaseButton openCreateCaseContainer={openCreateCaseContainer} style={createButtonStyle} />
                )}
                {enableOrders && (
                    <CreateOrderButton openCreateOrderModal={openCreateOrderModal} style={createButtonStyle} />
                )}
            </View>
            <View style={{}}>
                <FilterButtons
                    selectedListPanelTab={selectedListPanelTab}
                    jobFilterApplied={jobFilterApplied}
                    onSetFilterApplied={onSetFilterApplied}
                    setShowFilters={setShowFilters}
                    fetchedJobsRef={fetchedJobsRef}
                    caseFilterApplied={caseFilterApplied}
                    setCaseFilterApplied={setCaseFilterApplied}
                    holdingFilterApplied={holdingFilterApplied}
                    setHoldingFilterApplied={setHoldingFilterApplied}
                    onClearHoldingSearchResults={onClearHoldingSearchResults}
                    holdingSearchResults={holdingSearchResults}
                    RenderMemoizedRoutesFilters={RenderMemoizedRoutesFilters}
                    RenderMemoizedOrdersFilters={RenderMemoizedOrdersFilters}
                />
            </View>

            <View style={{ maxWidth: 150 }}>
                <View style={{ flexDirection: 'row' }}>
                    <ViewModeButtons
                        style={{ flex: 1 }}
                        viewMode={viewMode}
                        setViewMode={setViewMode}
                    />
                </View>
            </View>
            <View style={{
                // flex: 1,
                minWidth: 100,
                maxWidth: "30%"
            }}>
                {selectedListPanelTab == LIST_TABS.JOBS && (
                    <SearchJobsHoldingsCases
                        onSearchResultsReturned={onSetJobSearchResults}
                        resetSearch={resetSearch}
                    />
                )}
                {selectedListPanelTab == LIST_TABS.HOLDINGS && (
                    <SearchJobsHoldingsCases
                        onSearchResultsReturned={onSetHoldingSearchResults}
                        resetSearch={resetSearch}
                        searchType={SEARCH_TYPE.HOLDING}
                    />
                )}
            </View>
        </View>
    );
}; 