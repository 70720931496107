import React, { useState, useEffect } from 'react';
import { View, StyleSheet, ScrollView, useWindowDimensions } from 'react-native';
import { Button, Text, Layout, Input, Card } from '@ui-kitten/components';
import { CartItem } from './PartsNetworkContainerScreen';
import { createOrder } from '../../functions/order.controller';
import { Order, OrderDetails, OrderItem, OrderStatus } from '../../models/Order.model';
import { Company, CompanySavedAddress } from '../../models/Company.model';
import { JobAddress, JobCustomerDetails, JobLocation, JobVehicleDetails, LINE_ITEM_BILLED_TO } from '../../models/Job.model';
import AddressSearch from '../job/details/addressSearch.component';
import { AddressDetailsCard } from '../common/address/AddressComponents';
import * as CompanyController from '../../functions/company.controller';

interface CheckoutComponentProps {
  cartItems: CartItem[];
  currentCompany: Company;
  onCheckoutComplete: (order: Order) => void;
  onClose: () => void;
}



export const CheckoutComponent: React.FC<CheckoutComponentProps> = ({
  cartItems,
  currentCompany,
  onCheckoutComplete,
  onClose,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [orderDetails, setOrderDetails] = useState<OrderDetails>({
    dispatch_comments: '',
    customer_details: new JobCustomerDetails({}),
    vehicle_details: new JobVehicleDetails({}),
    address: new JobAddress({}),
    location: new JobLocation({}),
    delivery_fee: 0,
    scheduled_date: null,
    client_company_name: currentCompany.name ?? '',
    vendor_company_name: '',
  });

  // get vendor name from company
  const getVendorName = async () => {
    const vendor = await CompanyController.getCompanyById(currentCompany._id);
    return vendor?.name ?? '';
  };


  useEffect(() => {
    // Pre-fill details from company settings if available
    prefillData();
  }, []);

  const prefillData = async () => {
    const vendorName = await getVendorName();
    setOrderDetails((prevDetails: OrderDetails) => ({
      ...prevDetails,
      address: new JobAddress(currentCompany.settings.location.address ?? new JobAddress({})),
      location: new JobLocation(currentCompany.settings.location.coords ?? new JobLocation({})),
      customer_details: new JobCustomerDetails({
        name: currentCompany.name ?? '',
        phone: currentCompany.settings.contact_info.phone ?? '',
        email: currentCompany.settings.contact_info.email ?? '',
      }),
      client_company_name: currentCompany.name ?? '',
      vendor_company_name: vendorName,
    }));
  };


  useEffect(() => {
    console.log('orderDetails', orderDetails);
  }, [orderDetails]);

  const { width, height } = useWindowDimensions();

  const handleItemPriceIfGSTExclusive = (item: CartItem) => {
    if (item.isGSTInclusive) {
      return item.default_price;
    }
    return item.default_price = item.default_price * 1.1;
  }


  const handleCheckout = async () => {
    setIsProcessing(true);
    let createdOrder: Order | null = null;
    for (const [vendorId, items] of Object.entries(groupedItems)) {
      const order: Partial<Order> = {
        company_id: currentCompany._id ?? null,
        client_company_id: currentCompany._id ?? null,
        vendor_company_id: vendorId,
        // client_profile_id: selectedClient?._id ?? null,
        // vendor_profile_id: (await CompanyController.getCompanyById(vendorId))?._id ?? null,
        items: items.map(item => new OrderItem({
          inventory_item_id: item._id ?? '',
          quantity: item.quantity,
          price: handleItemPriceIfGSTExclusive(item),
          // is_gst_inclusive: item.isGSTInclusive,
          billed_to: LINE_ITEM_BILLED_TO.CUSTOMER_COST,
          name: item.name,
          description: item.description ?? '',
        })),
        total_amount: items.reduce((sum, item) => sum + item.default_price * item.quantity, 0),
        status: OrderStatus.REQUEST,
        details: {
          ...orderDetails,
          vendor_company_name: (await CompanyController.getCompanyById(vendorId))?.name ?? '',
        }
      };

      try {
        createdOrder = await createOrder(new Order(order));
        if (createdOrder) {
          console.log('Order created:', createdOrder);
        }
      } catch (error) {
        console.error('Error creating order:', error);
      }
    }
    setIsProcessing(false);
    if (createdOrder) {
      onCheckoutComplete(createdOrder);
    }
  };

  const updateOrderDetails = (field: string, value: any) => {
    setOrderDetails((prevDetails: OrderDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const handleSetAddress = (address: JobAddress) => {
    updateOrderDetails('address', address);
  };

  const handleSetLocation = (location: JobLocation) => {
    updateOrderDetails('location', location);
  };

  const handleSetSavedAddress = (savedAddress: CompanySavedAddress) => {
    const address = new JobAddress(savedAddress.address);
    const location = new JobLocation(savedAddress.location);
    const details = { ...orderDetails, address, location } as OrderDetails;
    setOrderDetails(details);
  };

  const groupedItems = cartItems.reduce((acc, item) => {
    if (!acc[item.vendorId]) {
      acc[item.vendorId] = [];
    }
    acc[item.vendorId].push(item);
    return acc;
  }, {} as Record<string, CartItem[]>);

  const totalPrice = cartItems.reduce((sum, item) => sum + item.default_price * item.quantity, 0);

  const calculateGSTOnly = (items: CartItem[]) => {
    let total = 0;
    items.forEach(item => {
      const price = item.default_price * item.quantity;
      if (item.isGSTInclusive) {
        let gstOnly = price / 1.1;
        total += price - gstOnly;
      } else {
        total += price * 0.1;
      }
    });
    return total;
  }

  return (
    <Layout style={[styles.container, { width: width * 0.9, height: height * 0.9 }]}>
      <View style={styles.header}>
        <Text category="h5">Confirm order details</Text>
        <Button appearance="ghost" status="basic" onPress={onClose}>X</Button>
      </View>
      <View style={styles.content}>
        <View style={styles.leftColumn}>
          <Card style={styles.card}>
            <Text category="h6">Customer details</Text>
            <Input
              label="Name"
              size='small'
              value={orderDetails.customer_details.name}
              onChangeText={(value) => updateOrderDetails('customer_details', { ...orderDetails.customer_details, name: value })}
              style={styles.input}
            />
            <Input
              label="Phone"
              size='small'
              value={orderDetails.customer_details.phone}
              onChangeText={(value) => updateOrderDetails('customer_details', { ...orderDetails.customer_details, phone: value })}
              style={styles.input}
            />
            <Input
              label="Email"
              size='small'
              value={orderDetails.customer_details.email}
              onChangeText={(value) => updateOrderDetails('customer_details', { ...orderDetails.customer_details, email: value })}
              style={styles.input}
            />
          </Card>
          {/* <Card style={styles.card}>
            <Text category="h6">Delivery Address</Text>
            <AddressSearch
              onSetAddress={handleSetAddress}
              onSetLocation={handleSetLocation}
              displayValue={orderDetails.address.formatted_address || ''}
              allowEdit={true}
            />
          </Card> */}
          <AddressDetailsCard
            address={orderDetails.address}
            onSetAddress={handleSetAddress}
            onSetLocation={handleSetLocation}
            addressDisplayValue={orderDetails.address.formatted_address || ''}
            allowEdit={true}
            onSetSavedAddress={handleSetSavedAddress}

          />
        </View>
        <View style={styles.rightColumn}>
          <Card style={styles.card}>
            <Text category="h6">Items</Text>
            <ScrollView style={styles.itemsList}>
              {Object.entries(groupedItems).map(([vendorId, items]) => (
                <View key={vendorId}>
                  <Text category="s1">Vendor: {items[0].vendorName}</Text>
                  {items.map(item => (
                    <Text key={item._id}>
                      {item.name} - Quantity: {item.quantity}, Price: ${item.default_price.toFixed(2)} {item.isGSTInclusive ? '(GST Included)' : '(GST Excluded)'}
                    </Text>
                  ))}
                </View>
              ))}
            </ScrollView>
            <Text category="s1" style={styles.totalPrice}>GST: ${calculateGSTOnly(cartItems).toFixed(2)}</Text>
            <Text category="h6" style={styles.totalPrice}>Total: ${(totalPrice + calculateGSTOnly(cartItems)).toFixed(2)}</Text>
          </Card>
        </View>
      </View>
      <Button
        onPress={handleCheckout}
        disabled={isProcessing}
        style={styles.confirmButton}
      >
        {isProcessing ? 'Processing...' : 'CONFIRM'}
      </Button>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    borderRadius: 10,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  content: {
    flexDirection: 'row',
    flex: 1,
  },
  leftColumn: {
    flex: 1,
    marginRight: 8,
  },
  rightColumn: {
    flex: 1,
    marginLeft: 8,
  },
  card: {
    marginBottom: 16,
  },
  input: {
    marginBottom: 8,
  },
  itemsList: {
    maxHeight: 200,
  },
  totalPrice: {
    textAlign: 'right',
    marginTop: 8,
  },
  confirmButton: {
    marginTop: 16,
  },
});
