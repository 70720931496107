import React from 'react';
import { Layout, Spinner, Text } from '@ui-kitten/components';

interface SpinnerOverlayProps {
    renderSpinner: boolean;
    loadingText: string;
}

export const SpinnerOverlay = ({ renderSpinner, loadingText }: SpinnerOverlayProps) => {
    if (!renderSpinner) return null;

    return (
        <Layout style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1000,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.5)'
        }}>
            <Spinner size='giant' />
            <Text>{loadingText}</Text>
        </Layout>
    );
}; 