export class DeviceDetails {
    name = ""
    app_version = 0;
    system_version = "";
    device_id = "";
    update_id = "";
    runtime_version = "";
    update_timestamp: Date | null = null;

    battery_level = 0;
    location_permission: any = {};
    location_background_permission: any = {};
    location_enabled = false;
    battery_enabled = false;
    battery_low_power_mode = false;
    battery_optimization_enabled = false;
    camera_permission: any = {};
    media_library_permission: any = {};
    constructor(details: any = {}) {
        this.name = details?.name || "";
        this.app_version = details?.app_version || ""
        this.system_version = details?.system_version || ""
        this.device_id = details?.device_id || ""
        this.update_id = details?.update_id || "";
        this.runtime_version = details?.runtime_version || "";
        this.update_timestamp = details?.update_timestamp || null;
        this.battery_level = details?.battery_level || 0;
        this.location_permission = details?.location_permission || false;
        this.location_background_permission = details?.location_background_permission || false;
        this.location_enabled = details?.location_enabled || false;
        this.battery_enabled = details?.battery_enabled || false;
        this.battery_low_power_mode = details?.battery_low_power_mode || false;
        this.battery_optimization_enabled = details?.battery_optimization_enabled || false;
        this.camera_permission = details?.camera_permission || false;
        this.media_library_permission = details?.media_library_permission || false;
    }

}