import React from 'react';
import { useWindowDimensions, View, ViewStyle } from 'react-native';
import { JobStatusFilterButtons } from '../../job/components/JobStatusFilterButtons';
import { CaseStatusFilterButtons } from '../../job/CaseStatusFilterButtons';
import { HoldingStatusFilterButtons } from '../../job/HoldingStatusFilterButtons';
import { LIST_TABS } from '../../job/ListPanelTabs';
import { styles } from '../styles/overview.styles';

interface FilterButtonsProps {
    selectedListPanelTab: LIST_TABS;
    jobFilterApplied: any;
    onSetFilterApplied: (filter: any) => void;
    setShowFilters: (show: boolean) => void;
    fetchedJobsRef: React.MutableRefObject<any[]>;
    caseFilterApplied: any;
    setCaseFilterApplied: (filter: any) => void;
    holdingFilterApplied: any;
    setHoldingFilterApplied: (filter: any) => void;
    onClearHoldingSearchResults: () => void;
    holdingSearchResults: any[];
    RenderMemoizedRoutesFilters: React.ReactNode;
    RenderMemoizedOrdersFilters: React.ReactNode;
}

export const FilterButtons: React.FC<FilterButtonsProps> = ({
    selectedListPanelTab,
    jobFilterApplied,
    onSetFilterApplied,
    setShowFilters,
    fetchedJobsRef,
    caseFilterApplied,
    setCaseFilterApplied,
    holdingFilterApplied,
    setHoldingFilterApplied,
    onClearHoldingSearchResults: clearHoldingSearchResults,
    holdingSearchResults,
    RenderMemoizedRoutesFilters,
    RenderMemoizedOrdersFilters
}) => {

    const { width: windowWidth } = useWindowDimensions();
    const isSmallScreen = windowWidth < 800;

    let filterButtonStyle = {
        // minWidth: 100,
        flexWrap: 'wrap',
        flexDirection: 'row',
        gap: 10,
    } as ViewStyle;

    if (isSmallScreen) {
        filterButtonStyle = {
            ...filterButtonStyle,
            // flex: 1,
            width: windowWidth,
        }
    }

    return (
        <View style={filterButtonStyle}>
            {selectedListPanelTab == LIST_TABS.CASES && (
                <CaseStatusFilterButtons
                    caseFilterApplied={caseFilterApplied}
                    setCaseFilterApplied={setCaseFilterApplied}
                />
            )}
            {selectedListPanelTab == LIST_TABS.HOLDINGS && (
                <HoldingStatusFilterButtons
                    holdingFilterApplied={holdingFilterApplied}
                    setHoldingFilterApplied={setHoldingFilterApplied}
                    onClearSearchResults={clearHoldingSearchResults}
                    holdingSearchResults={holdingSearchResults}
                // style={{ flex: 1 }}
                />
            )}
            {(selectedListPanelTab == LIST_TABS.JOBS ||
                selectedListPanelTab == LIST_TABS.SCHEDULED ||
                selectedListPanelTab == LIST_TABS.DRIVERS) && (
                    <JobStatusFilterButtons
                        style={{ maxWidth: windowWidth }}
                        onSetFilterApplied={onSetFilterApplied}
                        filterApplied={jobFilterApplied}
                        setShowFilters={setShowFilters}
                        fetchedJobsRef={fetchedJobsRef}
                    />
                )}
            {selectedListPanelTab == LIST_TABS.ROUTES && RenderMemoizedRoutesFilters}
            {selectedListPanelTab == LIST_TABS.ORDERS && RenderMemoizedOrdersFilters}
        </View>
    );
}; 