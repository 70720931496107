import React, { useState, useEffect, useRef, useReducer, useCallback, useMemo, createContext, useContext } from 'react';
//@ts-ignore
import { useWindowDimensions, StyleSheet, View, TouchableOpacity } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
//@ts-ignore
import { Divider, Icon, Layout, Text, TopNavigation, TopNavigationAction, Card, Button, Input, ButtonGroup, CheckBox, Modal, RangeDatepicker, Datepicker, useTheme, IndexPath, Select, SelectItem, Spinner, Toggle } from '@ui-kitten/components';
import 'react-data-grid/lib/styles.css';
import moment from 'moment';
//@ts-ignore
import DataGrid, { Row, RowRendererProps, SelectColumn, textEditor, useFocusRef, Too, Column, DataGridProps } from 'react-data-grid';
import * as StorageController from '../../functions/storageController'
import * as JobController from '../../functions/job.controller'
import * as InvoiceController from '../../functions/invoice.controller'
import * as ClientController from '../../functions/client.controller'
import * as UpdateController from '../../functions/update.controller'
import * as ServiceController from '../../functions/service.controller'
import * as GoogleController from '../../functions/google.controller'
import ErrorBoundary from '../ErrorBoundary.component';
import { ScrollView } from 'react-native-gesture-handler';
//@ts-ignore
import { CSVLink } from "react-csv";
import { ReportDetailsCard } from '../job/details/reportDetailsCard.component';
import { LogDetailsCard } from '../job/details/logDetailsCard.component';
import { Job, JobAddress, JobCustomerDetails, JobDetails, JobLocation, JobReport, JobTowingDetails, JobVehicleDetails, TOWING_TYPE } from '../../models/Job.model'
import { Service } from '../../models/Service';
import { LineItem } from '../../models/LineItem.model';
import { Case } from '../../models/Case.model';
import { Holding } from '../../models/Holding.model';
import { Invoice, InvoiceItem } from '../../models/Invoice.model';
import * as Utils from './invoiceUtils';
import * as Formatters from './invoiceFormatters';
import Tooltip from '../modals/Tooltip';
import { Member } from '../../models/Member.model';
import { Client } from '../../models/Client.model';
import { Company } from '../../models/Company.model';
import { FullJobReport } from '../dashboardComponents/jobReportModal.component';
import { JobInvoiceRow, HoldingInvoiceRow } from './invoicingTypes';

const defaultWidth = 150;

const EyeIcon = (props: any) => (
    <Icon {...props} fill='#fff' name="eye-outline" />
);

interface IColumsRendererJobsProps {
    onItemsExpanded: () => void;
    itemsExpanded: boolean;
    handleGridSort: (columnKey: string, sortDirection: 'ASC' | 'DESC') => void;
    onOpenJobDetailsPanel: (row: JobInvoiceRow) => void;
    getMemberName: (memberId: string) => string;
    getClientName: (clientId: string) => string;
    getClientAccountCode: (clientId: string) => string;
}

interface IColumsRendererHoldingProps {
    onItemsExpanded: () => void;
    itemsExpanded: boolean;
    handleGridSort: (columnKey: string, sortDirection: 'ASC' | 'DESC') => void;
    onOpenHoldingDetailsPanel: (row: HoldingInvoiceRow) => void;
    getMemberName: (memberId: string) => string;
    getClientName: (clientId: string) => string;
    getClientAccountCode: (clientId: string) => string;
}
// Holds no actual data, just the columns definitions HoldingInvoiceRowtype
export const HoldingColumnsRenderer = ({
    onItemsExpanded,
    itemsExpanded,
    handleGridSort,
    onOpenHoldingDetailsPanel,
    getMemberName,
    getClientName,
    getClientAccountCode
}: IColumsRendererHoldingProps) => {

    const cols = [
        SelectColumn,
        {
            // Button to open job report, only appears if the row is not a subrow
            key: 'expand',
            name: '',
            width: 15,
            frozen: true,
            formatter: ({ row }: { row: HoldingInvoiceRow }) => {
                if (!row.isSubRow) {
                    return (
                        <Button
                            size='tiny'
                            appearance='outline'
                            status="success"
                            accessoryLeft={EyeIcon}
                            onPress={() => {
                                onOpenHoldingDetailsPanel(row)
                            }}
                        />
                    );
                } else {
                    return <Text></Text>
                }
            }
        },
        {
            key: 'id',
            name: '#',
            width: defaultWidth,
            frozen: true,
            formatter: ({ row }: { row: HoldingInvoiceRow }) => {
                if (row.status === "cancelled") {
                    return <Text style={{ backgroundColor: 'purple' }}>{row.id}- CANCELLED</Text>
                }
                else if (row.status == "transferred_out") {
                    return <Text style={{ backgroundColor: 'cyan', color: "black" }}>{row.id}- TRANSFERRED OUT</Text>
                }
                else {
                    return <Text>{row.id}</Text>
                }
            },
            summaryFormatter({ row }: { row: HoldingInvoiceRow }) {

                return <>{row.totalCount} Records</>;
            }
        },
        {
            key: '_id', name: 'ID', width: 170,
            formatter: ({ row }: { row: HoldingInvoiceRow }) => {
                return (<Text style={{ backgroundColor: row.status === 'cancelled' ? 'purple' : 'transparent' }}>{row.name}</Text>)
            }
        },
        // invoice created bool
        {
            key: 'isInvoiceCreated', name: 'Invoice Created', width: defaultWidth,
            formatter: ({ row }: { row: HoldingInvoiceRow }) => {
                if (!row.isSubRow) {
                    if (StorageController.getAppState().selectedMembership?.is_client) return <Text>----</Text>
                    if (row.details.invoice_details) {
                        if (row.details.invoice_details.submitted) {
                            return <Text style={{ backgroundColor: 'green' }}>Yes</Text>
                        } else {
                            return <Text style={{ backgroundColor: 'red' }}>No</Text>
                        }
                    } else {
                        return <Text style={{ backgroundColor: 'red' }}>No</Text>
                    }
                } else {
                    return <Text>----</Text>
                }
            }
        },
        //Client
        { key: 'client_reference_id', width: defaultWidth, name: 'Client Reference ID' },
        { key: 'client.account_code', width: defaultWidth, name: 'Account Code', formatter: ({ row }: { row: HoldingInvoiceRow }) => <Text>{getClientAccountCode(row.client_id as string)}</Text> },
        { key: 'client.name', name: 'Client', formatter: ({ row }: { row: HoldingInvoiceRow }) => <Text>{getClientName(row.client_id as string)}</Text> },
        { key: 'invoice_date', width: defaultWidth, name: 'Invoice Date', formatter: ({ row }: { row: HoldingInvoiceRow }) => <Text>{moment(row.released_time).format('DD/MM/YYYY')}</Text> },
        {
            key: 'client_rate_name',
            width: 130,
            name: 'Client Rate',
            formatter: ({ row }: { row: HoldingInvoiceRow }) => {
                if (row.details?.hasOwnProperty("client_rate")) {
                    return <Text>{row.details.client_rate?.name}</Text>
                }
                else {
                    return <Text></Text>
                }
            }
        },
        {
            key: 'client_rate_amount',
            width: defaultWidth,
            name: 'Client Rate $',
            summaryFormatter: ({ row }: { row: HoldingInvoiceRow }) => {
                return <Text>{row.clientRateTotal}</Text>;
            },
            formatter: ({ row }: { row: HoldingInvoiceRow }) => {
                if (row.details?.hasOwnProperty("client_rate")) {
                    return <Text>{row.details.client_rate?.cost}</Text>
                }
                else {
                    return <Text></Text>
                }
            }
        },
        {
            key: 'items',
            name: 'Items',
            formatter: ({ row, isCellSelected }: { row: HoldingInvoiceRow, isCellSelected: boolean }) => {
                return (
                    <Text>{row?.line_items?.length > 0 ? `${row?.line_items?.length} Items` : ''}</Text>
                );
            },
        },
        { key: "details.vehicle_details.make", width: defaultWidth, name: 'Make', formatter: ({ row }: { row: HoldingInvoiceRow }) => <Text>{row.details?.vehicle_details.make}</Text>, sortCompare: (a: HoldingInvoiceRow, b: HoldingInvoiceRow) => a.details?.vehicle_details.make.localeCompare(b.details?.vehicle_details.make) },
        { key: 'details.vehicle_details.model', width: defaultWidth, name: 'Model', formatter: ({ row }: { row: HoldingInvoiceRow }) => <Text>{row.details?.vehicle_details.model}</Text> },
        { key: 'details.vehicle_details.rego', width: defaultWidth, name: 'Rego', formatter: ({ row }: { row: HoldingInvoiceRow }) => <Text>{row.details?.vehicle_details.rego}</Text> },
        { key: 'details.vehicle_details.year', width: defaultWidth, name: 'Year', formatter: ({ row }: { row: HoldingInvoiceRow }) => <Text>{row.details?.vehicle_details.year}</Text> },
        { key: 'details.vehicle_details.colour', width: defaultWidth, name: 'Colour', formatter: ({ row }: { row: HoldingInvoiceRow }) => <Text>{row.details?.vehicle_details.colour}</Text> },
        { key: 'details.customer_details.name', width: defaultWidth, name: 'Name', formatter: ({ row }: { row: HoldingInvoiceRow }) => <Text>{row.details?.customer_details.name}</Text> },
        { key: 'details.customer_details.phone', width: defaultWidth, name: 'Phone', formatter: ({ row }: { row: HoldingInvoiceRow }) => <Text>{row.details?.customer_details.phone}</Text> },
        { key: 'details.customer_details.email', width: defaultWidth, name: 'Email', formatter: ({ row }: { row: HoldingInvoiceRow }) => <Text>{row.details?.customer_details.email}</Text> },
        // Service address
        { key: 'details.address', width: 500, name: 'Address', formatter: ({ row }: { row: HoldingInvoiceRow }) => Formatters.addressFormatter(row.details?.address) },
        // comments
        {
            key: 'details.notes', width: 500, name: 'Notes', formatter: ({ row }: { row: HoldingInvoiceRow }) => <Text>{row.details?.notes?.map((note) => {
                return `${note.note} - ${Utils.formatDate(note.created_time)}`
            })}</Text>
        },
        // location
        { key: 'status', width: defaultWidth, name: 'Status' },
        // times
        { key: 'holding_time', width: defaultWidth, name: 'Held Time', formatter: ({ row }: { row: HoldingInvoiceRow }) => <Text>{row.isSubRow ? "" : Utils.formatDate(row.holding_time as number)}</Text> },
        { key: 'released_time', width: defaultWidth, name: 'Released Time', formatter: ({ row }: { row: HoldingInvoiceRow }) => <Text>{row.isSubRow ? "" : Utils.formatDate(row.released_time as number)}</Text> },
        { key: 'holding_type', width: defaultWidth, name: 'Holding Type', formatter: ({ row }: { row: HoldingInvoiceRow }) => <Text>{row.isSubRow ? "" : row.holding_type}</Text> },
        { key: 'reason', width: defaultWidth, name: 'Reason', formatter: ({ row }: { row: HoldingInvoiceRow }) => <Text>{row.isSubRow ? "" : row.reason}</Text> },
        { key: 'createdAt', width: defaultWidth, name: 'Created At', formatter: ({ row }: { row: HoldingInvoiceRow }) => row.isSubRow ? "" : Utils.formatDate(row.createdAt as string) },
        { key: 'updatedAt', width: defaultWidth, name: 'Updated At', formatter: ({ row }: { row: HoldingInvoiceRow }) => row.isSubRow ? "" : Utils.formatDate(row.updatedAt as string) }
    ]
    const defaultColumnProperties = {
        resizable: true,
        sortable: true,
        filterable: true,
        autoSize: true,
        headerRenderer: ({ column }: { column: any }) => {

            return (
                <View style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                    <TouchableOpacity
                    // onPress={() => handleGridSort(column.key, column.sortDirection === 'ASC' ? 'DESC' : 'ASC')}
                    >
                        <Text>{column.name}</Text>
                    </TouchableOpacity>
                </View>
            );
        },
    };
    const columnsWithProperties = cols?.map((column) => {
        return {
            ...defaultColumnProperties,
            ...column,
        }
    });

    return columnsWithProperties;

}


//Holds no actual data, just the columns definitions
export const JobsColumnsRenderer = ({
    onItemsExpanded,
    itemsExpanded,
    handleGridSort,
    onOpenJobDetailsPanel,
    getMemberName,
    getClientName,
    getClientAccountCode
}: IColumsRendererJobsProps) => {

    const cols = [
        SelectColumn,
        {
            // Button to open job report, only appears if the row is not a subrow
            key: 'expand',
            name: '',
            width: 15,
            frozen: true,
            formatter: ({ row }: { row: JobInvoiceRow }) => {
                if (!row.isSubRow) {
                    return (
                        <Button
                            size='tiny'
                            appearance='outline'
                            status="success"
                            accessoryLeft={EyeIcon}
                            onPress={() => {
                                onOpenJobDetailsPanel(row)
                            }}
                        />
                    );
                } else {
                    return <Text></Text>
                }
            }
        },
        {
            key: 'id',
            name: '#',
            width: defaultWidth,
            frozen: true,
            formatter: ({ row }: { row: JobInvoiceRow }) => {
                if (row.status === "cancelled") {
                    return <Text style={{ backgroundColor: 'purple' }}>{row.id}- CANCELLED</Text>
                }
                else if (row.status == "transferred_out") {
                    return <Text style={{ backgroundColor: 'cyan', color: "black" }}>{row.id}- TRANSFERRED OUT</Text>
                }
                else {
                    return <Text>{row.id}</Text>
                }
            },
            summaryFormatter({ row }: { row: JobInvoiceRow }) {

                return <>{row.totalCount} Records</>;
            }
        },
        {
            key: '_id', name: 'ID', width: 180,
            formatter: ({ row }: { row: JobInvoiceRow }) => {

                return (<Text style={{ backgroundColor: row.status === 'cancelled' ? 'purple' : 'transparent' }}>{row.friendly_id}</Text>)
            }
        },
        // invoice created bool TODO: ADD THIS LATER
        {
            key: 'isInvoiceCreated', name: 'Invoice Created', width: defaultWidth,
            formatter: ({ row }: { row: JobInvoiceRow }) => {
                if (!row.isSubRow) {
                    if (StorageController.getAppState().selectedMembership?.is_client) return <Text>----</Text>
                    if (row.invoice_details) {
                        if (row.invoice_details.submitted) {
                            return <Text style={{ backgroundColor: 'green' }}>Yes</Text>
                        } else {
                            return <Text style={{ backgroundColor: 'red' }}>No</Text>
                        }
                    } else {
                        return <Text style={{ backgroundColor: 'red' }}>No</Text>
                    }
                } else {
                    return <Text>----</Text>
                }
            }
        },
        //Client
        { key: 'client_reference_id', width: defaultWidth, name: 'Client Reference ID' },
        { key: 'client.account_code', width: defaultWidth, name: 'Account Code', formatter: ({ row }: { row: JobInvoiceRow }) => <Text>{getClientAccountCode(row.client_id as string)}</Text> },
        { key: 'client.name', name: 'Client', formatter: ({ row }: { row: JobInvoiceRow }) => <Text>{getClientName(row.client_id as string)}</Text> },
        { key: 'invoice_date', width: defaultWidth, name: 'Invoice Date', formatter: ({ row }: { row: JobInvoiceRow }) => <Text>{moment(row.pending_time || row.start_time).format('DD/MM/YYYY')}</Text> },
        {
            key: 'client_rate_name',
            width: 130,
            name: 'Client Rate',
            formatter: ({ row }: { row: JobInvoiceRow }) => {
                if (row.details?.hasOwnProperty("client_rate")) {
                    return <Text>{row.details.client_rate?.name}</Text>
                }
                else {
                    return <Text></Text>
                }
            }
        },
        {
            key: 'client_rate_amount',
            width: defaultWidth,
            name: 'Client Rate $',
            summaryFormatter: ({ row }: { row: JobInvoiceRow }) => {
                return <Text>{row.clientRateTotal}</Text>;
            },
            formatter: ({ row }: { row: JobInvoiceRow }) => {
                if (row.details?.hasOwnProperty("client_rate")) {
                    return <Text>{row.details.client_rate?.cost}</Text>
                }
                else {
                    return <Text></Text>
                }
            }
        },
        {
            key: 'items',
            name: 'Items',
            formatter: ({ row, isCellSelected }: { row: JobInvoiceRow, isCellSelected: boolean }) => {
                return (
                    <Text>{row.details?.report?.items?.length > 0 ? `${row.details?.report?.items?.length} Items` : ''}</Text>
                );
            },
        },
        // Customer payment
        { key: 'details.report.customerPaidCard', width: defaultWidth, name: 'Card', formatter: ({ row }: { row: JobInvoiceRow }) => <Text>{row.details?.report?.customerPaidCard ? 'Yes' : ''}</Text> },
        { key: 'details.report.customerPaidCash', width: defaultWidth, name: 'Cash', formatter: ({ row }: { row: JobInvoiceRow }) => <Text>{row.details?.report?.customerPaidCash ? 'Yes' : ''}</Text> },
        {
            key: 'details.report.customerPaidCardAmount',
            width: defaultWidth,
            name: 'Card Amount',
            summaryFormatter: ({ row }: { row: JobInvoiceRow }) => {
                return <Text >${row.cardAmount}</Text>;
            },
            formatter: ({ row }: { row: JobInvoiceRow }) => Formatters.paymentFormatter(row.details?.report?.customerPaidCardAmount)
        },
        {
            key: 'details.report.customerPaidCashAmount',
            width: defaultWidth,
            name: 'Cash Amount',
            summaryFormatter: ({ row }: { row: JobInvoiceRow }) => {
                return <Text>${row.cashAmount}</Text>;
            },
            formatter: ({ row }: { row: JobInvoiceRow }) => Formatters.paymentFormatter(row.details?.report?.customerPaidCashAmount)
        },
        {
            key: 'details.report.customerPaidAmount',
            width: defaultWidth,
            name: 'Total Paid',
            summaryFormatter: ({ row }: { row: JobInvoiceRow }) => {
                return <Text style={{ width: "100%", backgroundColor: '#006400' }}>${row.totalPaid}</Text>;
            },
            formatter: ({ row }: { row: JobInvoiceRow }) => Formatters.totalPaymentFormatter(row.details?.report?.customerPaidCardAmount, row.details?.report?.customerPaidCashAmount)
        },
        // Customer details
        { key: 'member.name', width: defaultWidth, name: 'Member', formatter: ({ row }: { row: JobInvoiceRow }) => <Text>{getMemberName(row.member_id as string)}</Text> },
        // { key: "details.vehicle_details.make", width: defaultWidth, name: 'Make', formatter: ({ row }: { row: JobInvoiceRow }) => <Text>{row.details?.vehicle_details.make}</Text>, sortCompare: (a: JobInvoiceRow, b: JobInvoiceRow) => a.details?.vehicle_details.make.localeCompare(b.details?.vehicle_details.make) },
        // { key: 'details.vehicle_details.model', width: defaultWidth, name: 'Model', formatter: ({ row }: { row: JobInvoiceRow }) => <Text>{row.details?.vehicle_details.model}</Text> },
        // { key: 'details.vehicle_details.rego', width: defaultWidth, name: 'Rego', formatter: ({ row }: { row: JobInvoiceRow }) => <Text>{row.details?.vehicle_details.rego}</Text> },
        // { key: 'details.vehicle_details.year', width: defaultWidth, name: 'Year', formatter: ({ row }: { row: JobInvoiceRow }) => <Text>{row.details?.vehicle_details.year}</Text> },
        // { key: 'details.vehicle_details.colour', width: defaultWidth, name: 'Colour', formatter: ({ row }: { row: JobInvoiceRow }) => <Text>{row.details?.vehicle_details.colour}</Text> },
        { key: 'details.customer_details.name', width: defaultWidth, name: 'Name', formatter: ({ row }: { row: JobInvoiceRow }) => <Text>{row.details?.customer_details.name}</Text> },
        { key: 'details.customer_details.phone', width: defaultWidth, name: 'Phone', formatter: ({ row }: { row: JobInvoiceRow }) => <Text>{row.details?.customer_details.phone}</Text> },
        { key: 'details.customer_details.email', width: defaultWidth, name: 'Email', formatter: ({ row }: { row: JobInvoiceRow }) => <Text>{row.details?.customer_details.email}</Text> },
        // Service address
        { key: 'details.address', width: 500, name: 'Address', formatter: ({ row }: { row: JobInvoiceRow }) => Formatters.addressFormatter(row.details?.address) },
        // comments
        { key: 'details.report.comments', width: 500, name: 'Dispatch Comments', formatter: ({ row }: { row: JobInvoiceRow }) => <Text>{row.details?.comments}</Text> },
        { key: 'details.report.driverComments', width: 500, name: 'Driver Comments', formatter: ({ row }: { row: JobInvoiceRow }) => <Text>{row.details?.report?.comments}</Text> },
        // location
        { key: 'status', width: defaultWidth, name: 'Status' },
        { key: 'dispatchable', width: defaultWidth, name: 'Dispatchable' },
        // times
        { key: 'accepted_time', width: defaultWidth, name: 'Accepted', formatter: ({ row }: { row: JobInvoiceRow }) => <Text>{row.isSubRow ? "" : Utils.formatDate(row.accepted_time as number)}</Text> },
        { key: 'arrived_time', width: defaultWidth, name: 'Driver Arrived', formatter: ({ row }: { row: JobInvoiceRow }) => <Text>{row.isSubRow ? "" : Utils.formatDate(row.arrived_time as number)}</Text> },
        { key: 'driver_on_route_time', width: defaultWidth, name: 'Driver On Route', formatter: ({ row }: { row: JobInvoiceRow }) => <Text>{row.isSubRow ? "" : Utils.formatDate(row.driver_on_route_time as number)}</Text> },
        { key: 'pending_time', width: defaultWidth, name: 'Pending Review', formatter: ({ row }: { row: JobInvoiceRow }) => <Text>{row.isSubRow ? "" : Utils.formatDate(row.pending_time as number)}</Text> },
        { key: 'start_time', width: defaultWidth, name: 'Start Time', formatter: ({ row }: { row: JobInvoiceRow }) => <Text>{row.isSubRow ? "" : Utils.formatDate(row.start_time)}</Text> },
        { key: 'createdAt', width: defaultWidth, name: 'Created At', formatter: ({ row }: { row: JobInvoiceRow }) => row.isSubRow ? "" : Utils.formatDate(row.createdAt as string) },
        { key: 'updatedAt', width: defaultWidth, name: 'Updated At', formatter: ({ row }: { row: JobInvoiceRow }) => row.isSubRow ? "" : Utils.formatDate(row.updatedAt as string) }
    ]

    const defaultColumnProperties = {
        resizable: true,
        sortable: true,
        filterable: true,
        autoSize: true,
        headerRenderer: ({ column }: { column: any }) => {
            // if (column.key == "items") {
            //     return (
            //         <View style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
            //             <TouchableOpacity onPress={() => onItemsExpanded()} style={{ flexDirection: "row" }}>
            //                 <Text>Items</Text>
            //                 <Text style={{ color: "green" }}>{itemsExpanded ? '\u25BC' : '\u25B6'}</Text>
            //             </TouchableOpacity>
            //         </View>
            //     );
            // }
            return (
                <View style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                    <TouchableOpacity
                    // onPress={() => handleGridSort(column.key, column.sortDirection === 'ASC' ? 'DESC' : 'ASC')}
                    >
                        <Text>{column.name}</Text>
                    </TouchableOpacity>
                </View>
            );
        },
    };
    const columnsWithProperties = cols?.map((column) => {
        return {
            ...defaultColumnProperties,
            ...column,
        }
    });

    return columnsWithProperties;

    // Add other column definitions here...
};

export const createTowingColumns = (job: Job) => {
    let towing_details = job.details?.towing_details || {} as JobTowingDetails;
    let towingType = [] as Column<JobInvoiceRow>[]
    let pickupDetails = [] as Column<JobInvoiceRow>[]
    let dropoffDetails = [] as Column<JobInvoiceRow>[]
    let otherDetails = [] as Column<JobInvoiceRow>[]

    if (towing_details?.towing_type?.toLowerCase() === "equipment" || towing_details?.towing_type == TOWING_TYPE.EQUIPMENT) {
        towingType = [
            { key: 'towing_type', name: 'Towing Type', width: defaultWidth, formatter: ({ row }) => row.isSubRow ? <Text></Text> : <Text>{row.details.towing_details?.towing_type}</Text> },
            { key: 'equipment', name: 'Equipment', width: defaultWidth, formatter: ({ row }) => row.isSubRow ? <Text></Text> : <Text>{row.details.towing_details?.equipment_details?.equipment}</Text> },
            { key: 'serial', name: 'Serial', width: defaultWidth, formatter: ({ row }) => row.isSubRow ? <Text></Text> : <Text>{row.details.towing_details?.equipment_details?.serial}</Text> },
            { key: 'size', name: 'Size', width: defaultWidth, formatter: ({ row }) => row.isSubRow ? <Text></Text> : <Text>{row.details.towing_details?.equipment_details?.size}</Text> },
            { key: 'type', name: 'Type', width: defaultWidth, formatter: ({ row }) => row.isSubRow ? <Text></Text> : <Text>{row.details.towing_details?.equipment_details?.type}</Text> },
            { key: 'weight', name: 'Weight', width: defaultWidth, formatter: ({ row }) => row.isSubRow ? <Text></Text> : <Text>{row.details.towing_details?.equipment_details?.weight}</Text> },
        ]
    } else if (towing_details?.towing_type?.toLowerCase() === "vehicle" || towing_details?.towing_type == TOWING_TYPE.VEHICLE) {
        towingType = [
            { key: 'towing_type', name: 'Towing Type', width: defaultWidth, formatter: ({ row }) => row.isSubRow ? <Text></Text> : <Text>{row.details.towing_details?.towing_type}</Text> },
            { key: 'make', name: 'Make', width: defaultWidth, formatter: ({ row }) => row.isSubRow ? <Text></Text> : <Text>{row.details.towing_details?.vehicle_details?.make}</Text> },
            { key: 'model', name: 'Model', width: defaultWidth, formatter: ({ row }) => row.isSubRow ? <Text></Text> : <Text>{row.details.towing_details?.vehicle_details?.model}</Text> },
            { key: 'rego', name: 'Rego', width: defaultWidth, formatter: ({ row }) => row.isSubRow ? <Text></Text> : <Text>{row.details.towing_details?.vehicle_details?.rego}</Text> },
            { key: 'year', name: 'Year', width: defaultWidth, formatter: ({ row }) => row.isSubRow ? <Text></Text> : <Text>{row.details.towing_details?.vehicle_details?.year}</Text> },
            { key: 'colour', name: 'Colour', width: defaultWidth, formatter: ({ row }) => row.isSubRow ? <Text></Text> : <Text>{row.details.towing_details?.vehicle_details?.colour}</Text> },
        ]
    }

    pickupDetails = [
        { key: 'pickup_location_type', name: 'Pickup Location Type', width: defaultWidth, formatter: ({ row }) => row.isSubRow ? <Text></Text> : <Text>{row.details.towing_details?.pickup_location_type}</Text> },
        { key: 'pickup_address', name: 'Pickup Address', width: defaultWidth, formatter: ({ row }) => row.isSubRow ? <Text></Text> : <Text>{GoogleController.addressBuilder(row.details.towing_details?.pickup_address || new JobAddress({}))}</Text> }
    ]
    if (towing_details?.pickup_location_type?.toLowerCase() === "address") {
        // get address type, address
        pickupDetails = [
            ...pickupDetails,
            { key: 'pickup_address_type', name: 'Pickup Address Type', width: defaultWidth, formatter: ({ row }) => row.isSubRow ? <Text></Text> : <Text>{row.details.towing_details?.pickup_address_type}</Text> },
        ]
    } else if (towing_details?.pickup_location_type?.toLowerCase() === "holding") {
        // get holding reason
        pickupDetails = [
            ...pickupDetails,
            { key: 'pickup_holding_reason', name: 'Pickup Holding Reason', width: defaultWidth, formatter: ({ row }) => row.isSubRow ? <Text></Text> : <Text>{row.details.towing_details?.pickup_holding_reason}</Text> },
        ]
    }

    dropoffDetails = [
        { key: 'dropoff_location_type', name: 'Dropoff Location Type', width: defaultWidth, formatter: ({ row }) => row.isSubRow ? <Text></Text> : <Text>{row.details.towing_details?.dropoff_location_type}</Text> },
        { key: 'dropoff_address', name: 'Dropoff Address', width: defaultWidth, formatter: ({ row }) => row.isSubRow ? <Text></Text> : <Text>{GoogleController.addressBuilder(row.details.towing_details?.dropoff_address || new JobAddress({}))}</Text> }
    ]
    if (towing_details?.dropoff_location_type?.toLowerCase() === "address") {
        // get address type, address
        dropoffDetails = [
            ...dropoffDetails,
            { key: 'dropoff_address_type', name: 'Dropoff Address Type', width: defaultWidth, formatter: ({ row }) => row.isSubRow ? <Text></Text> : <Text>{row.details.towing_details?.dropoff_address_type}</Text> },
        ]
    } else if (towing_details?.dropoff_location_type?.toLowerCase() === "holding") {
        // get holding reason
        dropoffDetails = [
            ...dropoffDetails,
            { key: 'dropoff_holding_reason', name: 'Dropoff Holding Reason', width: defaultWidth, formatter: ({ row }) => row.isSubRow ? <Text></Text> : <Text>{row.details.towing_details?.dropoff_holding_reason}</Text> },
        ]
    }

    otherDetails = [
        { key: 'distance_kms', name: 'Distance (kms)', width: defaultWidth, formatter: ({ row }) => row.isSubRow ? <Text></Text> : <Text>{row.details.towing_details?.distance_kms}</Text> },
        { key: 'duration_seconds', name: 'Duration (Mins)', width: defaultWidth, formatter: ({ row }) => row.isSubRow ? <Text></Text> : <Text>{row.details.towing_details?.duration_seconds}</Text> },
        { key: 'cost_per_km', name: 'Cost Per Km', width: defaultWidth, formatter: ({ row }) => row.isSubRow ? <Text></Text> : <Text>{row.details.towing_details?.cost_per_km}</Text> },
        { key: 'covered_distance_kms', name: 'Covered Distance (kms)', width: defaultWidth, formatter: ({ row }) => row.isSubRow ? <Text></Text> : <Text>{row.details.towing_details?.covered_distance_kms}</Text> },
        { key: 'total_cost', name: 'Total Cost', width: defaultWidth, formatter: ({ row }) => row.isSubRow ? <Text></Text> : <Text>{row.details.towing_details?.total_cost}</Text> },
        { key: 'pickup_time', name: 'Pickup Time', width: defaultWidth, formatter: ({ row }) => row.isSubRow ? <Text></Text> : <Text>{Utils.formatTimestamp(row.details.towing_details?.pickup_time || 0)}</Text> },
        { key: 'dropoff_time', name: 'Dropoff Time', width: defaultWidth, formatter: ({ row }) => row.isSubRow ? <Text></Text> : <Text>{Utils.formatTimestamp(row.details.towing_details?.dropoff_time || 0)}</Text> },
    ]

    return [
        ...towingType,
        ...pickupDetails,
        ...dropoffDetails,
        ...otherDetails
    ]
}


export const createVehicleColumns = (job: Job) => {
    let vehicle_details = {}
    if (job.details?.vehicle_details) {
        vehicle_details = job.details.vehicle_details;
    }
    return [
        { key: 'make', name: 'Make', width: defaultWidth, formatter: ({ row }: { row: JobInvoiceRow }) => row.isSubRow ? <Text></Text> : <Text>{row.details.vehicle_details?.make}</Text> },
        { key: 'model', name: 'Model', width: defaultWidth, formatter: ({ row }: { row: JobInvoiceRow }) => row.isSubRow ? <Text></Text> : <Text>{row.details.vehicle_details?.model}</Text> },
        { key: 'year', name: 'Year', width: defaultWidth, formatter: ({ row }: { row: JobInvoiceRow }) => row.isSubRow ? <Text></Text> : <Text>{row.details.vehicle_details?.year}</Text> },
        { key: 'color', name: 'Color', width: defaultWidth, formatter: ({ row }: { row: JobInvoiceRow }) => row.isSubRow ? <Text></Text> : <Text>{row.details.vehicle_details?.colour}</Text> },
        { key: 'rego', name: 'Rego', width: defaultWidth, formatter: ({ row }: { row: JobInvoiceRow }) => row.isSubRow ? <Text></Text> : <Text>{row.details.vehicle_details?.rego}</Text> },
    ]
}

export const itemSubrowColumns = [
    { key: 'description', name: 'Item-Description', width: 200, formatter: ({ row }: { row: JobInvoiceRow }) => Formatters.itemRowFormatter(row, row.description) },
    { key: 'cost', width: 100, name: '-Cost', formatter: ({ row }: { row: JobInvoiceRow }) => Formatters.itemRowFormatter(row, row.cost?.toString() || "") },
    { key: 'quantity', width: 100, name: '-Quantity', formatter: ({ row }: { row: JobInvoiceRow }) => Formatters.itemRowFormatter(row, row.quantity?.toString() || "") },
    { key: 'billingType', width: 180, name: 'Item-Billing Type', formatter: ({ row }: { row: JobInvoiceRow }) => row.billAllBack ? Formatters.itemRowFormatter(row, 'Bill All Back') : row.customerCost ? Formatters.itemRowFormatter(row, 'Customer Cost') : '' },
    {
        key: 'customerCost',
        width: 150,
        name: '-Customer Cost',
        summaryFormatter: ({ row }: { row: JobInvoiceRow }) => {
            return (
                <Text>${row.itemCustomerCostTotal}</Text>
            );
        },
        formatter: ({ row }: { row: JobInvoiceRow }) => row.customerCost ? `$${parseFloat(row.cost?.toString()) * parseFloat(row.quantity?.toString() || "")}` : ''
    },
    {
        key: 'billAllBack',
        width: 120,
        name: '-Bill Back',
        summaryFormatter: ({ row }: { row: JobInvoiceRow }) => {
            return (
                <Text>${row.itemBillAllBackTotal}</Text>
            );
        },
        formatter: ({ row }: { row: JobInvoiceRow }) => row.billAllBack ? `$${parseFloat(row.cost?.toString()) * parseFloat(row.quantity?.toString() || "")}` : ''
    },
    {
        key: "total",
        width: 120,
        name: "Item-Total",
        summaryFormatter: ({ row }: { row: JobInvoiceRow }) => {
            return (
                <Text>${row.itemTotal}</Text>
            );
        },
        formatter: ({ row }: { row: JobInvoiceRow }) => Formatters.itemRowFormatter(row, row.total, row.id?.toString().includes("total") ? "rgba(0,0,255,0.2)" : "rgba(255,255,0,0.3)")
    }
];