import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
    filter_row: {
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between',
        padding: 7,
        gap: 2
    },
    filter_row_wrap: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 20,
        flexWrap: 'wrap'
    },
    filter_button_container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 5,
        gap: 2,
        height: "60%",
        flexWrap: 'wrap'
    },
    filter_button_style: {
        flex: 1,
        minWidth: 70,
    },
    view_button_style: {
        flex: 1,
        minWidth: 50,
    },
    content: {
        flex: 18,
        flexDirection: "column",
    },
    main_area: {
        flexDirection: "row",
        height: "100%",
        width: "100%",
        padding: 0,
        margin: 0
    },
    list_section: {
        flex: 2,
        justifyContent: 'center',
        height: "100%",
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
        zIndex: 2
    },
    map_container: {
        flex: 3,
        justifyContent: 'center',
        display: 'flex',
        height: "100%"
    },
    list_detail_container: {
        flex: 4,
        justifyContent: 'center',
        display: 'flex',
        height: "100%"
    },
    job_details_modal_backdrop: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 100
    },
    job_details_modal_container: {
        position: 'absolute',
        top: '0%',
        right: '5%',
        left: '5%',
        bottom: '0%',
        zIndex: 100000
    }
}); 