import React, { useState, useEffect, useReducer, useRef } from 'react';
import { StyleSheet, View, ScrollView, useWindowDimensions } from "react-native";
import {
    Button,
    Icon,
    Layout,
    Card,
    Input,
    Modal,
    Text,
    List,
    ListItem,
    IndexPath,
    Select,
    SelectItem,
    Toggle,
    Radio,
    RadioGroup,
    CheckBox
} from "@ui-kitten/components";

import { LINE_ITEM_BILLED_TO } from '../../models/Job.model';
import { Service, ServiceTagsArr, SERVICE_FIELD_TYPES, SERVICE_FIELD_TYPE } from '../../models/Service';
import DMModal from '../common/Modal';
import { InputTextField } from './fields/InputTextField.component';
import { InputNumberField } from './fields/InputNumberField.component';
import { OptionsField } from './fields/OptionsField.component';
import { NewFieldModal } from './modals/NewFieldModal.component';
import { ImageField } from './fields/ImageField.component';
import { ServiceFieldOtherParams } from '../../models/Service';

interface ServiceEditorProps {
    service: Service,
    canEditField: boolean,
    selectedService: Service,
    unsavedChangesRef: any,
    handleServiceCancel: Function,
    handleServiceSave: Function,
    onSetUnsavedChangesRef: Function,
    capitalizeFirstLetter: Function,
    availableServices: Service[]
}

const ServiceEditor = ({
    service,
    // _unsavedChanges = false,
    canEditField,
    selectedService,
    unsavedChangesRef,
    handleServiceCancel,
    handleServiceSave,
    onSetUnsavedChangesRef,
    capitalizeFirstLetter,
    availableServices = []
}: ServiceEditorProps) => {

    const [serviceState, setServiceState] = useState(service);
    const [selectedField, setSelectedField] = useState(null as null | typeof service.fields[keyof typeof service.fields]);
    const [serviceOptions, setServiceOptions] = useState(service.options);
    const [selectedOption, setSelectedOption] = useState(null);
    const [x, forceUpdate] = useReducer(x => x + 1, 0);
    const [showNewFieldModal, setShowNewFieldModal] = useState(false);


    const [optionEditInputField, setOptionEditInputField] = useState("");
    const [selectedOptionText, setSelectedOptionText] = useState("");

    const [selectedDestination, setSelectedDestination] = useState(null);
    const [selectedDestinationIndex, setSelectedDestinationIndex] = useState(0);
    const [selectedDestinationName, setSelectedDestinationName] = useState("");
    const [selectedDestinationTime, setSelectedDestinationTime] = useState("");
    const [selectedDestinationTypes, setSelectedDestinationTypes] = useState([]); //radio options
    const [selectedDestinationTypeText, setSelectedDestinationTypeText] = useState("");
    const [selectedDestinationTypeIndex, setSelectedDestinationTypeIndex] = useState(0);
    const [selectedDestinationPhotos, setSelectedDestinationPhotos] = useState([]);
    const [selectedDestinationAddress, setSelectedDestinationAddress] = useState("");

    const [unsavedChanges, setUnsavedChanges] = useState(false);

    const { width, height } = useWindowDimensions();

    const [showCopyFieldsModal, setShowCopyFieldsModal] = useState(false);
    const [selectedFieldsToCopy, setSelectedFieldsToCopy] = useState<string[]>([]);
    const [destinationService, setDestinationService] = useState<Service | null>(null);

    const onSetServiceState = (service: Service, changes = true) => {
        const s = new Service(service)
        setUnsavedChanges(changes);
        // unsavedChangesRef.current = changes;
        onSetUnsavedChangesRef(changes);
        setServiceState(s);
    }

    useEffect(() => {
        setServiceState(service);
        setSelectedField(null);
    }, [service])

    // useEffect(() => {
    //     setUnsavedChanges(_unsavedChanges);
    // }, [_unsavedChanges])













    // fields
    const addField = (field: string) => {
        let _service = serviceState;
        _service.addField(field);




        // _service.fields[fieldKey] = val;
        // _service.values[fieldKey] = "";
        onSetServiceState(_service);
        forceUpdate();
    }

    const removeField = (field: string) => {
        let _service = serviceState;
        try {
            _service.removeField(field);
            // delete _service.fields?.[field];
            // delete _service.types?.[field];
            // delete _service.values?.[field];
            // delete _service.options?.[field];
            // delete _service.invoiced?.[field];
            // delete _service.required?.[field];
            // delete _service.driver_editable?.[field];
            // delete _service.other?.[field];
            onSetServiceState(_service);
        } catch (e) {
            console.log("Error removing field: ", e);
        }
    }

    const updateField = (field: string, value: any) => {
        let _service = serviceState;
        _service.fields[field] = value;
        onSetServiceState(_service);
    }

    // types
    const addType = (field: string, type: any) => {
        let _service = serviceState;
        _service.types[field] = type;
        onSetServiceState(_service);
    }

    const removeType = (field: string) => {
        let _service = serviceState;
        delete _service.types[field];
        onSetServiceState(_service);
    }

    const updateType = (field: string, type: any) => {
        let _service = serviceState;
        _service.types[field] = type;
        if (type === SERVICE_FIELD_TYPE.OPTIONS || type === SERVICE_FIELD_TYPE.RADIO) {
            _service.options[field] = [];
        }

        onSetServiceState(_service);
        forceUpdate();
    }

    // values
    const addValue = (field: string, value: any) => {
        let _service = serviceState;
        _service.values[field] = value;
        onSetServiceState(_service);
    }

    const removeValue = (field: string) => {
        let _service = serviceState;
        delete _service.values[field];
        onSetServiceState(_service);
    }

    const updateValue = (field: string, value: any) => {
        let _service = serviceState;
        _service.values[field] = value;
        onSetServiceState(_service);
        forceUpdate();
    }

    // options
    const addOption = (field: string, option: string) => {
        let _service = serviceState;
        if (!_service.options[field]) {
            _service.options[field] = [];
        }
        option = `${option} ${_service.options[field].length}`;
        _service.options[field].push(option);
        //console.log("pushed:", _service.options[field]);
        onSetServiceState(_service);
        forceUpdate();
    }

    const removeOption = (field: string, option: string) => {
        let _service = serviceState;
        _service.options[field] = _service.options[field].filter((o) => o !== option);
        onSetServiceState(_service);
        forceUpdate();
    }

    const updateOption = (field: string, option: string, value: any) => {
        let _service = serviceState;
        //console.log('Updating option', field, option, value);
        // find index of option
        let index = _service.options[field].indexOf(option);
        _service.options[field][index] = value;
        //console.log('update option', _service.options[field][index], _service);
        setSelectedOption(value);
        onSetServiceState(_service);
        // forceUpdate();
    }






    const updateInvoiced = (field: string, value: boolean) => {
        let _service = new Service({ ...serviceState })
        if (!_service.hasOwnProperty("invoiced")) {
            _service.invoiced = {};
            _service.invoiced[field] = value;
        } else {
            _service.invoiced[field] = value;
        }
        onSetServiceState(_service);
        forceUpdate();
    }

    const updateRequired = (field: string, value: boolean) => {
        let _service = new Service({ ...serviceState })
        if (!_service.hasOwnProperty("required")) {
            _service.required = {};
            _service.required[field] = value;
        } else {
            _service.required[field] = value;
        }
        onSetServiceState(_service);
        forceUpdate();
    }

    // driver_editable
    const updateDriverEditable = (field: string, value: boolean) => {
        let _service = new Service({ ...serviceState });
        if (!_service.hasOwnProperty("driver_editable")) {
            _service.driver_editable = {};
            _service.driver_editable[field] = value;
        } else {
            _service.driver_editable[field] = value;
        }
        onSetServiceState(_service);
        forceUpdate();
    }

    const updateServiceTags = (tags: string[]) => {
        let _service = new Service({ ...serviceState })
        _service.tags = tags || [];
        onSetServiceState(_service);
        forceUpdate();
    }


    const handleSave = () => {
        handleServiceSave(serviceState);
        setUnsavedChanges(false);
    }

    const handleCancel = () => {
        handleServiceCancel();
    }

    // const handleDelete = () => {
    //     handleServiceDelete(serviceState);
    // }


    // if selectedService.other[field] does not exist, create it

    const handleFieldSelect = (field: string) => {
        // check has fields
        let _service = new Service({ ...serviceState })
        if (!_service.driver_editable) {
            _service.driver_editable = {};
        }
        if (!_service.invoiced) {
            _service.invoiced = {};
        }
        if (!_service.required) {
            _service.required = {};
        }
        if (!_service.driver_editable[field]) {
            _service.driver_editable[field] = false;
        }
        if (!_service.invoiced[field]) {
            _service.invoiced[field] = false;
        }
        if (!_service.required[field]) {
            _service.required[field] = false;
        }
        const c = unsavedChanges;
        onSetServiceState(_service, c);
        setSelectedField(field);
    }

    const updateFieldExternalParams = (field: string, params: Partial<ServiceFieldOtherParams>) => {
        let _service = new Service({ ...serviceState });
        if (!_service.other[field]) {
            _service.other[field] = {};
        }
        _service.other[field] = {
            ..._service.other[field],
            ...params
        };
        onSetServiceState(_service);
        forceUpdate();
    }

    const handleCopyFields = (fieldsToCopy: string[], destinationService: Service) => {
        try {
            // Create a new service instance for the destination
            const updatedDestination = new Service({ ...destinationService });
            // Copy each selected field and its associated properties
            fieldsToCopy.forEach(fieldName => {
                // Copy basic field properties
                updatedDestination.fields[fieldName] = serviceState.fields[fieldName];

                // Copy field type if it exists
                if (serviceState.types?.[fieldName]) {
                    updatedDestination.types[fieldName] = serviceState.types[fieldName];
                }

                // Copy options array if it exists and is not empty
                if (serviceState.options?.[fieldName]?.length > 0) {
                    updatedDestination.options[fieldName] = [...serviceState.options[fieldName]];
                }

                // Copy boolean flags
                ['required', 'driver_editable', 'invoiced'].forEach(prop => {
                    // @ts-ignore
                    if (serviceState[prop]?.[fieldName] !== undefined) {
                        // @ts-ignore
                        if (!updatedDestination[prop]) {
                            // @ts-ignore
                            updatedDestination[prop] = {};
                        }
                        // @ts-ignore
                        updatedDestination[prop][fieldName] = serviceState[prop][fieldName];
                    }
                });

                // Copy other parameters if they exist
                if (serviceState.other?.[fieldName]) {
                    if (!updatedDestination.other) {
                        updatedDestination.other = {};
                    }
                    updatedDestination.other[fieldName] = { ...serviceState.other[fieldName] };
                }
            });

            // Save the updated destination service
            handleServiceSave(updatedDestination);

            // Show success message or feedback
            console.log(`Successfully copied ${fieldsToCopy.length} fields to ${destinationService.name}`);
        } catch (error) {
            console.error('Error copying fields:', error);
            // Handle error - maybe show an error message to the user
        }
    };

    return (
        <Layout style={{ flex: 1, flexDirection: 'column', height: height * 0.9 }}>
            <NewFieldModal
                onClose={() => setShowNewFieldModal(false)}
                addField={addField}
                showNewFieldModal={showNewFieldModal} />
            <Layout style={{ flex: 1, flexDirection: 'row' }}>
                <View style={{ flex: 1, flexDirection: 'row' }}></View>
                <View style={{ flex: 2, justifyContent: 'center', alignContent: 'center' }}>
                    {
                        unsavedChanges ? <Text category='h6' style={{ color: 'red' }}>Unsaved Changes</Text> : <Text></Text>
                    }
                </View>
                <Layout style={{ flex: 1 }}>
                    <Button
                        disabled={!canEditField}
                        onPress={handleSave}
                        accessoryLeft={(props: any) => <Icon {...props} name="save-outline" />}
                        status="success"
                    >
                        Save
                    </Button>
                </Layout>
                <Layout style={{ flex: 1 }}>
                    <Button
                        disabled={!canEditField}
                        onPress={handleCancel}
                        accessoryLeft={(props: any) => <Icon {...props} name="close-outline" />}
                        status="warning"
                    >
                        Cancel
                    </Button>
                </Layout>
            </Layout>
            <Layout style={{ flex: 15, flexDirection: 'column' }}>
                <Layout style={{ flex: 2, flexDirection: 'row', border: '1px solid', gap: 10 }}>
                    <Layout style={{ flex: 1, flexDirection: 'column' }}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
                            <Text category="h5">Service : {serviceState.name}</Text>
                            <Text category="p1">Service ID: {serviceState._id || ""}</Text>
                        </View>
                        <InputTextField
                            field="name"
                            disabled={!canEditField}
                            label="Name"
                            value={serviceState.name}
                            onChange={updateField}
                        />
                        <Layout style={{ flex: 2 }}>
                            <InputTextField
                                field="description"
                                disabled={!canEditField}
                                label="Description"
                                value={serviceState.description}
                                onChange={updateField}
                            />
                        </Layout>
                    </Layout>
                    <Layout style={{ flex: 1 }}>
                        <Text category='h6'>Tags</Text>
                        <Text>Select the categories this service is applicable to. This assists in showing the clients what services you have at a glance.</Text>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                            {ServiceTagsArr.map((tag, index) => (
                                <Button
                                    size='small'
                                    key={index}
                                    disabled={!canEditField}
                                    onPress={() => {
                                        let tags = serviceState.tags || [];
                                        if (tags.includes(tag)) {
                                            tags = tags.filter((t) => t !== tag);
                                        } else {
                                            tags.push(tag);
                                        }
                                        updateServiceTags(tags);
                                    }}
                                    appearance={serviceState.tags?.includes(tag) ? 'filled' : 'outline'}
                                >
                                    {tag}
                                </Button>

                            ))}
                        </View>
                    </Layout>
                </Layout>
                <Layout style={{ flex: 5, flexDirection: 'row' }}>
                    <Card
                        style={{ flex: 1 }}
                        header={() => <Text category='s1'>Fields</Text>}
                        disabled
                        status="warning"
                    >
                        <Layout style={{ flex: 1, flexDirection: 'column', margin: -15, marginLeft: -20, marginRight: -20, gap: 5 }}>
                            <View style={{ flexDirection: 'row', gap: 5, justifyContent: 'space-between' }}>
                                <Button
                                    style={{ flex: 1 }}
                                    disabled={!canEditField}
                                    onPress={() => {
                                        // addField('newField');
                                        setShowNewFieldModal(true);
                                    }}
                                    accessoryLeft={(props: any) => <Icon {...props} name="plus-outline" />}
                                    status="success"
                                />
                                <Button
                                    style={{ flex: 1 }}
                                    disabled={!canEditField}
                                    onPress={() => {
                                        removeField(selectedField as string);
                                        setSelectedField(null);
                                    }}
                                    accessoryLeft={(props: any) => <Icon {...props} name="minus-outline" />}
                                    status="danger"
                                />
                                <Button
                                    style={{ flex: 1 }}
                                    disabled={!canEditField}
                                    onPress={() => setShowCopyFieldsModal(true)}
                                    accessoryLeft={(props: any) => <Icon {...props} name="copy-outline" />}
                                    status="info"
                                />
                            </View>
                            <List
                                data={Object.keys(serviceState.fields).sort((a, b) =>
                                    serviceState.fields[a].localeCompare(serviceState.fields[b])
                                )}
                                renderItem={({ item }: { item: string }) => (
                                    <ListItem
                                        title={serviceState.fields[item]}
                                        style={{
                                            backgroundColor: selectedField === item ? 'green' : '',
                                            border: '1px solid black'
                                        }}
                                        onPress={() => {
                                            handleFieldSelect(item);
                                            console.log('selected field', height);
                                        }}
                                    />
                                )}
                            />
                        </Layout>
                    </Card>
                    <Layout style={{ flex: 3 }}>
                        <Card
                            style={{ flex: 1 }}
                            disabled
                            header={() => <Text category='s1'>Field Details</Text>}
                            status="primary"
                        >
                            {selectedField &&
                                <ScrollView style={{ height: height * 0.6 }}>
                                    <Layout style={{ flexDirection: 'row', gap: 10 }}>
                                        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', gap: 3 }}>
                                            <View style={{ flex: 1 }}>
                                                <InputTextField
                                                    field={selectedField}
                                                    label="Field Name"
                                                    value={serviceState.fields[selectedField]}
                                                    onChange={(field: string, value: string) => {
                                                        let _service = serviceState;
                                                        _service.fields[field] = value;
                                                        onSetServiceState(_service);
                                                        forceUpdate();
                                                    }}
                                                    disabled={!canEditField}
                                                />
                                            </View>
                                            <View style={{ flex: 1 }}>
                                                <Select
                                                    disabled={!canEditField}
                                                    label="Field Type"
                                                    value={serviceState.types[selectedField]}
                                                    onSelect={(index: any) => {
                                                        let value = Object.keys(SERVICE_FIELD_TYPES)[index.row];
                                                        updateType(selectedField, value);
                                                    }}
                                                >
                                                    {Object.keys(SERVICE_FIELD_TYPES).map((type, index) => (
                                                        <SelectItem key={index} title={type} />
                                                    ))}
                                                </Select>
                                            </View>
                                        </View>
                                        <View style={{ flex: 1, flexDirection: 'row', gap: 3 }}>
                                            <View>
                                                <View style={{ flex: 1 }}>
                                                    <Toggle
                                                        disabled={!canEditField}
                                                        checked={serviceState.invoiced?.[selectedField] || false}
                                                        onChange={(checked: boolean) => {
                                                            updateInvoiced(selectedField, checked);
                                                        }}
                                                    >
                                                        Invoiced
                                                    </Toggle>
                                                </View>
                                                <View style={{ flex: 1 }}>
                                                    <Toggle
                                                        disabled={!canEditField}
                                                        checked={serviceState.required?.[selectedField] || false}
                                                        onChange={(checked: boolean) => {
                                                            updateRequired(selectedField, checked);
                                                        }}
                                                    >
                                                        Required
                                                    </Toggle>
                                                </View>
                                            </View>
                                            <View style={{ flex: 1 }}>
                                                <Toggle
                                                    disabled={!canEditField}
                                                    checked={serviceState.driver_editable?.[selectedField] || false}
                                                    onChange={(checked: boolean) => {
                                                        updateDriverEditable(selectedField, checked);
                                                    }}
                                                >
                                                    Driver Editable
                                                </Toggle>
                                            </View>
                                        </View>
                                    </Layout>
                                    <Layout style={{ flex: 3, flexDirection: 'row' }}>
                                        <Layout style={{ flex: 2, flexDirection: 'row' }}>
                                            {/* Value */}
                                            {(serviceState.types[selectedField] === SERVICE_FIELD_TYPE.INPUT ||
                                                serviceState.types[selectedField] === SERVICE_FIELD_TYPE.DATE ||
                                                serviceState.types[selectedField] === SERVICE_FIELD_TYPE.TIME) &&
                                                <Layout style={{ flex: 1 }}>
                                                    <InputTextField
                                                        field={selectedField}
                                                        value={serviceState.values[selectedField] as string}
                                                        onChange={updateValue}
                                                        label={"Default Value"}
                                                        disabled={!canEditField}
                                                    />
                                                </Layout>
                                            }
                                            {/* is cost/rebate/commission */}
                                            {(serviceState.types[selectedField] === SERVICE_FIELD_TYPE.COST ||
                                                serviceState.types[selectedField] === SERVICE_FIELD_TYPE.DRIVER_REBATE ||
                                                serviceState.types[selectedField] === SERVICE_FIELD_TYPE.DRIVER_COMMISSION) &&
                                                <Layout style={{ flex: 1 }}>
                                                    <InputNumberField
                                                        field={selectedField}
                                                        value={serviceState.values[selectedField] as string}
                                                        onChange={updateValue}
                                                        label={"Default $ value"}
                                                        disabled={!canEditField}
                                                    />
                                                </Layout>
                                            }
                                            {serviceState.types[selectedField] === SERVICE_FIELD_TYPE.QUANTITY &&
                                                <Layout style={{ flex: 1 }}>
                                                    <InputNumberField
                                                        field={selectedField}
                                                        value={serviceState.values[selectedField] as string}
                                                        onChange={updateValue}
                                                        label={"Default quantity"}
                                                        disabled={!canEditField}
                                                    />
                                                </Layout>
                                            }
                                            {/* Options */}
                                            {(serviceState.types[selectedField] === SERVICE_FIELD_TYPE.OPTIONS ||
                                                serviceState.types[selectedField] === SERVICE_FIELD_TYPE.RADIO) &&
                                                <OptionsField
                                                    field={selectedField}
                                                    options={serviceState.options[selectedField]}
                                                    label={"Options"}
                                                    disabled={!canEditField}
                                                    onAddOption={addOption}
                                                    onRemoveOption={removeOption}
                                                    onUpdateOption={updateOption}
                                                />
                                            }
                                        </Layout>
                                    </Layout>
                                    {selectedField && (
                                        <View style={{ flex: 1, marginTop: 10 }}>
                                            <Text category='s1'>External Parameters</Text>
                                            <View style={{ flexDirection: 'row', gap: 10 }}>
                                                <View style={{ flex: 1 }}>
                                                    <InputTextField
                                                        field="external_id"
                                                        label="External ID"
                                                        value={serviceState.other?.[selectedField]?.external_id || ''}
                                                        onChange={(field: string, value: string) => {
                                                            updateFieldExternalParams(selectedField, { external_id: value });
                                                        }}
                                                        disabled={!canEditField}
                                                    />
                                                </View>
                                                <View style={{ flex: 1 }}>
                                                    <InputTextField
                                                        field="external_system"
                                                        label="External System"
                                                        value={serviceState.other?.[selectedField]?.external_system || ''}
                                                        onChange={(field: string, value: string) => {
                                                            updateFieldExternalParams(selectedField, { external_system: value });
                                                        }}
                                                        disabled={!canEditField}
                                                    />
                                                </View>
                                            </View>
                                            <View style={{ flex: 1 }}>
                                                <InputTextField
                                                    field="external_description"
                                                    label="External Description"
                                                    value={serviceState.other?.[selectedField]?.external_description || ''}
                                                    onChange={(field: string, value: string) => {
                                                        updateFieldExternalParams(selectedField, { external_description: value });
                                                    }}
                                                    disabled={!canEditField}
                                                />
                                            </View>
                                        </View>
                                    )}
                                </ScrollView>
                            }
                        </Card>
                    </Layout>
                    {selectedService &&
                        <Layout style={{ flex: 2, border: '1px solid' }}>
                            <Layout style={{ flex: 1 }}>
                                <Text category='h6'>Preview</Text>
                            </Layout>
                            <ScrollView style={{ flex: 15 }}>
                                <Card disabled>
                                    <Text category='h5'>{selectedService?.name}</Text>
                                    {Object.keys(selectedService?.fields)
                                        .sort((a, b) =>
                                            selectedService.fields[a].localeCompare(selectedService.fields[b])
                                        )
                                        .map((field, index) => (
                                            <Layout key={index} style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", margin: 5, height: selectedService?.fields.length || 1 * 60 }}>
                                                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', gap: 4 }}>
                                                    <Text category="s1">{selectedService?.fields[field]}</Text>
                                                    {selectedService?.required?.[field] && (
                                                        <Text category="s1" style={{ color: 'red' }}>*</Text>
                                                    )}
                                                    {selectedService?.driver_editable?.[field] && (
                                                        <Icon
                                                            name='car-outline'
                                                            width={16}
                                                            height={16}
                                                            fill='#228B22'
                                                            style={{ marginTop: 2 }}
                                                        />
                                                    )}
                                                    {selectedService?.invoiced?.[field] && (
                                                        <Icon
                                                            name='attach-outline'
                                                            width={16}
                                                            height={16}
                                                            fill='#4169E1'
                                                            style={{ marginTop: 2 }}
                                                        />
                                                    )}
                                                </View>
                                                {(selectedService?.types?.[field] === SERVICE_FIELD_TYPE.INPUT ||
                                                    selectedService?.types?.[field] === SERVICE_FIELD_TYPE.COST ||
                                                    selectedService?.types?.[field] === SERVICE_FIELD_TYPE.ADDRESS ||
                                                    selectedService?.types?.[field] === SERVICE_FIELD_TYPE.DRIVER_REBATE ||
                                                    selectedService?.types?.[field] === SERVICE_FIELD_TYPE.DRIVER_COMMISSION ||
                                                    selectedService?.types?.[field] === SERVICE_FIELD_TYPE.QUANTITY) &&
                                                    <Input
                                                        value={selectedService?.values[field] as string || ""}
                                                    />}
                                                {selectedService?.types?.[field] === SERVICE_FIELD_TYPE.OPTIONS &&
                                                    selectedService?.options[field] &&
                                                    <Select
                                                        //@ts-ignore
                                                        value={selectedService?.values?.[field] || ""}
                                                    //@ts-ignore
                                                    // selectedIndex={new IndexPath(selectedService?.values?.[field] as number)}
                                                    >
                                                        {selectedService?.options[field].map((option, index) => (
                                                            <SelectItem
                                                                key={index}
                                                                title={option}
                                                                //@ts-ignore
                                                                value={option}
                                                            />
                                                        ))}
                                                    </Select>}
                                                {selectedService?.types?.[field] === SERVICE_FIELD_TYPE.TOGGLE && <Toggle
                                                    checked={selectedService?.values[field] as boolean}
                                                />}
                                                {selectedService?.types?.[field] === SERVICE_FIELD_TYPE.RADIO &&
                                                    <RadioGroup
                                                        //@ts-ignore
                                                        selectedIndex={new IndexPath(selectedService?.values?.[field] as number)}
                                                    >
                                                        {selectedService?.options &&
                                                            <>
                                                                {selectedService?.options?.[field]?.map((option, index) => {
                                                                    return (<Radio key={index}>{option}</Radio>)
                                                                })}
                                                            </>
                                                        }
                                                    </RadioGroup>
                                                }
                                                {selectedService?.types?.[field] === SERVICE_FIELD_TYPE.IMAGE &&
                                                    <ImageField
                                                        field={field}
                                                        value={selectedService?.values[field] as string}
                                                        label="Image Upload"
                                                        disabled={!canEditField}
                                                    />
                                                }

                                            </Layout>
                                        ))}
                                </Card>
                                {Object.keys(selectedService?.fields)
                                    .sort((a, b) =>
                                        selectedService.fields[a].localeCompare(selectedService.fields[b])
                                    )
                                    .map((field, index) => (
                                        <View key={index} style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                            {selectedService?.other?.[field]?.external_description && (
                                                <View style={{
                                                    // backgroundColor: '#f5f5f5',
                                                    padding: 4,
                                                    borderRadius: 4,
                                                    marginTop: 2
                                                }}>
                                                    <Text category='s1'>{field} - {selectedService.other[field].external_id}</Text>
                                                    <Text
                                                        category="c1"
                                                        style={{
                                                            fontSize: 10,
                                                            color: 'grey',
                                                            fontStyle: 'italic'
                                                        }}
                                                    >
                                                        {selectedService.other[field].external_system && (
                                                            <Text style={{ fontWeight: 'bold' }}>
                                                                {selectedService.other[field].external_system}:
                                                            </Text>
                                                        )} {selectedService.other[field].external_description}
                                                    </Text>
                                                </View>
                                            )}
                                        </View>
                                    ))}
                            </ScrollView>
                        </Layout>
                    }
                </Layout>
            </Layout>
            <CopyFieldsModal
                visible={showCopyFieldsModal}
                onClose={() => setShowCopyFieldsModal(false)}
                sourceService={serviceState}
                onCopyFields={handleCopyFields}
                availableServices={availableServices}
            />
        </Layout>
    );
}

interface CopyFieldsModalProps {
    visible: boolean;
    onClose: () => void;
    sourceService: Service;
    onCopyFields: (fieldsToCopy: string[], destinationService: Service) => void;
    availableServices: Service[];
}

const CopyFieldsModal = ({
    visible,
    onClose,
    sourceService,
    onCopyFields,
    availableServices
}: CopyFieldsModalProps) => {
    const [selectedFields, setSelectedFields] = useState<string[]>([]);
    const [selectedDestination, setSelectedDestination] = useState<Service | null>(null);

    // Reset state when modal closes
    useEffect(() => {
        if (!visible) {
            setSelectedFields([]);
            setSelectedDestination(null);
        }
    }, [visible]);

    const handleConfirm = () => {
        if (selectedDestination && selectedFields.length > 0) {
            onCopyFields(selectedFields, selectedDestination);
            onClose();
        }
    };

    return (
        <Modal
            visible={visible}
            onBackdropPress={onClose}
            style={{ width: '80%', maxHeight: '80%' }}
        >
            <Card disabled>
                <Text category='h6'>Copy Fields to Another Service</Text>

                <View style={{ marginVertical: 10 }}>
                    <Text category='s1'>Select Destination Service:</Text>
                    <Select
                        value={selectedDestination?.name || 'Select a service'}
                        onSelect={(index: any) => {
                            const service = availableServices.filter(s => s._id !== sourceService._id)[index.row];
                            setSelectedDestination(service);
                        }}
                    >
                        {availableServices
                            .filter(s => s._id !== sourceService._id)
                            .map((service, index) => (
                                <SelectItem key={service._id || index} title={service.name} />
                            ))}
                    </Select>
                </View>

                <ScrollView style={{ maxHeight: 300 }}>
                    <Text category='s1'>Select Fields to Copy:</Text>
                    {Object.keys(sourceService.fields)
                        .sort((a, b) =>
                            sourceService.fields[a].localeCompare(sourceService.fields[b])
                        )
                        .map((fieldName) => (
                            <CheckBox
                                key={fieldName}
                                checked={selectedFields.includes(fieldName)}
                                onChange={(checked) => {
                                    setSelectedFields(prev =>
                                        checked
                                            ? [...prev, fieldName]
                                            : prev.filter(f => f !== fieldName)
                                    );
                                }}
                            >
                                {sourceService.fields[fieldName]}
                            </CheckBox>
                        ))}
                </ScrollView>

                <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: 20, gap: 10 }}>
                    <Button status='basic' onPress={onClose}>
                        Cancel
                    </Button>
                    <Button
                        status='primary'
                        onPress={handleConfirm}
                        disabled={!selectedDestination || selectedFields.length === 0}
                    >
                        Copy Fields
                    </Button>
                </View>
            </Card>
        </Modal>
    );
};

export default ServiceEditor;