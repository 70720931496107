import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
    StyleSheet,
    View,
    useWindowDimensions,
    Modal, // Import React Native Modal
    TouchableWithoutFeedback,
    TextInputEndEditingEventData,
    FlatList,
    //@ts-ignore
} from "react-native";
import {
    Card,
    Button,
    Icon,
    Input,
    List,
    ListItem,
    Divider,
    Text,
    Layout,
    OverflowMenu,
    useTheme,
    IndexPath,
    MenuItem,
} from "@ui-kitten/components";
import * as StorageController from '../../../../functions/storageController';
import * as CompanyController from '../../../../functions/company.controller';
import ErrorBoundary from '../../../ErrorBoundary.component';
import Toast from 'react-native-toast-message';
import { Job, LINE_ITEM_BILLED_TO } from '../../../../models/Job.model';
import { LineItem } from '../../../../models/LineItem.model';
import { InventoryItem, InventoryCategory, ExtendedInventoryCategory, ExtendedInventoryItem } from '../../../../models/Inventory.model';
import { Company } from '../../../../models/Company.model';
import { VendorProfile } from '../../../../models/VendorProfile.model';
//@ts-ignore
import { ListItemProps } from '@ui-kitten/components';
import * as InventoryController from '../../../../functions/Inventory.controller';
import { LineItemComponent } from './LineItem.component'; // Added import
import InventoryItemDetail from '../../../inventory/InventoryItemDetail.component';
import { ScrollView } from 'react-native-gesture-handler';
import { NativeSyntheticEvent } from 'react-native';
import { Client } from '../../../../models/Client.model';
import { PricingTier } from '../../../../models/Inventory.model';
import DMModal from '../../../common/Modal';
import { TextDetailPill } from '../../../common/TextDetailPill';
import { RenderInventoryItem } from './RenderInventoryItem.component';
import { InventoryListModal } from './InventoryListModal.component';
import { LineItemsList } from './LineItemsList.component';
import { AddItemForm } from './AddItemForm.component';
import { InventoryStock } from '../../../../models/Inventory.model';

interface InventoryDetailsCardProps {
    style?: any;
    selectedItems: LineItem[];
    onChangeSelectedItems: (items: LineItem[]) => void;
    vendorProfile: VendorProfile | null | undefined;
    jobSource: string;
    allowEdit?: boolean;
    selectedClient?: Client | null;
    showInventoryList?: boolean;
}

interface ItemStockMap {
    [key: string]: number;
}

export const InventoryDetailsCard = ({
    style,
    selectedItems = [],
    onChangeSelectedItems = (items) => { },
    vendorProfile,
    jobSource,
    allowEdit = true,
    selectedClient,
    showInventoryList = false
}: InventoryDetailsCardProps) => {

    const theme = useTheme();
    const { width: windowWidth } = useWindowDimensions();
    const [companyInventoryList, setCompanyInventoryList] = useState<ExtendedInventoryCategory[]>([]);
    const [selectedLineItems, setSelectedLineItems] = useState<LineItem[]>(selectedItems);
    const [totalSelectedInventoryCost, setTotalSelectedInventoryCost] = useState<string>('0.00');
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
    const [newLineItem, setNewLineItem] = useState<LineItem | null>(null); // New state for item name
    const [filteredItems, setFilteredItems] = useState<InventoryItem[]>([]); // New state for filtered items
    const [menuSearchQuery, setMenuSearchQuery] = useState<string>('');
    const [menuVisible, setMenuVisible] = useState(false);
    const [isInventoryListVisible, setIsInventoryListVisible] = useState(showInventoryList);
    const [quantityError, setQuantityError] = useState('');
    const [priceError, setPriceError] = useState('');
    const [itemStockLevels, setItemStockLevels] = useState<ItemStockMap>({});

    const companyInventoryListRef = useRef<ExtendedInventoryCategory[]>(companyInventoryList);

    const onSetCompanyInventoryList = (list: ExtendedInventoryCategory[]) => {
        companyInventoryListRef.current = list;
        setCompanyInventoryList(list);
    }

    const IS_CLIENT = StorageController.getAppState().selectedMembership?.is_client

    useEffect(() => {
        loadVendorInventory()
    }, [])

    const IS_SMALL_SCREEN = windowWidth < 768;



    useEffect(() => {
        // Added here because broke on holding item removing?
        setSelectedLineItems(selectedItems)
    }, [selectedItems])


    /**
    * Update the selected inventory list when the selected inventory items change
    */
    useEffect(() => {
        if (selectedItems != null && selectedItems.length > 0) {

            let total = 0;
            selectedItems.forEach(item => {
                if (!item.quantity) item.quantity = 1;
                if (!item.cost) item.cost = 0;
                total += item.cost * item.quantity;
            });
            setSelectedLineItems(selectedItems)
            setTotalSelectedInventoryCost(total.toFixed(2));
        }
    }, [selectedItems]);


    const onSetSelectedItems = (items: LineItem[]) => {
        // console.log("🚀============== ~ file: inventoryDetailsCard.component.tsx:122 ~ onSetSelectedItems ~ items🚀==============", items)
        onChangeSelectedItems(items);
    };

    const loadVendorInventory = async () => {
        if (jobSource === "client" && vendorProfile && vendorProfile.linkedVendorAccountId) {
            const linkedCompany = await CompanyController.getCompanyById(vendorProfile.linkedVendorAccountId) as Company;
            if (linkedCompany?.inventory?.length > 0) {
                const inv = await InventoryController.getInventoryCategoriesAndItemsByCompanyId(linkedCompany._id)
                onSetCompanyInventoryList(inv || [])
                await loadInventoryStock();
            }
        } else if (jobSource === "vendor" || jobSource === "local_client") {
            const company = StorageController.getCurrentCompany()
            const inv = await InventoryController.getInventoryCategoriesAndItemsByCompanyId(company._id)
            onSetCompanyInventoryList(inv || [])
            await loadInventoryStock();
        } else {
            onSetCompanyInventoryList([]);
        }
    };

    const loadInventoryStock = async () => {
        let stockLevels: ItemStockMap = {};
        let nonZeroStockItems = [] as any[];
        try {
            // Get all stock for each category at once
            for (const category of companyInventoryListRef.current) {
                if (category._id) {
                    const stock = await InventoryController.getInventoryStockByCategoryId(category._id);

                    // Process stock data for each item in the category
                    if (stock && Array.isArray(stock)) {
                        stock.forEach((stockItem) => {
                            let itemId = ""
                            if (typeof stockItem.inventory_item_id === 'string') {
                                itemId = stockItem.inventory_item_id;
                            } else {
                                //@ts-ignore
                                itemId = stockItem.inventory_item_id._id;
                            }
                            if (!stockLevels[itemId]) {
                                stockLevels[itemId] = 0;
                            }
                            stockLevels[itemId] += stockItem.quantity || 0;
                            if (stockItem.quantity && stockItem.quantity > 0) {
                                nonZeroStockItems.push(itemId);
                            }
                        });
                    }
                }
            }
            setItemStockLevels(stockLevels);
        } catch (error) {
            console.error('Error loading inventory stock:', error);
            Toast.show({
                type: 'error',
                text1: 'Error loading stock levels',
                position: 'bottom',
            });
        }
    };

    const memoBillBackItems = useMemo(() => {
        return selectedLineItems.filter(item => item.billed_to == LINE_ITEM_BILLED_TO.BILL_ALL_BACK)
    }, [selectedLineItems])

    const memoCustomerCostItems = useMemo(() => {
        return selectedLineItems.filter(item => item.billed_to == LINE_ITEM_BILLED_TO.CUSTOMER_COST)
    }, [selectedLineItems])

    const memoBillBackItemsTotal = useMemo(() => {
        let total = 0;
        memoBillBackItems.forEach(item => {
            total += item.cost * item.quantity;
        });
        return total.toFixed(2);
    }, [memoBillBackItems])

    const memoCustomerCostItemsTotal = useMemo(() => {
        let total = 0;
        memoCustomerCostItems.forEach(item => {
            total += item.cost * item.quantity;
        });
        return total.toFixed(2);
    }, [memoCustomerCostItems])


    const calculateItemTierPrice = (item: InventoryItem, category: ExtendedInventoryCategory): number | null => {
        if (!selectedClient) return null;

        const clientTier = category.pricing_tiers?.find(tier =>
            tier.client_ids.includes(selectedClient._id as string)
        );

        if (clientTier) {
            if (clientTier.item_prices?.[item._id as string]) {
                return clientTier.item_prices[item._id as string];
            } else if (clientTier.multiplier) {
                return item.default_price * clientTier.multiplier;
            } else if (clientTier.fixed_price) {
                return clientTier.fixed_price;
            }
        }
        return null;
    };


    const onAddItemToSelectedItems = async (item: InventoryItem) => {
        try {
            const category = companyInventoryList.find(c =>
                c.inventoryItems.some(i => i._id === item._id)
            );

            // Get pricing tier and price
            const clientTier = category?.pricing_tiers?.find(tier =>
                tier.client_ids.includes(selectedClient?._id as string)
            );
            const tierPrice = category ? calculateItemTierPrice(item, category) : null;

            const lineItem = new LineItem({
                name: item.name,
                description: item.description || "",
                quantity: 1,
                cost: tierPrice !== null ? tierPrice : item.default_price,
                billed_to: LINE_ITEM_BILLED_TO.BILL_ALL_BACK,
                inventoryItemId: item._id,
                // inventoryStockId: item.inventoryStockId, // TODO: add this when supported
                // customPricingId: item.customPricingId, // TODO: add this when supported
                // Use the tier's GST setting if available, otherwise default to true
                is_gst_inclusive: clientTier ? clientTier.is_gst_inclusive : StorageController.getCurrentCompany()?.settings?.business_info?.gst_registered || true,
            });

            let newSelectedItems = [...selectedItems, lineItem];
            onSetSelectedItems(newSelectedItems);
            Toast.show({
                type: 'success',
                position: 'bottom',
                text1: 'Item Added',
                text2: `${item.name} added to selected items`,
                visibilityTime: 1000,
                autoHide: true,
            });
        } catch (error) {
            console.error("Failed to add item:", error);
            Toast.show({
                type: 'error',
                position: 'bottom',
                text1: 'Error',
                text2: 'Failed to add item to selected items',
                visibilityTime: 1000,
                autoHide: true,
            });
        }
    }

    const flattenedInventoryList = useMemo<ExtendedInventoryItem[]>(() => {
        return companyInventoryList.flatMap(category =>
            category.inventoryItems.map(item => (new ExtendedInventoryItem({
                ...item,
                category_id: category._id,
                categoryName: category.name
            })))
        );
    }, [companyInventoryList]);

    const filteredInventoryList = useMemo(() => {
        if (!searchQuery) return flattenedInventoryList;
        return flattenedInventoryList.filter(item =>
            item.name.toLowerCase().includes(searchQuery.toLowerCase())
        ).map(item => ({
            ...item,
            stockLevel: itemStockLevels[item._id as string] || 0
        }));
    }, [searchQuery, flattenedInventoryList, itemStockLevels]);

    // New useEffect to filter inventory based on newItemName
    useEffect(() => {
        if (newLineItem?.name) {
            const matches = flattenedInventoryList.filter((item) => {
                return item.name.toLowerCase().includes(newLineItem.name.toLowerCase());
            });
            console.log("🚀============== ~ file: inventoryDetailsCard.component.tsx:191 ~ useEffect ~ matches🚀==============", matches)
            setFilteredItems(matches);
        } else {
            setFilteredItems([]);
        }
    }, [newLineItem, flattenedInventoryList]);


    const filteredMenuItems = useMemo(() => {
        if (!menuSearchQuery) return [];
        setMenuVisible(true)
        return flattenedInventoryList.filter(item =>
            item.name.toLowerCase().includes(menuSearchQuery.toLowerCase())
        );
    }, [menuSearchQuery, flattenedInventoryList]);

    const renderMenuToggleButton = () => (
        <Button onPress={() => setMenuVisible(true)}>
            Search Items
        </Button>
    );

    const onMenuItemSelect = (index: IndexPath) => {
        const selectedItem = filteredMenuItems[index.row];
        if (selectedItem) {
            const lineItem = selectedItem.toLineItem();
            setNewLineItem(lineItem);
            setCustomItemCost(lineItem.cost.toString());
            setCustomItemQuantity(lineItem.quantity.toString());
            // setNewLineItem(new LineItem({
            //     name: selectedItem.name,
            //     description: selectedItem.description,
            //     quantity: 1,
            //     cost: selectedItem.default_price,
            //     billed_to: LINE_ITEM_BILLED_TO.BILL_ALL_BACK,
            //     inventoryItemId: selectedItem._id,
            //     // inventoryStockId: selectedItem.inventoryStockId, TODO:add these when supported
            //     // customPricingId: selectedItem.custom_pricing_id,
            // }));
        }
        setMenuVisible(false);
    };

    const validateQuantity = (value: string) => {
        if (value === '') {
            setQuantityError('');
        } else if (isNaN(Number(value))) {
            setQuantityError('Invalid quantity');
        } else {
            setQuantityError('');
        }
    };

    const validatePrice = (value: string) => {
        if (value === '') {
            setPriceError('');
        } else if (isNaN(Number(value))) {
            setPriceError('Invalid price');
        } else {
            setPriceError('');
        }
    };

    useEffect(() => {
        if (newLineItem) {
            validateQuantity(newLineItem.quantity.toString());
            validatePrice(newLineItem.cost.toString());
        }
    }, [newLineItem?.quantity, newLineItem?.cost]);

    const handleCostChange = (text: string) => {
        if (/^\d*\.?\d*$/.test(text)) {
            setNewLineItem(new LineItem({ ...newLineItem, cost: Number(text) }));
            validatePrice(text);
        }
    };

    const handleQuantityChange = (text: string) => {
        if (/^\d*\.?\d*$/.test(text)) {
            setNewLineItem(new LineItem({ ...newLineItem, quantity: Number(text) }));
            validateQuantity(text);
        }
    };

    const handleAddNewItem = () => {
        if (newLineItem && !quantityError && !priceError) {
            const itemToAdd = new LineItem({
                ...newLineItem,
                quantity: newLineItem.quantity || 1, // Default to 1 if quantity is empty
                cost: newLineItem.cost || 0 // Default to 0 if cost is empty
            });
            onSetSelectedItems([...selectedLineItems, itemToAdd]);
            Toast.show({
                type: 'success',
                position: 'bottom',
                text1: 'Item Added',
                text2: `${newLineItem.name} added to selected items`,
                visibilityTime: 1000,
                autoHide: true,
            });
            setNewLineItem(null);
            setFilteredItems([]);
            setQuantityError('');
            setPriceError('');
        }
    };

    const getCategoryNameFromId = (categoryId: string) => {
        const category = companyInventoryList.find(c => c._id === categoryId);
        return category?.name || '';
    }


    const toggleInventoryList = () => {
        setIsInventoryListVisible(!isInventoryListVisible);
    };

    const getPricingTier = (item: ExtendedInventoryItem) => {
        const category = companyInventoryList.find(c => c._id === item.inventory_category_id);
        return category?.pricing_tiers?.find(tier => tier.client_ids.includes(selectedClient?._id as string)) || null;
    };

    // Separate lists for customer cost and billback items
    const customerCostItems = useMemo(() => {
        return selectedLineItems.filter(item => item.billed_to === LINE_ITEM_BILLED_TO.CUSTOMER_COST);
    }, [selectedLineItems]);

    const billbackItems = useMemo(() => {
        return selectedLineItems.filter(item => item.billed_to === LINE_ITEM_BILLED_TO.BILL_ALL_BACK);
    }, [selectedLineItems]);

    // Calculate totals for each list
    const customerCostTotal = useMemo(() => {
        return customerCostItems.reduce((total, item) => total + item.cost * item.quantity, 0).toFixed(2);
    }, [customerCostItems]);

    const billbackTotal = useMemo(() => {
        return billbackItems.reduce((total, item) => total + item.cost * item.quantity, 0).toFixed(2);
    }, [billbackItems]);

    const [customItemCost, setCustomItemCost] = useState('0');
    const [customItemQuantity, setCustomItemQuantity] = useState('1');





    const calculateTotalSelectedInventoryCost = useMemo(() => {
        try {
            let gstAmount = 0;
            let totalWithoutGST = 0;
            let totalWithGST = 0;
            let costNoGST = 0;

            selectedLineItems.forEach(item => {
                item = new LineItem(item);
                const itemTotal = item.cost * item.quantity;
                gstAmount += item.getGSTOnlyAmount();
                totalWithoutGST += item.getCostWithoutGST();
                totalWithGST += item.getCostWithGST();
                costNoGST += item.getTotalCost(); // if the company is not GST registered
            });

            return {
                GSTTotal: gstAmount.toFixed(2),
                totalWithoutGST: totalWithoutGST.toFixed(2),
                totalWithGST: totalWithGST.toFixed(2),
                costNoGST: costNoGST.toFixed(2),
            }
        } catch (error) {
            console.log(error);
            return {
                GSTTotal: '0',
                totalWithoutGST: '0',
                totalWithGST: '0',
                costNoGST: '0',
            }
        }
    }, [selectedLineItems]);

    return (
        <Layout style={style}>
            <View style={{ flexDirection: 'row', alignItems: 'flex-start', margin: 10, flexWrap: 'wrap', gap: 10 }}>
                <View style={{ flexDirection: 'column', maxWidth: 450 }}>
                    <Divider />
                    <Button onPress={toggleInventoryList}>
                        {isInventoryListVisible ? 'Hide Inventory List' : 'Show Inventory List'}
                    </Button>
                    <InventoryListModal
                        visible={isInventoryListVisible}
                        onClose={toggleInventoryList}
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        filteredInventoryList={filteredInventoryList as ExtendedInventoryItem[]}
                        selectedClient={selectedClient as Client}
                        theme={theme}
                        onSetSelectedItems={onSetSelectedItems}
                        selectedItems={selectedItems}
                        companyInventoryList={companyInventoryList}
                        getCategoryNameFromId={getCategoryNameFromId}
                        getPricingTier={getPricingTier}
                        isSmallScreen={IS_SMALL_SCREEN}
                        itemStockLevels={itemStockLevels}
                    />
                </View>
                <View style={{ flexDirection: 'column', flex: 1, minWidth: 200 }}>
                    {!IS_CLIENT && (
                        <AddItemForm
                            newLineItem={newLineItem}
                            onNewLineItemChange={setNewLineItem}
                            menuVisible={menuVisible}
                            setMenuVisible={setMenuVisible}
                            onMenuItemSelect={onMenuItemSelect}
                            filteredMenuItems={filteredMenuItems}
                            setMenuSearchQuery={setMenuSearchQuery}
                            customItemCost={customItemCost}
                            setCustomItemCost={setCustomItemCost}
                            customItemQuantity={customItemQuantity}
                            setCustomItemQuantity={setCustomItemQuantity}
                            handleCostChange={handleCostChange}
                            handleQuantityChange={handleQuantityChange}
                            validatePrice={validatePrice}
                            validateQuantity={validateQuantity}
                            priceError={priceError}
                            quantityError={quantityError}
                            handleAddNewItem={handleAddNewItem}
                        />
                    )}
                    <Divider />
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 20, flexWrap: 'wrap', gap: 5 }}>
                        <LineItemsList
                            title="Customer Cost Items"
                            items={customerCostItems}
                            total={customerCostTotal}
                            allowEdit={allowEdit}
                            style={{ flex: 1, minWidth: 450 }}
                            onRemove={(item) => {
                                const updatedItems = selectedLineItems.filter(i => i._id !== item._id);
                                onSetSelectedItems(updatedItems);
                            }}
                            onUpdate={(updatedItem) => {
                                const updatedItems = selectedLineItems.map(i => i._id === updatedItem._id ? updatedItem : i);
                                onSetSelectedItems(updatedItems);
                            }}
                        />
                        <LineItemsList
                            title="Billback Items"
                            items={billbackItems}
                            total={billbackTotal}
                            allowEdit={allowEdit}
                            style={{ flex: 1, minWidth: 400 }}
                            onRemove={(item) => {
                                const updatedItems = selectedLineItems.filter(i => i._id !== item._id);
                                onSetSelectedItems(updatedItems);
                            }}
                            onUpdate={(updatedItem) => {
                                const updatedItems = selectedLineItems.map(i => i._id === updatedItem._id ? updatedItem : i);
                                onSetSelectedItems(updatedItems);
                            }}
                        />
                    </View>
                    <Divider />
                    {StorageController.getCurrentCompany()?.settings?.business_info?.gst_registered &&
                        <Text style={{ margin: 10, fontWeight: 'bold', textAlign: 'right' }}>
                            GST: ${calculateTotalSelectedInventoryCost.GSTTotal || '0'}
                        </Text>
                    }
                    <Text style={{ margin: 10, fontWeight: 'bold', textAlign: 'right' }}>
                        {StorageController.getCurrentCompany()?.settings?.business_info?.gst_registered ?
                            `Total: ${calculateTotalSelectedInventoryCost.totalWithGST || '0'}`
                            :
                            `Total: ${calculateTotalSelectedInventoryCost.costNoGST || '0'}`
                        }
                    </Text>
                </View>
                <Divider />
            </View>

        </Layout>
    );

}

const styles = StyleSheet.create({
    searchInput: {
        margin: 10,
    },
    sectionTitle: {
        margin: 10,
    },
    selectedItemsList: {
        maxHeight: 200,
        marginHorizontal: 10,
    },
    totalCost: {
        margin: 10,
        fontWeight: 'bold',
        textAlign: 'right',
    },
    rowContainer: {
        flexDirection: 'row',  // Align children in a row
        alignItems: 'center',  // Center items vertically
        margin: 10,            // Optional: add margin for spacing
    },
    detailTextPill: {
        borderWidth: 1,
        borderRadius: 8,
        paddingHorizontal: 3,
        paddingVertical: 1,
        // borderColor: theme['color-primary-500'], // Use theme color for border
    },
    icon: {
        width: 15,
        height: 15,
    },
    modalOverlay: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContainer: {
        padding: 20,
        borderRadius: 10,
        width: '90%',
        maxHeight: '80%',
    },
    modalContent: {
        flex: 1,
    },
    closeButton: {
        marginTop: 20,
    },
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modal: {
        width: '80%',
        maxWidth: 500,
    },
});
