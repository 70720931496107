import React from 'react';
import { View } from 'react-native';
import { Layout, Button } from "@ui-kitten/components";

enum VIEW_MODE {
    LIST = "LIST",
    MAP = "MAP",
    CALENDAR = "CALENDAR"
}

interface ViewModeButtonsProps {
    style: any;
    setViewMode: (mode: VIEW_MODE) => void;
    viewMode: VIEW_MODE;
}

export const ViewModeButtons: React.FC<ViewModeButtonsProps> = ({
    style,
    setViewMode,
    viewMode
}) => {
    return (
        <View style={style}>
            <View style={styles.filter_button_container}>
                <Button
                    style={styles.view_button_style}
                    onPress={() => { setViewMode(VIEW_MODE.LIST) }}
                    appearance={viewMode == VIEW_MODE.LIST ? "filled" : "outline"}
                    status={viewMode == VIEW_MODE.LIST ? "success" : "basic"}
                    size="small"
                >
                    LIST
                </Button>
                <Button
                    style={styles.view_button_style}
                    onPress={() => { setViewMode(VIEW_MODE.MAP) }}
                    appearance={viewMode == VIEW_MODE.MAP ? "filled" : "outline"}
                    status={viewMode == VIEW_MODE.MAP ? "success" : "basic"}
                    size="small"
                >
                    MAP
                </Button>
                <Button
                    style={[styles.view_button_style]}
                    onPress={() => { setViewMode(VIEW_MODE.CALENDAR) }}
                    appearance={viewMode == VIEW_MODE.CALENDAR ? "filled" : "outline"}
                    status={viewMode == VIEW_MODE.CALENDAR ? "success" : "basic"}
                    size="small"
                >
                    DATE
                </Button>
            </View>
        </View>
    )
}

const styles = {
    filter_button_container: {
        flexDirection: 'row' as const,
        justifyContent: 'space-between' as const,
        padding: 5,
        gap: 2,
        height: "60%",
        flexWrap: 'wrap' as const,
    },
    view_button_style: {
        flex: 1,
        minWidth: 40,
    },
};