import React, { useEffect, useCallback } from 'react';
//@ts-ignore
import { View } from 'react-native';
//@ts-ignore
import { Card, Select, Input, Button, Text, SelectItem, Icon, IndexPath, Autocomplete, AutocompleteItem } from '@ui-kitten/components';
import { BaseClientDetailsCardProps } from './ClientDetailsCardTypes';
import * as StorageController from '../../../../functions/storageController';
import { CLIENT_RATE_BILLED_TO, JobClientRate } from '../../../../models/Job.model';
import { useAppStateContext } from '../../../../contexts/AppStateContext';

export function BaseClientDetailsCard({
    style = {},
    cardStatus = 'info',
    headerText = 'Client Details',
    showClientSelect = false,
    showRateSelect = true,
    allowEdit = true,
    clients = [],
    clientsSelectIndexPath,
    clientDisplayValue = '',
    clientRateOptions = [],
    clientRateIndex,
    clientRatesDisplayValue = '',
    clientReferenceId,
    JobClientRateState,
    linkedClientCompany,
    showCustomClientRate = false,
    hasChosenClient = false,
    hasChosenClientRate = false,
    onClientSelected,
    onClientRateSelectedIndex,
    onSetClientRefrerenceValue,
    onSetClientBilling,
    onSetCustomerPaid,
    onSetClientRate,
    setShowCustomClientRate,
    onInputFocus,
}: BaseClientDetailsCardProps) {

    const { getAppState, getCurrentCompany } = useAppStateContext();
    const clientReferenceIdRef = React.useRef<any>(null);
    const [clientSearchValue, setClientSearchValue] = React.useState(hasChosenClient ? clientDisplayValue : '');
    const [filteredClients, setFilteredClients] = React.useState<any[]>([]);

    // Sort clients alphabetically by name - only when needed
    const sortedClients = React.useMemo(() => {
        if (!showClientSelect) return [];

        return [...clients].sort((a, b) => a.name.localeCompare(b.name));
    }, [clients, showClientSelect]);

    // Initialize filtered clients only when needed
    React.useEffect(() => {
        if (!showClientSelect) return;

        if (clientSearchValue.trim() === '') {
            setFilteredClients(sortedClients);
        } else {
            setFilteredClients(
                sortedClients.filter(client =>
                    client.name.toLowerCase().includes(clientSearchValue.toLowerCase())
                )
            );
        }
    }, [sortedClients, clientSearchValue, showClientSelect]);

    useEffect(() => {
        // on mount - if there is only one client - select it
        if (clients.length === 1 && onClientSelected && !hasChosenClient) {
            const onlyClient = clients[0];
            onClientSelected(new IndexPath(0));
            setClientSearchValue(onlyClient.name);
        }
        // Add this condition to set the search value when a client is already chosen
        else if (hasChosenClient && clientDisplayValue) {
            setClientSearchValue(clientDisplayValue);
        }
    }, [clients, clientDisplayValue, hasChosenClient]);


    const onChangeClientSearch = (query: string) => {
        setClientSearchValue(query);
    };

    const onClientSelect = (index: number) => {
        const selectedClient = filteredClients[index];
        if (onClientSelected) {
            // Find the original index in the unsorted clients array
            const originalIndex = clients.findIndex(c => c._id === selectedClient._id);
            onClientSelected(new IndexPath(originalIndex));
        }
        setClientSearchValue(selectedClient.name);
    };

    const renderClientOption = (item: any, index: number) => (
        <AutocompleteItem key={item._id} title={item.name} />
    );

    const renderClientRatesOption = (item: any) => (
        <SelectItem key={item.name} title={`${item.name} : $${item.cost}`} />
    );

    const RenderCustomClientRateCard = useCallback(() => {
        return (
            <Card
                status="info"
                header={() => <Text>Custom Rate</Text>}
                disabled={true}
                style={style}
            >
                <View style={{ flexDirection: 'row', flexWrap: "wrap", justifyContent: 'flex-start', gap: 5 }}>
                    <Input
                        size='small'
                        style={{ flexBasis: '50%', minWidth: 200 }}
                        label='Description'
                        placeholder='Description'
                        defaultValue={JobClientRateState?.name}
                        onBlur={(e: any) => {
                            JobClientRateState.name = e.nativeEvent.text;
                            onSetClientRate(JobClientRateState);
                        }}
                    />
                    <Input
                        size='small'
                        style={{ flexBasis: '30%', minWidth: 100 }}
                        label='Client Rate $'
                        placeholder='Client Rate'
                        textStyle={{ minWidth: 100 }}
                        defaultValue={JobClientRateState?.cost?.toString()}
                        onBlur={(e: any) => {
                            const cost = e.nativeEvent.text;
                            if (isNaN(cost)) return;
                            const numCost = parseFloat(cost);
                            JobClientRateState.cost = numCost;
                            onSetClientRate(JobClientRateState);
                        }}
                    />
                    <Input
                        size='small'
                        style={{ flexBasis: '50%', minWidth: 200 }}
                        label='EFT Auth'
                        placeholder='EFT Auth'
                        defaultValue={JobClientRateState?.eft_auth_number}
                        onBlur={(e: any) => {
                            JobClientRateState.eft_auth_number = e.nativeEvent.text;
                            onSetClientRate(JobClientRateState);
                        }}
                    />
                </View>
            </Card>
        );
    }, [JobClientRateState]);

    const RenderToggleCustomClientRateButton = ({ style }: any) => {
        return (
            <Button
                size='tiny'
                status='warning'
                appearance='outline'
                style={style}
                disabled={StorageController.getAppState().selectedMembership?.is_client}
                accessoryLeft={showCustomClientRate ? (props: any) => <Icon {...props} name='close-outline' /> : (props: any) => <Icon {...props} name='edit-outline' />}
                onPress={() => setShowCustomClientRate(!showCustomClientRate)}
            >
                {showCustomClientRate ? "Hide Edit" : "Edit Rate"}
            </Button>
        );
    };

    const showCustomRateInputs = clientRateOptions.length === 0 || showCustomClientRate;

    return (
        <Card
            disabled
            header={() => <Text style={{ alignSelf: 'center' }}>{headerText}</Text>}
            status={cardStatus}
            style={style}
        >
            <View style={{ flexDirection: "column", justifyContent: 'flex-start', gap: 5 }}>
                {linkedClientCompany && (
                    <Text category="h6">{linkedClientCompany.name || ""}</Text>
                )}
                <View style={{ flexDirection: 'row', gap: 10 }}>
                    {showClientSelect ? (
                        <View style={{ flexDirection: 'row', flex: 3 }}>
                            <Autocomplete
                                size='small'
                                style={{ flex: 1 }}
                                label='Client'
                                placeholder="---- Search Client ----"
                                value={clientSearchValue}
                                disabled={!allowEdit}
                                onSelect={onClientSelect}
                                onChangeText={onChangeClientSearch}
                                onFocus={() => setFilteredClients(sortedClients)}
                                accessoryRight={(props: any) => <Icon {...props} name='arrow-ios-downward-outline' />}
                            >
                                {filteredClients.map(renderClientOption)}
                            </Autocomplete>
                        </View>
                    ) :
                        <Text>{clientDisplayValue}</Text>
                    }
                    {clientRateOptions.length > 0 && (
                        <View style={{ flex: 1, height: 25 }}>
                            <RenderToggleCustomClientRateButton />
                        </View>
                    )}
                </View>

                {showRateSelect ? (
                    showCustomRateInputs ? (
                        <RenderCustomClientRateCard />
                    ) : (
                        clientRateOptions.length > 0 && (
                            <Select
                                size='small'
                                selectedIndex={clientRateIndex}
                                label='Client Rate'
                                value={clientRatesDisplayValue}
                                placeholder='Select Client Rate'
                                disabled={!allowEdit}
                                onSelect={(index: IndexPath | IndexPath[]) => {
                                    onClientRateSelectedIndex && onClientRateSelectedIndex(index);
                                }}
                            >
                                {clientRateOptions.map(renderClientRatesOption)}
                            </Select>
                        )
                    )
                ) : null}

                <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: 5, justifyContent: 'space-between' }}>
                    <Input
                        ref={clientReferenceIdRef}
                        size='small'
                        style={{ flexBasis: '50%', minWidth: 200 }}
                        label='Client Reference'
                        placeholder='Client Reference'
                        defaultValue={clientReferenceId || ""}
                        disabled={!allowEdit}
                        onChangeText={onSetClientRefrerenceValue}
                        onFocus={() => onInputFocus && onInputFocus(clientReferenceIdRef)}
                    />
                    {hasChosenClientRate && !showCustomRateInputs && (
                        <Input
                            size='small'
                            style={{ flexBasis: '30%', minWidth: 100 }}
                            textStyle={{ minWidth: 100 }}
                            label='EFT Auth'
                            placeholder='EFT Auth'
                            defaultValue={JobClientRateState?.eft_auth_number}
                            disabled={!allowEdit}
                            onChangeText={(value) => {
                                JobClientRateState.eft_auth_number = value;
                                onSetClientRate(JobClientRateState);
                            }}
                        />
                    )}
                </View>

                <View style={{ flexDirection: 'row', gap: 5 }}>
                    <Button
                        size='small'
                        status={JobClientRateState?.billed_to == CLIENT_RATE_BILLED_TO.CUSTOMER_COST ? 'success' : 'basic'}
                        appearance={JobClientRateState?.billed_to == CLIENT_RATE_BILLED_TO.CUSTOMER_COST ? 'filled' : 'outline'}
                        onPress={() => onSetClientBilling(CLIENT_RATE_BILLED_TO.CUSTOMER_COST)}
                    >
                        Customer Cost
                    </Button>
                    <Button
                        size='small'
                        disabled={!allowEdit}
                        status={JobClientRateState?.billed_to == CLIENT_RATE_BILLED_TO.BILL_ALL_BACK ? 'success' : 'basic'}
                        appearance={JobClientRateState?.billed_to == CLIENT_RATE_BILLED_TO.BILL_ALL_BACK ? 'filled' : 'outline'}
                        onPress={() => onSetClientBilling(CLIENT_RATE_BILLED_TO.BILL_ALL_BACK)}
                    >
                        Bill All Back
                    </Button>
                </View>

                {JobClientRateState?.billed_to == CLIENT_RATE_BILLED_TO.CUSTOMER_COST && (
                    <View style={{ flexDirection: 'row', gap: 5 }}>
                        <Button
                            size='small'
                            disabled={!allowEdit}
                            status={JobClientRateState?.customer_paid ? 'success' : 'basic'}
                            appearance={JobClientRateState?.customer_paid ? 'filled' : 'outline'}
                            onPress={() => onSetCustomerPaid(true)}
                        >
                            Paid
                        </Button>
                        <Button
                            size='small'
                            disabled={!allowEdit}
                            status={!JobClientRateState?.customer_paid ? 'warning' : 'basic'}
                            appearance={!JobClientRateState?.customer_paid ? 'filled' : 'outline'}
                            onPress={() => onSetCustomerPaid(false)}
                        >
                            Unpaid
                        </Button>
                    </View>
                )}
            </View>
        </Card>
    );
}