import React, { useState, useEffect, useRef, useReducer, useMemo, useCallback } from 'react';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';
import { jwtDecode } from "jwt-decode";
//@ts-ignore
import { useWindowDimensions, View, Image, AppState } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler'
import { NavigationContainer, createNavigationContainerRef, useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createDrawerNavigator } from '@react-navigation/drawer';
//@ts-ignore
import { Drawer, DrawerItem, Button, Layout, Text, IndexPath, Icon, Toggle, Spinner } from '@ui-kitten/components';

import * as StorageController from './functions/storageController';
import * as UserController from './functions/user.controller';
import * as UpdateController from './functions/update.controller';
import { STATE_ACTIONS } from './functions/update.controller';
import JobsScreen from "./components/job/jobs.component";
import createJobScreen from "./components/job/vendorJobs/newJobContainerScreen";;
import { MembersScreen } from "./components/members/members.component";
import DashboardScreen from "./components/dashboardComponents/DashboardScreen.component";
import { LoginScreen } from "./components/login/login.component";
import { CompanySelectScreen } from './components/login/companySelect.component';
import { ClientsScreen } from './components/clients/clients.component';
import { InvoiceScreen } from './components/invoicing/invoices.component';
import { UserProfileScreen } from './components/user/userProfileScreen.component';
import { ServicesScreen } from './components/services/serviceScreen.component';
import { SettingsContainerScreen } from './components/settings/settingsContainer.component';
import { FormsContainerScreen } from './components/settings/formsContainer.component';
import { InventoryContainerScreen } from './components/inventory/inventoryContainerScreen.component';
import { VendorProfileContainerScreen } from './components/vendorProfile/vendorProfileContainerScreen.component';
import { ContractsContainerScreen } from './components/contracts/contractsContainerScreen.component';
import { ServiceNetworkScreen } from './components/serviceNetwork/serviceNetworkScreen.component';
import { DocumentsContainerScreen } from './components/documents/documentsContainerScreen'
import { CaseInvoicingScreen } from './components/invoicing/CaseInvoicingScreen.component';
import { useTheme } from './context/theme.context'
import { headerRenderer } from 'react-data-grid';
import { ResizeMode } from 'expo-av';
import { AppStateModel } from './models/AppStateModel.model';
import * as MqttService from './services/mqtt.service';
import { useAppStateChange, IAction } from './hooks/appStateChange.hook';
import { useAppStateContext } from './contexts/AppStateContext';
import { PartsNetworkContainerScreen } from './components/PartsNetwork/PartsNetworkContainerScreen';
import { OverviewScreen } from './components/overview/overview.screen';
import { UpdatesDrawerItem } from './components/updates/UpdatesDrawerItem.component';
const Stack = createStackNavigator();
// const Draw = createDrawerNavigator();
const { Navigator, Screen } = createDrawerNavigator();
export const navigationRef = createNavigationContainerRef()


const TEST_MODE_USER_IDS = [
  "624ad134558d8526b021c12a",
  "640d6ebbb8ad3309985789f8",
  "64e69b2a6a2910a5515c0292",
  "6728cfac368c4b44f111f831" // C A
];

export function navigate(name: any, params: any = {}) {
  if (navigationRef.isReady()) {
    //@ts-ignore
    navigationRef.navigate(name, params);
    // console.log("navigating..")
  }
}

function logout() {
  UserController.logout().then(() => {
    navigate("Login")
    // refresh browser
    window.location.reload()
  })
}

const LogoutScreen = () => (
  <Layout style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
    <Text category='h1'>Logout</Text>
    <Button
      onPress={() => { logout() }}
    >
      Logout
    </Button>
  </Layout>
)

const LoadingScreen = () => {
  return (
    <Layout style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Spinner />
      <RenderSpinnerOverlay renderSpinner={true} loadingText="Loading..." />
    </Layout>
  )
}


const createIcon = (name: string) => (props: any) => {
  const { theme } = useTheme();
  return (
    <Icon {...props} name={name} width={24} height={24} fill={theme['text-basic-color']} />
  );
};
// Define constants for each icon
const ServiceNetworkIcon = createIcon('globe-outline');
const JobsIcon = createIcon('briefcase-outline');
const DashboardIcon = createIcon('grid-outline');
const OverviewIcon = createIcon('home-outline');
const MessagesIcon = createIcon('message-circle-outline');
const MembersIcon = createIcon('people-outline');
const ClientsIcon = createIcon('person-add-outline');
const CompanySelectIcon = createIcon('home-outline');
const ServicesIcon = createIcon('archive-outline');
const UserProfileIcon = createIcon('person-outline');
const InvoicesIcon = createIcon('file-text-outline');
const SettingsIcon = createIcon('settings-outline');
const FormsIcon = createIcon('file-text-outline');
const InventoryIcon = createIcon('archive-outline');
const LoginIcon = createIcon('log-in-outline');
const LogoutIcon = createIcon('log-out-outline');
const VendorProfilesIcon = createIcon('smiling-face-outline');
const ContractsIcon = createIcon('archive-outline');
const CaseInvoicingScreenIcon = createIcon('layers-outline');
const DocumentsScreenIcon = createIcon('folder-outline');
const PartsNetworkIcon = createIcon('car-outline');

const getSelectedMembership = () => {
  return StorageController.getAppState().selectedMembership
}



const checkAdmin = () => {
  if (getSelectedMembership()?.is_admin) {
    return true;
  }
  return false;
}

const checkClient = () => {
  if (getSelectedMembership()?.is_client) {
    return true;
  }
  return false
}
const checkClientAdmin = () => {
  const membership = getSelectedMembership()
  if (membership?.is_client && membership?.is_client_admin) {
    return true;
  }
  return false
}

const showOverview = () => {
  return true
}

const showDashboard = () => {
  if (checkClient() && !checkClientAdmin()) {
    return false;
  }
  return true;
}

const showInvoices = () => {
  if (checkAdmin() || checkClientAdmin()) {
    return true;
  }
  return false;
}

const showSettings = () => {
  if (checkAdmin()) {
    return true;
  }
  return false;
}

const showClients = () => {
  if (checkClient()) {
    return false;
  }
  return true;
}

const showForms = () => {
  if (checkClient()) {
    return false;
  }
  return true;
}

const showInventory = () => {
  if (checkClient()) {
    return false;
  }
  return true;
}

const showDocuments = () => {
  if (checkClient() && !checkClientAdmin()) {
    return false;
  }
  return true;
}

const showPartsNetwork = () => {
  let company = StorageController.getAppState().selectedCompany
  if (checkClient()) {
    return false;
  }
  if (company?.settings?.parts_network?.is_enabled) {
    return true
  }
  return false;
}

const showVendorProfiles = () => {
  let company = StorageController.getAppState().selectedCompany
  if (!checkAdmin()) return false
  if (company?.settings?.company_roles?.showContracts) {
    return true
  }
  return false;
}

const showContracts = () => {
  let company = StorageController.getAppState().selectedCompany
  if (!checkAdmin()) return false
  if (company?.settings?.company_roles?.showContracts) {
    return true
  }
  return false;
}

const showServiceNetworkScreen = () => {
  let company = StorageController.getAppState().selectedCompany
  if (!checkAdmin()) return false
  if (company?.settings?.company_roles?.showContracts) {
    return true
  }
  return false;
}

const showCaseInvoicingScreen = () => {
  let company = StorageController.getAppState().selectedCompany
  if (company?.settings?.company_roles?.showCases) {
    if (checkClient()) {
      // check is clietn admin
      if (checkClientAdmin()) {
        return true
      }
      return false
    }
    return true
  }
  return false;
}

const isVisible = () => { return true }



const LeftHeaderButton = ({ iconUrl, theme }: any) => {
  const { width } = useWindowDimensions();
  const navigation = useNavigation();
  return (
    <View
      style={{
        flex: 1,
        width: width * 0.2,
        flexDirection: "row",
        justifyContent: "space-between",
        paddingLeft: 10,
        // borderColor: "yellow",
        // borderWidth: 1,
      }}>
      <TouchableOpacity style={{ width: 200, height: "99%", paddingBottom: 3 }}
        onPress={() => {
          //@ts-ignore - it does exist
          navigation.toggleDrawer()
        }}
      >
        <View style={{ flexDirection: 'row' }}>
          <Button
            style={{ width: "10%", zIndex: 100 }}
            appearance='ghost'
            onPress={() => { console.log("drawer opening") }}
          >
            <Icon name='menu-outline' width={24} height={24} fill={theme['text-basic-color']} />
          </Button>
          {iconUrl &&
            <Image
              style={{ width: '100%', height: '100%', resizeMode: 'stretch', padding: 5 }}
              source={{ uri: iconUrl }}
            />
          }
        </View>
      </TouchableOpacity>
    </View>
  );
};



const DrawerContent = ({ navigation, state }: any) => {
  const { getSelectedMembership, getAppState } = useAppStateContext()
  const [useTestApi, setUseTestApi] = useState(getAppState().use_test_api)
  const drawerRef = useRef(null)
  useEffect(() => {
    setTimeout(() => {
      setUseTestApi(getAppState().use_test_api || false)
    }, 1000)
  }, [])
  const RenderDevToggle = () => {
    const userId = getAppState()?.user?._id;
    if (!userId) return null;
    if (!TEST_MODE_USER_IDS.includes(userId)) return null;
    return (
      <View style={{
        position: "absolute",
        top: 20,
        left: 0,
        zIndex: 1000,
      }}>
        <Toggle
          checked={useTestApi}
          onChange={(v: boolean) => {
            setUseTestApi(v);
            StorageController.setUseTestApi(v);
          }}
        >
          TEST MODE
        </Toggle>
      </View>
    );
  };

  const [selectedDrawerIndex, setSelectedDrawerIndex] = useState(0)
  const [currentScreenName, setCurrentScreenName] = useState("Jobs")
  const [currentDrawItems, setCurrentDrawerItems] = useState([] as any)

  const currentScreenNameRef = useRef(currentScreenName)
  useEffect(() => {
    currentScreenNameRef.current = currentScreenName
  }, [currentScreenName])



  const m_currentDrawItems = useMemo(() => currentDrawItems, [currentDrawItems])


  const companyEvent = useCallback(
    (action: IAction) => {
      const renderedDrawerItems = drawerItems.filter(item => item.visible());
      setCurrentDrawerItems(renderedDrawerItems);
      const index = renderedDrawerItems.findIndex(item => item.screenName === currentScreenNameRef.current);
      if (index !== -1) {
        setSelectedDrawerIndex(index);
      }
    },
    [state]
  );




  const navigatedEvent =
    (action: IAction) => {
      console.log("🚀============== ~ file: routes.tsx:362 ~ DrawerContent ~ action🚀==============", action)
      const routeName = action.data;
      setCurrentScreenName(routeName);

      const renderedDrawerItems = drawerItems.filter(item => item.visible());
      setCurrentDrawerItems(renderedDrawerItems);

      const index = renderedDrawerItems.findIndex(item => item.screenName === routeName);
      if (index !== -1) {
        setSelectedDrawerIndex(index);
      }
    }

  const { dispatchEventStateChange } = useAppStateChange({
    onUpdatedSelectedCompany: companyEvent,
    onNavigated: navigatedEvent,
  });


  // const navigate = (name: string) => {
  //   const index = state.routeNames.indexOf(name)

  //   if (index != -1) {
  //     setSelectedDrawerIndex(index)
  //     navigation.navigate(state.routeNames[index])
  //   }
  //   else {
  //   }
  // }

  const navigate = (name: string) => {
    const index = state.routeNames.indexOf(name);
    if (index !== -1) {
      const renderedDrawerItems = drawerItems.filter(item => item.visible());
      const visibleIndex = renderedDrawerItems.findIndex(item => item.screenName === name);
      setSelectedDrawerIndex(visibleIndex);
      navigation.navigate(state.routeNames[index]);
    }
  };

  const lastItemStyle = { marginBottom: 10, borderBottomWidth: 1, borderBottomColor: 'black' }
  const contractsBorderStyle = { borderLeftWidth: 5, borderLeftColor: 'blue' }
  const baseFunctionalityStyle = { borderLeftWidth: 5, borderLeftColor: 'green' }
  const adminStyle = { borderLeftWidth: 5, borderLeftColor: 'purple' }
  const allUserStyle = { borderLeftWidth: 5, borderLeftColor: 'orange' }

  const drawerItems = [
    { style: [contractsBorderStyle], title: 'Service Network', screenName: 'Service Network', icon: ServiceNetworkIcon, visible: showServiceNetworkScreen, group: 'contracts' },
    { style: [contractsBorderStyle], title: 'Vendor Profiles', screenName: 'Vendor Profiles', icon: VendorProfilesIcon, visible: showVendorProfiles, group: 'contracts' },
    { style: [contractsBorderStyle, lastItemStyle], title: 'Contracts', screenName: 'Contracts', icon: ContractsIcon, visible: showContracts, group: 'contracts' },
    { style: [baseFunctionalityStyle], title: 'Overview', screenName: 'Overview', icon: OverviewIcon, visible: showOverview, group: 'base' },
    // { style: [baseFunctionalityStyle], title: 'Jobs', screenName: 'Jobs', icon: JobsIcon, visible: isVisible, group: 'base' },
    { style: [baseFunctionalityStyle], title: 'Dashboard', screenName: 'Dashboard', icon: DashboardIcon, visible: showDashboard, group: 'base' },
    { style: [baseFunctionalityStyle], title: 'Documents', screenName: 'Documents', icon: DocumentsScreenIcon, visible: showDocuments, group: 'base' },
    { style: [baseFunctionalityStyle], title: 'Team Members', screenName: 'Members', icon: MembersIcon, visible: isVisible, group: 'base' },
    { style: [baseFunctionalityStyle], title: 'Client Profiles', screenName: 'Client Profiles', icon: ClientsIcon, visible: showClients, group: 'base' },
    { style: [baseFunctionalityStyle], title: 'Company Select', screenName: 'Company Select', icon: CompanySelectIcon, visible: isVisible, group: 'base' },
    { style: [baseFunctionalityStyle], title: 'Services', screenName: 'Services', icon: ServicesIcon, visible: isVisible, group: 'base' },
    { style: [baseFunctionalityStyle, lastItemStyle], title: 'Inventory', screenName: 'Inventory', icon: InventoryIcon, visible: showInventory, group: 'base' },
    { style: [baseFunctionalityStyle], title: 'Parts Network', screenName: 'Parts Network', icon: PartsNetworkIcon, visible: showPartsNetwork, group: 'base' },
    { style: [adminStyle], title: 'Invoices', screenName: 'Invoices', icon: InvoicesIcon, visible: showInvoices, group: 'admin' },
    { style: [adminStyle], title: 'Case Invoicing', screenName: 'Case Invoicing', icon: CaseInvoicingScreenIcon, visible: showCaseInvoicingScreen, group: 'admin' },
    { style: [adminStyle, lastItemStyle], title: 'Settings', screenName: 'Settings', icon: SettingsIcon, visible: showSettings, group: 'admin' },
    { style: [allUserStyle], title: 'User Profile', screenName: 'UserProfile', icon: UserProfileIcon, visible: isVisible, group: 'all' },
    { style: [allUserStyle], title: 'Logout', screenName: 'Logout', icon: LogoutIcon, visible: isVisible, group: 'all' },
  ];
  const renderedDrawerItems = drawerItems.filter(item => item.visible());



  const loggedInDrawer = () => {
    return (
      //@ts-ignore
      <Drawer
        ref={drawerRef}
        selectedIndex={new IndexPath(selectedDrawerIndex)}
        onSelect={(index: any) => {
          setSelectedDrawerIndex(index.row)
          navigate(renderedDrawerItems[index.row].screenName);
        }}
        style={{ paddingTop: 10 }}
      >
        {/* <UpdatesDrawerItem /> */}

        {renderedDrawerItems.filter(item => item.group === 'contracts').map((item, index) => (
          <DrawerItem {...item} key={index} title={item.title} accessoryLeft={item.icon} onPress={() => navigate(item.screenName)} />
        ))}

        {renderedDrawerItems.filter(item => item.group === 'base').map((item, index) => (
          <DrawerItem {...item} key={index} title={item.title} accessoryLeft={item.icon} onPress={() => navigate(item.screenName)} />
        ))}

        {renderedDrawerItems.filter(item => item.group === 'admin').map((item, index) => (
          <DrawerItem {...item} key={index} title={item.title} accessoryLeft={item.icon} onPress={() => navigate(item.screenName)} />
        ))}
        {renderedDrawerItems.filter(item => item.group === 'all').map((item, index) => (
          <DrawerItem {...item} key={index} title={item.title} accessoryLeft={item.icon} onPress={() => navigate(item.screenName)} />
        ))}
        <RenderDevToggle />
      </Drawer>
    );
  }

  const notLoggedInDrawer = () => (
    <Drawer
      ref={drawerRef}
      // navigation={navigation}
      selectedIndex={new IndexPath(state.index)}
      onSelect={(index: any) => navigation.navigate(state.routeNames[index.row])}>
      <DrawerItem title='Login' />
    </Drawer>
  )


  if (getAppState().loggedIn && getAppState().selectedCompany?._id) {
    return (loggedInDrawer())
  }
  else {
    return (notLoggedInDrawer())
  }
}

export const DrawerNavigator = ({ navigation, options }: any) => {

  const { theme } = useTheme()
  const { height } = useWindowDimensions()
  const getColourFromThemeProperty = (themeValue: string) => {
    const result = themeValue.replace("$", "")
    return theme[result]
  }

  const LoggedInNavigator = useMemo(() => (
    <Navigator
      backBehavior="history"
      useLegacyImplementation
      drawerContent={props => <DrawerContent
        navigation={props.navigation}
        state={props.state}
      />}
      screenOptions={{
        swipeEnabled: true,
        unmountOnBlur: true,
        headerTitle: "",
        headerStyle: {
          backgroundColor: getColourFromThemeProperty(theme['background-basic-color-1']),
        },
        headerLeft: (props: any) => (
          <View style={{ flexDirection: 'row' }}>
            {/* <Button onPress={async () => {
              await UpdateController.getAllCompanyDashboardData()
              navigation.navigate("Jobs")
            }}>
              Update
            </Button> */}
            <LeftHeaderButton iconUrl={theme['logo-url']} theme={theme} />
          </View>
        ),
        // headerRight: () => (
        //   <View style={{ height: 0, width: 0 }}></View>
        // ),
        drawerType: options.var_isLargeScreen ? 'permanent' : 'slide',
        headerTintColor: theme['text-basic-color'],
        drawerStyle: {
          // set width
          width: 150,
          overflow: 'hidden',
          maxHeight: height,
        }
      }}>
      <Screen name="Loading" component={LoadingScreen} />
      {
        showServiceNetworkScreen() &&
        <Screen name="Service Network" component={ServiceNetworkScreen} />
      }
      {
        showVendorProfiles() &&
        <Screen name="Vendor Profiles" component={VendorProfileContainerScreen} />
      }
      {
        showContracts() &&
        <Screen name="Contracts" component={ContractsContainerScreen} />
      }
      <Screen name="Overview" component={OverviewScreen} />
      {/* <Screen name="Jobs" component={JobsScreen} /> */}
      {
        showDashboard() &&
        <Screen name="Dashboard" component={DashboardScreen} />
      }
      {/* <Screen name="Messages" component={MessageContainerScreen} /> */}
      {/* {showForms() &&
        <Screen name="Forms" component={FormsContainerScreen} />
      } */}
      {showDocuments() &&
        <Screen name="Documents" component={DocumentsContainerScreen} />
      }
      <Screen name="Members" component={MembersScreen} />
      {
        showClients() &&
        <Screen name="Client Profiles" component={ClientsScreen} />
      }
      <Screen name="Company Select" component={CompanySelectScreen} />
      <Screen name="Services" component={ServicesScreen} />
      {
        showInventory() &&
        <Screen name="Inventory" component={InventoryContainerScreen} />
      }
      {
        showPartsNetwork() &&
        <Screen name="Parts Network" component={PartsNetworkContainerScreen} />
      }
      {showInvoices() &&
        <Screen name="Invoices" component={InvoiceScreen} />
      }
      {showSettings() &&
        <Screen name="Settings" component={SettingsContainerScreen} />
      }
      {showCaseInvoicingScreen() &&
        <Screen name="Case Invoicing" component={CaseInvoicingScreen} />
      }
      <Screen name="UserProfile" component={UserProfileScreen} />
      {/* <Screen name="Rostering" component={InventoryContainerScreen} /> */}
      <Screen name='Logout' component={LogoutScreen} />


      {/* not visible */}
      <Screen name="Login">
        {props => <LoginScreen {...props} options={{ fn_update: options.fn_update }} />}
      </Screen>
      {/* @ts-ignore */}
      <Screen name="Create Job" component={createJobScreen} />
      {/* <Screen name="Search Jobs" component={searchJobsScreen} /> */}

    </Navigator>
  ), [theme, navigation])


  const NotLoggedInNavigator = useMemo(() => (
    <Navigator
      backBehavior="history"
      drawerContent={props => <DrawerContent
        navigation={props.navigation}
        state={props.state}
      />}
      useLegacyImplementation
      screenOptions={{
        headerStyle: {
          backgroundColor: theme['color-basic-1000'],
        },
        drawerType: options.var_isLargeScreen ? 'permanent' : 'slide',
        headerTintColor: '#fff',
      }}>

      {/* ===== */}
      {/* SCREENS */}
      {/* ===== */}
      <Screen name="Login">
        {props => <LoginScreen {...props} options={{ fn_update: options.fn_update }} />}
      </Screen>
      {/* not visible - So were able to nav to jobs*/}
      <Screen name="Overview">
        {props => <OverviewScreen {...props} />}
      </Screen>
      {/* <Screen name="Jobs">
        {props => <JobsScreen {...props} />}
      </Screen> */}
      <Screen name="Loading" component={LoadingScreen} />
      <Screen name="CompanySelect" component={CompanySelectScreen} />
    </Navigator>
  ), [theme])

  if (StorageController.getAppState().loggedIn && StorageController.getAppState().selectedCompany?._id) {
    // console.log("logged in nav")
    return (
      <>
        {LoggedInNavigator}
      </>
    )
  }
  else {
    // console.log("not logged in nav")
    return (<>
      {NotLoggedInNavigator}
    </>
    )
  }
}



export const AppNavigator = () => {
  const [ignored, forceUpdateNav] = useReducer(x => x + 1, 0);
  const [appLoaded, setAppLoaded] = useState(false);
  const dimensions = useWindowDimensions();
  const isLargeScreen = dimensions.width >= 1550;
  const routeNameRef = useRef("");
  const { theme } = useTheme();
  const [renderSpinner, setRenderSpinner] = useState(false)
  const lastBackgroundTime = useRef<number | null>(null);

  const [themeState, setThemeState] = useState(theme)

  useEffect(() => {
    // theme changes
    // console.log("Theme changed")
    setThemeState(theme)
    // console.log("🚀============== ~ file: routes:495 ~ AppNavigator ~ theme🚀==============", theme)
  }, [theme])






  useEffect(() => {
    if (!appLoaded) {
      StorageController.loadStateFromDisk(false).then(data => {
        setAppLoaded(true);
        console.log("App loaded")
        forceUpdate()
      })
    }

    loadLoggedInUser()

  }, [])


  const { dispatchEventStateChange } = useAppStateChange({
    onLoggedOut: () => {
      navigate("Login")
    }
  })

  // const { dispatchEventStateChange } = useAppStateChange({
  //   onNavigated: (action: IAction) => {
  //     console.log("🚀============== ~ file: routes.tsx:684 ~ AppNavigator ~ action🚀==============", action)
  //   },
  //   onUpdatedSelectedJob: (action: IAction) => {
  //     console.log("🚀============== ~ file: routes.tsx:684 ~ AppNavigator ~ action🚀==============", action)
  //   },
  //   onLoggedOut: () => {
  //     navigate("Login")
  //   }
  // })




  const loadLoggedInUser = async () => {
    const appState = await StorageController.loadStateFromDisk(false) as AppStateModel | null
    // console.log("🚀============== ~ file: routes.tsx:654 ~ loadLoggedInUser ~ appState🚀==============", appState)
    if (appState?.loggedIn && appState?.selectedMembership?._id) {
      // validate JWT token
      const access_token = await StorageController.getAccessToken()
      // check if its valid
      if (!access_token) {
        await StorageController.setDefaultAppState()
        navigate("Login")
        return
      }
      const decodedToken = jwtDecode(access_token) as UpdateController.IdecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);
      const tokenUserId = decodedToken.user_id;
      if (tokenUserId != appState?.user?._id) {
        // console.log("🚀============== ~ file: routes.tsx:675 ~ loadLoggedInUser ~ Token does not match user🚀==============")
        await StorageController.setDefaultAppState()
        navigate("Login")
        return
      }
      if (decodedToken.exp && decodedToken.exp < currentTime) {
        const newToken = await UserController.debounceRefreshToken()
        if (!newToken) {
          // Token has expired
          navigate("Login");
          return;
        }
      }
      navigate("Loading")
      const allData = await updateAllData()
      navigate("Overview", { data: allData })
      forceUpdateNav()
    } else {
      console.log("No user logged in")
      await StorageController.setDefaultAppState()
      navigate("Login")
    }
  }

  const updateAllData = async () => {
    // setRenderSpinner(true)
    const data = await UpdateController.getAllCompanyDashboardData()
    // MqttService.createACLSubscriptions()
    // setRenderSpinner(false)
    return data
  }

  useEffect(() => {
    const handleAppStateChange = (nextAppState: string) => {
      if (nextAppState === 'active') {
        if (!StorageController.getAppState().loggedIn) return

        const mqttState = MqttService.getConnectionStatus();
        if (mqttState != MqttService.CONNECTION_STATUS.CONNECTED) {
          MqttService.connect()
          updateAllData().then(() => {
            // Only navigate if we've been in background for > 2 minutes
            const shouldNavigate = lastBackgroundTime.current &&
              (Date.now() - lastBackgroundTime.current > 2 * 60 * 1000);

            if (shouldNavigate && routeNameRef.current === "Overview") {
              navigate("Overview", { x: Date.now() })
            }
          })
        }
        // Reset background time
        lastBackgroundTime.current = null;
      } else if (nextAppState === 'background') {
        // Store timestamp when going to background
        lastBackgroundTime.current = Date.now();
      }
    };
    AppState.addEventListener('change', handleAppStateChange);
    return () => {
      AppState.removeEventListener('change', handleAppStateChange);
    };
  }, []);

  //force update drawer options
  function forceUpdate() {
    // console.log("Force update")
    forceUpdateNav()
  }


  return (
    <SafeAreaProvider
      //@ts-ignore
      style={{ flex: 1, overflow: "hidden" }}>
      <NavigationContainer ref={navigationRef}
        onStateChange={async () => {
          const previousRouteName = routeNameRef.current;
          const currentRouteName = navigationRef.getCurrentRoute()?.name;
          const trackScreenView = () => {
            console.log("Navigation event dispatched:", currentRouteName);
            dispatchEventStateChange({
              type: STATE_ACTIONS.NAVIGATED,
              data: currentRouteName
            });
          };

          if (previousRouteName !== currentRouteName) {
            routeNameRef.current = currentRouteName || '';

            // Replace the line below to add the tracker from a mobile analytics SDK
            await trackScreenView();
          }
        }}
      >
        <RenderSpinnerOverlay renderSpinner={renderSpinner} loadingText={"Loading"} />
        <DrawerNavigator
          navigation={navigationRef}
          options={{
            fn_update: forceUpdate,
            var_isLargeScreen: isLargeScreen
          }}
        />
      </NavigationContainer>
    </SafeAreaProvider>
  )
}

// export default AppNavigator

const RenderSpinnerOverlay = ({ renderSpinner, loadingText }: any) => {
  if (!renderSpinner) return <></>
  return (
    <Layout style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1000, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.5)' }}>
      <Spinner size='giant' />
      <Text>{loadingText || ""}</Text>
    </Layout>
  )
}