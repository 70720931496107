import React, { useState } from 'react';
import { View } from 'react-native';
import { Card, Input, Text, Toggle } from '@ui-kitten/components';
import { JobOptions, CompanyJobRoadsideOptions, CompanyJobTowingOptions, CompanyJobTransportOptions } from '../../models/Company.model';

interface JobOptionsProps {
    jobOptions: JobOptions;
    onUpdateJobOptions: (updatedOptions: JobOptions) => void;
    style?: any;
}

export const JobOptionsComponent: React.FC<JobOptionsProps> = ({
    jobOptions,
    onUpdateJobOptions,
    style = {}
}) => {
    const updateJobOption = (key: keyof JobOptions, value: boolean) => {
        const updatedOptions = {
            ...jobOptions,
            [key]: value
        };
        onUpdateJobOptions(updatedOptions);
    };

    const updateTowingOption = (key: keyof CompanyJobTowingOptions, value: string) => {
        // Only update if value is a valid number or empty string
        const numValue = parseFloat(value);
        if (!isNaN(numValue)) {
            const updatedOptions = {
                ...jobOptions,
                towingOptions: {
                    ...jobOptions.towingOptions,
                    [key]: numValue
                }
            };
            onUpdateJobOptions(updatedOptions);
        }
    };

    const RenderToggle = (label: string, key: keyof JobOptions) => (
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
            <Text category='s1'>{label}</Text>
            <Toggle
                checked={Boolean(jobOptions[key])}
                onChange={(nextChecked) => updateJobOption(key, nextChecked)}
            />
        </View>
    );

    return (
        <View style={{ flex: 1 }}>
            <Card style={[style, { flex: 1 }]} disabled header={() => <Text category='h6'>Job Options</Text>} status="basic">
                <View style={{ flexDirection: 'column' }}>
                    {RenderToggle('Show Roadside Job Options', 'showRoadsideJobOptions')}
                    {RenderToggle('Show Transport Job Options', 'showTransportJobOptions')}
                    {RenderToggle('Show Towing Job Options', 'showTowingJobOptions')}
                </View>
            </Card>

            {jobOptions.showTowingJobOptions && (
                <Card style={[style, { flex: 1, marginTop: 10 }]} disabled header={() => <Text category='h6'>Towing Options</Text>} status="basic">
                    <View style={{ flexDirection: 'column' }}>
                        <TowingOptionInput
                            label="Default Cost Per KM (in $)"
                            value={jobOptions.towingOptions.default_cost_per_km}
                            onChange={(value) => updateTowingOption('default_cost_per_km', value)}
                        />
                        <TowingOptionInput
                            label="Default Covered Distance KM"
                            value={jobOptions.towingOptions.default_covered_distance_km}
                            onChange={(value) => updateTowingOption('default_covered_distance_km', value)}
                        />
                    </View>
                </Card>
            )}
        </View>
    );
};

interface TowingOptionInputProps {
    label: string;
    value: number;
    onChange: (value: string) => void;
}

const TowingOptionInput = ({ label, value, onChange }: TowingOptionInputProps) => {
    const [localValue, setLocalValue] = useState(value.toString());
    const [isError, setIsError] = useState(false);

    const handleChange = (nextValue: string) => {
        setLocalValue(nextValue);
        setIsError(isNaN(parseFloat(nextValue)));
        onChange(nextValue);
    };

    return (
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
            <Text category='s1'>{label}</Text>
            <View style={{ maxWidth: 100 }}>
                <Input
                    style={{ width: '100%' }}
                    textStyle={{ maxWidth: 80 }}
                    keyboardType="numeric"
                    defaultValue={localValue}
                    onChangeText={(value) => setLocalValue(value)}
                    onBlur={() => handleChange(localValue)}
                    status={isError ? 'danger' : 'basic'}
                    caption={isError ? 'Please enter a valid number' : ''}
                />
            </View>
        </View>
    );
};