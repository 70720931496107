import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import {
    Image,
    StyleSheet,
    TouchableOpacity,
    View,
    ScrollView,
    FlatList,
    useWindowDimensions,
} from "react-native";
import {
    Button,
    Icon,
    Input,
    Text,
    List,
    ListItem,
    Card,
    Select,
    SelectItem,
} from "@ui-kitten/components";
import * as StorageController from '../../../functions/storageController';
import * as GoogleController from '../../../functions/google.controller';
import AddressSearch from '../../job/details/addressSearch.component';
import { JobAddress } from '../../../models/Job.model';
import { CompanySavedAddress, CompanyAddressBookCategory, CompanyAddressBookSettings } from '../../../models/Company.model';
import { JOB_LOCATION_TYPE } from '../../../models/Job.model';
import DMModal from '../../common/Modal';
import { LocationDetailsCard } from '../../job/details/locationDetailsCard.component';
//@ts-ignore
import { SketchPicker, CirclePicker, SwatchesPicker, SliderPicker, ChromePicker } from 'react-color'

const useInputState = (initialValue = '') => {
    const [value, setValue] = useState(initialValue);
    return { value, onChangeText: setValue };
};

interface AddressDetailsCardProps {
    style?: any;
    onSetAddress: (address: JobAddress) => void;
    onSetLocation: (location: any) => void;
    onSetAddressType?: (type: JOB_LOCATION_TYPE) => void;
    onSetSavedAddress?: (savedAddress: CompanySavedAddress) => void;
    address: JobAddress;
    addressDisplayValue: string;
    allowEdit?: boolean;
    collapsed?: boolean;
    addressName?: string;
    onToggleCollapse?: (collapsed: boolean) => void;
}

export const AddressDetailsCard = ({
    style = {},
    onSetAddress = (j) => { },
    onSetLocation = (j) => { },
    onSetAddressType = () => { },
    onSetSavedAddress = () => { },
    address = new JobAddress(),
    addressDisplayValue = "",
    allowEdit = true,
    collapsed = false,
    addressName = "Address",
    onToggleCollapse = () => { }
}: AddressDetailsCardProps) => {
    const isLocalClient = StorageController.getAppState().selectedMembership?.is_client;
    const [isCollapsed, setIsCollapsed] = useState(collapsed);
    const [editAddress, setEditAddress] = useState(false);
    const [companyAddressBook, setCompanyAddressBook] = useState([] as CompanySavedAddress[]);
    const [showCompanyAddressBook, setShowCompanyAddressBook] = useState(false);

    const unitNumberInputState = useInputState(address.unit_number);
    const streetNumberInputState = useInputState(address.street_number);
    const streetNameInputState = useInputState(address.street_name);
    const suburbInputState = useInputState(address.suburb);
    const cityInputState = useInputState(address.city);
    const postCodeInputState = useInputState(address.post_code);
    const stateInputState = useInputState(address.state);
    const countryInputState = useInputState(address.country);
    const descriptionInputState = useInputState(address.description);

    useEffect(() => {
        const company = StorageController.getCurrentCompany();
        if (company) {
            setCompanyAddressBook(company.settings.address_book || []);
        }
    }, []);

    useEffect(() => {
        unitNumberInputState.onChangeText(address.unit_number);
        streetNumberInputState.onChangeText(address.street_number);
        streetNameInputState.onChangeText(address.street_name);
        suburbInputState.onChangeText(address.suburb);
        cityInputState.onChangeText(address.city);
        postCodeInputState.onChangeText(address.post_code);
        stateInputState.onChangeText(address.state);
        countryInputState.onChangeText(address.country);
        descriptionInputState.onChangeText(address.description);
    }, [address]);

    useEffect(() => {
        setIsCollapsed(collapsed);
    }, [collapsed]);

    const onBlur = () => {
        const _address = new JobAddress({
            unit_number: unitNumberInputState.value,
            street_number: streetNumberInputState.value,
            street_name: streetNameInputState.value,
            suburb: suburbInputState.value,
            city: cityInputState.value,
            post_code: postCodeInputState.value,
            state: stateInputState.value,
            country: countryInputState.value,
            description: descriptionInputState.value
        });
        onSetAddress(_address);
    };

    const toggleCollapse = () => {
        const newCollapsed = !isCollapsed;
        onToggleCollapse(newCollapsed);
        setIsCollapsed(newCollapsed);
    };

    const styles = StyleSheet.create({
        addressDetailComponent: {
            flexBasis: '50%',
            borderBottomWidth: 1,
        },
        collapsedAddressDetailComponent: {
            flex: 1,
            borderBottomWidth: 1
        },
        collapsibleHeader: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        }
    });

    const Header = () => (
        <View style={styles.collapsibleHeader}>
            <Text style={{ alignSelf: 'center' }}>{addressName}</Text>
            {!isLocalClient &&
                <Button
                    appearance='outline'
                    size='tiny'
                    status='info'
                    onPress={() => setShowCompanyAddressBook(true)}
                >
                    <>
                        <Icon name='book-open-outline' width={20} height={20} fill="white" />
                        <Text category='p1' style={{ fontSize: 12 }}>Open Address Book</Text>
                    </>
                </Button>
            }
            <Button
                onPress={toggleCollapse}
                appearance='outline'
                size='tiny'
                status='basic'
            >
                {isCollapsed ?
                    <Icon name='arrow-ios-downward-outline' width={20} height={20} fill="white" />
                    :
                    <Icon name='arrow-ios-upward-outline' width={20} height={20} fill="white" />
                }
            </Button>
        </View>
    );

    return (
        <Card
            status="success"
            header={Header}
            disabled={true}
            style={style}
        >
            <AddressBookModal
                showCompanyAddressBook={showCompanyAddressBook}
                setShowCompanyAddressBook={setShowCompanyAddressBook}
                companyAddressBook={companyAddressBook}
                onSetSavedAddress={onSetSavedAddress}
                onSetAddress={onSetAddress}
                onSetLocation={onSetLocation}
            />
            <View style={{ margin: -15 }}>
                {(!isCollapsed && allowEdit) &&
                    <AddressSearch
                        displayValue={addressDisplayValue}
                        onSetAddress={onSetAddress}
                        allowEdit={true}
                        onSetLocation={onSetLocation}
                    />
                }

                {isCollapsed &&
                    <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                        <View style={{ flexDirection: 'column', flex: 10 }}>
                            <View style={{ flexDirection: 'row' }}>
                                <View style={styles.collapsedAddressDetailComponent}>
                                    <Text category='label'>Address:</Text>
                                    <Text style={{ fontSize: 12 }} numberOfLines={1} adjustsFontSizeToFit>
                                        <Icon name="pin-outline" width={15} height={15} fill='white' />
                                        {GoogleController.addressBuilder(address)}
                                    </Text>
                                    <Text style={{ fontSize: 12 }} numberOfLines={1} adjustsFontSizeToFit>
                                        <Icon name="smiling-face-outline" width={15} height={15} fill='white' />
                                        {address.description}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                }

                {(!isCollapsed && editAddress) &&
                    <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', gap: 3 }}>
                        <Button
                            style={{ position: 'absolute', right: 0, top: 0, zIndex: 10 }}
                            onPress={() => setEditAddress(false)}
                            appearance='ghost'
                            size='tiny'
                            status='warning'
                            accessoryLeft={(props: any) => <Icon {...props} name="edit-outline" />}
                        />
                        <Input
                            size='small'
                            placeholder='Unit Number'
                            label='Unit Number'
                            onBlur={onBlur}
                            {...unitNumberInputState}
                        />
                        <Input
                            size='small'
                            placeholder='Street Number'
                            label='Street Number'
                            onBlur={onBlur}
                            {...streetNumberInputState}
                        />
                        <Input
                            size='small'
                            placeholder='Street Name'
                            label='Street Name'
                            onBlur={onBlur}
                            {...streetNameInputState}
                        />
                        <Input
                            size='small'
                            placeholder='Suburb'
                            label='Suburb'
                            onBlur={onBlur}
                            {...suburbInputState}
                        />
                        <Input
                            size='small'
                            placeholder='City'
                            label='City'
                            onBlur={onBlur}
                            {...cityInputState}
                        />
                        <Input
                            size='small'
                            placeholder='Postal / Zip code'
                            label='Postal / Zip code'
                            onBlur={onBlur}
                            {...postCodeInputState}
                        />
                        <Input
                            size='small'
                            placeholder='State'
                            label='State'
                            onBlur={onBlur}
                            {...stateInputState}
                        />
                        <Input
                            size='small'
                            placeholder='Country'
                            label='Country'
                            onBlur={onBlur}
                            {...countryInputState}
                            style={{ paddingLeft: 24, paddingRight: 24 }}
                        />
                        <Input
                            size='small'
                            placeholder='Description'
                            label='Description'
                            onBlur={onBlur}
                            {...descriptionInputState}
                            style={{ paddingLeft: 24, paddingRight: 24 }}
                        />
                    </View>
                }

                {(!isCollapsed && !editAddress) &&
                    <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                        <Button
                            style={{ position: 'absolute', right: 0, top: 0, zIndex: 10 }}
                            onPress={() => setEditAddress(true)}
                            appearance='ghost'
                            size='tiny'
                            status='warning'
                            disabled={!allowEdit}
                            accessoryLeft={(props: any) => <Icon {...props} name="edit-outline" />}
                        />
                        <View style={{ flexDirection: 'column', flex: 1 }}>
                            <View style={styles.addressDetailComponent}>
                                <Text category='label'>Address:</Text>
                                <Text>{address.unit_number && address.unit_number + "/"}{address.street_number + " "}{address.street_name}</Text>
                            </View>
                            <View style={{ flexDirection: 'row' }}>
                                <View style={styles.addressDetailComponent}>
                                    <Text category='label'>Suburb: </Text>
                                    <Text>{address.suburb}</Text>
                                </View>
                                <View style={styles.addressDetailComponent}>
                                    <Text category='label'>City: </Text>
                                    <Text>{address.city}</Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row' }}>
                                <View style={styles.addressDetailComponent}>
                                    <Text category='label'>Post Code: </Text>
                                    <Text>{address.post_code}</Text>
                                </View>
                                <View style={styles.addressDetailComponent}>
                                    <Text category='label'>State: </Text>
                                    <Text>{address.state}</Text>
                                </View>
                            </View>
                            <View style={styles.addressDetailComponent}>
                                <Text category='label'>Description: </Text>
                                <Text>{address.description}</Text>
                            </View>
                        </View>
                    </View>
                }
            </View>
        </Card>
    );
};

interface AddressBookModalProps {
    showCompanyAddressBook: boolean;
    setShowCompanyAddressBook: (value: boolean) => void;
    companyAddressBook: CompanySavedAddress[];
    onSetAddress?: (value: JobAddress) => void;
    onSetLocation?: (value: any) => void;
    onSetAddressType?: (value: JOB_LOCATION_TYPE) => void;
    onSetSavedAddress?: (value: CompanySavedAddress) => void;
}

const AddressBookModal = ({
    showCompanyAddressBook,
    setShowCompanyAddressBook,
    companyAddressBook,
    onSetAddress,
    onSetLocation,
    onSetAddressType,
    onSetSavedAddress
}: AddressBookModalProps) => {
    const { width, height } = useWindowDimensions();
    const [filteredAddresses, setFilteredAddresses] = useState(companyAddressBook);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
    const company = StorageController.getCurrentCompany();
    const categories = company?.settings?.address_book_settings?.categories || [];

    useEffect(() => {
        setFilteredAddresses(companyAddressBook);
    }, [companyAddressBook]);

    const fuzzySearchAddress = (search: string) => {
        if (search.length == 0) {
            filterAddresses('', selectedCategory);
            return;
        }
        filterAddresses(search, selectedCategory);
    };

    const filterAddresses = (search: string, categoryId: string | null) => {
        let filtered = companyAddressBook;

        // Apply category filter
        if (categoryId) {
            filtered = filtered.filter(item => item.category === categoryId);
        }

        // Apply search filter
        if (search) {
            filtered = filtered.filter((item) => {
                const addrObj = new JobAddress(item.address);
                const addrString = addrObj.addressToString() + " " + addrObj.description + " " + item.name;
                return addrString.toLowerCase().includes(search.toLowerCase());
            });
        }

        setFilteredAddresses(filtered);
    };

    const handleCategorySelect = (categoryId: string | null) => {
        setSelectedCategory(categoryId);
        filterAddresses(searchQuery, categoryId);
    };

    const handleUseAddress = (address: CompanySavedAddress) => {
        if (!address.address.formatted_address || address.address.formatted_address == "") {
            let addrObj = new JobAddress(address.address);
            address.address.formatted_address = addrObj.addressToString();
        }
        onSetAddress && onSetAddress(address.address);
        onSetLocation && onSetLocation(address.location);
        onSetSavedAddress && onSetSavedAddress(address);
        setShowCompanyAddressBook(false);
    };

    const IS_SMALL_SCREEN = width < 768;

    return (
        <DMModal
            visible={showCompanyAddressBook}
            onClose={() => setShowCompanyAddressBook(false)}
        >
            <Card
                disabled
                status='info'
                header={() =>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text>Company Address Book</Text>
                        <Button
                            style={{ position: 'absolute', right: 0, top: 0, zIndex: 10 }}
                            onPress={() => setShowCompanyAddressBook(false)}
                            appearance='filled'
                            size='tiny'
                            status='danger'
                            accessoryLeft={(props: any) => <Icon {...props} name="close-outline" />}
                        />
                    </View>
                }
                style={{ minWidth: IS_SMALL_SCREEN ? width * 0.9 : width * 0.5, maxWidth: IS_SMALL_SCREEN ? width * 0.9 : width * 0.9, height: height * 0.7 }}
            >
                <View style={{ minWidth: IS_SMALL_SCREEN ? width * 0.9 : width * 0.5, maxWidth: IS_SMALL_SCREEN ? width * 0.9 : width * 0.9, height: height * 0.7, flexDirection: 'row' }}>
                    {!IS_SMALL_SCREEN &&
                        <View style={{ width: 200, borderRightWidth: 1, borderColor: 'gray', padding: 10 }}>
                            <Text category="h6">Categories</Text>
                            <List
                                data={[{ _id: null, name: 'All', color: '#808080' }, ...categories]}
                                renderItem={({ item }) => (
                                    <ListItem
                                        title={item.name}
                                        onPress={() => handleCategorySelect(item._id)}
                                        style={selectedCategory === item._id ? { backgroundColor: 'rgba(128, 128, 128, 0.2)' } : {}}
                                        accessoryLeft={(props) => (
                                            <View
                                                style={{
                                                    width: 20,
                                                    height: 20,
                                                    backgroundColor: item.color,
                                                    borderRadius: 10
                                                }}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </View>
                    }

                    {/* Addresses Panel */}
                    <View style={{ flex: 1, padding: 10 }}>
                        <Input
                            placeholder='Search Address Book'
                            onChangeText={(value: string) => {
                                setSearchQuery(value);
                                fuzzySearchAddress(value);
                            }}
                            style={{ marginBottom: 10 }}
                        />
                        {companyAddressBook.length == 0 &&
                            <View style={{ flexDirection: 'column' }}>
                                <Text style={{ alignSelf: 'center', color: 'grey' }}>No addresses found</Text>
                                <Text style={{ alignSelf: 'center', color: 'grey' }}>Add addresses in company settings</Text>
                            </View>
                        }
                        <List
                            data={filteredAddresses}
                            ItemSeparatorComponent={() => <View style={{ height: 1, backgroundColor: 'grey' }}></View>}
                            renderItem={({ item }: { item: CompanySavedAddress }) => {
                                const addrObj = new JobAddress(item.address);
                                const category = categories.find(c => c._id === item.category);
                                const title = `${item.name} ${category ? `- ${category.name}` : ''}`;
                                return (
                                    <ListItem
                                        onPress={() => { console.log(item) }}
                                        title={() => <View style={{ flexDirection: 'row', gap: 10 }}>
                                            <Text status="primary" category="s1">{category?.name || ""}</Text>
                                            <Text category="s1">{item.name}</Text>
                                            <Text status="info" category="s1">{item.type ? item.type : ""}</Text>
                                        </View>}
                                        description={() => <View style={{ flexDirection: 'row', gap: 10 }}>
                                            <Text category="c1">{addrObj.addressToString()}</Text>
                                            <Text category="c1">{item.address.description}</Text>
                                        </View>}
                                        accessoryLeft={(props: any) => <Icon {...props} fill={category?.color || 'orange'} name='pin-outline' />}
                                        accessoryRight={(props: any) =>
                                            <Button
                                                appearance='outline'
                                                size='tiny'
                                                status='info'
                                                onPress={() => handleUseAddress(item)}
                                            >
                                                Use
                                            </Button>
                                        }
                                    />
                                );
                            }}
                        />
                    </View>
                </View>
            </Card>
        </DMModal >
    );
};

interface SavedAddressesProps {
    company: any;
    onSave?: (companySavedAddresses: CompanySavedAddress[], companyAddressBookSettings: CompanyAddressBookSettings) => void;
    onSelectAddress?: (address: CompanySavedAddress) => void;
    allowEdit?: boolean;
    style?: any;
}

export const SavedAddresses = ({
    company,
    onSave,
    onSelectAddress,
    allowEdit = false,
    style = {}
}: SavedAddressesProps) => {
    const [companyAddressBook, setCompanyAddressBook] = useState<CompanySavedAddress[]>(company?.settings?.address_book || []);
    const [selectedAddress, setSelectedAddress] = useState<CompanySavedAddress | null>(null);
    const [showAddNewAddressModal, setShowAddNewAddressModal] = useState(false);
    const [showEditAddressModal, setShowEditAddressModal] = useState(false);
    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const [categories, setCategories] = useState<CompanyAddressBookCategory[]>(company?.settings?.address_book_settings?.categories || []);
    const [searchQuery, setSearchQuery] = useState('');
    const editingAddressRef = useRef<CompanySavedAddress | null>(null);
    const isAdmin = StorageController.getAppState().selectedMembership?.is_admin;
    const [editingCategory, setEditingCategory] = useState<CompanyAddressBookCategory | null>(null);
    const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(null);

    const [enableEdit, setEnableEdit] = useState(allowEdit);

    useEffect(() => {
        setCompanyAddressBook(company?.settings.address_book || []);
        setCategories(company?.settings?.address_book_settings?.categories || []);
    }, [company]);

    const handleDelete = (address: CompanySavedAddress) => {
        const newAddresses = companyAddressBook.filter(a => a._id !== address._id);
        setCompanyAddressBook(newAddresses);
        onSave && onSave(newAddresses, {
            ...company?.settings?.address_book_settings,
            categories
        });
    };

    const handleAddNewAddress = () => {
        editingAddressRef.current = new CompanySavedAddress({});
        setShowAddNewAddressModal(true);
    };

    const handleSaveNew = (address: CompanySavedAddress) => {
        let companyAddresses = [...companyAddressBook, address];
        setCompanyAddressBook(companyAddresses);
        onSave && onSave(companyAddresses, {
            ...company?.settings?.address_book_settings,
            categories
        });
        setShowAddNewAddressModal(false);
    };

    const handleEdit = (address: CompanySavedAddress) => {
        editingAddressRef.current = address;
        setSelectedAddress(address);
        setShowEditAddressModal(true);
    };

    const handleSaveEdit = (address: CompanySavedAddress) => {
        let companyAddresses = [...companyAddressBook];
        const index = companyAddresses.findIndex(a => a._id === address._id);
        companyAddresses[index] = address;
        setCompanyAddressBook(companyAddresses);
        onSave && onSave(companyAddresses, {
            ...company?.settings?.address_book_settings,
            categories
        });
        setShowEditAddressModal(false);
    };

    const handleAddCategory = (category: CompanyAddressBookCategory) => {
        const newCategories = [...categories, category];
        setCategories(newCategories);
        onSave && onSave(companyAddressBook, {
            ...company?.settings?.address_book_settings,
            categories: newCategories
        });
    };

    const handleEditCategory = (category: CompanyAddressBookCategory) => {
        const newCategories = categories.map(c => c._id === category._id ? category : c);
        setCategories(newCategories);
        onSave && onSave(companyAddressBook, {
            ...company?.settings?.address_book_settings,
            categories: newCategories
        });
    };

    const handleDeleteCategory = (categoryId: string) => {
        const newCategories = categories.filter(c => c._id !== categoryId);
        setCategories(newCategories);
        const updatedAddresses = companyAddressBook.map(addr => {
            if (addr.category === categoryId) {
                return { ...addr, category: '' };
            }
            return addr;
        });
        setCompanyAddressBook(updatedAddresses);
        onSave && onSave(updatedAddresses, {
            ...company?.settings?.address_book_settings,
            categories: newCategories
        });
    };

    const handleUseAddress = (address: CompanySavedAddress) => {
        onSelectAddress && onSelectAddress(address);
    };

    const handleEditCategoryFromList = (category: CompanyAddressBookCategory) => {
        setEditingCategory(category);
        setShowCategoryModal(true);
    };

    const filteredAddresses = useMemo(() => {
        let filtered = companyAddressBook;

        // Apply category filter
        if (selectedCategoryId) {
            filtered = filtered.filter(addr => addr.category === selectedCategoryId);
        }

        // Apply search filter
        if (searchQuery) {
            filtered = filtered.filter(addr => {
                const searchStr = `${addr.name} ${addr.address.addressToString()} ${categories.find(c => c._id === addr.category)?.name || ''}`.toLowerCase();
                return searchStr.includes(searchQuery.toLowerCase());
            });
        }

        return filtered;
    }, [companyAddressBook, searchQuery, selectedCategoryId, categories]);

    return (
        <Card
            disabled
            status='info'
            style={[style, {}]}
            header={() => <Text category="h6">Company Address Book</Text>}
        >
            <View style={[{ flex: 1, flexDirection: 'row' }, style]}>
                {/* <View style={{ flexDirection: 'row' }}>
                    <Button size="small" onPress={() => setEnableEdit(!enableEdit)}>
                        {enableEdit ? 'Disable Edit' : 'Enable Edit'}
                    </Button>
                </View> */}
                {/* Left Panel - Categories */}
                <View style={{ width: 200, borderRightWidth: 1, borderColor: 'gray', padding: 10 }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
                        <Text category="h6">Categories</Text>
                        {enableEdit && isAdmin && (
                            <Button
                                size="tiny"
                                status="primary"
                                onPress={() => setShowCategoryModal(true)}
                                accessoryLeft={(props) => <Icon {...props} name='plus-outline' />}
                            />
                        )}
                    </View>
                    <List
                        data={[{ _id: null, name: 'ALL', color: '#808080' }, ...categories]}
                        renderItem={({ item }) => (
                            <ListItem
                                title={item.name}
                                onPress={() => setSelectedCategoryId(item._id)}
                                style={selectedCategoryId === item._id ? { backgroundColor: 'rgba(128, 128, 128, 0.2)' } : {}}
                                accessoryLeft={(props) => (
                                    <View
                                        style={{
                                            width: 20,
                                            height: 20,
                                            backgroundColor: item.color,
                                            borderRadius: 10
                                        }}
                                    />
                                )}
                                accessoryRight={enableEdit && isAdmin && item._id !== null ? (props) => (
                                    <View style={{ flexDirection: 'row', gap: 5 }}>
                                        <Button
                                            size='tiny'
                                            status="primary"
                                            onPress={() => handleEditCategoryFromList(item)}
                                            accessoryLeft={(props) => <Icon {...props} name='edit-outline' />}
                                        />
                                        <Button
                                            size='tiny'
                                            status="danger"
                                            onPress={() => handleDeleteCategory(item._id)}
                                            accessoryLeft={(props) => <Icon {...props} name='trash-2-outline' />}
                                        />
                                    </View>
                                ) : undefined}
                            />
                        )}
                    />
                </View>

                {/* Right Panel - Addresses */}
                <View style={{ flex: 1, padding: 10 }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
                        <Input
                            style={{ flex: 1, marginRight: 10 }}
                            placeholder="Search addresses..."
                            value={searchQuery}
                            onChangeText={setSearchQuery}
                            accessoryLeft={(props) => <Icon {...props} name='search-outline' />}
                        />
                        {enableEdit && (
                            <Button size="small" onPress={handleAddNewAddress}>
                                Add Address
                            </Button>
                        )}
                    </View>

                    {/* <ScrollView> */}
                    <List
                        data={filteredAddresses}
                        ItemSeparatorComponent={() => <View style={{ height: 1, backgroundColor: 'grey' }}></View>}
                        renderItem={({ item }) => {
                            const address = item;
                            const category = categories.find(c => c._id === item.category);
                            return (
                                <View key={address._id}>
                                    <ListItem
                                        title={() =>
                                            <View style={{ flexDirection: 'row', gap: 10 }}>
                                                <Text status='primary' category="s1">{category?.name || ""}</Text>
                                                <Text category="s1">{address.name}</Text>
                                                <Text status="info" category="s1">{address.type ? address.type : ""}</Text>
                                            </View>
                                        }
                                        description={() => <Text category="c1">{`${address.address?.addressToString()}`}</Text>}
                                        accessoryLeft={(props) => <Icon {...props} fill={category?.color || 'orange'} name='pin-outline' />}
                                        accessoryRight={(props) => (
                                            <View style={{ flexDirection: 'row', gap: 5 }}>
                                                {!enableEdit && (
                                                    <Button
                                                        size='tiny'
                                                        status="info"
                                                        onPress={() => handleUseAddress(address)}
                                                    >
                                                        Use
                                                    </Button>
                                                )}
                                                {enableEdit && (
                                                    <>
                                                        <Button
                                                            size='tiny'
                                                            status="primary"
                                                            onPress={() => handleEdit(address)}
                                                            accessoryLeft={(props) => <Icon {...props} name='edit-outline' />}
                                                        />
                                                        {isAdmin && (
                                                            <Button
                                                                size='tiny'
                                                                status="danger"
                                                                onPress={() => handleDelete(address)}
                                                                accessoryLeft={(props) => <Icon {...props} name='trash-2-outline' />}
                                                            />
                                                        )}
                                                    </>
                                                )}
                                            </View>
                                        )}
                                    />
                                </View>
                            );
                        }}
                    />
                    {/* </ScrollView> */}
                </View>

                {/* Modals */}
                {enableEdit && (
                    <>
                        <AddressFormModal
                            showModal={showAddNewAddressModal}
                            setShowModal={setShowAddNewAddressModal}
                            onSave={handleSaveNew}
                            isNewAddress={true}
                            categories={categories}
                        />
                        <AddressFormModal
                            showModal={showEditAddressModal}
                            setShowModal={setShowEditAddressModal}
                            selectedAddress={selectedAddress}
                            onSave={handleSaveEdit}
                            isNewAddress={false}
                            categories={categories}
                        />
                        <CategoryManagementModal
                            visible={showCategoryModal}
                            onClose={() => {
                                setShowCategoryModal(false);
                                setEditingCategory(null);
                            }}
                            categories={categories}
                            onAdd={handleAddCategory}
                            onEdit={handleEditCategory}
                            onDelete={handleDeleteCategory}
                            editingCategory={editingCategory}
                        />
                    </>
                )}
            </View>
        </Card>
    );
};

interface CategoryManagementModalProps {
    visible: boolean;
    onClose: () => void;
    categories: CompanyAddressBookCategory[];
    onAdd: (category: CompanyAddressBookCategory) => void;
    onEdit: (category: CompanyAddressBookCategory) => void;
    onDelete: (categoryId: string) => void;
    editingCategory: CompanyAddressBookCategory | null;
}

const CategoryManagementModal = ({
    visible,
    onClose,
    categories,
    onAdd,
    onEdit,
    onDelete,
    editingCategory: initialEditingCategory
}: CategoryManagementModalProps) => {
    const [editingCategory, setEditingCategory] = useState<CompanyAddressBookCategory | null>(null);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [newCategoryColor, setNewCategoryColor] = useState('#000000');

    useEffect(() => {
        if (initialEditingCategory) {
            setEditingCategory(initialEditingCategory);
            setNewCategoryName(initialEditingCategory.name);
            setNewCategoryColor(initialEditingCategory.color);
        } else {
            setEditingCategory(null);
            setNewCategoryName('');
            setNewCategoryColor('#000000');
        }
    }, [initialEditingCategory]);

    const handleSave = () => {
        if (!newCategoryName.trim()) return;

        if (editingCategory) {
            onEdit({
                ...editingCategory,
                name: newCategoryName,
                color: newCategoryColor
            });
        } else {
            onAdd(new CompanyAddressBookCategory({
                name: newCategoryName,
                color: newCategoryColor
            }));
        }
        resetForm();
    };

    const handleEdit = (category: CompanyAddressBookCategory) => {
        setEditingCategory(category);
        setNewCategoryName(category.name);
        setNewCategoryColor(category.color);
    };

    const resetForm = () => {
        setEditingCategory(null);
        setNewCategoryName('');
        setNewCategoryColor('#000000');
        onClose();
    };

    return (
        <DMModal
            visible={visible}
            onClose={onClose}
        >
            <Card disabled status='info'>
                <View style={{ flexDirection: 'column', gap: 10 }}>
                    <Text category="h6">Manage Categories</Text>
                    <View style={{ flexDirection: 'row', gap: 10 }}>
                        <Input
                            size='small'
                            label="Category Name"
                            placeholder="Enter category name"
                            value={newCategoryName}
                            onChangeText={setNewCategoryName}
                        />
                        <View style={{ flex: 1 }}>
                            <CirclePicker
                                color={newCategoryColor}
                                onChange={(color: any) => setNewCategoryColor(color.hex)}
                            />
                        </View>
                        <Button size="small" onPress={handleSave}>
                            {editingCategory ? 'Update' : 'Add'}
                        </Button>
                    </View>
                </View>
            </Card>
        </DMModal>
    );
};

// Update AddressFormModal to include category selection
interface AddressFormModalProps {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
    selectedAddress?: CompanySavedAddress | null;
    onSave: (address: CompanySavedAddress) => void;
    isNewAddress: boolean;
    categories: CompanyAddressBookCategory[];
}

const AddressFormModal = ({
    showModal,
    setShowModal,
    selectedAddress,
    onSave,
    isNewAddress,
    categories
}: AddressFormModalProps) => {
    const editingAddressRef = useRef<CompanySavedAddress>(
        isNewAddress ? new CompanySavedAddress({}) : new CompanySavedAddress(selectedAddress || {})
    );
    const [address, setAddress] = useState<any>(
        isNewAddress ? new JobAddress({}) : selectedAddress?.address || new JobAddress({})
    );
    const [location, setLocation] = useState<any>(
        isNewAddress ? {} : selectedAddress?.location || {}
    );
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [addressType, setAddressType] = useState<JOB_LOCATION_TYPE>(
        isNewAddress ? JOB_LOCATION_TYPE.ADDRESS : selectedAddress?.type || JOB_LOCATION_TYPE.ADDRESS
    );
    const [selectedCategory, setSelectedCategory] = useState<string>(
        isNewAddress ? '' : selectedAddress?.category || ''
    );

    useEffect(() => {
        if (showModal) {
            editingAddressRef.current = isNewAddress ?
                new CompanySavedAddress({}) :
                new CompanySavedAddress(selectedAddress || {});
            setAddress(isNewAddress ? new JobAddress({}) : selectedAddress?.address || new JobAddress({}));
            setLocation(isNewAddress ? {} : selectedAddress?.location || {});
            setIsError(false);
            setErrorMessage('');
            setAddressType(isNewAddress ? JOB_LOCATION_TYPE.ADDRESS : selectedAddress?.type || JOB_LOCATION_TYPE.ADDRESS);
            setSelectedCategory(isNewAddress ? '' : selectedAddress?.category || '');
        }
    }, [showModal, selectedAddress, isNewAddress]);

    const handleSave = () => {
        if (!editingAddressRef.current.name || editingAddressRef.current.name.length < 1) {
            setIsError(true);
            setErrorMessage("Name is required");
            return;
        }
        if (!editingAddressRef.current.address) {
            setIsError(true);
            setErrorMessage("Address is required");
            return;
        }
        editingAddressRef.current.category = selectedCategory;
        onSave(editingAddressRef.current);
    };

    const renderAddressTypeButtons = () => (
        <View style={{ flexDirection: 'row', gap: 5 }}>
            {Object.values(JOB_LOCATION_TYPE).map((type) => (
                <Button
                    size='tiny'
                    key={type}
                    onPress={() => {
                        setAddressType(type);
                        editingAddressRef.current.type = type;
                    }}
                    appearance={addressType === type ? 'filled' : 'outline'}
                >
                    {type.toUpperCase()}
                </Button>
            ))}
        </View>
    );

    return (
        <DMModal
            visible={showModal}
            onClose={() => setShowModal(false)}
        >
            <Card disabled={true} status={isNewAddress ? 'success' : 'warning'}>
                <View style={{ flexDirection: 'column', gap: 10 }}>
                    {isError && <Text status="danger">{errorMessage}</Text>}
                    <Text category="h6">{isNewAddress ? 'Add New Address' : 'Edit Address'}</Text>
                    <View style={{ flexDirection: 'row', gap: 10 }}>
                        <View style={{ flex: 3 }}>
                            <Input
                                size='small'
                                label="Name"
                                placeholder="Home"
                                defaultValue={editingAddressRef.current?.name}
                                onChangeText={(nextValue: string) => {
                                    editingAddressRef.current.name = nextValue;
                                }}
                            />
                        </View>
                        <View style={{ flex: 1 }}>
                            <Select
                                size='small'
                                label="Category"
                                value={categories.find(c => c._id === selectedCategory)?.name || 'Select Category'}
                                onSelect={(index: any) => {
                                    setSelectedCategory(categories[index.row]._id);
                                }}
                            >
                                {categories.map(category => (
                                    <SelectItem
                                        key={category._id}
                                        title={category.name}
                                        accessoryLeft={(props) => (
                                            <View
                                                style={{
                                                    width: 20,
                                                    height: 20,
                                                    backgroundColor: category.color,
                                                    borderRadius: 10,
                                                    marginRight: 10
                                                }}
                                            />
                                        )}
                                    />
                                ))}
                            </Select>
                        </View>
                        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                            {renderAddressTypeButtons()}
                        </View>
                    </View>
                    <LocationDetailsCard
                        address={address}
                        location={location}
                        onSetAddress={(address: any) => {
                            setAddress(new JobAddress(address));
                            editingAddressRef.current.address = new JobAddress(address);
                        }}
                        onSetLocation={(location: any) => {
                            editingAddressRef.current.location = location;
                            setLocation(location);
                        }}
                        searchDisplayValue={GoogleController.addressBuilder(address)}
                        allowEdit={true}
                    />
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Button status='success' onPress={() => handleSave()}>Save</Button>
                        <Button appearance='ghost' status='danger' onPress={() => setShowModal(false)}>Cancel</Button>
                    </View>
                </View>
            </Card>
        </DMModal>
    );
}; 